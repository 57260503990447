<template>
  <TransferList />
</template>

<script>
import TransferList from "@/packages/contact-us/components/list/Index.vue";

export default {
  components: { TransferList },
};
</script>
