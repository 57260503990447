<template>
  <b-container fluid class="px-0">
    <!--    <transition name="fade" mode="out-in">-->
    <router-view />
    <!--    </transition>-->
  </b-container>
</template>

<script>
export default {
  name: "Content",
};
</script>
