<template>
  <b-row
    no-gutters
    class="text-right pt-2 fs-11 ss02 font-weight-bold d-flex justify-start align-content-start px-2"
  >
    <b-col cols="6" class="d-flex justify-content-start align-items-start">
      <span> مهر و امضا فروشنده : </span>
      <img
        width="175"
        height="175"
        class="stamp"
        :src="require('@/assets/img/logo/stamp.png')"
        alt=""
      />
    </b-col>
    <b-col cols="6">
      <span> مهر و امضا خریدار : </span>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "InvoiceFooter",
};
</script>

<style scoped></style>
