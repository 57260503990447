export const CANCELED_ORDERS_FILTER = {
  /* Form SCHEMA */
  schema: [
    {
      id: "shippingSettingId",
      cols: 12,
      lg: 3,
      md: 12,
      sm: 12,
      type: "select",
      options: [],
      defaultOptionDisabled: false,
      required: false,
    },
    {
      id: "fromDate",
      cols: 12,
      lg: 3,
      md: 12,
      sm: 12,
      type: "date",
      required: false,
    },
    {
      id: "toDate",
      cols: 12,
      lg: 3,
      md: 12,
      sm: 12,
      type: "date",
      required: false,
    },
  ],

  /* Form MODEL */
  model: {
    shippingSettingId: "",
    fromDate: "",
    toDate: "",
  },

  /* VALIDATION SCHEMA */
  validations: {
    form: {
      shippingSettingId: {},
      fromDate: {},
      toDate: {},
    },
  },
};
