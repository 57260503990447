<template>
  <b-row no-gutters class="mb-3 ss02 fs-11 font-weight-bold">
    <b-col cols="3" class="mr-1 font-weight-bold">
      <span> شماره: </span>
      <span> {{ orderSepidarId }} </span>
    </b-col>
    <b-col cols="3" class="mr-1">
      <span> شماره سفارش: </span>
      <span> {{ orderCode }} </span> </b-col
    ><b-col cols="3">
      <span> تاریخ: </span>
      <span> {{ $_date(orderDate) }} </span>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "OrderTopDetail",
  props: {
    orderDate: {
      type: String,
      required: true,
    },
    orderCode: {
      type: Number,
      required: true,
    },
    orderSepidarId: {
      type: [Number, String],
      required: true,
    },
  },
};
</script>

<style scoped></style>
