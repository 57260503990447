import { fetchAllCustomersService } from "@/packages/orders/services/customer.service";

export const RETURNED_ORDERS_FILTER = {
  schema: [
    {
      id: "fromOrderDate",
      cols: "12",
      lg: "3",
      type: "date",
      title: "از تاریخ ثبت سفارش",
    },
    {
      id: "toOrderDate",
      cols: "12",
      lg: "3",
      type: "date",
      title: "تا تاریخ ثبت سفارش",
    },
    {
      id: "fromReturnedDate",
      cols: "12",
      lg: "3",
      type: "date",
      title: "از تاریخ ثبت مرجوعی",
    },
    {
      id: "toReturnedDate",
      cols: "12",
      lg: "3",
      type: "date",
      title: "تا تاریخ ثبت مرجوعی",
    },
    {
      cols: "12",
      lg: "3",
      id: "username",
      placeholder: "username",
      options: [],
      default_option_disabled: false,
      type: "select",
      customAutoComplete: true,
      parentValues: [],
      hasAction: true,
      doAction: async function (query_params = null) {
        return await fetchAllCustomersService(query_params);
      },
    },
    {
      id: "orderId",
      cols: "12",
      lg: "3",
      type: "text",
      title: "کد سفارش",
    },
    {
      id: "returnedId",
      cols: "12",
      lg: "3",
      type: "text",
      title: "کد مرجوعی",
    },
    {
      id: "state",
      cols: "12",
      lg: "3",
      title: "وضعیت",
      type: "select",
      default_option_disabled: false,
      options: [
        { title: "ثبت اولیه", value: "RETURNED_BY_CUSTOMER" },
        { title: "تایید پشتیبانی", value: "SUPPORT_CONFIRMED" },
        { title: "تحویل کنترل کیفیت", value: "QC_DELIVERED" },
        { title: "تایید کنترل کیفیت", value: "QC_CONFIRMED" },
        { title: "لغو شده توسط مشتری", value: "CANCEL_BY_CUSTOMER" },
        { title: "لغو شده توسط پشتیبانی", value: "CANCEL_BY_SUPPORT" },
      ],
    },
    {
      id: "isComplete",
      cols: "12",
      lg: "3",
      title: "وضعیت بسته ‌شدن مرجوعی",
      placeholder: "isComplete",
      type: "select",
      defaultOptionDisabled: false,
      options: [
        { title: "بسته شده", value: "true" },
        { title: "بسته نشده", value: "false" },
      ],
    },
  ],
  model: {
    fromOrderDate: "",
    toOrderDate: "",
    fromReturnedDate: "",
    toReturnedDate: "",
    username: "",
    usernameSearch: "",
    orderId: "",
    returnedId: "",
    state: "",
    isComplete: "",
  },
  validations: {
    form: {
      fromOrderDate: {},
      toOrderDate: {},
      fromReturnedDate: {},
      toReturnedDate: {},
      username: {},
      usernameSearch: {},
      orderId: {},
      returnedId: {},
      state: {},
      isComplete: {},
    },
  },
};
