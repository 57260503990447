"use strict";
import { _t } from "@/tools/Utils";

export default [
  {
    path: "/panel/auth/change-password",
    name: "panel.change-password",
    component: () =>
      import(
        /* webpackChunkName: "auth-pkg" */ "@/packages/change-password/presentation/views/ChangePasswordInPanel"
      ),
    meta: {
      requiresAuth: true,
      title: _t("route_titles.login.title"),
    },
  },
];
