import {
  BContainer,
  BRow,
  BCol,
  BSpinner,
  BTable,
  BFormGroup,
  BForm,
  BButton,
  BModal,
  VBModal,
  BFormSelect,
  BPagination,
  BAlert,
  BTh,
  BTr,
  BSkeletonWrapper,
  BSkeletonTable,
  BSkeletonImg,
  BSkeleton,
  BCard,
  BAvatar,
  BIconChevronCompactDown,
  BFormInput,
  BCardFooter,
  VBHover,
  BNav,
  BNavItem,
  BTabs,
  BTab,
  BFormRadioGroup,
  BFormRadio,
  BCardText,
  BIconShieldLock,
  BFormTextarea,
  BImg,
  TablePlugin,
  BFormCheckbox,
  BFormFile,
  BSidebar,
  VBToggle,
  BOverlay,
} from "bootstrap-vue";

export function initBootstrapVue(vue) {
  vue.use(TablePlugin);
  vue.component("b-container", BContainer);
  vue.component("b-row", BRow);
  vue.component("b-col", BCol);
  vue.component("b-spinner", BSpinner);
  vue.component("b-alert", BAlert);
  vue.component("b-table", BTable);
  vue.component("b-button", BButton);
  vue.component("b-form-select", BFormSelect);
  vue.component("b-pagination", BPagination);
  vue.component("b-form", BForm);
  vue.component("b-form-group", BFormGroup);
  vue.component("b-modal", BModal);
  vue.component("b-th", BTh);
  vue.component("b-tr", BTr);
  vue.component("b-skeleton-wrapper", BSkeletonWrapper);
  vue.component("b-skeleton-table", BSkeletonTable);
  vue.directive("b-modal", VBModal);
  vue.component("b-card", BCard);
  vue.component("b-avatar", BAvatar);
  vue.component("b-icon-chevron-compact-down", BIconChevronCompactDown);
  vue.component("b-form-input", BFormInput);
  vue.component("b-card-footer", BCardFooter);
  vue.component("b-nav", BNav);
  vue.component("b-nav-item", BNavItem);
  vue.component("b-skeleton-img", BSkeletonImg);
  vue.directive("b-hover", VBHover);
  vue.component("b-tabs", BTabs);
  vue.component("b-tab", BTab);
  vue.component("b-skeleton", BSkeleton);
  vue.component("b-form-radio-group", BFormRadioGroup);
  vue.component("b-form-radio", BFormRadio);
  vue.component("b-card-text", BCardText);
  vue.component("b-icon-shield-lock", BIconShieldLock);
  vue.component("b-textarea", BFormTextarea);
  vue.component("b-checkbox", BFormCheckbox);
  vue.component("b-file", BFormFile);
  vue.component("b-img", BImg);
  vue.component("b-sidebar", BSidebar);
  vue.directive("b-toggle", VBToggle);
  vue.component("b-overlay", BOverlay);
}
