<template>
  <b-card
    class="bg-grey-light text-start d-flex justify-content-start border-0 card-rounded p-lg-3 mt-4 fa-num-font"
  >
    <div
      class="d-flex justify-content-start align-items-center text-more font-weight-bold mt-2 my-2 fs-18"
    >
      <p
        class="bg-primary rounded-pill ml-2 mt-2"
        style="width: 10px; height: 10px"
      ></p>
      اطلاعات ارسال
    </div>
    <b-row
      class="list-info d-block d-lg-flex justify-content-start list-unstyled w-100 px-lg-2 px-0 mx-0 mb-0 mt-lg-4"
    >
      <template v-for="item in innerDeliveryDetail">
        <b-col
          class="text-right d-flex flex-wrap d-lg-block pt-4 pt-lg-0 px-0 px-lg-3 pb-lg-5"
          :cols="item.col"
          :md="item.md"
          :lg="item.lg"
          :xl="item.xl"
          :key="item.index"
          v-if="item.show"
        >
          <div
            class="d-flex flex-nowrap align-items-center fs-15 font-weight-bold text-secondary pb-2 pl-1 pl-lg-0"
          >
            {{ item.title }}:
          </div>

          <div
            v-if="item.key === 'price'"
            class="fs-17 font-weight-bold text-secondary pb-2 pt-lg-2"
          >
            {{ $_currency(item.value) }} ریال
          </div>
          <div
            v-else
            class="fs-17 font-weight-bold text-secondary pb-2 pt-lg-2"
          >
            {{ item.value }}
          </div>
        </b-col>
      </template>
    </b-row>
  </b-card>
</template>

<script>
export default {
  name: "PostInfo",

  props: {
    deliveryDetail: {
      type: Object,
      required: true,
    },
    hasDeliveryCode: {
      type: Object,
      required: true,
    },
  },
  computed: {
    innerDeliveryDetail() {
      return [
        {
          title: "روش ارسال",
          value: this.deliveryDetail.shippingMethod,
          col: "12",
          md: "4",
          lg: "6",
          xl: "3",
          key: "shippingMethod",
          show: true,
        },
        {
          title: "هزینه ارسال",
          value: Number(this.deliveryDetail.shippingCost),
          col: "12",
          md: "4",
          lg: "6",
          xl: "3",
          key: "price",
          show: true,
        },
        {
          title: "کد رهگیری",
          value: this.deliveryDetail.postalTrackingCode,
          col: "12",
          md: "4",
          lg: "6",
          xl: "3",
          key: "postalTrackingCode",
          show: this.hasDeliveryCode,
        },
        {
          title: "تاریخ تحویل سفارش",
          value: this.deliveryDetail.shippingDate
            ? this.$_date(this.deliveryDetail.shippingDate, "jalali") +
              " - " +
              this.deliveryDetail.shippingShift +
              " - ساعت " +
              this.deliveryDetail.shippingTime
            : null,
          col: "12",
          md: "4",
          lg: "6",
          xl: "3",
          key: "shippingDate",
          show: this.deliveryDetail.shippingDate,
        },
      ];
    },
  },
};
</script>

<style scoped></style>
