<template>
  <ReturnRegister />
</template>

<script>
import ReturnRegister from "@/packages/return-register/components/detail/Index";
export default {
  name: "List.view",
  components: { ReturnRegister },
};
</script>

<style scoped></style>
