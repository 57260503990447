var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex"},_vm._l((_vm.items),function(item,index){return _c('div',{key:item.title,staticClass:"d-flex mx-2",class:{
      'w-100': index !== _vm.items.length - 1,
      'text-primary': _vm.getStatusOfItem(index) === 'active',
      'text-secondary': _vm.getStatusOfItem(index) === 'remained',
      'text-success': _vm.getStatusOfItem(index) === 'completed',
    }},[_c('div',{staticClass:"stepper-count-wrapper ml-2",class:{
        'bg-primary': _vm.getStatusOfItem(index) === 'active',
        'bg-secondary': _vm.getStatusOfItem(index) === 'remained',
        'bg-success': _vm.getStatusOfItem(index) === 'completed',
      }},[_vm._v(" "+_vm._s(index + 1)+" ")]),_c('div',{staticClass:"text-right stepper-title"},[_c('div',[_vm._v(_vm._s(item.title))]),(item.date)?_c('div',{staticClass:"text-center fs-12"},[_vm._v(" "+_vm._s(_vm.$_date(item.date))+" ")]):_vm._e()]),(index !== _vm.items.length - 1)?_c('div',{staticClass:"ml-1 mr-3 mt-2 w-100 stepper-line",class:{
        'stepper-line-completed': _vm.getStatusOfItem(index) === 'completed',
      }}):_vm._e()])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }