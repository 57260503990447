<template>
  <div class="text-right">
    <div class="text-more fs-18 font-weight-bold">وضعیت مرجوعی</div>
    <div class="d-flex">
      <LipakInput
        type="radio"
        v-model="status"
        label="تایید مرجوعی"
        data="APPROVED"
        :disabled="isDisabled"
      />
      <LipakInput
        class="mr-3"
        type="radio"
        v-model="status"
        label="رد مرجوعی"
        data="REJECTED"
        :disabled="isDisabled"
      />
    </div>
    <transition mode="out-in" name="fade">
      <div v-if="item.status === 'REJECTED'">
        <LipakInput
          style="width: 500px"
          type="select"
          :options="rejectReasons"
          placeholder="دلیل رد مرجوعی را انتخاب کنید"
          title="انتخاب دلیل رد مرجوعی"
          v-model="rejectReasonId"
          :disabled="isDisabled"
        />
        <div class="lipak-input-title">دلیل رد مرجوعی</div>
        <b-textarea
          style="width: 100%"
          rows="4"
          placeholder="توضیح رد مرجوعی این آیتم را وارد کنید"
          v-model="rejectDescription"
          :disabled="isDisabled"
        ></b-textarea>
      </div>
    </transition>
    <div v-if="isConfirmed && !isEditing" class="text-left">
      <b-button class="mt-3" variant="info" @click="activeEditing">
        ویرایش
      </b-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: ["item", "rejectReasons"],
  data() {
    return {
      status: this.item.status || "",
      rejectReasonId: this.item.rejectReasonId || "",
      rejectDescription: this.item.rejectDescription || "",
      isEditing: false,
    };
  },
  computed: {
    ...mapGetters({
      state: "returned/state",
      step2Editing: "returned/step2Editing",
    }),
    isConfirmed() {
      return this.state === "SUPPORT_CONFIRMED";
    },
    isDisabled() {
      return this.isConfirmed && !this.isEditing;
    },
  },
  watch: {
    item: {
      deep: true,
      handler(newValue) {
        for (const key in newValue) {
          this[key] = newValue[key];
        }
      },
    },
    status(newValue) {
      if (this.item.status === newValue) return;
      this.setItemForm({
        id: this.item.id,
        status: newValue,
        rejectReasonId: "",
        rejectDescription: "",
      });
    },
    rejectDescription(newValue) {
      this.setItemForm({
        id: this.item.id,
        status: this.item.status,
        rejectReasonId: this.item.rejectReasonId || "",
        rejectDescription: newValue,
      });
    },
    rejectReasonId(newValue) {
      this.setItemForm({
        id: this.item.id,
        status: this.item.status,
        rejectReasonId: newValue,
        rejectDescription: this.item.rejectDescription || "",
      });
    },
    step2Editing(newValue) {
      if (newValue === false) this.isEditing = false;
    },
  },
  methods: {
    ...mapActions({
      setItemForm: "returned/setItemForm",
      setStep2Editing: "returned/setStep2Editing",
    }),
    activeEditing() {
      this.isEditing = true;
      this.setStep2Editing(true);
    },
  },
};
</script>

<style scoped></style>
