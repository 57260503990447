<template>
  <div>
    <b-card
      v-if="skeletonLoader"
      class="rounded-lg shadow border-0 justify-content-center px-1 px-lg-5 mb-3"
    >
      <b-skeleton height="65px"></b-skeleton>
      <b-skeleton height="80px" class="mt-3"></b-skeleton>
      <b-skeleton-table
        no-aspect
        height="150px"
        class="mt-5"
      ></b-skeleton-table>
      <b-skeleton
        v-for="i in 3"
        :key="i.index"
        height="160px"
        class="mt-3"
      ></b-skeleton>
    </b-card>
    <b-card
      v-else
      class="rounded-lg shadow border-0 d-block justify-content-space-between px-1 px-lg-5 mb-3"
    >
      <!---------------start filter receipts-detail-received------------------------------->

      <!---------------------------start personal info buyer ---------------------------->
      <OrderPersonInfo :personal-info="orderDetail.personalInfoDto" />
      <!---------------------------end personal info buyer ------------------------------>

      <!---------------------------start orders info ------------------------------------>
      <OrderFactor
        :id="orderDetail.id"
        :code="String(orderDetail.code)"
        :state="orderDetail.stateFlow.state"
      />
      <!---------------------------end orders info -------------------------------------->

      <Title
        class="title font-weight-bold mt-5"
        title="وضعیت سفارش"
        variant="danger"
      ></Title>

      <!-----------------------------start orders tab ---------------------------------->
      <StepsOfOrder :state-flow="orderDetail.stateFlow" />
      <!-----------------------------end orders tab------------------------------------->

      <b-col class="d-flex justify-content-between align-items-end px-0 mt-5">
        <Title
          class="title font-weight-bold pa-0"
          title="کالای خریداری شده"
          variant="danger"
        ></Title>
      </b-col>

      <!-----------------------------start orders detail table ---------------------------------->
      <OrderDetailTable :order-list="orderDetail.orderItemPages.content" />
      <!-----------------------------start orders detail table----------------------------------->

      <!-----------------------------start info about payment way , sending way ----------------->
      <RecipientDeliveryInfo
        :order-id="orderDetail.id"
        :user-id="orderDetail.personalInfoDto.userId"
        :recipient-delivery-info="orderDetail.userDetailDto"
        :delivery-detail="orderDetail.deliveryDetailsDto"
        :state="orderDetail.stateFlow"
      />

      <PaymentInfo :payment-detail="orderDetail.paymentDetailsDto" />
      <!-----------------------------end info about payment way , sending way-------------------->

      <!-----------------------------start comment ---------------------------------------------->
      <Comment :comment="orderDetail.comment" />
      <!-----------------------------end comment ------------------------------------------------>

      <!-----------------------------start refund info ------------------------------------------>
      <!--      v-if="!orderDetail.refund"-->
      <!--      <RefundInfo :payment-detail="orderDetail.paymentDetailsDto" />-->
      <!-----------------------------end refund info -------------------------------------------->
    </b-card>
  </div>
</template>

<script>
import OrderPersonInfo from "../all-order-detail/OrderPersonInfo";
import OrderFactor from "../all-order-detail/OrderFactor";
import Title from "@/components/app/Title";
import OrderDetailTable from "../all-order-detail/OrderDetailTable";
import StepsOfOrder from "../all-order-detail/steps-of-order/StepsOfOrder";
import OrderService from "@/packages/orders/services/order.service";
import RecipientDeliveryInfo from "../all-order-detail/final-information/RecipientDeliveryInfo";
import PaymentInfo from "../all-order-detail/final-information/PaymentInfo";
import { mapGetters, mapActions } from "vuex";
import Comment from "../all-order-detail/Comment";
// import RefundInfo from "../all-order-detail/RefundInfo";
export default {
  name: "CanceledOrderDetail",
  data() {
    return {
      skeletonLoader: true,
      Order: new OrderService(),
    };
  },
  components: {
    OrderPersonInfo,
    OrderFactor,
    Title,
    OrderDetailTable,
    RecipientDeliveryInfo,
    PaymentInfo,
    StepsOfOrder,
    Comment,
    // RefundInfo,
  },
  mounted() {
    this.initOrderDetail();
  },

  computed: {
    ...mapGetters({
      orderDetail: "order/orderDetail",
      hasError: "order/hasError",
    }),
  },

  watch: {
    hasError(newValue) {
      this.skeletonLoader = !newValue;
    },
  },
  methods: {
    ...mapActions({
      setHasError: "order/setHasError",
    }),
    async initOrderDetail() {
      this.setHasError(false);
      await this.Order.fetchOrderDetailById(this.$route.params.id);
      this.skeletonLoader = false;
    },
    async cancelOrder() {
      await this.Order.cancelOrders(this.$route.params.id);
      this.$router.go(-1);
    },
  },
};
</script>
