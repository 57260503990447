import message from "@/tools/Message";
import { registerOrderTrackingCodeAxiosReq } from "@/packages/tracking-code/api/register-order-tracking.api";

/**
 *  register order code
 *  ------------------------
 *  Description| Make api call to register order code
 *  ------------------------
 *  @param payload {Object}
 *  @param query_params {Object}
 *  ------------------------
 *  @return  type {Promise}
 *  ------------------------
 **/
export const registerOrderTrackingCodeService = async function (
  payload,
  query_params = null
) {
  try {
    await registerOrderTrackingCodeAxiosReq(payload, query_params);
    message.success("REGISTERED_SUCCESSFULLY");
  } catch (e) {
    message.error(errorObject[e.response.status]);
  }
};

const errorObject = {
  400: "IS_NOT_POST_OR_TIPAX_OR_MAHEX",
  403: "INVALID_CREDENTIAL",
  404: "ORDER_NOT_FOUND",
};
