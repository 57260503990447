import message from "@/tools/Message";
import store from "@/store";
import {
  fetchCommentsAxiosRequest,
  approveCommentAxiosRequest,
  rejectionCommentAxiosRequest,
  submitAnswerAxiosRequest,
  editCommentAxiosRequest,
} from "@/packages/comments/api";
import { abortApiHandler, AbortSource } from "@/tools/AbortApi";

export default class CommentsService {
  /*
   * fetch comments
   * -----------------------
   * @param query_params {Object}
   * -----------------------
   * Description
   * -----------------------
   * Make api call to fetch comments
   * -----------------------
   * return promise
   * */
  async fetchComments(query_params = null, cancelPrevApi) {
    try {
      if (cancelPrevApi) {
        abortApiHandler();
      }
      console.log(cancelPrevApi, "cancelPrevApicancelPrevApicancelPrevApi");
      /* call api to fetch users */
      let response = await fetchCommentsAxiosRequest(query_params, {
        cancelToken: AbortSource.axiosSource.token,
      });

      /*Update users in store */
      await store.dispatch("comments/setComments", response.content);
      await store.dispatch("pagination/setTotalItems", response.totalElements);
      await store.dispatch("pagination/setTotalIPages", response.totalPages);
      return response;
    } catch (err) {
      if (err.response) {
        message.error("در دریافت لیست نظرات مشکلی پیش آمده است.");
      }
    }
  }

  /*
   * reject comment
   * -----------------------
   * @param payload {Object}
   * -----------------------
   * Description
   * -----------------------
   * Make api call to reject comment
   * -----------------------
   * return promise
   * */
  async rejectionComment(payload) {
    try {
      /* call api for like comment */
      await rejectionCommentAxiosRequest(payload);
      message.success("عدم تایید نظر");
    } catch (err) {
      message.error(err);
      message.error("مشکلی پیش آمده است, لطفا دوباره سعی کنید");
    }
  }

  /*
   * approve comment
   * -----------------------
   * @param payload {Object}
   * -----------------------
   * Description
   * -----------------------
   * Make api call to approve comment
   * -----------------------
   * return promise
   * */
  async approveComment(payload) {
    try {
      /* call api for like comment */
      await approveCommentAxiosRequest(payload);
      message.success("موفقیت در تایید نظر");
    } catch (err) {
      message.error(err);
      message.error("مشکلی پیش آمده است, لطفا دوباره سعی کنید");
    }
  }

  /*
   * submit answer
   * -----------------------
   * @param payload {Object}
   * -----------------------
   * Description
   * -----------------------
   * Make api call to submit answer
   * -----------------------
   * return promise
   * */
  async submitAnswer(payload) {
    try {
      /* call api for submit answer */
      await submitAnswerAxiosRequest(payload);
      message.success("پاسخ با موفقیت ثبت شد");
      return true;
    } catch (err) {
      message.error(err);
      message.error("خطا در ثبت پاسخ");
    }
  }

  /*
   * edit comment
   * -----------------------
   * @param commentId {String}
   * @param commentContent {String}
   * @param commentTitle {String}
   * -----------------------
   * Description
   * -----------------------
   * Make api call to edit comment
   * -----------------------
   * return promise
   * */
  async editComment(payload) {
    try {
      /* call api for submit answer */
      await editCommentAxiosRequest(payload);
      message.success("نظر با موفقیت ویرایش شد");
      return true;
    } catch (err) {
      message.error(err);
      message.error("خطا در ویرایش نظر");
    }
  }
}
