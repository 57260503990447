/**
 *  map customers array
 *  ------------------------
 *  Description| map customers array to compatible format for lipak-input select options
 *  ------------------------
 *  @param customers {array}
 *  @return  type {array}
 *  ------------------------
 **/
import { fetchAllCustomersAxiosReq } from "@/packages/orders/api/fetch_customers.api";
import message from "@/tools/Message";

const customerMapper = function (customers) {
  return customers.map((customer) => {
    return {
      title: customer.name,
      value: customer.id,
    };
  });
};

/**
 *  fetch all customers
 *  ------------------------
 *  Description| Make api call to fetch all customers
 *  ------------------------
 *  @param query_params {Object}
 *  ------------------------
 *  @return  type {Promise}
 *  ------------------------
 **/
export const fetchAllCustomersService = async function (query_params = null) {
  try {
    const customers = await fetchAllCustomersAxiosReq(query_params);
    return Promise.resolve({
      username: customerMapper(customers.content),
    });
  } catch (error) {
    message.error("ERROR_FETCH_CUSTOMERS");
    return Promise.reject(error);
  }
};
