<template>
  <div class="d-flex justify-content-center">
    <div>{{ row.value ? "فعال" : "غیرفعال" }}</div>
    <ToggleButton class="mr-3" v-model="isActive" :loading="loading" />
  </div>
</template>

<script>
import ToggleButton from "@/components/app/ToggleButton";
import message from "@/tools/Message";
import { returnRejectReasonUpdateAxiosRequest } from "@/packages/manage/api/returnRejectReason.api";
export default {
  components: { ToggleButton },
  props: ["row"],
  data() {
    return {
      isActive: this.row.item.isActive,
      oldIsActive: this.row.item.isActive,
      loading: false,
    };
  },
  watch: {
    async isActive(newValue) {
      this.loading = true;
      if (newValue === this.oldIsActive) {
        this.loading = false;
        return;
      }
      await this.changeActiveStatus(newValue);
      this.loading = false;
    },
  },
  methods: {
    async changeActiveStatus(newIsActive) {
      try {
        await returnRejectReasonUpdateAxiosRequest(this.row.item.id, {
          isActive: newIsActive,
        });
        this.row.item.isActive = newIsActive;
        this.oldIsActive = newIsActive;
      } catch (error) {
        console.log(error);
        message.error("مشکلی پیش آمده");
        this.isActive = this.oldIsActive;
      }
    },
  },
};
</script>

<style scoped></style>
