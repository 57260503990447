import { required } from "vuelidate/lib/validators";

export const EDIT_COMMENT = {
  schema: [
    {
      id: "title",
      cols: 12,
      lg: 12,
      md: 12,
      sm: 12,
      type: "text",
    },
    {
      id: "content",
      cols: 12,
      lg: 12,
      md: 12,
      sm: 12,
      type: "textarea",
    },
  ],

  /* Form MODEL */
  model: {
    title: "",
    content: "",
  },

  /* VALIDATION SCHEMA */
  validations: {
    form: {
      title: { required },
      content: { required },
    },
  },
};
