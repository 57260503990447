<template>
  <b-container
    fluid
    class="d-flex justify-content-center align-content-center align-items-center"
    style="height: calc(100vh - 120px)"
  >
    <b-col
      cols="12"
      lg="9"
      class="d-flex justify-content-center align-items-center shadow bg-white h-auto"
    >
      <LipakAlert
        v-if="dialog"
        variant="info"
        :title="$_t('components.dashboard.welcome.title')"
        :on-close-redirect-link="{ name: 'users' }"
      >
        <template v-slot:content>
          <p>
            کاربر گرامی برای شروع ابتدا گزینه
            <router-link :to="{ name: 'users' }"> کاربران </router-link>
            را از منو انتخاب کنید.
          </p>
        </template>
      </LipakAlert>
    </b-col>
  </b-container>
</template>

<script>
import LipakAlert from "@/components/app/LipakAlert";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Welcome",
  components: { LipakAlert },
  computed: {
    ...mapGetters({
      dialog: "dialog/dialog",
    }),
  },
  methods: {
    ...mapActions({
      openDialog: "dialog/openDialog",
    }),
  },

  mounted() {
    this.openDialog();
  },
};
</script>
