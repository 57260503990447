import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./plugins/i18n";
import translator from "./plugins/translator";
import date_formatter from "./plugins/date-formatter";
import faToEn from "./plugins/convertFaNumberToEnNumber";
import currency_formatter from "./plugins/currency-formatter";
import unit_formatter from "./plugins/unit-formatter";
import dynamic_image_loader from "./plugins/dynamic-image-loader";
import "./registerServiceWorker";
import LipakInput from "@lipak/lipak-input";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import Vuelidate from "vuelidate";
import vuelidateErrorExtractor, { templates } from "vuelidate-error-extractor";

import { initBootstrapVue } from "@/configs/bootstrap.config";
initBootstrapVue(Vue);
import { initLipakIcon } from "@/configs/lipak-icon.config";
initLipakIcon(Vue);

import goBackNavigator from "./plugins/goBackNavigator";
import "./scss/custom.scss";

import VueHtmlToPaper from "@/plugins/html-to-paper";
Vue.use(VueHtmlToPaper);

Vue.config.productionTip = false;
Vue.use(translator);
Vue.use(date_formatter);
Vue.use(faToEn);
Vue.use(currency_formatter);
Vue.use(unit_formatter);
Vue.use(dynamic_image_loader);
Vue.use(goBackNavigator);
Vue.use(Vuelidate);
Vue.use(LipakInput);
Vue.use(vuelidateErrorExtractor, {
  /**
   * Optionally provide the template in the configuration.
   * or register like so Vue.component("FormField", templates.singleErrorExtractor.foundation6)
   */
  template: templates.singleErrorExtractor.foundation6,
  i18n: "validations", // Path to validation messages. Can be deeply.nested.property.
});

require("@/router/middlewares");

new Vue({
  router,
  store,
  i18n,
  Vuelidate,
  render: (h) => h(App),
}).$mount("#app");
