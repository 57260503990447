<template>
  <LipakModal
    @close="closeModal(false)"
    :modal="modal"
    title="آیا از تغییر روش پرداخت به حواله اطمینان دارید؟"
    size="md"
  >
    <template v-slot:body>
      <b-col cols="12" class="mx-auto justify-content-between px-0 fa-num-font">
      </b-col>
    </template>

    <template v-slot:footer>
      <b-row class="w-100 flex-row-reverse mt-3">
        <b-col cols="12">
          <b-button
            class="w-25 text-white ml-2"
            size="sm"
            @click="closeModal(false)"
          >
            خیر
          </b-button>
          <b-button
            class="w-25 text-white"
            variant="success"
            size="sm"
            @click="submit"
          >
            بله
          </b-button>
        </b-col>
      </b-row>
    </template>
  </LipakModal>
</template>

<script>
import LipakModal from "@/components/app/Modal/LipakModal";
import OrderService from "@/packages/orders/services/order.service";
export default {
  name: "ChangePaymentMethodModal",
  data: function () {
    return {
      Order: new OrderService(),
    };
  },
  props: {
    modal: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    async submit() {
      try {
        let data = {
          orderId: this.$route.params.id,
          toMethod: "receipt",
        };
        await this.Order.changePaymentMethod(data);
        this.closeModal("success");
      } catch (e) {
        this.closeModal(false);
      }
    },
    closeModal(state) {
      this.$emit("close", state);
    },
  },
  components: {
    LipakModal,
  },
};
</script>
<style scoped></style>
