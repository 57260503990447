const { required, maxLength, minLength } = require("vuelidate/lib/validators");
import { mixCharacterPersianAndEnglishValidation } from "@/plugins/vuelidate-custom-validators";
export const FORM_EDIT_SCHEMA = {
  /* Form SCHEMA */
  schema: [
    {
      title: "addressDetails",
      id: "addressDetails",
      label: "addressDetails",
      placeholder: "addressDetails",
      cols: 12,
      md: 12,
      sm: 12,
      lg: 12,
      xl: 12,
      type: "text",
      required: false,
    },
    {
      title: "province",
      id: "province",
      label: "province",
      placeholder: "province",
      cols: 12,
      md: 12,
      lg: 6,
      sm: 12,
      xl: 4,
      required: true,
      taxonomy: "",
      type: "select",
      options: [],
      service: "province",
      autoComplete: true,
      search: "provinceSearch",
    },
    {
      title: "city",
      id: "city",
      label: "city",
      placeholder: "city",
      cols: 12,
      md: 12,
      lg: 6,
      xl: 4,
      taxonomy: "",
      type: "select",
      options: [],
      required: true,
      service: "cityByProvince",
      autoComplete: true,
      search: "citySearch",
      provinceId: true,
    },
    {
      title: "quarter",
      id: "quarter",
      label: "quarter",
      placeholder: "quarter",
      cols: 12,
      md: 12,
      lg: 6,
      xl: 4,
      taxonomy: "",
      type: "select",
      options: [],
      required: true,
      service: "quarterSearch",
      autoComplete: true,
      search: "quarterSearch",
      cityId: true,
      disabled: false,
    },
    {
      title: "receiverName",
      id: "receiverName",
      label: "receiverName",
      placeholder: "receiverName",
      show_details: true,
      cols: 12,
      md: 12,
      lg: 6,
      xl: 4,
      type: "text",
      class: "py-0",
    },
    {
      title: "receiverLastName",
      id: "receiverLastName",
      label: "receiverLastName",
      placeholder: "receiverLastName",
      show_details: true,
      cols: 12,
      md: 12,
      lg: 6,
      xl: 4,
      type: "text",
      class: "py-0",
    },
    {
      title: "receiverPhone",
      id: "receiverPhone",
      label: "receiverPhone",
      placeholder: "receiverPhone",
      show_details: true,
      cols: 12,
      md: 12,
      lg: 6,
      xl: 4,
      type: "text",
      class: "py-0",
    },

    {
      title: "postalCode",
      id: "postalCode",
      label: "postalCode",
      placeholder: "postalCode",
      cols: 12,
      md: 12,
      lg: 6,
      xl: 4,
      type: "text",
      required: false,
    },
  ],

  /* Form MODEL */
  model: {
    receiverName: "",
    receiverLastName: "",
    receiverPhone: "",
    addressDetails: "",
    province: "",
    provinceSearch: "",
    city: "",
    citySearch: "",
    quarter: "",
    quarterSearch: "",
    postalCode: "",
    provinceId: "",
  },

  /* VALIDATION SCHEMA */
  validations: {
    form: {
      receiverName: { required },
      receiverLastName: { required },
      receiverPhone: {
        required,
        mixCharacterPersianAndEnglishValidation,
        minLength: minLength(11),
        maxLength: maxLength(11),
      },
      addressDetails: { required },
      province: { required },
      provinceSearch: {},
      city: { required },
      citySearch: {},
      quarterSearch: {},
      quarter: {},
      postalCode: {
        required,
        mixCharacterPersianAndEnglishValidation,
        minLength: minLength(10),
        maxLength: maxLength(10),
      },
      provinceId: {},
    },
  },
};
