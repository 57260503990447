<template>
  <b-card no-body class="shadow border-0 text-right p-3">
    <b-nav vertical pills class="px-2 pt-2 d-block h-100 shadow-none">
      <b-nav-item
        v-for="item in links"
        :key="'nav-link-' + item.title"
        class="mt-1 font-weight-bold d-block py-1 text-dark"
        :to="item.to"
        exact-active-class="active-link-text-color bg-primary text-white"
        active-class="bg-primary text-white active-link-text-color"
      >
        <b-avatar rounded="sm" size="md" :variant="item.color" class="ml-1 p-2">
          <component :is="item.icon" />
        </b-avatar>
        {{
          $_t(
            `components.users.user.container.user_management_side_navigation.${item.title}`
          )
        }}
      </b-nav-item>
    </b-nav>
  </b-card>
</template>

<script>
import { USER_MANAGEMENT_LINKS } from "@/packages/users/schema/links/USER_MANAGEMENT_LINKS";
export default {
  name: "UserManagementSideNavigation",
  data() {
    return {
      links: USER_MANAGEMENT_LINKS,
    };
  },
};
</script>

<style scoped>
.nav-link {
  color: black !important;
}
.active-link-text-color {
  color: white !important;
}
</style>
