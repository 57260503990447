<template>
  <b-form-group class="text-right font-weight-bold fa-num-font">
    <hr class="my-4" />
    <span> شیفت ها </span>
    <b-form-radio-group v-model="shippingShift" class="d-flex flex-wrap mt-4">
      <template v-for="item in shippingShifts.hoursList">
        <b-col cols="6" lg="3" :key="item.title" class="my-2 mt-lg-0">
          <b-card
            class="shadow border rounded-lg text-right"
            style="height: 100%"
          >
            <div class="d-flex align-content-start fs-14">
              <b-form-radio
                :value="item.shiftId"
                class="ml-3 mr-0"
                :disabled="
                  currentShippingMethodInfo.shippingShift !== item.shiftId &&
                  !item.active
                "
              />
              ساعت {{ item.start.substring(0, 2) }} -
              {{ item.end.substring(0, 2) }}
            </div>
          </b-card>
        </b-col>
      </template>
    </b-form-radio-group>
  </b-form-group>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ShippingShift",
  props: {
    shippingShifts: {
      type: Object,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      shippingShift: this.value,
    };
  },
  computed: {
    ...mapGetters({
      currentShippingMethodInfo: "order/currentShippingMethodInfo",
    }),
  },
  watch: {
    shippingShift(newValue) {
      if (newValue === this.value) return;
      this.$emit("input", newValue);
    },
    value(newValue) {
      this.shippingShift = newValue;
    },
  },
};
</script>

<style scoped></style>
