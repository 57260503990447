<template>
  <b-col cols="12" class="p-0 mt-5 pt-5 fa-num-font">
    <!----------------------start table of receipts-detail received----------------->
    <b-table
      class="tableBox"
      :tbody-tr-class="rowClass"
      thead-class="text-secondary fs-12 fw-lighter text-center"
      responsive
      hover
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :no-local-sorting="true"
      @sort-changed="sortChange"
      :fields="tableHeader"
      :items="returnedOrders"
      primary-key="1"
      show-empty
      :busy="isLoading"
      :empty-text="$_t('table.default.empty_text')"
      :empty-filtered-text="$_t('table.default.empty_text')"
    >
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle mx-1" small></b-spinner>
          <strong>{{ $_t("table.default.busy_text") }}</strong>
        </div>
      </template>
      <template #cell(row)="{ index }">
        <span>
          {{ index + 1 + (currentPage - 1) * pageSize }}
        </span>
      </template>
      <template #cell(orderDate)="row">
        <span v-if="row.value">
          {{ $_date(row.value) }}
        </span>
      </template>
      <template #cell(returnRegisterDate)="row">
        <span v-if="row.value">
          {{ $_date(row.value) }}
        </span>
      </template>
      <template #cell(state)="row">
        <span class="py-1 px-3">
          {{ getState(row.value) }}
        </span>
      </template>
      <template #cell(isComplete)="row">
        <div v-if="showCompleteReturn(row.item.state)">
          <span v-if="row.item.isComplete" class="py-1 px-3 text-success">
            بسته شده
          </span>
          <span v-else class="py-1 px-3 text-danger"> بسته‌ نشده </span>
        </div>
      </template>
      <template #cell(price)="row">
        <span v-if="row.value"> {{ $_currency(row.value) }}</span>
      </template>
      <template #cell(operations)="{ item }">
        <b-button
          size="sm"
          @click="navigateToOrderDetail(item.id)"
          class="mr-1 text-info w-100 border-0"
          block
          variant="accent"
        >
          جزئیات
        </b-button>
      </template>
    </b-table>
    <!----------------------end table of receipts-detail received------------------->
  </b-col>
</template>

<script>
import { PRICE_OF_ORDERS_ABOVE_THE_SPECIFIED_LIMIT } from "@/packages/orders/config/PRICE_OF_ORDERS_ABOVE_THE_SPECIFIED_LIMIT";
import { RETURNED_ORDER_TABLE_LIST_HEADER } from "@/packages/orders/schema/table/RETURNED_ORDER_TABLE_LIST_HEADER";
import { mapGetters } from "vuex";

const STATES = {
  RETURNED_BY_CUSTOMER: "ثبت اولیه",
  SUPPORT_CONFIRMED: "تایید پشتیبانی",
  QC_DELIVERED: "تحویل کنترل کیفیت",
  QC_CONFIRMED: "تایید کنترل کیفیت",
  CANCEL_BY_CUSTOMER: "لغو شده توسط مشتری",
  CANCEL_BY_SUPPORT: "لغو شده توسط پشتیبانی",
};
export default {
  name: "OrderTable",
  data: () => {
    return {
      sortBy: "createdAt",
      sortDesc: false,
      tableHeader: RETURNED_ORDER_TABLE_LIST_HEADER,
    };
  },
  computed: {
    ...mapGetters({
      isLoading: "loading/is_loading",
      returnedOrders: "returned/returnedOrders",
      currentPage: "pagination/current_page",
      pageSize: "pagination/size",
    }),
  },
  methods: {
    rowClass(item) {
      if (!item) return;
      if (
        Number(item.price) > Number(PRICE_OF_ORDERS_ABOVE_THE_SPECIFIED_LIMIT)
      ) {
        return "align-middle fs-14 text-center order-above";
      } else {
        return "align-middle fs-14 text-center";
      }
    },
    showCompleteReturn(state) {
      return state !== "CANCEL_BY_SUPPORT" && state !== "CANCEL_BY_CUSTOMER";
    },
    sortChange(e) {
      if (!e.sortBy) return;
      this.$emit("input", e);
    },
    getState(state) {
      return STATES[state];
    },
    navigateToOrderDetail(id) {
      let routeData = this.$router.resolve({
        name: "order.details-returned-orders",
        params: { id: id },
      });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>

<style>
.order-above {
  background-color: #dedeec;
}
</style>
