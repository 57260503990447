import { _t } from "@/tools/Utils";

export const ORDER_DETAIL_TABEL_HEEADER = [
  {
    key: "row",
    label: _t("components.orders.detail_all_orders.order_detail_tabel.row"),
    sortable: false,
    class: "text-center",
  },
  {
    key: "lpvCode",
    label: _t("components.orders.detail_all_orders.order_detail_tabel.code"),
    sortable: false,
    class: "text-right",
  },
  {
    key: "lpvImageUrl",
    label: _t(
      "components.orders.detail_all_orders.order_detail_tabel.lpvImageUrl"
    ),
    sortable: false,
    class: "col-1 text-center",
  },
  {
    key: "lpvName",
    label: _t("components.orders.detail_all_orders.order_detail_tabel.lpvName"),
    sortable: false,
    class: "col-3 text-right",
  },
  {
    key: "color",
    label: _t("components.orders.detail_all_orders.order_detail_tabel.color"),
    sortable: false,
    class: "col-1 text-right",
  },
  {
    key: "guarantee",
    label: _t(
      "components.orders.detail_all_orders.order_detail_tabel.guarantee"
    ),
    sortable: false,
    class: "col-1 text-right",
  },
  {
    key: "count",
    label: _t("components.orders.detail_all_orders.order_detail_tabel.count"),
    sortable: false,
    class: "text-center",
  },
  {
    key: "serials",
    label: _t("components.orders.detail_all_orders.order_detail_tabel.serials"),
    sortable: false,
    class: "text-center col-2",
  },
  {
    key: "price",
    label: _t("components.orders.detail_all_orders.order_detail_tabel.price"),
    sortable: false,
    class: "col-1 text-center",
  },
  {
    key: "discount",
    label: _t(
      "components.orders.detail_all_orders.order_detail_tabel.discount"
    ),
    sortable: false,
    class: "col-1 text-center",
  },
  {
    key: "finalPrice",
    label: _t(
      "components.orders.detail_all_orders.order_detail_tabel.finalPrice"
    ),
    sortable: false,
    class: "col-1 text-center",
  },
  {
    key: "total",
    label: _t("components.orders.detail_all_orders.order_detail_tabel.total"),
    sortable: false,
    class: "col-1 text-center",
  },
];
