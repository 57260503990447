import { changePasswordAxiosRequest } from "@/packages/change-password/repository/api/change_password.api";
import MessageService from "@/tools/Message";
import { logoutUser } from "@/packages/authentication/services/Auth.service";
/**
 * change password
 * -----------------------
 * @param payload {Object}
 * -----------------------
 * Description
 * -----------------------
 * Make api call to change password
 * -----------------------
 * @return users {Array}
 * */
export const changePasswordService = async function (payload) {
  try {
    const { header } = await changePasswordAxiosRequest(payload);

    MessageService.success("UPDATED_SUCCESS");
    await logoutUser();
    return header;
  } catch (e) {
    //code 504
    MessageService.error("UPDATED_FAILED");
    await Promise.reject(e);
  }
};
