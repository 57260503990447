<template>
  <div class="w-100 mt-5">
    <b-row no-gutters class="depotFilter d-flex justify-content-center mt-5">
      <!-----------------------start button of show filter------------------------------>
      <b-col cols="12" class="d-block justify-content-end p-0">
        <b-button
          @click="isShowFilters = true"
          class="bg-primary text-white border-0 fs-13 px-4"
          size="md"
        >
          <LIWifiFlat class="ml-2 text-white" />
          {{
            $_t(
              "components.orders.total_orders.total_order_filter.filter_button"
            )
          }}
        </b-button>
      </b-col>
      <!-----------------------end button of show filter-------------------------------->

      <!------------------------start filter inputs of receipts-detail-received --------------->
      <b-row
        v-if="isShowFilters"
        class="filterBox mt-3 d-block justify-content-center w-100 pa-0 ma-0"
      >
        <b-col cols="12"
          ><LipakForm>
            <!--Start form body    ------------------------------------------------------------------>
            <template v-slot:body>
              <!--Start form wrapper for catch errors  ------------------------>
              <LipackFormInput
                :schema="schema"
                :validator="$v"
              ></LipackFormInput>
              <!--End   form wrapper for catch errors  ------------------------>
            </template>
            <!--End   form body    ------------------------------------------------------------------>
          </LipakForm></b-col
        >

        <!-----------start button search and filter ------------------------------------>
        <b-row class="d-flex flex-row justify-content-end mt-5">
          <b-col cols="12" md="4" lg="1">
            <b-button
              class="bg-light text-dark w-100"
              size="sm"
              @click="isShowFilters = false"
            >
              {{
                $_t(
                  "components.orders.total_orders.total_order_filter.close_button"
                )
              }}
            </b-button>
          </b-col>

          <b-col cols="12" md="4" lg="1">
            <b-button
              class="bg-primary text-white w-100 border-0"
              size="sm"
              @click="initializeOrders(), (isShowFilters = true)"
              >{{
                $_t(
                  "components.orders.total_orders.total_order_filter.search_button"
                )
              }}
            </b-button>
          </b-col>
        </b-row>
        <!-----------end button search and filter -------------------------------------->
      </b-row>
      <!------------------------end filter inputs of receipts-detail-received ----------------->
    </b-row>
  </div>
</template>

<script>
import { RETURNED_ORDERS_FILTER } from "@/packages/orders/schema/filter/RETURNED_ORDERS_FILTER";
import LipackFormInput from "@/components/form/LipackFormGenerator";
import LipakForm from "@/components/app/form/LipakForm";
import { mapActions } from "vuex";
import filter from "@/mixins/mixin.filter-sort";
import cloneDeep from "lodash.clonedeep";
export default {
  name: "ReceiptsReceivedFilter",
  mixins: [filter],

  data: () => {
    return {
      schema: cloneDeep(RETURNED_ORDERS_FILTER.schema),
      isShowFilters: false,
      form: cloneDeep(RETURNED_ORDERS_FILTER.model),
    };
  },
  validations: {
    ...RETURNED_ORDERS_FILTER.validations,
  },

  components: {
    LipakForm,
    LipackFormInput,
  },
  watch: {
    /* v-model implementation on form */
    form: {
      // This will let Vue know to look inside the object
      deep: true,

      // We have to move our method to a handler field
      async handler(value) {
        let filters = this.filterEmptyItems(value);
        if (filters.fromOrderDate) {
          filters.fromOrderDate = filters.fromOrderDate.replace(" 00:00", "");
        }
        if (filters.toOrderDate) {
          filters.toOrderDate = filters.toOrderDate.replace(" 00:00", "");
        }
        if (filters.fromReturnedDate) {
          filters.fromReturnedDate = filters.fromReturnedDate.replace(
            " 00:00",
            ""
          );
        }
        if (filters.toReturnedDate) {
          filters.toReturnedDate = filters.toReturnedDate.replace(" 00:00", "");
        }
        this.$emit("input", filters);
      },
    },
  },
  methods: {
    ...mapActions({
      resetPagination: "pagination/reset",
    }),
    async initializeOrders() {
      this.resetPagination();
      let paginationParams = await this.paginationQueryBuilder();
      let query_params = this.filterEmptyItems(this.form);

      if (query_params.fromOrderDate) {
        query_params.fromOrderDate = query_params.fromOrderDate.replace(
          " 00:00",
          ""
        );
      }
      if (query_params.toOrderDate) {
        query_params.toOrderDate = query_params.toOrderDate.replace(
          " 00:00",
          ""
        );
      }
      if (query_params.fromReturnedDate) {
        query_params.fromReturnedDate = query_params.fromReturnedDate.replace(
          " 00:00",
          ""
        );
      }
      if (query_params.toReturnedDate) {
        query_params.toReturnedDate = query_params.toReturnedDate.replace(
          " 00:00",
          ""
        );
      }
      /* Filter empty inputs */

      this.$emit("input", cloneDeep(query_params));
      Object.assign(query_params, paginationParams);
      this.$emit(
        "fetchAgainOrders",
        Object.assign(query_params, paginationParams)
      );
    },
  },
};
</script>

<style lang="scss">
.depotFilter {
  .filterButton {
    border: none;
    background: #fe6a34;
    box-shadow: 0 3px 16px -4px rgba(0, 0, 0, 0.13);
    border-radius: 4px;
  }
  .closeFilterButton {
    border: 1px solid #c3c3c3 !important;
    color: #c3c3c3;
  }
  .filterBox {
    border: 1px solid #f2f2f2;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
    padding: 12px 26px;
  }
}
.row {
  --bs-gutter-x: 0 !important;
}
</style>
