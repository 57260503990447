<template>
  <b-card v-bind="$attrs">
    <slot />
  </b-card>
</template>

<script>
export default {
  name: "GlassCard",
};
</script>

<style scoped>
.card-glass {
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 24px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}
</style>
