import store from "@/store";
import { fetchCommentsAxiosRequest } from "@/packages/users/api";
export default class OrderComments {
  /*
   * fetch comments
   * -----------------------
   * @param customerMobile {String}
   * -----------------------
   * Description
   * -----------------------
   * Make api call to fetch comments
   * -----------------------
   * return promise
   * */
  async fetchComments(customerMobile) {
    try {
      let response = await fetchCommentsAxiosRequest(customerMobile);
      await store.dispatch("users/setUserComments", response.content);
      return true;
    } catch (err) {
      await Promise.reject(err);
    }
  }
}
