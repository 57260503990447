<template>
  <b-card
    class="bg-grey-light text-start d-flex justify-content-start border-0 card-rounded p-lg-3 mt-4 fa-num-font"
  >
    <div
      class="d-flex justify-content-start align-items-center text-more font-weight-bold mt-2 my-2 fs-18"
    >
      <p
        class="bg-primary rounded-pill ml-2 mt-2"
        style="width: 10px; height: 10px"
      ></p>
      اطلاعات پرداخت
    </div>
    <b-row
      class="list-info d-block d-lg-flex justify-content-start list-unstyled w-100 px-lg-2 px-0 mx-0 mb-0 mt-lg-4"
    >
      <template v-for="item in innerPaymentDetail">
        <b-col
          class="text-right d-flex d-lg-block pt-4 pt-lg-0 px-0 px-lg-3 pb-lg-5"
          :cols="item.col"
          :md="item.md"
          :lg="item.lg"
          :xl="item.xl"
          :key="item.index"
          v-if="item.show"
        >
          <div
            class="d-flex align-items-center fs-15 font-weight-bold text-secondary pb-2 pl-1 pl-lg-0"
          >
            {{ item.title }}:
          </div>
          <div
            v-if="item.key === 'price'"
            class="fs-17 font-weight-bold text-secondary pb-2 pt-lg-2"
          >
            {{ $_currency(Number(item.value)) }} ریال
          </div>
          <div
            v-else
            class="fs-17 font-weight-bold text-secondary pb-2 pt-lg-2"
          >
            {{ item.value }}
          </div>
        </b-col>
      </template>
    </b-row>
    <div v-for="item in paymentDetail.details" :key="item.index">
      <PaymentDetailList
        :list="item"
        :payment-type="paymentDetail.paymentType"
        :payment-status="paymentDetail.paymentStatus"
        :canceled-by="paymentDetail.canceledBy"
        :discount="paymentDetail.discount"
        :balance="paymentDetail.balance"
      />
    </div>
  </b-card>
</template>

<script>
import { mapGetters } from "vuex";
import PaymentDetailList from "./PaymentDetailList";
export default {
  name: "PaymentInfo",
  props: {
    paymentDetail: {
      type: Object,
      required: true,
    },
  },
  components: {
    PaymentDetailList,
  },

  computed: {
    ...mapGetters({
      gateways: "order/gateways",
    }),
    innerPaymentDetail() {
      return [
        {
          title: "لغو شده توسط",
          value: this.paymentDetail.canceledBy,
          col: "12",
          md: "4",
          lg: "6",
          xl: "3",
          show: this.paymentDetail.canceledBy !== null,
          key: "canceledBy",
        },
        {
          title: "روش پرداخت",
          value: this.checkType(this.paymentDetail.paymentType),
          col: "12",
          md: "4",
          lg: "6",
          xl: "3",
          show: true,
          key: "paymentType",
        },
        {
          title: "وضعیت پرداخت",
          value: this.checkStatus(this.paymentDetail.paymentStatus),
          col: "12",
          md: "4",
          lg: "6",
          xl: "3",
          show: true,
          key: "paymentStatus",
        },
        {
          title: "مبلغ پرداخت",
          value: this.paymentDetail.paymentPrice || "",
          col: "12",
          md: "4",
          lg: "6",
          xl: "3",
          key: "price",
          show:
            (!this.paymentDetail.details.length ||
              this.paymentDetail.paymentStatus !== "success") &&
            this.paymentDetail.paymentType !== "credit" &&
            this.paymentDetail.paymentType !== "baloan",
        },
        {
          title: "کسر شده از کیف پول بالون",
          value:
            this.paymentDetail.paymentStatus === "success"
              ? this.paymentDetail.installmentPrice
              : null,
          col: "3",
          key: "price",
          show:
            this.paymentDetail.paymentType === "credit" ||
            this.paymentDetail.paymentType === "baloan",
        },
      ];
    },
  },
  methods: {
    checkStatus(value) {
      switch (value) {
        case "waiting":
          return "در انتظار پرداخت";
        case "success":
          return "موفق";
        case "failure":
          return "ناموفق";
        case "inPlace":
          return "پرداخت در محل";
      }
    },
    checkType(value) {
      switch (value) {
        case "receipt":
          return "حواله";
        case "online":
          return "آنلاین";
        case "inPlace":
          return "پرداخت در محل";
        case "baloan":
          return "بالون";
        case "credit":
          return "اعتباری بالون";
      }
    },
  },
};
</script>
<style scoped></style>
