<template>
  <b-container fluid class="mt-4 fa-num-font">
    <b-row class="d-flex justify-content-end">
      <OrderFilter
        v-model="filters"
        @fetchAgainOrders="initializeOrders(paginationParams)"
      />
      <OrderTable
        v-model="sort"
        :skeletonLoader="skeletonLoader"
        :hasError="hasError"
      />
      <!--Start pagination -------------------------------------------->
      <Pagination
        v-if="returnedOrders"
        class="my-5"
        v-model="paginationParams"
        key="product-pagination"
      />
      <!--End   pagination -------------------------------------------->
    </b-row>
  </b-container>
</template>

<script>
import OrderFilter from "./OrderFilter";
import OrderTable from "./OrderTable";
import OrderService from "@/packages/orders/services/order.service";
import Pagination from "@/components/app/Pagination";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "ReturnedOrders",
  data() {
    return {
      paginationParams: null,
      filters: null,
      sort: {
        sortBy: "createdAt",
        sortDesc: false,
      },
      Order: new OrderService(),
      skeletonLoader: true,
      hasError: false,
    };
  },
  components: {
    OrderFilter,
    OrderTable,
    // OrderSearch,
    Pagination,
  },
  watch: {
    paginationParams: {
      // This will let Vue know to look inside the object
      deep: true,

      // We have to move our method to a handler field
      async handler(value) {
        if (value) await this.initializeOrders(value);
      },
    },

    sort: {
      // This will let Vue know to look inside the object
      deep: true,

      // We have to move our method to a handler field
      async handler(value) {
        let { sortBy, sortDesc } = value;
        if (value)
          await this.initializeOrders({
            ...this.filters,
            ...this.paginationParams,
            sortBy: sortBy,

            /* force server to sort items asc/desc */
            sortDirection: !sortDesc ? "DESC" : "ASC",
          });
      },
    },
  },
  computed: {
    ...mapGetters({
      returnedOrders: "returned/returnedOrders",
    }),
  },
  mounted() {
    this.resetPagination();
  },
  methods: {
    ...mapActions({
      resetPagination: "pagination/reset",
    }),
    async initializeOrders(paginationParams) {
      try {
        this.skeletonLoader = true;
        let { sortBy, sortDesc } = this.sort;
        await this.Order.fetchAllOrders(
          {
            ...paginationParams,
            ...this.filters,
            sortBy: sortBy,

            /* force server to sort items asc/desc */
            sortDirection: !sortDesc ? "DESC" : "ASC",
          },
          "returned",
          true
        );
        this.skeletonLoader = false;
      } catch (e) {
        console.log(e);
        this.hasError = true;
        this.skeletonLoader = false;
      }
    },
  },
};
</script>

<style scoped></style>
