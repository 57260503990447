import MessageService from "@/tools/Message";
import { verifyOtpAxiosRequest } from "@/packages/authentication/repository/api/verify_otp.api";
import store from "@/store";

/**
 * send otp to verification
 * -----------------------
 * @param payload {Object}
 * -----------------------
 * Description
 * -----------------------
 * Make api call to send otp to verification
 * -----------------------
 * @return Promise
 * */
export const verifyOtpService = async function (payload) {
  try {
    const { header } = await verifyOtpAxiosRequest(payload);
    await store.dispatch(
      "authentication/saveResetPasswordToken",
      header.input_password
    );
    MessageService.success("VERIFY_SUCCESS");
  } catch (e) {
    //code 504
    MessageService.error("VERIFY_FAILED");
    await Promise.reject(e);
  }
};
