<template>
  <b-row>
    <!--Start Filter show/hide handler    --------------------------------------------------------------->
    <b-col cols="12" class="d-flex flex-row-reverse mb-3">
      <b-button
        @click.stop="filtersShowHideToggle"
        variant="primary"
        size="sm"
        class="text-white px-4"
      >
        <LIWifiFlat class="fs-8" />
        {{
          $_t(
            "components.comments.comments.filters.comments_filters.buttons.filter"
          )
        }}
      </b-button>
    </b-col>
    <!--Start Filter show/hide handler    --------------------------------------------------------------->

    <!--Start Filter content              --------------------------------------------------------------->
    <b-col cols="12" class="px-0 px-lg-3">
      <transition name="fade">
        <b-card v-if="isShowFilters" class="border-0">
          <b-card class="border-0 bg-pink-light rounded mb-3">
            <TextSearch
              @addMobileOrProductNameToForm="addMobileOrProductNameToForm"
            />
          </b-card>

          <b-card class="border">
            <LipakForm>
              <template slot="body">
                <LipakFormGenerator :schema="schema" :validator="$v.form" />
              </template>
            </LipakForm>
            <b-form>
              <b-row class="d-flex flex-row-reverse">
                <b-col cols="12" md="4" lg="2" class="mt-4">
                  <b-button
                    @click.stop="submit"
                    class="w-100 text-white"
                    block
                    variant="primary"
                    size="sm"
                  >
                    {{
                      $_t(
                        "components.comments.comments.filters.comments_filters.buttons.submit"
                      )
                    }}
                  </b-button>
                </b-col>
                <b-col cols="12" md="4" lg="2" class="mt-4">
                  <b-button
                    class="w-100"
                    block
                    variant="outline-secondary"
                    size="sm"
                    @click.stop="closeFilters"
                  >
                    {{
                      $_t(
                        "components.comments.comments.filters.comments_filters.buttons.close"
                      )
                    }}
                  </b-button>
                </b-col>
              </b-row>
              <!--End   Form Actions    ------------------------------------------------------------------------>
            </b-form>
          </b-card>
        </b-card>
      </transition>
    </b-col>
    <!--Start Filter content              --------------------------------------------------------------->
  </b-row>
</template>

<script>
import filter from "@/mixins/mixin.filter-sort";
import { COMMENTS_FILTER_SCHEMA } from "@/packages/comments/schema/filters/product-managment/comments.filter";
import TextSearch from "@/packages/comments/components/comments/filters/TextSearch";
import CommentsService from "@/packages/comments/services/comments.service";
import { mapActions } from "vuex";
import select_options from "@/mixins/mixin.select_options";
import LipakForm from "@/components/app/form/LipakForm";
import LipakFormGenerator from "@/components/app/form/LipakFormInput";
export default {
  mixins: [filter, select_options],
  name: "CommentsFilters",
  components: { LipakFormGenerator, LipakForm, TextSearch },
  data() {
    return {
      isShowFilters: false,
      schema: COMMENTS_FILTER_SCHEMA.schema,
      form: { ...COMMENTS_FILTER_SCHEMA.model },
      Comment: new CommentsService(),
    };
  },
  validations: {
    form: COMMENTS_FILTER_SCHEMA.validations,
  },

  watch: {
    /* v-model implementation on form */
    form: {
      // This will let Vue know to look inside the object
      deep: true,
      // We have to move our method to a handler field
      async handler(value) {
        let filters = this.filterEmptyItems(value);
        if (filters.fromDate) {
          filters.fromDate = filters.fromDate.replace(" 00:00", "");
        }
        if (filters.toDate) {
          filters.toDate = filters.toDate.replace(" 00:00", "");
        }
        this.$emit("input", filters);
      },
    },
  },
  methods: {
    ...mapActions({
      resetPagination: "pagination/reset",
    }),

    addMobileOrProductNameToForm(value) {
      this.form.customerMobile = value;
    },
    async submit() {
      await this.resetPagination();
      let paginationParams = await this.paginationQueryBuilder();

      /* Filter empty inputs */
      let query_params = this.filterEmptyItems(this.form);
      Object.assign(query_params, paginationParams);
      await this.Comment.fetchComments(query_params, true);
    },
    filtersShowHideToggle() {
      this.isShowFilters = !this.isShowFilters;
    },
    closeFilters() {
      this.isShowFilters = false;
    },
  },
};
</script>

<style scoped></style>
