import api from "@/tools/Api";
import urls from "@/tools/Urls";

/*
 *  accept payment
 *  ------------------------
 *  Api address | api/v1/order/editUndeliveredOrderShipment/{order-id}/{address-id}
 *  ------------------------
 *  @param orderId {String}
 *  @param addressId {String}
 *  ------------------------
 *  method : Put
 *  ------------------------
 *  Description :
 *  ------------------------
 *  accept payment
 *  ------------------------
 *  @return Promise
 *  ------------------------
 */

export const editUndeliveredOrderShipmentAxiosRequest = function (
  orderId,
  addressId,
  payload
) {
  return api.put(
    urls.api("update_address_and_shippingMethod_in_undelivered_state", [
      orderId,
      addressId,
    ]),
    payload
  );
};
