<template>
  <LipakModal
    @close="closeModal"
    :modal="modal"
    title="ویرایش آدرس و زمان ارسال"
    size="xl"
  >
    <template v-slot:body>
      <b-col cols="12" class="mx-auto justify-content-between px-0 fa-num-font">
        <!--------------------start modal for create new address -------------->
        <NewAddress :user-id="userId" :order-id="orderId" />
        <!--------------------start modal for create new address -------------->

        <!--------------------start address list ------------------------------>
        <Addresses
          v-model="address"
          :order-id="orderId"
          :current-address="currentAddress"
        />
        <!--------------------end address list -------------------------------->

        <!--------------------start list shipping methods --------------------->
        <template>
          <div v-if="address">
            <div
              v-if="shippingMethodSkeletonLoader"
              class="d-flex justify-content-center"
            >
              <b-spinner variant="primary"></b-spinner>
            </div>
            <div v-else-if="shippingMethodHasError" class="w-100 text-center">
              <span class="text-primary fs-14">خطایی رخ داده است. </span><br />
              <b-button
                class="mt-2"
                variant="outline-primary"
                @click="fetchShippingMethods"
              >
                درخواست مجدد</b-button
              >
            </div>

            <ShippingMethods
              v-else
              v-model="shippingMethod"
              :shipping-methods="shippingMethods"
            />
          </div>
        </template>
        <!--------------------end shipping methods list------------------------>

        <!--------------------start sendingTimes list ------------------------->
        <template>
          <div v-if="shippingMethod && shippingMethod.shippingMethodCityId">
            <div
              v-if="shippingTimeSkeletonLoader"
              class="d-flex justify-content-center"
            >
              <b-spinner variant="primary"></b-spinner>
            </div>
            <div v-else-if="shippingTimeHasError" class="w-100 text-center">
              <span class="text-primary fs-14">خطایی رخ داده است. </span><br />
              <b-button
                class="mt-3"
                variant="outline-primary"
                @click="fetchSippingTimes"
              >
                درخواست مجدد</b-button
              >
            </div>
            <ShippingTimes
              v-else
              :current-address="currentAddress"
              v-model="shippingTime"
              :shipping-times="shippingTimes"
            />
          </div>
        </template>
        <!--------------------start sendingTimes list ------------------------->

        <!--------------------start shift list -------------------------------->
        <div
          v-if="
            shippingMethod &&
            shippingMethod.shippingMethodCityId &&
            shippingTime
          "
        >
          <div
            v-if="shippingShiftSkeletonLoader"
            class="d-flex justify-content-center"
          >
            <b-spinner variant="primary"></b-spinner>
          </div>
          <div v-else-if="shippingShiftHasError" class="w-100 text-center">
            <span class="text-primary fs-14">خطایی رخ داده است. </span><br />
            <b-button
              class="mt-2"
              variant="outline-primary"
              @click="fetchShippingShiftsFromTimes"
            >
              درخواست مجدد</b-button
            >
          </div>
          <ShippingShift
            v-else
            v-model="shippingShift"
            :shipping-shifts="shippingShifts"
          />
        </div>
        <!--------------------start shift list -------------------------------->
      </b-col>
    </template>

    <template v-slot:footer>
      <b-row class="w-100 flex-row-reverse mt-3">
        <b-col cols="12">
          <b-button class="w-25 text-white ml-2" size="sm" @click="closeModal">
            بستن
          </b-button>
          <b-button
            v-if="
              state.state.first === 'UNDELIVERED' ||
              state.state.first === 'undelivered'
            "
            class="w-25 text-white"
            variant="success"
            size="sm"
            @click="submitUndelivered"
          >
            ویرایش
          </b-button>
          <b-button
            v-else
            class="w-25 text-white"
            variant="success"
            size="sm"
            @click="submit"
          >
            ویرایش
          </b-button>
        </b-col>
      </b-row>
    </template>
  </LipakModal>
</template>

<script>
import LipakModal from "@/components/app/Modal/LipakModal";
import OrderService from "@/packages/orders/services/order.service";
import NewAddress from "./NewAddress";
import Addresses from "./Addresses";
import ShippingMethods from "./ShippingMethods";
import ShippingTimes from "./ShippingTimes";
import ShippingShift from "./ShippingShift";
import message from "@/tools/Message";
import { mapGetters } from "vuex";

export default {
  name: "EditAddress",
  props: {
    modal: {
      type: Boolean,
      default: false,
    },
    userId: { type: String, required: true },
    orderId: { type: String, required: true },
    currentAddress: { type: Object, required: true },
    state: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      OrderService: new OrderService(),
      address: {},
      shippingMethod: "",
      shippingMethods: [],
      shippingTime: "",
      shippingTimes: [],
      shippingShift: null,
      shippingShifts: [],

      shippingMethodSkeletonLoader: true,
      shippingMethodHasError: false,

      shippingTimeSkeletonLoader: false,
      shippingTimeHasError: false,

      shippingShiftSkeletonLoader: false,
      shippingShiftHasError: false,
    };
  },
  computed: {
    ...mapGetters({
      currentShippingMethodInfo: "order/currentShippingMethodInfo",
    }),
    selectaddressDefult() {
      return !!(
        this.currentShippingMethodInfo.shippingMethod ||
        this.address.addressId === this.currentAddress.addressId
      );
    },

    ...mapGetters({
      addresses: "order/addressList",
    }),
  },

  watch: {
    modal(val) {
      if (!val) {
        this.shippingMethod = "";
        this.shippingTime = "";
        this.shippingShift = "";
      }
    },
    address: async function () {
      this.shippingMethod = "";
      this.shippingTime = "";
      this.shippingShift = "";
      await this.fetchShippingMethods();
    },

    shippingMethod: {
      handler(val) {
        this.shippingTime = "";
        this.shippingShift = "";
        if (val && val.shippingMethodCityId) {
          this.fetchSippingTimes();
        }
      },
      deep: true,
    },
    shippingTime() {
      this.fetchShippingShiftsFromTimes();
    },
  },

  methods: {
    async fetchShippingMethods() {
      this.shippingMethodSkeletonLoader = true;
      this.shippingMethodHasError = false;
      try {
        this.shippingMethods = [];
        this.shippingMethods = await this.OrderService.fetchShippingMethodsForEditAddress(
          this.address.addressId
        );
        if (this.currentShippingMethodInfo) {
          this.shippingMethod = {
            shippingMethodId:
              this.currentShippingMethodInfo.shippingMethod.id ||
              this.addresses.shippingMethodV2.id,
            title:
              this.currentShippingMethodInfo.shippingMethod.title ||
              this.addresses.shippingMethodV2.originalName,
            englishTitle:
              this.currentShippingMethodInfo.shippingMethod.englishTitle ||
              this.addresses.shippingMethodV2.shippingMethod.displayName,
            cost:
              this.currentShippingMethodInfo.shippingMethod.cost ||
              this.addresses.shippingMethodV2.defaultPrice,
            // shippingMethods: false,
            active:
              this.currentShippingMethodInfo.shippingMethod.active ||
              this.addresses.shippingMethodV2.active,
            updatedDate:
              this.currentShippingMethodInfo.shippingMethod.updatedDate || "",
          };
        } else {
          this.shippingMethod = "";
          this.shippingTime = "";
          this.shippingShift = "";
        }
      } catch (e) {
        this.shippingMethodHasError = true;
      }
      this.shippingMethodSkeletonLoader = false;
    },

    async fetchSippingTimes() {
      try {
        this.shippingTimeSkeletonLoader = true;
        this.shippingTimeHasError = false;
        this.shippingTimes = [];
        this.shippingTimes = await this.OrderService.fetchSendingTimes({
          settingId: this.shippingMethod.shippingMethodCityId,
          addressId: this.address.addressId,
        });

        let exitShippingTimes = this.shippingTimes.find((item) => {
          let exitShippingTime =
            this.addresses.shiftSetting !== null
              ? this.addresses.shiftSetting.shiftDayId
              : null;
          return item.dayId === exitShippingTime;
        });
        if (exitShippingTimes) {
          this.shippingTime = this.addresses.shiftSetting.shiftDayId;
        }
      } catch (e) {
        this.shippingTimeHasError = true;
      }
      this.shippingTimeSkeletonLoader = false;
    },

    fetchShippingShiftsFromTimes() {
      this.shippingShifts = this.shippingTimes.find((item) => {
        return item.dayId === this.shippingTime;
      });

      if (this.shippingTime === this.addresses.shiftSetting?.shiftDayId) {
        this.shippingShift = this.addresses.shiftSetting?.assignmentId;
      } else {
        this.shippingShift = "";
      }
    },

    formValidate() {
      let m = "";
      if (
        !this.shippingMethod ||
        Object.keys(this.shippingMethod).length === 0
      ) {
        m = "لطفا‌ روش ارسال را انتخاب کنید";
      } else if (
        this.shippingMethod &&
        this.shippingMethod.shippingMethodCityId &&
        !this.shippingTime
      ) {
        m = "لطفا زمان تحویل سفارش را انتخاب کنید";
      } else if (
        this.shippingMethod &&
        this.shippingMethod.shippingMethodCityId &&
        !this.shippingShift
      ) {
        m = "لطفا شیفت ارسال را انتخاب کنید";
      }
      if (m) {
        message.error(m);
      }
      return m;
    },

    async submit() {
      try {
        let formIsValid = this.formValidate();
        if (formIsValid) return;
        let data = {
          shippingMethodId: this.shippingMethod.shippingMethodId
            ? this.shippingMethod.shippingMethodId
            : "",
          assignmentId: this.shippingShift ? this.shippingShift : "",
          shippingDayId: this.shippingTime ? this.shippingTime : "",
        };
        await this.OrderService.updateAddressAndShippingMethod(
          this.orderId,
          this.address.addressId,
          data
        );
        await this.OrderService.fetchOrderDetailById(this.$route.params.id);
        await this.closeModal();
      } catch (e) {
        console.log(e);
      }
    },

    async submitUndelivered() {
      try {
        let formIsValid = this.formValidate();
        if (formIsValid) return;
        let data = {
          shippingMethodId: this.shippingMethod.shippingMethodId
            ? this.shippingMethod.shippingMethodId
            : "",
          assignmentId: this.shippingShift ? this.shippingShift : "",
          shippingDayId: this.shippingTime ? this.shippingTime : "",
        };
        await this.OrderService.editUndeliveredOrderShipment(
          this.orderId,
          this.address.addressId,
          data
        );

        await this.OrderService.fetchOrderDetailById(this.$route.params.id);
        await this.closeModal();
      } catch (e) {
        console.log(e);
      }
    },

    closeModal() {
      this.$emit("close");
    },
  },

  components: {
    LipakModal,
    NewAddress,
    Addresses,
    ShippingMethods,
    ShippingTimes,
    ShippingShift,
  },
};
</script>
<style scoped></style>
