import message from "@/tools/Message";
import store from "@/store";
import { fetchUsersAxiosRequest } from "@/packages/users/api";
import { abortApiHandler, AbortSource } from "@/tools/AbortApi";
import { addNewAddressAxiosRequest } from "@/packages/orders/api";
import { createNewLegalAxiosRequest } from "@/packages/users/api";
export default class Users {
  /*
   * fetch users
   * -----------------------
   * @param query_params {Object}
   * -----------------------
   * Description
   * -----------------------
   * Make api call to fetch users
   * -----------------------
   * return promise
   * */
  async fetchUsers(query_params = null, cancelPrevApi) {
    try {
      if (cancelPrevApi) {
        abortApiHandler();
      }
      /* call api to fetch users */
      let response = await fetchUsersAxiosRequest(query_params, {
        cancelToken: AbortSource.axiosSource.token,
      });

      /*Update users in store */
      await store.dispatch("users/setUsers", response.content);
      await store.dispatch("pagination/setTotalItems", response.totalElements);
      await store.dispatch("pagination/setTotalIPages", response.totalPages);
      return response;
    } catch (err) {
      if (err.response) {
        message.error("در دریافت لیست کاربران مشکلی پیش آمده است.");
      }
    }
  }

  /*
   * add new address
   * -----------------------
   * @param payload {Object}
   * @param userId {String}
   * -----------------------
   * Description
   * -----------------------
   * Make api call to add new address
   * -----------------------
   * return promise
   * */
  async addNewAddress(payload, userId) {
    try {
      /* call api to fetch address */
      await addNewAddressAxiosRequest(payload, userId);
      message.success("آدرس با موفقیت ایجاد شد");
    } catch (err) {
      let messageError = err.response.data.message;
      if (messageError.includes("city and province not match")) {
        message.error("شهر و استان باهم مطابقت ندارند");
      } else {
        message.error("خطا در افزودن آدرس جدید");
      }
    }
  }

  /*
   * create new legal
   * -----------------------
   * @param payload {Object}
   * -----------------------
   * Description
   * -----------------------
   * Make api call to create new legal
   * -----------------------
   * return promise
   * */
  async createNewLegal(payload, userId) {
    try {
      /* call api to fetch legal */
      await createNewLegalAxiosRequest(payload, userId);
      message.success("اصلاعات حقوقی با موفقیت ایجاد شد");
    } catch (err) {
      message.success("خطا در ایجاد اصلاعات حقوقی");
      message.error(err);
    }
  }
}
