import { render, staticRenderFns } from "./EditLegaInformationModal.vue?vue&type=template&id=7d0e110d&scoped=true&"
import script from "./EditLegaInformationModal.vue?vue&type=script&lang=js&"
export * from "./EditLegaInformationModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7d0e110d",
  null
  
)

export default component.exports