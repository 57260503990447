<template>
  <UsersWithFilters />
</template>

<script>
import UsersWithFilters from "@/packages/users/components/users/UsersWithFilters";
export default {
  name: "List.view",
  components: { UsersWithFilters },
};
</script>

<style scoped></style>
