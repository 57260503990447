import { _t } from "@/tools/Utils";

export const RETURNED_ORDER_TABLE_LIST_HEADER = [
  {
    key: "row",
    label: _t(
      "components.orders.total_orders.total_order_table.total_order_table_header.row"
    ),
    sortable: false,
  },
  {
    key: "orderDate",
    label: _t(
      "components.orders.total_orders.total_order_table.total_order_table_header.register_date"
    ),
    sortable: false,
  },
  {
    key: "returnRegisterDate",
    label: _t(
      "components.orders.total_orders.total_order_table.total_order_table_header.return_date"
    ),
    sortable: false,
  },
  {
    key: "username",
    label: _t(
      "components.orders.total_orders.total_order_table.total_order_table_header.customer_name"
    ),
    sortable: false,
  },
  {
    key: "phoneNumber",
    label: _t(
      "components.orders.total_orders.total_order_table.total_order_table_header.mobileNum"
    ),
    sortable: false,
  },

  {
    key: "orderId",
    label: _t(
      "components.orders.total_orders.total_order_table.total_order_table_header.orderId"
    ),
    sortable: false,
  },
  {
    key: "returnedId",
    label: _t(
      "components.orders.total_orders.total_order_table.total_order_table_header.return_code"
    ),
    sortable: false,
    class: "col-1",
  },
  {
    key: "price",
    label: _t(
      "components.orders.total_orders.total_order_table.total_order_table_header.return_price"
    ),
    sortable: false,
  },
  {
    key: "state",
    label: "وضعیت",
    sortable: false,
  },
  {
    key: "isComplete",
    label: "وضعیت بسته ‌شدن",
    sortable: false,
  },
  {
    key: "operations",
    label: _t(
      "components.orders.total_orders.total_order_table.total_order_table_header.detail"
    ),
    sortable: false,
  },
];
