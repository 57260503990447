<template>
  <div
    class="w-100 d-flex flex-column justify-content-center align-content-center"
  >
    <b-avatar
      variant="primary-20"
      size="100"
      class="mx-auto d-flex justify-content-center align-items-center"
    >
      <LIUserAlt class="fs-32 text-primary" />
    </b-avatar>
    <p class="text-primary text-center mt-3">اطلاعات شخصی</p>
  </div>
</template>

<script>
export default {
  name: "Avatar",
};
</script>

<style scoped></style>
