<template>
  <b-row class="h-100 fa-num-font">
    <b-col
      cols="12"
      md="6"
      lg="4"
      v-for="item in bankDetails"
      :key="item.index"
      class="my-2 my-lg-0"
    >
      <span>{{ item.title }}:</span>
      <span class="mx-lg-3">{{ item.value }} </span>
      <div v-if="item.title === 'شماره کارت'">
        <BankNameBasedOnBankCardNumber :cart-number="item.value" />
      </div>
      <div v-if="item.title === 'شماره شبا'">
        <BankNameBasedOnShebaNumber :sheba-number="item.value" />
      </div>
    </b-col>
  </b-row>
</template>

<script>
import BankNameBasedOnBankCardNumber from "@/packages/users/components/user/profile/bank-information/BankNameBasedOnBankCardNumber.vue";
import BankNameBasedOnShebaNumber from "@/packages/users/components/user/profile/bank-information/BankNameBasedOnShebaNumber.vue";

export default {
  name: "InformationList",
  components: { BankNameBasedOnShebaNumber, BankNameBasedOnBankCardNumber },
  props: {
    bankDetails: {
      type: Object,
    },
  },
};
</script>

<style scoped></style>
