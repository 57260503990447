import { render, staticRenderFns } from "./SubmitComment.vue?vue&type=template&id=15231f43&scoped=true&"
import script from "./SubmitComment.vue?vue&type=script&lang=js&"
export * from "./SubmitComment.vue?vue&type=script&lang=js&"
import style0 from "./SubmitComment.vue?vue&type=style&index=0&id=15231f43&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15231f43",
  null
  
)

export default component.exports