const { required } = require("vuelidate/lib/validators");
export const TRACKING_CODE_REGISTER_SCHEMA = {
  /*SCHEMA */
  schema: [
    {
      id: "orderNum",
      type: "text",
      placeholder: "orderNum",
      required: true,
      cols: "12",
      lg: "12",
      md: "12",
    },
    {
      id: "postalTrackingCode",
      type: "text",
      placeholder: "postalTrackingCode",
      required: true,
      cols: "12",
      lg: "12",
      md: "12",
    },
  ],

  /* VALIDATION SCHEMA */
  validations: {
    form: {
      orderNum: { required },
      postalTrackingCode: {
        required,
      },
    },
  },

  /*MODEL SCHEMA*/
  model: {
    orderNum: "",
    postalTrackingCode: "",
  },
};
