import api from "@/tools/Api";
import urls from "@/tools/Urls";

/*
 *  accept payment
 *  ------------------------
 *  Api address | /api/v1/payment/approvePaymentCheck/:order-id
 *  ------------------------
 *  @param orderId {String}
 *  ------------------------
 *  method : Put
 *  ------------------------
 *  Description :
 *  ------------------------
 *  accept payment
 *  ------------------------
 *  @return Promise
 *  ------------------------
 */

export const acceptPaymentAxiosRequest = function (orderId) {
  return api.put(urls.api("accept_payment", [orderId]));
};
