<template>
  <LipakInput
    v-model="values"
    :disabled="false"
    :readonly="false"
    :type="type"
    :search.sync="search"
    :title="$_t(`attribute.${title}`)"
    :placeholder="placeHolder"
    :show-message="false"
    :default-option-disabled="false"
    :options="optionsFiltered"
    :auto-complete="true"
  />
</template>

<script>
// import { debounce } from "debounce";
import _ from "lodash";
export default {
  name: "LipakAutoComplete",
  props: {
    parentValues: {
      type: [Array, String],
    },
    options: {
      type: Array,
      default: () => [],
    },
    placeHolder: {
      type: String,
      default: "مقدار را وارد کنید.",
    },
    title: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "select-multiple",
    },
    item: {
      type: Object,
      default: null,
    },
    hasAction: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      search: "",
      values: this.type === "select" ? "" : [],
      optionsFiltered: [],
    };
  },
  watch: {
    values: {
      handler(newValue) {
        this.$emit("input", newValue);
      },
      deep: true,
    },
    options: {
      handler(newValue) {
        this.optionsFiltered = [...newValue];
      },
      deep: true,
    },
    async search(newValue) {
      if (this.hasAction) {
        this.fetchOptions(newValue);
      } else {
        this.optionsFiltered = [
          ...this.options.filter((item) => {
            return item.title.toLowerCase().includes(newValue.toLowerCase());
          }),
        ];
      }
    },
  },
  created() {
    this.optionsFiltered = [...this.options];
    this.values =
      this.type === "select"
        ? this.parentValues.toString()
        : [...this.parentValues];
    if (this.hasAction) this.item.doAction();
  },
  methods: {
    queryParamBuilder(inputId, searchValue) {
      let query_params = {};
      switch (inputId) {
        case "username":
          query_params = { name: searchValue };
          break;
        case "customer":
          query_params = { name: searchValue };
          break;
      }
      return query_params;
    },
    fetchOptions: _.debounce(async function (searchValue) {
      let query_params = this.queryParamBuilder(this.title, searchValue);
      let response = await this.item.doAction(query_params);
      console.log(response);
      this.optionsFiltered = response[this.title];
    }, 500),
  },
};
</script>

<style scoped></style>
