<template>
  <b-card class="rounded shadow mt-4 fa-num-font">
    <!--Start Actions                ------------------------------------------------------>

    <b-button
      size="sm"
      pill
      variant="outline-primary"
      class="border-0"
      @click="isShowModal = true"
    >
      <LIEdit class="fs-12" />
    </b-button>

    <!--End   Actions                ------------------------------------------------------>

    <ul
      class="list d-flex flex-column justify-content-between h-100"
      v-if="address"
    >
      <!--Start Address             ------------------------------------------------------>

      <li>
        <div class="d-block d-lg-flex text-right">
          <div>
            {{
              $_t(`components.users.user.profile.address_information.address`)
            }}:
          </div>

          <div class="mx-lg-3 font-weight-bold overflow-wrap">
            {{ address.province.name }} , {{ address.city.name }} ,{{
              address.quarter ? address.quarter.name + " , " : ""
            }}
            {{ address.addressDetails }}
          </div>
        </div>
      </li>

      <!--End   Address             ------------------------------------------------------>

      <!--Start customer full name  ------------------------------------------------------>

      <li class="pt-3 pt-lg-0">
        <div class="d-block d-lg-flex text-right">
          <div>
            {{
              $_t(
                `components.users.user.profile.address_information.full_name`
              )
            }}:
          </div>

          <div class="mx-lg-3 font-weight-bold overflow-wrap">
            {{ address.receiverName }} {{ address.receiverLastName }}
          </div>
        </div>
      </li>

      <!--End   customer full name  ------------------------------------------------------>

      <!--Start customer contact    ------------------------------------------------------>

      <li>
        <Contact
          :address-contact="{
            receiverPhone: address.receiverPhone,
            postalCode: address.postalCode,
          }"
        />
      </li>

      <!--End   customer contact    ------------------------------------------------------>
    </ul>
    <EditAddressInformationModal
      :address="address"
      :modal="isShowModal"
      @close="isShowModal = false"
    />
  </b-card>
</template>

<script>
import Contact from "@/packages/users/components/user/profile/address-information/Contact";

export default {
  name: "Address",
  components: {
    Contact,

    EditAddressInformationModal: () =>
      import(
        "@/packages/users/components/user/profile/address-information/modal/EditAddressInformationModal"
      ),
  },
  data() {
    return {
      isShowModal: false,
    };
  },
  props: {
    address: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped></style>
