const { required } = require("vuelidate/lib/validators");
import { mixCharacterPersianAndEnglishValidation } from "@/plugins/vuelidate-custom-validators";
import { nationalId } from "@/plugins/vuelidate-custom-validators";
export const FORM_EDIT_SCHEMA = {
  /* Form SCHEMA */
  schema: [
    {
      id: "companyName",
      cols: 12,
      lg: 12,
      md: 12,
      sm: 12,
      type: "text",
      required: false,
    },

    {
      id: "nationalId",
      cols: 12,
      lg: 12,
      md: 12,
      sm: 12,
      type: "text",
      required: false,
    },
    {
      id: "registerNumber",
      cols: 12,
      lg: 12,
      md: 12,
      sm: 12,
      type: "text",
      required: false,
    },
  ],

  /* Form MODEL */
  model: {
    companyName: "",
    nationalId: "",
    registerNumber: "",
  },

  /* VALIDATION SCHEMA */
  validations: {
    form: {
      companyName: { required },
      nationalId: {
        required,
        nationalId,
      },
      registerNumber: {
        required,
        mixCharacterPersianAndEnglishValidation,
      },
    },
  },
};
