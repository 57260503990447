<template>
  <b-card class="border-0 shadow">
    <b-row class="text-center px-5 text-more font-weight-bold fs-12">
      <b-col cols="2">
        <div>تاریخ ثبت سفارش:</div>
        <div v-if="data.orderDate">{{ $_date(data.orderDate) }}</div>
      </b-col>
      <b-col cols="2">
        <div>تاریخ ثبت مرجوعی:</div>
        <div v-if="data.returnRegisteredDate">
          {{ $_date(data.returnRegisteredDate) }}
        </div>
      </b-col>
      <b-col cols="2">
        <div>نام کاربر:</div>
        <div>{{ data.username }}</div>
      </b-col>
      <b-col cols="2">
        <div>شماره موبایل:</div>
        <div>{{ data.phoneNumber }}</div>
      </b-col>
      <b-col cols="2">
        <div>کد سفارش:</div>
        <div>{{ data.orderId }}</div>
      </b-col>
      <b-col cols="2">
        <div>کد مرجوعی:</div>
        <div>{{ data.returnId }}</div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "HeaderDetail",
  computed: {
    ...mapGetters({ data: "returned/header" }),
  },
};
</script>

<style scoped></style>
