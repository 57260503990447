<template>
  <b-modal
    v-model="showModal"
    :size="size"
    scrollable
    centered
    @hide="closeLipakModal"
    @ok="$emit('ok')"
    @cancel="$emit('cancel')"
    header-class="border-bottom-0"
    footer-class="border-top-0"
  >
    <!--Start Header    ----------------------------------------------------------------------->
    <template #modal-header="scope" class="flex-column justify-content-center">
      <ModalHeader :title="title" @close="scope.hide"></ModalHeader>
    </template>
    <!--End   Header    ----------------------------------------------------------------------->

    <!--Start Content   ----------------------------------------------------------------------->
    <template #default>
      <b-col
        cols="12"
        class="d-flex flex-column justify-content-center align-items-center"
      >
        <slot name="body" />
      </b-col>
    </template>
    <!--End   Content   ----------------------------------------------------------------------->

    <!--Start Footer    ----------------------------------------------------------------------->
    <template #modal-footer="">
      <slot name="footer"></slot>
    </template>
    <!--End   Footer    ----------------------------------------------------------------------->

    <template #modal-ok>
      <span>{{ okButtonText }}</span>
    </template>

    <template #modal-cancel>
      <span>{{ cancelButtonText }}</span>
    </template>
  </b-modal>
</template>

<script>
import ModalHeader from "@/components/app/Modal/ModalHeader";
export default {
  name: "LipakModal",
  components: { ModalHeader },
  props: {
    modal: {
      type: Boolean,
      default: false,
    },
    okButtonText: {
      type: String,
      default: "تایید",
    },
    cancelButtonText: {
      type: String,
      default: "لغو",
    },
    /*
     * Modal width size
     * values:
     * sm
     * md
     * lg
     * xl
     * xxl
     * */
    size: {
      type: String,
      default: "md",
    },
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      showModal: false,
    };
  },
  watch: {
    modal(val) {
       this.showModal = val;
    },
  },
  methods: {
    closeLipakModal() {
      this.showModal = false;
      this.$emit("close");
    },
  },
};
</script>
