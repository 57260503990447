<template>
  <b-container fluid class="shadow border-0 bg-white p-4 m-3 mt-lg-2">
    <b-row>
      <b-col cols="12">
        <!--Start Title              ------------------------------------------------------>
        <div class="d-flex justify-content-start align-items-center">
          <b-avatar
            variant="success-20"
            size="70"
            class="d-flex justify-content-center align-items-center"
          >
            <LIScroll icon="scroll" class="fs-20 text-success" />
          </b-avatar>
          <span class="text-info mx-3">{{
            $_t(
              `components.users.user.profile.legal_information.information_list.title`
            )
          }}</span>
        </div>

        <!--End   Title              ------------------------------------------------------>
      </b-col>
      <!--Start Legal info             ------------------------------------------------------>

      <!--Start modal for create new legal  ----------------------------------------------->
      <b-col cols="12">
        <b-button
          class="text-white px-3 py-2 mr-auto"
          variant="info"
          size="sm"
          @click="openCreateNewLegalModal"
        >
          افزودن اطلاعات حقوقی جدید
        </b-button>

        <CreateLegal
          :modal="isShowCreateNewLegalModal"
          @close="closeCreateNewLegalModal($event)"
        />
      </b-col>
      <!--End modal for create new legal  ------------------------------------------------->

      <b-col
        cols="12"
        class="text-right d-block justify-content-between"
        v-for="legal in legals"
        :key="'legal-container-' + legal.id"
      >
        <InformationList :key="'legal-' + legal.id" :legal-detail="legal" />
      </b-col>
      <!--Start Legal info             ------------------------------------------------------>
    </b-row>
  </b-container>
</template>

<script>
import InformationList from "@/packages/users/components/user/profile/legal-information/InformationList";
import { mapGetters } from "vuex";
import CreateLegal from "./modal/CreateLegal";
export default {
  name: "LegalInformation",
  components: {
    InformationList,
    CreateLegal,
  },
  data() {
    return {
      isShowCreateNewLegalModal: false,
    };
  },

  computed: {
    ...mapGetters({
      legals: "profile/legals",
    }),
  },
  methods: {
    openCreateNewLegalModal() {
      this.isShowCreateNewLegalModal = true;
    },

    closeCreateNewLegalModal(state) {
      if (state === "success") {
        this.$emit("fetchAgainUserInformation");
        this.isShowCreateNewLegalModal = false;
      } else {
        this.isShowCreateNewLegalModal = false;
      }
    },
  },
};
</script>

<style>
.list-horizontal {
  display: flex;
  flex-direction: row;
  list-style: none;
  padding: 0 !important;
}

.list-horizontal > li {
  margin: 0 0 0 10px;
}
</style>
