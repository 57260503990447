import api from "@/tools/Api";
import urls from "@/tools/Urls";

/*
 *  update address user
 *  ------------------------
 *  Api address | /api/v1/user-manage/address/:user_id
 *  ------------------------
 *  @param payload { object }
 *  @param address_id { string }
 *  ------------------------
 *  method : put
 *  ------------------------
 *  Description :
 *  ------------------------
 *   update address user
 *  ------------------------
 *  @return Promise
 *  ------------------------
 */

export const updateAddressUserAxiosRequest = function (payload, address_id) {
  return api.put(urls.api("update_address_user", [address_id]), payload);
};
