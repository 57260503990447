module.exports = {
  components: {
    users: {
      users: {
        filters: {
          user_filters: {
            buttons: {
              filter: "فیلتر",
              submit: "جستجو",
              close: "بستن",
            },
          },
        },
        users_table: {
          title: "لیست کاربران",
          table: {
            headers: {
              id: "ردیف",
              name: "نام کاربر",
              lastName: "نام خانوادگی کاربر",
              companyName: "سازمان",
              phone: "موبایل",
              email: "ایمیل",
              nationalCode: "کد ملی",
              province: "استان",
              city: "شهر",
              createdAt: "تاریخ ثبت نام",
              actions: "عملیات",
            },
            buttons: {
              detail_text: "جزئیات",
            },
          },
        },
      },
      user: {
        container: {
          user_management_side_navigation: {
            user: "مدیریت کاربر",
            profile: "پروفایل کاربران",
            orders: "سفارش ها",
            payments: "پرداخت ها",
            last_seen: "آخرین بازدیدها",
            comments: "نظرات",
            discount_code: "کدهای تخفیف",
            wallet: "کیف پول",
          },
        },
        profile: {
          base_information: {
            modal: {
              edit_base_information_modal: {
                title: "ویرایش پروفایل",
                buttons: {
                  submit_text: "ذخیره تغییرات",
                  close: "بستن",
                },
              },
            },
          },
          address_information: {
            modal: {
              edit_address_information_modal: {
                title: "ویرایش آدرس",
                buttons: {
                  submit_text: "ذخیره تغییرات",
                  close: "بستن",
                },
              },
            },
            title: "آدرس ها",
            address: "آدرس",
            full_name: "نام و نام خانوادگی تحویل گیرنده",
            phone: "شماره تلفن گیرنده",
            postal_code: "کد پستی",
            description: "توضیحات",
          },
          personal_info: {
            dob: "تاریخ تولد",
            id_code: "کد ملی",
          },
          bank_information: {
            modal: {
              edit_bank_information_modal: {
                title: "ویرایش اطلاعات بانکی",
                payment_confirmation_title: "ویرایش پرداخت بصورت حواله",
                buttons: {
                  submit_text: "ذخیره تغییرات",
                  close: "بستن",
                },
              },
            },
            title: "اطلاعات حساب بانکی",
            card_number: "شماره کارت",
            sheba: "شماره شبا",
          },
          legal_information: {
            modal: {
              edit_legal_information_modal: {
                title: "ویرایش اطلاعات حقوقی",
                buttons: {
                  submit_text: "ذخیره تغییرات",
                  close: "بستن",
                },
              },
            },
            information_list: {
              title: "اطلاعات حقوقی",
              companyName: "نام سازمان",
            },
          },
        },
        orders: {
          orders_user_tabel: {
            row: "ردیف",
            code: "کد سفارش",
            price: "مبلغ سفارش(تومان)",
            date: "تاریخ",
            state: "مرحله سفارش",
            payment: "وضعیت",
            returnDate: "تاریخ مرجوعی",
            returnCode: "کد موجوعی",
            returnStatus: "وضعیت مرجوعی",
            actions: "جزئیات",
          },
          order_detail: {
            order_items_tabel_header: {
              row: "ردیف",
              lpvImageUrl: "تصویر کالا",
              lpvName: "نام کالا",
              color: "رنگ",
              guarantee: "گارانتی",
              count: "تعداد",
              discount: "تخفیف(تومان)",
              price: "قیمت کالا(تومان)",
              sumPrice: "مجموع(تومان)",
            },
          },
        },
      },
    },
  },
  router: {},
  views: {},
  route_titles: {
    users_routes: {
      users: {
        user: {
          title: "کاربر",
          profile: "پروفایل",
          orders: "سفارش ها",
          payments: "پرداختی ها",
          last_seen: "آخرین بازدید",
          comments: "نظرات",
          discount_code: "کدهای تخفیف",
          wallet: "کیف پول",
          order_detail: "جزئیات سفارش",
          inpayments: "سفارش های در انتظار پرداخت",
          inproces: "سفارش های در حال پردازش",
          deliverd: "سفارش های موفق",
          returned: "سفارش های مرجوع شده",
          canceled: "سفارش های لغو شده",
        },
        title: "کاربران",
        list: "لیست کاربران",
      },
    },
  },
  validations: {
    nationalId: "شناسه ملی صحیح نمی‌باشد",
  },
  attribute: {
    name: "نام کاربر",
    lastName: "نام خانوادگی کاربر",
    nationalCode: "کد ملی",
    province: "استان",
    city: "شهر",
    fromDateRegister: "از تاریخ ثبت نام",
    toDateRegister: "تا تاریخ ثبت نام",
    companyName: "سازمان",
    economicCode: "کد اقتصادی",
    registerNumber: "شماره ثبت",
    nationalId: "شناسه ملی",
    birthday: "تاریخ تولد",
    email: "ایمیل",
    phone: "تلفن همراه",
    ownerName: "صاحب کارت",
    accountOwner: "صاحب کارت",
    cartNumber: "شماره کارت بانکی",
    shebaNumber: "شماره شبا",
    addressDetails: "آدرس",
    quarter: "محله",
    receiverPhone: "شماره همراه تحویل گیرنده",
    receiverName: "نام تحویل گیرنده",
    receiverLastName: "نام خانوادگی تحویل گیرنده",
    postalCode: "کد پستی",
    search_text: "شماره تلفن همراه یا ایمیل کاربر",
  },
  placeholder: {
    search_text: "شماره تلفن همراه یا ایمیل کاربر را جست و جو کنید.",
    name: "نام کاربر را وارد کنید.",
    lastName: "نام خانوادگی کاربر را وارد کنید.",
    nationalCode: "کد ملی کاربر را وارد کنید.",
    province: "استان را انتخاب کنید.",
    city: "شهر را انتخاب کنید.",
    fromDateRegister: "از تاریخ ثبت نام کاربر",
    toDateRegister: "تا تاریخ ثبت نام کاربر",
    companyName: "نام سازمان را وارد کنید.",
    economicCode: "کد اقتصادی را وارد کنید.",
    nationalId: "شناسه ملی را وارد کنید.",
    birthday: "تاریخ تولد را وارد کنید.",
    email: "ایمیل را وارد کنید.",
    phone: "تلفن همراه را وارد کنید.",
    ownerName: "صاحب کارت را وارد کنید",
    accountOwner: "صاحب کارت را وارد کنید",
    cartNumber: "شماره کارت بانکی را وارد کنید.",
    shebaNumber: "شماره شبا را وارد کنید",
    registerNumber: "شماره ثبت را وارد کنید.",
    addressDetails: "آدرس را واردکنید.",
    quarter: "محله را وارد کنید.",
    receiverPhone: "شماره همراه تحویل گیرنده را وارد کنید.",
    receiverName: "نام تحویل گیرنده را وارد کنید.",
    receiverLastName: "نام خانوادگی تحویل گیرنده را وارد کنید",
    postalCode: "کد پستی را وارد کنید.",
  },
};
