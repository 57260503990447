<template>
  <b-row>
    <b-col cols="12">
      <Chips width="100%" variant="purple">خانه</Chips>
    </b-col>
    <b-col cols="12" lg="4" md="4">
      <Chips width="100%" variant="purple">تهران</Chips>
    </b-col>
    <b-col cols="12" lg="4" md="4">
      <Chips width="100%" variant="purple">تهران</Chips>
    </b-col>
    <b-col cols="12" lg="4" md="4">
      <Chips width="100%" variant="purple">جنت آباد</Chips>
    </b-col>
  </b-row>
</template>

<script>
import Chips from "@/components/app/Chips";
export default {
  name: "AddressChips",
  components: {
    Chips,
  },
};
</script>

<style scoped></style>
