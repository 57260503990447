import api from "@/tools/Api";
import urls from "@/tools/Urls";

/*
 *  fetch shipping methods
 *  ------------------------
 *  Api address | /api/v1/comments
 *  ------------------------
 *  @param cityId { String }
 *  ------------------------
 *  method : Get
 *  ------------------------
 *  Description :
 *  ------------------------
 *  fetch shipping methods
 *  ------------------------
 *  @return Promise
 *  ------------------------
 */

export const fetchShippingMethodsAxiosRequest = function (cityId) {
  return api.get(urls.api("fetch_shipping_methods_by_city_id", [cityId]));
};
