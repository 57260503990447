import api from "@/tools/Api";
import urls from "@/tools/Urls";

/*
 * get all customers
 *--------------------------
 * Api address / api/v1/customer-manage
 *--------------------------
 * @param query_params  {Object}
 *--------------------------
 * methods : GET
 * -------------------------
 * description :
 * -------------------------
 * get all customers
 *--------------------------
 * @return promise
 *--------------------------
 */
export const fetchAllCustomersAxiosReq = function (query_params = null) {
  return api.get(urls.api("fetch_orders_returned_customers"), {
    params: {
      ...query_params,
      pageSize: 10,
      pageNumber: 0,
    },
  });
};
