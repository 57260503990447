<template>
  <div
    class="d-flex flex-wrap flex-row justify-content-end align-items-center mb-3"
  >
    <b-form-select
      v-model="items_per_page"
      :options="page_size_options"
      style="width: 55px"
      class="mx-2 pr-4 pl-1"
    ></b-form-select>
    <b-pagination
      v-model="current_page"
      :total-rows="total_items"
      :per-page="per_page"
      class="mb-0"
      pills
      size="md"
      align="right"
      dir="ltr"
    ></b-pagination>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import filter from "@/mixins/mixin.filter-sort";

export default {
  name: "Pagination",
  mixins: [filter],
  data() {
    return {
      page_size_options: [
        { value: 4, text: "4" },
        { value: 10, text: "10" },
        { value: 15, text: "15" },
        { value: 20, text: "20" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      page: "pagination/current_page",
      total_items: "pagination/total_items",
      total_pages: "pagination/total_pages",
      per_page: "pagination/size",
    }),

    items_per_page: {
      get: function () {
        return this.per_page;
      },
      // setter
      set: function (newValue) {
        this.setItemPerPage(newValue);
      },
    },

    current_page: {
      get: function () {
        return this.page;
      },
      // setter
      set: function (newValue) {
        this.setCurrentPage(newValue);
      },
    },
  },
  methods: {
    ...mapActions({
      setCurrentPage: "pagination/setCurrentPage",
      setItemPerPage: "pagination/setSize",
      resetPagination: "pagination/reset",
    }),

    async initialize() {
      let paginationFilter = await this.paginationQueryBuilder(
        this.current_page,
        this.per_page
      );
      this.$emit("input", paginationFilter);
    },
  },
  watch: {
    page: async function (current_page) {
      let paginationFilter = await this.paginationQueryBuilder(
        current_page,
        this.per_page
      );
      this.$emit("input", paginationFilter);
    },

    items_per_page: async function (current_size) {
      let paginationFilter = await this.paginationQueryBuilder(
        this.page,
        current_size
      );
      this.$emit("input", paginationFilter);
    },
  },
  async created() {
    await this.initialize();
  },
  destroyed() {
    this.resetPagination();
  },
};
</script>

<style scoped></style>
