import api from "@/tools/Api";
import urls from "@/tools/Urls";

/*
 *  submit comment by suportAdmin
 *  ------------------------
 *  Api address | /api/v1/comments
 *  ------------------------
 *  @param
 *  ------------------------
 *  method : Post
 *  ------------------------
 *  Description :
 *  ------------------------
 *  submit comment by suportAdmin
 *  ------------------------
 *  @return Promise
 *  ------------------------
 */

export const submitCommentBySuportAdminAxiosRequest = function (payload) {
  return api.post(urls.api("submit_comment_by_suport_admin"), payload);
};
