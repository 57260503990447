<template>
  <div>
    <!------------------start create new address ------------------------------------->
    <b-button
      class="text-white px-3 py-2"
      variant="info"
      size="sm"
      @click="createNewAddress"
    >
      افزودن آدرس جدید
    </b-button>
    <!------------------new create new address --------------------------------------->
    <!------------------start modal for create new address --------------------------->
    <CreateNewAddress
      :modal="openModalCreateAddress"
      @close="openModalCreateAddress = false"
      :user-id="userId"
      :order-id="orderId"
    />
    <!------------------end modal for create new address ----------------------------->
  </div>
</template>

<script>
import CreateNewAddress from "./CreateNewAddress";

export default {
  name: "NewAddress",
  props: {
    userId: { type: String, required: true },
    orderId: { type: String, required: true },
  },
  data() {
    return {
      openModalCreateAddress: false,
    };
  },
  components: {
    CreateNewAddress,
  },
  methods: {
    createNewAddress() {
      this.openModalCreateAddress = true;
    },
  },
};
</script>

<style scoped></style>
