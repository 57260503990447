import api from "@/tools/Api";
import urls from "@/tools/Urls";

export const getPaymentMethodShipments = function () {
  return api.get(urls.api("get_payment_method_shipments"));
};

export const updateMappingPaymentMethodShipmentMethod = function (body) {
  return api.put(
    urls.api("update_mapping_payment_method_shipment_method"),
    body
  );
};

export const getPaymentGatewayStatus = function () {
  return api.get(urls.api("get_payment_gateway_status"));
};

export const updatePaymentGatewayStatus = function (body) {
  return api.put(urls.api("update_payment_gateway_status"), body);
};
