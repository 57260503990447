// state
const state = () => ({
  filtered_activities: [],
});

//getters
const getters = {
  filtered_activities: (state) => {
    return state.filtered_activities.map((activity) => {
      return {
        id: activity.id,
        ip: activity.ip,
        name: activity.name,
        operation: activity.operation,
        operationCode: activity.operationCode,
        operationType: activity.operationType,
        requestDate: activity.requestDate,
        time: activity.requestDate.substring(11, 19),
        username: activity.username,
      };
    });
  },
};

//mutations
const mutations = {
  mutateFilteredActivities(state, value) {
    state.filtered_activities = value;
  },
};

//actions
const actions = {
  setFilteredActivities(context, data) {
    context.commit("mutateFilteredActivities", data);
  },
};

export default {
  namespaced: true,
  name: "activities",
  state,
  getters,
  actions,
  mutations,
};
