<template>
  <div class="d-flex">
    <div
      v-for="(item, index) in items"
      :key="item.title"
      class="d-flex mx-2"
      :class="{
        'w-100': index !== items.length - 1,
        'text-primary': getStatusOfItem(index) === 'active',
        'text-secondary': getStatusOfItem(index) === 'remained',
        'text-success': getStatusOfItem(index) === 'completed',
      }"
    >
      <div
        class="stepper-count-wrapper ml-2"
        :class="{
          'bg-primary': getStatusOfItem(index) === 'active',
          'bg-secondary': getStatusOfItem(index) === 'remained',
          'bg-success': getStatusOfItem(index) === 'completed',
        }"
      >
        {{ index + 1 }}
      </div>
      <div class="text-right stepper-title">
        <div>{{ item.title }}</div>
        <div v-if="item.date" class="text-center fs-12">
          {{ $_date(item.date) }}
        </div>
        <!--        <div v-if="item.date" class="text-center fs-12">-->
        <!--          {{ $_date(item.date, "time") }}-->
        <!--        </div>-->
      </div>
      <div
        v-if="index !== items.length - 1"
        class="ml-1 mr-3 mt-2 w-100 stepper-line"
        :class="{
          'stepper-line-completed': getStatusOfItem(index) === 'completed',
        }"
      ></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "State",
  computed: {
    items() {
      return [
        {
          id: "RETURNED_BY_CUSTOMER",
          title: "ثبت اولیه",
          date: this.dates.returnRegisteredDate,
        },
        {
          id: "SUPPORT_CONFIRMED",
          title: "تایید پشتیبانی",
          date: this.dates.supportConfirmedDate,
        },
        {
          id: "QC_DELIVERED",
          title: "تحویل کنترل کیفیت",
          date: this.dates.qcDeliverDate,
        },
        {
          id: "QC_CONFIRMED",
          title: "تایید کنترل کیفیت",
          date: this.dates.qcApproveDate,
        },
      ];
    },
    ...mapGetters({ state: "returned/state", dates: "returned/dates" }),
    activeItemIndex() {
      return this.items.findIndex((item) => item.id === this.state);
    },
  },
  methods: {
    getStatusOfItem(index) {
      if (this.activeItemIndex === index) return "active";
      if (this.activeItemIndex > index) return "completed";
      return "remained";
    },
  },
};
</script>

<style scoped lang="scss">
.stepper {
  &-title {
    white-space: nowrap;
  }
  &-count {
    &-wrapper {
      height: 26px;
      width: 26px;
      min-height: 26px;
      min-width: 26px;
      max-height: 26px;
      max-width: 26px;
      line-height: 30px;
      border-radius: 50%;
      background-color: #858585;
      color: var(--white);
      text-align: center;
    }
  }
  &-line {
    border-top: 2px dashed var(--grey);
    &-completed {
      border-top-style: solid;
      border-top-color: var(--success);
    }
  }
}
</style>
