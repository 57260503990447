<template>
  <h1
    class="border-4 border-right px-3 heading mt-5 mb-4 text-right"
    :class="`border-${variant}`"
  >
    {{ title }}
    <slot />
  </h1>
</template>

<script>
export default {
  name: "Title",
  props: {
    title: {
      type: String,
      default: "",
    },
    variant: {
      type: String,
      default: "primary",
    },
  },
};
</script>

<style scoped>
.heading {
  font-size: 1rem;
}
.border-right {
  border-right: solid;
  border-width: 4px !important;
}
</style>
