<template>
  <Auth />
</template>

<script>
import Auth from "@/packages/authentication/presentation/components/Auth";
export default {
  name: "Login",
  components: { Auth },
};
</script>

<style scoped></style>
