<template>
  <b-row no-gutters>
    <LipakForm>
      <!--Start form body    ------------------------------------------------------------------>
      <template v-slot:body>
        <p class="text-white mt-3" style="font-size: 14px">
          کد تایید به ایمیل {{ showingEmail }}ارسال شده است.
        </p>
        <!--Start form wrapper for catch errors  ------------------------>
        <LipackFormGenerator
          lg="6"
          :schema="schema"
          :validator="$v"
        ></LipackFormGenerator>
        <!--End   form wrapper for catch errors  ------------------------>
      </template>
      <!--End   form body    ------------------------------------------------------------------>

      <!--Start form actions ------------------------------------------------------------------>
      <template v-slot:action>
        <b-col cols="12">
          <b-button
            variant="primary"
            class="w-100"
            @click.prevent="submit"
            :disabled="is_loading || $v.$error"
          >
            <b-spinner v-if="is_loading" small variants="primary"></b-spinner>
            {{ $_t("components.otp.buttons.submit") }}
          </b-button>
        </b-col>
      </template>
      <!--End   form actions ------------------------------------------------------------------>
    </LipakForm>
  </b-row>
</template>

<script>
import LipakForm from "@/components/form/LipakForm";
import LipackFormGenerator from "@/components/form/LipackFormGenerator";
import { SCHEMA } from "@/packages/authentication/repository/schema/form/OTP_VERIFICATION.schema";
import { mapGetters } from "vuex";
import localStorageServiceService from "@/tools/LocalStorageService.service";
import { verifyOtpService } from "@/packages/authentication/services/VerifyOtp.service";
export default {
  name: "Otp",
  components: { LipackFormGenerator, LipakForm },
  data: function () {
    return {
      schema: [...SCHEMA.schema],
      form: { ...SCHEMA.model },
    };
  },
  validations: {
    ...SCHEMA.validations,
  },
  computed: {
    ...mapGetters({
      is_loading: "loading/is_loading",
      reset_password_token: "authentication/reset_password_token",
    }),
    email: function () {
      return localStorageServiceService.get("forgot-email");
    },

    showingEmail: function () {
      let email = localStorageServiceService.get("forgot-email");
      let split = email.split("@");
      return (
        split[0].substring(0, 3) +
        "****" +
        split[0].substring(split[0].length - 3) +
        "@" +
        split[1]
      );
    },
  },
  methods: {
    async submit() {
      if (!this.isFormValidate()) return;
      this.form.email = this.email;
      try {
        await verifyOtpService(this.form);
        await this.navigateToChangePasswordPage();
        this.reset();
      } catch (e) {
        console.log(e);
      }
    },

    /* reset form after submitting */
    reset() {
      this.form = { ...SCHEMA.model };
      this.$nextTick(() => {
        /* reset form validator state */
        this.$v.$reset();
      });
    },

    /*
     *  Validate a form
     *  ------------------------
     *  @param validator {Object} (vuelidate object $v)
     *  ------------------------
     *  @return {Boolean}
     *  ------------------------
     */
    isFormValidate() {
      this.$v.$touch();
      return !this.$v.$invalid;
    },

    async navigateToChangePasswordPage() {
      await this.$router.push({
        name: "authentication.change-password",
        query: { token: this.reset_password_token },
      });
    },
  },
};
</script>

<style scoped></style>
