<template>
  <div class="fa-num-font">
    <SubmitComment />
    <Comments :comments.sync="comments" />
  </div>
</template>

<script>
import Comments from "./Comments";
import SubmitComment from "./SubmitComment";
export default {
  name: "CommentsSupport",
  props: {
    comments: {
      type: Array,
      required: true,
    },
  },
  components: {
    Comments,
    SubmitComment,
  },
  data() {
    return {};
  },
};
</script>

<style scoped></style>
