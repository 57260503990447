export const USER_MANAGEMENT_LINKS = [
  {
    title: "profile",
    to: { name: "user.profile" },
    icon: "LIUserAlt",
    color: "info",
    isDisabled: false,
    isLink: true,
  },
  {
    title: "orders",
    to: { name: "user.orders" },
    icon: "LIBasketCheck",
    color: "purple",
    isDisabled: false,
    isLink: true,
  },
  {
    title: "payments",
    to: { name: "user.payments" },
    icon: "LICreditCard",
    color: "pink",
    isDisabled: false,
    isLink: true,
  },
  {
    title: "last_seen",
    to: { name: "user.last_seen" },
    icon: "LIShow",
    color: "primary",
    isDisabled: false,
    isLink: true,
  },
  {
    title: "comments",
    to: { name: "user.comments" },
    icon: "LIConversation",
    color: "warning",
    isDisabled: false,
    isLink: true,
  },
  {
    title: "discount_code",
    to: { name: "user.discount_code" },
    icon: "LIPercentTag",
    color: "danger",
    isDisabled: false,
    isLink: true,
  },
  {
    title: "wallet",
    to: { name: "user.wallet" },
    icon: "LIwalletAlt",
    color: "success",
    isDisabled: false,
    isLink: true,
  },
];
