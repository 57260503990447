export const IRANIAN_BANKS = {
  603799: {
    name: "بانک ملی ایران",
    sheba: "017",
    imageUrl: "meli.png",
  },
  589210: {
    name: "بانک سپه",
    sheba: "015",
    imageUrl: "sepah.png",
  },
  627648: {
    name: "بانک توسعه صادرات",
    sheba: "020",
    imageUrl: "tose-saderat.png",
  },
  627961: {
    name: "بانک صنعت و معدن",
    sheba: "011",
    imageUrl: "sanat.png",
  },
  603770: {
    name: "بانک کشاورزی",
    sheba: "018",
    imageUrl: "keshvarzi.png",
  },
  628023: {
    name: "بانک مسکن",
    sheba: "014",
    imageUrl: "maskan.png",
  },
  627760: {
    name: "پست بانک ایران",
    sheba: "021",
    imageUrl: "",
  },
  502908: {
    name: "بانک توسعه تعاون",
    sheba: "051",
    imageUrl: "tavon.png",
  },
  627412: {
    name: "بانک اقتصاد نوین",
    sheba: "055",
    imageUrl: "eqtesad-novin.png",
  },
  622106: {
    name: "بانک پارسیان",
    sheba: "054",
    imageUrl: "parsiyan.png",
  },
  502229: {
    name: "بانک پاسارگاد",
    sheba: "057",
    imageUrl: "pasargad.png",
  },
  627488: {
    name: "بانک کارآفرین",
    sheba: "053",
    imageUrl: "karafarin.png",
  },
  621986: {
    name: "بانک سامان",
    sheba: "056",
    imageUrl: "saman.png",
  },
  639346: {
    name: "بانک سینا",
    sheba: "059",
    imageUrl: "sina.png",
  },
  639607: {
    name: "بانک سرمایه",
    sheba: "058",
    imageUrl: "sarmaye.png",
  },
  636214: {
    name: "بانک آینده",
    sheba: "062",
    imageUrl: "ayande.png",
  },
  502806: {
    name: "بانک شهر",
    sheba: "061",
    imageUrl: "shahr.png",
  },
  502938: {
    name: "بانک دی",
    sheba: "066",
    imageUrl: "dey.png",
  },
  603769: {
    name: "بانک صادرات",
    sheba: "019",
    imageUrl: "saderat.png",
  },
  610433: {
    name: "بانک ملت",
    sheba: "012",
    imageUrl: "mellat.png",
  },
  627353: {
    name: "بانک تجارت",
    sheba: "018",
    imageUrl: "tejarat0.png",
  },
  589463: {
    name: "بانک رفاه",
    sheba: "013",
    imageUrl: "refah-kargaran.png",
  },
  627381: {
    name: "بانک انصار",
    sheba: "063",
    imageUrl: "ansar.png",
  },
  505785: {
    name: "بانک ایران زمین",
    sheba: "069",
    imageUrl: "iranzamin.png",
  },
  505416: {
    name: "بانک گردشگری",
    sheba: "064",
    imageUrl: "gardeshgari.png",
  },
  636949: {
    name: "بانک سپه (حکمت ایرانیان)",
    sheba: "065",
    imageUrl: "sepah.png",
  },
  505801: {
    name: "بانک سپه (کوثر)",
    sheba: null,
    imageUrl: "sepah.png",
  },
  639370: {
    name: "بانک سپه (مهراقتصاد)",
    sheba: null,
    imageUrl: "sepah.png",
  },
  606373: {
    name: "بانک قرض‌الحسنه مهر ایران",
    sheba: "060",
    imageUrl: "mehriran.png",
  },
  504172: {
    name: "بانک قرض‌الحسنه رسالت",
    sheba: null,
    imageUrl: "resalat.png",
  },
};
