import { loginAxiosRequest } from "@/packages/authentication/repository/api/login.api";
import MessageService from "@/tools/Message";
import store from "@/store/index";
import { logoutUserAxiosRequest } from "@/packages/authentication/repository/api/logout.api";
import router from "@/router";
import { getUserProfileService } from "@/packages/authentication/services/UserProfile.service";
/**
 * user login
 * -----------------------
 * @param payload {Object}
 * -----------------------
 * Description
 * -----------------------
 * Make api call to user login
 * -----------------------
 * @return users {Array}
 * */
export const loginUserService = async function (payload) {
  try {
    const { header } = await loginAxiosRequest(payload);
    if (!Number(header.is_new)) {
      //Save token in state and local storage
      await store.dispatch("authentication/saveToken", header.token);
      await getUserProfileService();
      MessageService.success("LOGIN_SUCCESS");
    } else {
      await store.dispatch("authentication/saveFirstLoginToken", header.token);
    }

    return Number(header.is_new);
  } catch (e) {
    //code 504
    MessageService.error("LOGIN_FAILED");
    await Promise.reject(e);
  }
};

/**
 *  logout user
 *  ------------------------
 *  @param
 *  ------------------------
 *  Description| Make api call to logout user
 *  ------------------------
 *  @return  type {Promise}
 *  ------------------------
 **/
export const logoutUser = async function () {
  try {
    let response = await logoutUserAxiosRequest();
    console.log(response, "response");
    await store.dispatch("authentication/logout");
    await router.push("/");
  } catch (e) {
    MessageService.error("خروج از حساب کاربری با مشکل مواجه شد");
    // await Promise.reject(e);
  }
};
