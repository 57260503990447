import api from "@/tools/Api";
import urls from "@/tools/Urls";

/*
 * get list configs
 *--------------------------
 * Api address / api/v1/contents/configs
 *--------------------------
 *--------------------------
 * methods : GET
 * -------------------------
 * description :
 * -------------------------
 * get list brands
 *--------------------------
 * @return promise
 *--------------------------
 */
export const fetchConfigsAxiosReq = function () {
  return api.get(urls.api("get_all_configs"));
};
