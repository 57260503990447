<template>
  <div class="text-right">
    <img
      v-if="bank && bank.imageUrl"
      :src="require(`@/assets/img/bank-logo/${bank.imageUrl}`)"
      :alt="bank ? bank.name : 'نامشخص'"
      width="50"
      height="50"
    />
    <strong>{{ bank ? bank.name : "" }}</strong>
  </div>
</template>

<script>
import { IRANIAN_BANKS } from "@/configs/IRANIAN_BANKS";

export default {
  name: "BankNameBasedOnShebaNumber",
  props: {
    shebaNumber: {
      type: [String, Number],
    },
  },
  data() {
    return {
      bank: null,
    };
  },
  watch: {
    shebaNumber: {
      handler: function (value) {
        this.bank = Object.values(IRANIAN_BANKS).find(
          (bank) => bank.sheba === value.substring(2, 5)
        );
      },
      immediate: true,
    },
  },
};
</script>

<style scoped></style>
