<template>
  <div>
    <b-card
      v-if="skeletonLoader"
      class="rounded-lg shadow border-0 justify-content-center px-1 px-lg-5 mb-3 fa-num-font"
    >
      <b-skeleton height="65px"></b-skeleton>
      <b-skeleton height="80px" class="mt-3"></b-skeleton>
      <b-skeleton height="80px" class="mt-3"></b-skeleton>
      <b-skeleton-table
        no-aspect
        height="150px"
        class="mt-5"
      ></b-skeleton-table>
      <b-skeleton
        v-for="i in 3"
        :key="i.index"
        height="160px"
        class="mt-3"
      ></b-skeleton>
    </b-card>
    <b-card
      v-else-if="hasError"
      class="rounded-lg shadow border-0 d-block justify-content-space-between px-1 px-lg-5 mb-3"
    >
      <div class="text-primary text-center">خطای سرور !</div>
      <div class="text-center mt-2">
        <b-button variant="outline-primary" @click="initOrderDetail">
          درخواست مجدد</b-button
        >
      </div>
    </b-card>

    <!---------------start filter receipts-detail-received------------------------------->
    <b-card
      v-else
      class="rounded-lg shadow border-0 d-block justify-content-space-between px-1 px-lg-5 mb-3 fa-num-font"
    >
      <!---------------------------start personal info buyer ---------------------------->
      <OrderPersonInfo :personal-info="orderDetail.personalInfoDto" />
      <!---------------------------end personal info buyer ------------------------------>

      <!---------------------------start orders info ------------------------------------>

      <OrderFactor
        :id="orderDetail.id"
        :code="String(orderDetail.code)"
        :state="orderDetail.stateFlow.state"
      />

      <!---------------------------end orders info -------------------------------------->

      <b-col
        cols="12"
        class="px-0 d-flex align-items-center justify-content-between"
      >
        <Title
          class="title font-weight-bold"
          title="وضعیت سفارش"
          variant="danger"
        ></Title>
        <b-button
          v-if="orderDetail.returnable"
          variant="danger"
          :to="{
            name: 'return-register.detail',
            params: { id: orderDetail.id },
          }"
          class="mt-5 mb-4"
        >
          ثبت مرجوعی
        </b-button>
      </b-col>

      <!-----------------------------start orders tab ---------------------------------->
      <StepsOfOrder :state-flow="orderDetail.stateFlow" />
      <!-----------------------------end orders tab------------------------------------->

      <b-col cols="12" class="px-0"
        ><Title
          class="title font-weight-bold"
          title="کالای خریداری شده"
          variant="danger"
        ></Title
      ></b-col>

      <!-----------------------------start orders detail table ---------------------------------->
      <OrderDetailTable
        :order-list="orderDetail.orderItemPages"
        :discount="orderDetail.paymentDetailsDto.discount"
      />
      <!-----------------------------start orders detail table----------------------------------->

      <!-----------------------------start info about payment way , sending way ----------------->
      <RecipientDeliveryInfo
        :recipient-delivery-info="orderDetail.userDetailDto"
      />

      <PostInfo
        :delivery-detail="orderDetail.deliveryDetailsDto"
        :has-delivery-code="orderDetail.hasDeliveryCode"
      />

      <CourierInfo
        v-if="orderDetail.courierDetailsDto"
        :courier-info="orderDetail.courierDetailsDto"
      />

      <PaymentInfo :payment-detail="orderDetail.paymentDetailsDto" />

      <LegalInfo :legal-detail="orderDetail.userLegal" />

      <!-----------------------------end info about payment way , sending way-------------------->

      <!-----------------------------start comment ---------------------------------------------->
      <Comment :comment="orderDetail.comment" />
      <!-----------------------------end comment ------------------------------------------------>
    </b-card>
  </div>
</template>

<script>
import OrderPersonInfo from "./OrderPersonInfo";
import OrderFactor from "./OrderFactor";
import Title from "@/components/app/Title";
import OrderDetailTable from "./OrderDetailTable";
import StepsOfOrder from "./steps-of-order/StepsOfOrder";
import RecipientDeliveryInfo from "./final-information/RecipientDeliveryInfo";
import PostInfo from "./final-information/PostInfo";
import PaymentInfo from "./final-information/PaymentInfo";
import Comment from "./Comment";
import OrderUser from "@/packages/users/services/user.orders.service";
import { mapActions, mapGetters } from "vuex";
import { getPaymentGatewayStatus } from "@/packages/manage/api/payoff";
import LegalInfo from "./LegalInfo";
import CourierInfo from "./CourierInfo";
export default {
  name: "OrderDetail",
  data() {
    return {
      OrderUser: new OrderUser(),
      skeletonLoader: true,
    };
  },
  computed: {
    ...mapGetters({
      hasError: "order/hasError",
      orderDetail: "orders/orderDetail",
    }),
  },
  components: {
    OrderPersonInfo,
    OrderFactor,
    Title,
    OrderDetailTable,
    StepsOfOrder,
    RecipientDeliveryInfo,
    PostInfo,
    PaymentInfo,
    Comment,
    LegalInfo,
    CourierInfo,
  },
  created() {
    this.initialOrderDetailById();
    this.initGateways();
  },
  watch: {
    hasError(newValue) {
      this.skeletonLoader = !newValue;
    },
  },
  methods: {
    ...mapActions({
      setHasError: "order/setHasError",
      setGateways: "order/setGateways",
    }),
    initialOrderDetailById() {
      this.setHasError(false);
      this.OrderUser.fetchOrderDetailById(this.$route.params.id);
      this.skeletonLoader = false;
    },
    async initGateways() {
      const gateways = await getPaymentGatewayStatus();
      this.setGateways(gateways);
    },
  },
};
</script>
