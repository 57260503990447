<template>
  <b-container
    fluid
    class="w-100 p-0 m-0 h-100"
    :style="'font-family: ' + font"
  >
    <!--Start Header  --------------------------------------------->
    <Header></Header>
    <!--End   Header  --------------------------------------------->

    <!--Start Content --------------------------------------------->
    <div class="main bg-background py-1" style="height: 100%">
      <transition :name="transition" mode="out-in">
        <router-view />
      </transition>
    </div>
    <!--End   Content --------------------------------------------->
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";
import Header from "@/components/app/Header";

export default {
  name: "dashboardHeader",
  components: { Header },
  data() {
    return {
      transition: "fade",
    };
  },
  computed: {
    ...mapGetters({
      font: "lang/font",
    }),
  },
  watch: {
    $route(to, from) {
      if (from.meta.type && to.meta.type) this.transition = "";
      else if (from.meta.type && !to.meta.type) this.transition = "fade";
    },
  },
};
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
