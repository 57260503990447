import { fetchContactUsRequest } from "@/packages/contact-us/api/index.api";
import store from "@/store";
import message from "@/tools/Message";
import { abortApiHandler, AbortSource } from "@/tools/AbortApi";

const state = () => ({
  data: [],
  isBusy: true,
  hasError: false,
});

// getters
const getters = {
  data: (state) => {
    return state.data;
  },
  isBusy: (state) => {
    return state.isBusy;
  },
  hasError: (state) => {
    return state.hasError;
  },
};

// mutations
const mutations = {
  setData(state, value) {
    state.data = value;
  },
  setBusy(state, value) {
    state.isBusy = value;
  },
  setError(state, value) {
    state.hasError = value;
  },
};

// actions
const actions = {
  async searchByFilters({ commit }, payload) {
    try {
      if (payload.cancelPrevApi) {
        abortApiHandler();
      }
      commit("setError", false);
      commit("setBusy", true);
      const {
        content,
        totalElements,
        totalPages,
      } = await fetchContactUsRequest(payload.filters, {
        cancelToken: AbortSource.axiosSource.token,
      });
      await store.dispatch("pagination/setTotalItems", totalElements);
      await store.dispatch("pagination/setTotalIPages", totalPages);
      commit("setData", content);
      commit("setBusy", false);
      message.success("جستجو با موفقیت انجام شد");
    } catch (error) {
      if (error.response) {
        message.error("مشکلی پیش آمده");
        commit("setError", true);
        commit("setBusy", false);
      }
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
