<template>
  <nav aria-label="breadcrumb" dir="rtl">
    <ol class="breadcrumb">
      <!--Start Breadcrumb item  ---------------------------------->
      <router-link
        class="text-decoration-none"
        v-for="(route, index) in items"
        :key="'route-' + index"
        :to="{ name: `${route.name}` }"
      >
        <li
          :class="
            !isCurrentRoute(route.name)
              ? 'text-secondary'
              : 'text-dark font-weight-bold'
          "
          class="link-hover"
        >
          {{ route.meta.title }}
          <span v-if="!isCurrentRoute(route.name)"> / </span>
        </li>
      </router-link>
      <!--End   Breadcrumb item  ---------------------------------->
    </ol>
  </nav>
</template>

<script>
export default {
  name: "Breadcrumb",
  props: {
    items: {
      type: Array,
    },
  },
  methods: {
    isCurrentRoute(route) {
      return this.$route.name === route;
    },
  },
};
</script>

<style scoped>
.breadcrumb {
  background: unset !important;
  margin-bottom: 0;
}
.link-hover:hover {
  color: #0d6efd !important;
}
</style>
