<template>
  <b-card
    class="bg-grey-light text-start d-flex justify-content-start border-0 card-rounded p-lg-3 mt-4 fa-num-font"
  >
    <div
      class="d-flex justify-content-start align-items-center text-more font-weight-bold mt-2 my-2 fs-18"
    >
      <p
        class="bg-primary rounded-pill ml-2 mt-2"
        style="width: 10px; height: 10px"
      ></p>
      یادداشت ثبت شده
    </div>
    <b-row class="list-info d-lg-flex list-unstyled m-0 mt-lg-4">
      <b-col v-if="Comment" cols="12" class="text-right px-0 px-lg-3">
        <span class="fs-15 font-weight-bold text-secondary white-space-line">
          {{ Comment }}
        </span>
      </b-col>
      <b-col v-else cols="12" class="text-center py-3">
        <span class="fs-15 font-weight-bold text-primary"
          >! یادداشت ثبت نشده است
        </span>
      </b-col>
    </b-row></b-card
  >
</template>

<script>
export default {
  name: "Comment",
  props: {
    Comment: {
      type: String,
      require: true,
    },
  },
};
</script>

<style scoped></style>
