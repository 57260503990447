import cloneDeep from "lodash.clonedeep";
// initial state
const state = () => ({
  addressList: [],
  orders: [],
  canceledOrders: [],
  orderDetail: {},
  hasError: false,
  gateways: [],
  currentShippingMethodInfo: {},
  cities: [],
});

// getters
const getters = {
  addressList: (state) => state.addressList,
  orders: (state) => state.orders,
  canceledOrders: (state) => state.canceledOrders,
  orderDetail: (state) => {
    let orderDetail = cloneDeep(state.orderDetail);
    orderDetail.orderItemPages.content = orderDetail.orderItemPages.content.map(
      (item) => {
        return {
          ...item,
          finalPrice: item.price,
        };
      }
    );
    return orderDetail;
  },
  hasError: (state) => state.hasError,
  gateways: (state) => state.gateways,
  currentShippingMethodInfo: (state) => state.currentShippingMethodInfo,
  cities: (state) => state.cities,
};

// mutations
const mutations = {
  mutateAddressList(state, payload) {
    state.addressList = payload;
  },
  mutateAllOrders(state, payload) {
    state.orders = [];
    state.orders = payload;
    payload.forEach((element, index) => {
      if (!element.state) return;
      switch (element.state.first) {
        case "DELIVERED":
          state.orders[index].state = {
            ...element.state,
            color: "success",
            bgColor: "light-success",
          };
          return;
        case "RETURNED":
          state.orders[index].state = {
            ...element.state,
            color: "info",
            bgColor: "light-blue",
          };
          return;
        case "CANCELED":
          state.orders[index].state = {
            ...element.state,
            color: "danger",
            bgColor: "light-danger",
          };
          return;
        case "UNDELIVERED":
          state.orders[index].state = {
            ...element.state,
            color: "primary",
            bgColor: "light-primary",
          };
          return;
      }
    });
  },
  mutateCanceledOrders(state, payload) {
    state.canceledOrders = [];
    state.canceledOrders = payload;
  },
  /////////////start order detail
  mutateOrderDetail(state, payload) {
    state.orderDetail = payload;
  },
  mutationsHasError(state, payload) {
    state.hasError = payload;
  },

  mutateUpdateCommentsInOrderDetail(state, payload) {
    state.orderDetail.orderCommentDtoList.unshift(payload);
  },

  mutateGateways(state, payload) {
    state.gateways = payload;
  },
  mutateCurrentShippingMethodInfo(state, payload) {
    state.currentShippingMethodInfo = payload;
  },
  mutateCities(state, data) {
    state.cities = data;
  },
};

// actions
const actions = {
  setAddressList({ commit }, payload) {
    commit("mutateAddressList", payload);
  },

  setAllOrders({ commit }, payload) {
    commit("mutateAllOrders", payload);
  },
  setCanceledOrders({ commit }, payload) {
    commit("mutateCanceledOrders", payload);
  },
  setOrderDetail({ commit }, payload) {
    commit("mutateOrderDetail", payload);
  },
  setHasError({ commit }, payload) {
    commit("mutationsHasError", payload);
  },
  updateCommentsInOrderDetail({ commit }, payload) {
    commit("mutateUpdateCommentsInOrderDetail", payload);
  },
  setGateways({ commit }, payload) {
    commit("mutateGateways", payload);
  },
  setCurrentShippingMethodInfo({ commit }, payload) {
    commit("mutateCurrentShippingMethodInfo", payload);
  },
  setCities({ commit }, data) {
    commit("mutateCities", data);
  },
};

export default {
  namespaced: true,
  name: "order",
  state,
  getters,
  actions,
  mutations,
};
