import message from "@/tools/Message";
import store from "@/store";
import {
  fetchUserPersonalInfoAxiosRequest,
  updateBankAccountDetailAxiosRequest,
  updateBaseUserInformationAxiosRequest,
  updateLegalUserAxiosRequest,
  updateAddressUserAxiosRequest,
  fetchProvinceAxiosRequest,
  fetchCityAxiosRequest,
  paymentByRemittanceAxiosReq,
  // fetchOrderDetailByIdAxiosRequest,
} from "@/packages/users/api";

export default class Profile {
  /*
   * fetch users personal information
   * -----------------------
   * @param user_id {String}
   * -----------------------
   * Description
   * -----------------------
   * Make api call to fetch users
   * -----------------------
   * return promise
   * */
  async fetchUserPersonalInfoById(user_id) {
    try {
      /* call api to fetch personal user information */
      let response = await fetchUserPersonalInfoAxiosRequest(user_id);

      /*Update personal user information in store */
      await store.dispatch("profile/setUserDetail", response);
      return true;
    } catch (err) {
      message.error(err);
      await Promise.reject(err);
    }
  }

  /*
   * update base user information
   * -----------------------
   * @param payload {Object}
   * @param user_id {String}
   * -----------------------
   * Description
   * -----------------------
   * Make api call to update base user information
   * -----------------------
   * return promise
   * */
  async updateBaseUserInformation(payload, user_id) {
    try {
      await updateBaseUserInformationAxiosRequest(payload, user_id);
      message.success("ویرایش اطلاعات شخصی با موفقیت انجام شد");
    } catch (err) {
      message.success("ویرایش اطلاعات شخصی با خطا مواجه شد");

      // message.error(err);
    }
  }

  /*
   * update bankAccountDetail user
   * -----------------------
   * @param payload {Object}
   * @param user_id {String}
   * -----------------------
   * Description
   * -----------------------
   * Make api call to update bankAccountDetail user
   * -----------------------
   * return promise
   * */
  async updateBankAccountDetail(payload, user_id) {
    try {
      await updateBankAccountDetailAxiosRequest(payload, user_id);
      message.success("ویرایش اطلاعات بانکی با موفقیت انجام شد");
    } catch (err) {
      // message.error(err);
      message.success("ویرایش اطلاعات بانکی  با خطا مواجه شد");
    }
  }

  /*
   * update legal user
   * -----------------------
   * @param payload {Object}
   * @param legal_id {String}
   * -----------------------
   * Description
   * -----------------------
   * Make api call to update legal user
   * -----------------------
   * return promise
   * */
  async updateLegalUser(payload, legal_id) {
    try {
      await updateLegalUserAxiosRequest(payload, legal_id);
      message.success("ویرایش اطلاعات حقوقی با موفقیت انجام شد");
    } catch (err) {
      // message.error(err);
      message.success("ویرایش اطلاعات حقوقی با خطا مواجه شد");
    }
  }

  /*
   * update address user
   * -----------------------
   * @param payload {Object}
   * @param address_id {String}
   * -----------------------
   * Description
   * -----------------------
   * Make api call to update address user
   * -----------------------
   * return promise
   * */
  async updateAddressUser(payload, address_id) {
    try {
      await updateAddressUserAxiosRequest(payload, address_id);
      message.success("ویرایش آدرس با موفقیت انجام شد");
    } catch (err) {
      let messageError = err.response.data.message;
      if (messageError.includes("city and province not match")) {
        message.error("شهر و استان باهم مطابقت ندارند");
      } else {
        message.error("ویرایش آدرس  با خطا مواجه شد");
      }
    }
  }

  /*  fetch province...  */
  async fetchProvince() {
    try {
      let response = await fetchProvinceAxiosRequest();
      return response.content;
    } catch (err) {
      message.error(err);
    }
  }

  /*  fetch city  */
  async fetchCity(provinceId) {
    try {
      let response = await fetchCityAxiosRequest(provinceId);
      return response.cities;
    } catch (err) {
      message.error(err);
    }
  }

  async paymentByRemittance(user_id, payload) {
    try {
      await paymentByRemittanceAxiosReq(user_id, payload);
      message.success("عملیات با موفقیت انجام شد");
    } catch (err) {
      message.error("در ویرایش طریقه پرداخت مشکلی پیش آمده است");
      message.error(err);
    }
  }
}
