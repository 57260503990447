import api from "@/tools/Api";
import urls from "@/tools/Urls";

/*
 *  fetch OrderDetail by orderId
 *  ------------------------
 *  Api address | /api/v1/comments
 *  ------------------------
 *  @param user_id { Object }
 *  ------------------------
 *  method : Get
 *  ------------------------
 *  Description :
 *  ------------------------
 *  fetch OrderDetail by orderId
 *  ------------------------
 *  @return Promise
 *  ------------------------
 */

export const fetchOrderDetailByIdAxiosRequest = function (user_id = null) {
  return api.get(urls.api("fetch_order_detail_by_order_id", [user_id]));
};
