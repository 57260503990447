<template>
  <SendEmail />
</template>

<script>
import SendEmail from "@/packages/authentication/presentation/components/SendEmail";
export default {
  name: "ForgetPassword",
  components: { SendEmail },
};
</script>

<style scoped></style>
