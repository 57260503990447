import api from "@/tools/ApiService.service";
import urls from "@/tools/Urls";

/**
 *  reset password
 *  ------------------------
 *  Api address| /stuff/change/password
 *  ------------------------
 *  Method| PATCH
 *  ------------------------
 *  Description| reset password
 *  ------------------------
 *  @param payload {Object}
 *  @param token {String}
 *  ------------------------
 *  @return  type {Promise}
 *  ------------------------
 **/
export const resetPasswordAxiosRequest = function (payload, token) {
  return api.patch(urls.api("auth_reset_password"), payload, {
    headers: {
      token: `Bearer ${token}`,
    },
  });
};
