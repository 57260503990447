<template>
  <div style="min-height: calc(100vh - 102px)" class="bg-background">
    <router-view />
    <PwaUpdaterAlert />
    <Snackbar />
  </div>
</template>

<script>
import Snackbar from "./components/app/Snackbar";
import PwaUpdaterAlert from "@/components/app/PwaUpdaterAlert";
export default {
  components: { PwaUpdaterAlert, Snackbar },
};
</script>

<style>
@import "assets/styles/main.scss";
@import "~@lipak/lipak-icon/src/style.css";
</style>

<style lang="scss">
@import "~bootstrap/scss/bootstrap";
</style>
