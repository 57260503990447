import { helpers } from "vuelidate/lib/validators";

export const isEnglish = helpers.regex(
  "isEnglish",
  /^[a-zA-Z0-9\s/g?><;,{}[\]\-_+=!@#$%^&*|']*$/
);

export const isPersian = helpers.regex(
  "isPersian",
  /^[\u0600-\u06FF\s/g0-9?><;,{}[\]\-_+=!@#$%^&*|']+$/
);

export const mixCharacterPersianAndEnglishValidation = helpers.regex(
  "mixCharacterPersianAndEnglishValidation",
  /^[\u06F0-\u06F90-9]+$/
);
export const sameAsMultiple = (params) => (value) => params.includes(value);

export const strongPassword = (password) =>
  /[a-z]/.test(password) && // checks for a-z
  /[A-Z]/.test(password) && // checks for A-Z
  /\W|_/.test(password); // checks for special char

export const nationalId = (value) => checkNationalId(value);
function checkNationalId(code) {
  var L = code.length;

  if (L < 11 || parseInt(code, 10) === 0) return false;

  if (parseInt(code.substr(3, 6), 10) === 0) return false;
  var c = parseInt(code.substr(10, 1), 10);
  var d = parseInt(code.substr(9, 1), 10) + 2;
  var z = [29, 27, 23, 19, 17];
  var s = 0;
  for (var i = 0; i < 10; i++)
    s += (d + parseInt(code.substr(i, 1), 10)) * z[i % 5];
  s = s % 11;
  if (s === 10) s = 0;
  return c === s;
}
