export const USERS_FILTER_SCHEMA = {
  /* Form SCHEMA */
  schema: [
    {
      id: "name",
      label: "name",

      cols: 12,
      lg: 2,
      md: 4,
      sm: 6,
      type: "text",
      required: true,
    },
    {
      id: "lastName",
      label: "lastName",
      cols: 12,
      lg: 2,
      md: 4,
      sm: 6,
      type: "text",
      required: true,
    },
    {
      id: "nationalCode",
      label: "nationalCode",
      cols: 12,
      lg: 2,
      md: 4,
      sm: 6,
      type: "text",
      required: true,
    },

    {
      id: "fromDate",
      label: "fromDateRegister",
      cols: 12,
      lg: 2,
      md: 4,
      sm: 6,
      type: "date",
      required: true,
    },
    {
      label: "toDateRegister",
      id: "toDate",
      cols: 12,
      lg: 2,
      md: 4,
      sm: 6,
      type: "date",
      required: true,
    },
  ],

  /* Form MODEL */
  model: {
    name: "",
    lastName: "",
    nationalCode: "",
    province: "",
    city: "",
    fromDate: "",
    toDate: "",
    companyName: "",
    economicCode: "",
    nationalId: "",
    phoneNumber: "",
  },
};
