<template>
  <b-container fluid class="px-0 py-0 my-0">
    <b-row no-gutters>
      <b-col cols="12" class="px-0">
        <table
          width="100%"
          cellpadding="0"
          cellspacing="0"
          class="ss02 fs-11 font-weight-bold table-caption-top"
        >
          <caption class="text-center bg-header-table py-2">
            <span class=" ">مشخصات خریدار</span>
          </caption>
          <tr class="w-100 d-flex">
            <td width="50%" class="border-external align-center px-1 py-2">
              <span class=""> نام شخص حقیقی / حقوقی : </span>
              <span class="pr-2">
                {{ buyerDetail.name }}
              </span>
            </td>
            <td width="25%" class="border-external align-center px-1 py-2 w-25">
              <span class="">کد ملی / شناسه ملی :</span>
              <span class="pr-2">{{ buyerDetail.nationalCode }}</span>
            </td>
            <td width="25%" class="border-external align-center px-1 py-2 w-25">
              <span class="">شماره ثبت :</span>
              <span class="pr-2">{{ buyerDetail.registerNumber }}</span>
            </td>
          </tr>

          <tr class="w-100 d-flex">
            <td
              width="25%"
              class="border-external align-center px-1 py-2 w-25"
              style="width: 25%"
            >
              <span class=""> استان : </span>
              <span class="pr-2">
                {{ buyerDetail.province }}
              </span>
            </td>
            <td class="border-external align-center px-1 py-2 w-25">
              <span class=""> شهر : </span>
              <span class="pr-2">
                {{ buyerDetail.city }}
              </span>
            </td>
            <td class="border-external align-center px-1 py-2 w-25">
              <span class=""> کد پستی ۱۰ رقمی : </span>
              <span class="pr-2">
                {{ buyerDetail.postalCode }}
              </span>
            </td>
            <td class="border-external align-center px-1 py-2 w-25">
              <span class=""> تلفن : </span>
              <span class="pr-2">
                {{ buyerDetail.phone }}
              </span>
            </td>
          </tr>
          <tr class="w-100 d-flex">
            <td class="border-external align-center px-1 py-2 w-100">
              <span class=""> نشانی : </span>
              <span class="pr-2">
                {{ buyerDetail.address }}
              </span>
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "Buyer",

  props: {
    buyerDetail: {
      type: Object,
      require: true,
    },
  },
};
</script>

<style scoped>
.border-card {
  border: 2px solid black;
}
</style>
