import api from "@/tools/ApiService.service";
import urls from "@/tools/Urls";

/**
 *  send email for reset password
 *  ------------------------
 *  Api address| /auth/forget-password
 *  ------------------------
 *  Method| GET
 *  ------------------------
 *  Description| send email for reset password
 *  ------------------------
 *  @param payload {Object}
 *  ------------------------
 *  @return  type {Promise}
 *  ------------------------
 **/
export const sendEmailForForgotPasswordAxiosRequest = function (payload) {
  return api.get(urls.api("auth_forget_password"), {
    headers: {
      email: payload.email,
    },
  });
};
