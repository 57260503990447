"use strict";
import { _t } from "@/tools/Utils";
import Authentication from "@/packages/authentication/presentation/views/Authentication";
export default [
  {
    path: "/",
    name: "authentication",
    component: Authentication,
    redirect: { name: "authentication.login" },
    meta: {
      requiresGuest: true,
      title: _t("route_titles.login.title"),
    },
    children: [
      {
        path: "auth/login",
        name: "authentication.login",
        // TODO REMOVE THIS REDIRECTION
        // redirect: { name: "dashboard" },
        component: () =>
          import(
            /* webpackChunkName: "auth-pkg" */ "@/packages/authentication/presentation/views/Login"
          ),
        meta: {
          title: _t("route_titles.login.title"),
        },
      },
      {
        path: "auth/forget-password",
        name: "authentication.forget-password",
        component: () =>
          import(
            /* webpackChunkName: "auth-pkg" */ "@/packages/authentication/presentation/views/ForgetPassword"
          ),
        meta: {
          title: _t("route_titles.forget_password.title"),
        },
      },
      {
        path: "auth/verify-otp",
        name: "authentication.verify-otp",
        component: () =>
          import(
            /* webpackChunkName: "auth-pkg" */ "@/packages/authentication/presentation/views/VerifyOtp"
          ),
        meta: {
          title: _t("route_titles.verify_otp.title"),
        },
      },
      {
        path: "auth/reset-password",
        name: "authentication.reset-password",
        component: () =>
          import(
            /* webpackChunkName: "auth-pkg" */ "@/packages/authentication/presentation/views/ResetPassword"
          ),
        meta: {
          title: _t("route_titles.reset_password.title"),
        },
      },
      {
        path: "auth/change-password",
        name: "authentication.change-password",
        component: () =>
          import(
            /* webpackChunkName: "auth-pkg" */ "@/packages/authentication/presentation/views/ChangePassword"
          ),
        meta: {
          title: _t("route_titles.change_password.title"),
        },
      },
    ],
  },
];
