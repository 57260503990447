<template>
  <LipakModal
    @close="$emit('close')"
    :modal="modal"
    title="
    ویرایش آدرس
    "
    size="lg"
  >
    <template v-slot:body>
      <b-col cols="12" class="mx-auto justify-content-between">
        <!--Start form actions ------------------------------------------------------------------>
        <b-form-radio-group
          class="mb-3 text-right"
          v-model="selectedAddress"
          :options="address"
          value-field="item"
          text-field="name"
          disabled-field="notEnabled"
        ></b-form-radio-group>

        <!--End   form actions ------------------------------------------------------------------>
      </b-col>
    </template>
    <template v-slot:footer>
      <b-row class="w-100 flex-row-reverse">
        <b-button class="w-25 text-white" variant="success" size="sm">
          ثبت
        </b-button>
        <b-button class="w-25 text-white ml-2" size="sm" @click="closeModal">
          بستن
        </b-button>
      </b-row>
    </template>
  </LipakModal>
</template>

<script>
import LipakModal from "@/components/app/Modal/LipakModal";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "EditAddress",
  data: function () {
    return {
      selectedAddress: "",
      address: [
        {
          name:
            "  تهران - خیابان ولی‌عصر - بالاتر از میدان ولی‌عصر -کوچه شهید شهامتی - پلاک ۲۱ ",
          value: 1,
        },
        {
          name:
            "  تهران - خیابان ولی‌عصر - بالاتر از میدان ولی‌عصر -کوچه شهید شهامتی - پلاک ۲۱ ",
          value: 2,
        },
      ],
    };
  },
  components: {
    LipakModal,
  },

  props: {
    modal: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      is_loading: "loading/is_loading",
      addressList: "order/addressList",
    }),
  },

  validations: {
    // validations,
  },
  mounted() {
    this.setAddress(this.address);
  },
  methods: {
    ...mapActions({
      setAddress: "order/set_address_list",
    }),
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped></style>
