<template>
  <div>
    <b-button variant="primary" @click="showConfirmation = true"
      >لغو کلی سفارش مرجوعی</b-button
    >
    <b-card v-for="item in items" :key="item.id" class="border-0 shadow mt-3">
      <ItemDetail :item="item" />
      <hr />
      <ItemForm :item="item" :reject-reasons="rejectReasons" />
    </b-card>
    <ConfirmationModal
      @close="showConfirmation = false"
      @ok="deleteOrder"
      :modal="showConfirmation"
    />
  </div>
</template>

<script>
import ItemDetail from "@/packages/orders/components/order-detail/returned-orders-detail/step1-2/items/ItemDetail";
import ItemForm from "@/packages/orders/components/order-detail/returned-orders-detail/step1-2/items/ItemForm";
import { mapGetters } from "vuex";
import {
  deleteReturnedOrderDetailAxiosRequest,
  getRejectedReasonsAxiosRequest,
} from "@/packages/orders/api/returned-order-detail.api";
import ConfirmationModal from "@/components/app/ConfirmationModal";
import message from "@/tools/Message";
export default {
  components: { ConfirmationModal, ItemForm, ItemDetail },
  computed: { ...mapGetters({ items: "returned/items" }) },
  data() {
    return {
      rejectReasons: [],
      showConfirmation: false,
    };
  },
  created() {
    this.getRejectReasons();
  },
  methods: {
    async getRejectReasons() {
      const response = await getRejectedReasonsAxiosRequest();
      this.rejectReasons = response
        .filter((item) => item.isActive)
        .map((item) => ({ value: item.id, title: item.title }));
    },
    async deleteOrder() {
      try {
        await deleteReturnedOrderDetailAxiosRequest(this.$route.params.id);
        await this.$router.push({ name: "list.returned-orders" });
      } catch (err) {
        console.log(err);
        message.error("مشکلی پیش آمده");
      }
      this.showConfirmation = false;
    },
  },
};
</script>

<style scoped></style>
