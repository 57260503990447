import { render, staticRenderFns } from "./VerifyOtp.vue?vue&type=template&id=56b2f962&scoped=true&"
import script from "./VerifyOtp.vue?vue&type=script&lang=js&"
export * from "./VerifyOtp.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56b2f962",
  null
  
)

export default component.exports