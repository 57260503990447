<template>
  <b-col cols="12" md="6" lg="3" class="p-3">
    <LipakForm
      ref="topFilter"
      class="position-relative w-100 bg-white p-4 my-3 rounded-lg fa-num-font"
    >
      <!------------------ start body of form --------------->
      <template v-slot:body>
        <LipackFormGenerator :schema="schema" :validator="$v" />
      </template>
      <!------------------ end body of form ----------------->
      <!------------------- start action of form ------------>
      <template v-slot:action>
        <b-col cols="12" class="d-flex justify-content-end submit-button mt-3">
          <b-button
            @click="searchOrder"
            class="fs-14 text-white bg-success border-0 px-lg-4"
          >
            جست و جو
          </b-button>
        </b-col>
      </template>

      <!------------------ end action of form ---------------->
    </LipakForm>
  </b-col>
</template>

<script>
import { SEARCH_FORM_NATIONAL_CODE } from "@/packages/orders/schema/form/SEARCH_FORM_NATIONAL_CODE";
import LipakForm from "@/components/app/form/LipakForm";
import LipackFormGenerator from "@/components/app/form/LipackFormGenerator";
import OrderService from "@/packages/orders/services/order.service";
import filterContainer from "@/mixins/filter-container";

export default {
  name: "SearchByNationalCode",
  mixins: [filterContainer],
  components: {
    LipakForm,
    LipackFormGenerator,
  },
  data: () => {
    return {
      schema: SEARCH_FORM_NATIONAL_CODE.schema,
      form: { ...SEARCH_FORM_NATIONAL_CODE.model },
      Order: new OrderService(),
      filterName: "form1",
    };
  },
  validations: {
    ...SEARCH_FORM_NATIONAL_CODE.validations,
  },
};
</script>

<style scoped></style>
