import api from "@/tools/Api";
import urls from "@/tools/Urls";

/*
 *  update addreses
 *  ------------------------
 *  Api address | /api/v1/
 *  ------------------------
 *  @param orderId {String}
 *  @param addressId {String}
 *  ------------------------
 *  method : Put
 *  ------------------------
 *  Description :
 *  ------------------------
 *  update addreses
 *  ------------------------
 *  @return Promise
 *  ------------------------
 */

export const updateAddressAxiosRequest = function (orderId, addressId) {
  return api.get(urls.api("fetch_address_shipping_methods", [addressId]));
};
