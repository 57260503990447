<template>
  <Welcome_component></Welcome_component>
</template>

<script>
import Welcome_component from "@/components/dashboard/Welcome";
export default {
  name: "Welcome",
  components: {
    Welcome_component,
  },
};
</script>

<style scoped></style>
