<template>
  <b-container fluid class="shadow border-0 bg-white p-4 m-3 mt-lg-0">
    <b-row>
      <!--Start Actions                ------------------------------------------------------>
      <b-col
        cols="12"
        class="position-absolute"
        style="left: 10px; z-index: 1010"
      >
        <b-button
          size="sm"
          pill
          variant="outline-primary"
          class="border-0"
          @click="isShowModal = true"
        >
          <LIEdit class="fs-12" />
        </b-button>
      </b-col>
      <!--End   Actions                ------------------------------------------------------>

      <!--Start Avatar                 ------------------------------------------------------>
      <b-col cols="12" lg="2">
        <Avatar />
      </b-col>
      <!--End   Avatar                 ------------------------------------------------------>

      <!--Start Personal Information   ------------------------------------------------------>
      <b-col cols="12" lg="5" class="border-right text-right">
        <PersonalInfo :personal-info="personalInfo" />
      </b-col>
      <!--End   Personal Information   ------------------------------------------------------>

      <!--Start Contact Information    ------------------------------------------------------>
      <b-col cols="12" lg="5" class="border-right">
        <ContactInfo :personal-info="personalInfo" :phone="phone" />
      </b-col>
      <!--End   Contact Information    ------------------------------------------------------>
    </b-row>

    <EditBaseInformationModal
      :personal-info="personalInfo"
      :modal="isShowModal"
      @close="isShowModal = false"
    />
  </b-container>
</template>

<script>
import Avatar from "@/packages/users/components/user/profile/base-information/Avatar";
import PersonalInfo from "@/packages/users/components/user/profile/base-information/PersonalInfo";
import ContactInfo from "@/packages/users/components/user/profile/base-information/ContactInfo";
import EditBaseInformationModal from "@/packages/users/components/user/profile/base-information/modal/EditBaseInformationModal";
import { mapGetters } from "vuex";
export default {
  name: "BaseInformation",
  components: { EditBaseInformationModal, ContactInfo, PersonalInfo, Avatar },
  data() {
    return {
      isShowModal: false,
    };
  },

  computed: {
    ...mapGetters({
      personalInfo: "profile/personalInfo",
      phone: "profile/phone",
    }),
  },
};
</script>

<style>
.list {
  padding: 0 !important;
  list-style: none !important;
}

.list > li {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: auto;
  margin-top: 10px !important;
}
</style>
