<template>
  <div class="text-right">
    <div class="text-more fs-18 font-weight-bold">وضعیت مرجوعی</div>
    <div>
      <div class="mt-4">تایید توسط پشتیبانی: {{ supportStatus }}</div>
      <div v-if="item.status === 'APPROVED'" class="mt-2">
        <div class="d-block d-lg-flex flex-wrap align-items-center">
          <div>شماره سریال‌های تحویل شده:</div>
          <b-card
            no-body
            v-for="approvedItem in item.approvedItems"
            :key="approvedItem.id"
            class="my-3 mr-lg-2 px-3 py-2 py-lg-1"
          >
            {{ approvedItem.selectedSerialNumber }}
          </b-card>
        </div>
        <div class="d-flex mt-2">
          <div>تعداد آیتم‌های تحویل نشده:</div>
          <div class="mr-2">
            {{
              item.approvedItems.filter(
                (approvedItem) => !approvedItem.isDeliver
              ).length
            }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const SUPPORT_STATUS = {
  APPROVED: "تایید شده",
  REJECTED: "عدم تایید",
};
export default {
  props: ["item"],
  computed: {
    supportStatus() {
      return SUPPORT_STATUS[this.item.status];
    },
  },
};
</script>

<style scoped></style>
