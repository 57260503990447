<template>
  <div>
    <Items :items="step4Items" />
    <Description class="mt-3" :description="description" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Items from "@/packages/orders/components/order-detail/returned-orders-detail/step4/items/Index";
import Description from "@/packages/orders/components/order-detail/returned-orders-detail/step4/Description";
export default {
  name: "Index",
  components: { Description, Items },
  computed: {
    ...mapGetters({
      step4Items: "returned/step4Items",
      description: "returned/description",
    }),
  },
};
</script>

<style scoped></style>
