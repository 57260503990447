<template>
  <LipakModal
    @close="closeModal"
    :modal="modal"
    title="آیا از تایید پرداخت اطمینان دارید؟"
    size="md"
  >
    <template v-slot:body>
      <b-col cols="12" class="mx-auto justify-content-between px-0 fa-num-font">
      </b-col>
    </template>

    <template v-slot:footer>
      <b-row class="w-100 flex-row-reverse mt-3">
        <b-col cols="12">
          <b-button
            class="w-25 text-white ml-2"
            size="sm"
            @click="closeModal"
            :disabled="isLoading"
          >
            خیر
          </b-button>
          <b-button
            class="w-25 text-white"
            variant="success"
            size="sm"
            @click.prevent="acceptPayment"
            :disabled="isLoading"
          >
            <b-spinner v-if="isLoading" small variants="primary"></b-spinner>
            بله
          </b-button>
        </b-col>
      </b-row>
    </template>
  </LipakModal>
</template>

<script>
import LipakModal from "@/components/app/Modal/LipakModal";
import OrderService from "@/packages/orders/services/order.service";
import { mapGetters } from "vuex";
export default {
  name: "AcceptPayment",
  data: function () {
    return {
      Order: new OrderService(),
    };
  },
  props: {
    modal: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      isLoading: "loading/is_loading",
    }),
  },
  methods: {
    async acceptPayment() {
      try {
        await this.Order.acceptPayment(this.$route.params.id);
        await this.Order.fetchOrderDetailById(this.$route.params.id);
        this.closeModal();
        window.location.reload();
      } catch (e) {
        this.closeModal();
      }
    },
    closeModal() {
      this.$emit("close");
    },
  },
  components: {
    LipakModal,
  },
};
</script>
<style scoped></style>
