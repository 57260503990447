import api from "@/tools/ApiService.service";
import urls from "@/tools/Urls";

/**
 *  send otp to verification
 *  ------------------------
 *  Api address| /auth/verify-otp
 *  ------------------------
 *  Method| GET
 *  ------------------------
 *  Description| send otp to verification
 *  ------------------------
 *  @param payload {Object}
 *  ------------------------
 *  @return  type {Promise}
 *  ------------------------
 **/
export const verifyOtpAxiosRequest = function (payload) {
  return api.get(urls.api("auth_verify_otp"), {
    headers: {
      email: payload.email,
      otp: payload.otp,
    },
  });
};
