<template>
  <b-card class="border-0 shadow">
    <ItemDetail :item="item" />
  </b-card>
</template>

<script>
import ItemDetail from "@/packages/orders/components/order-detail/returned-orders-detail/step4/items/ItemDetail";
export default {
  name: "Serial",
  components: { ItemDetail },
  props: ["item"],
};
</script>

<style scoped></style>
