<template>
  <b-card class="shadow-sm border-0">
    <LipakInput
      v-model="reason"
      title="ثبت دلیل رد مرجوعی"
      placeholder="دلیل رد مرجوعی"
      :show-message="false"
      class="w-25 d-inline-block ml-3"
      required
    />
    <b-button variant="success" class="px-5" @click="submit" :disabled="pending"
      >ثبت</b-button
    >
  </b-card>
</template>

<script>
import message from "@/tools/Message";
import { returnRejectReasonRecordAxiosRequest } from "@/packages/manage/api/returnRejectReason.api";

export default {
  data() {
    return {
      reason: "",
      pending: false,
    };
  },
  methods: {
    async submit() {
      if (!this.reason) {
        return message.error("دلیل مرجوعی را بنویسید.");
      }
      this.pending = true;
      try {
        await returnRejectReasonRecordAxiosRequest({ title: this.reason });
        this.reason = "";
        message.success("با موفقیت ثبت شد");
        this.$emit("update-table");
      } catch (error) {
        console.log(error);
        message.error("مشکلی پیش آمده");
      }
      this.pending = false;
    },
  },
};
</script>

<style scoped></style>
