<template>
  <b-col style="height: 100px !important" cols="12" class="px-0 my-5 my-lg-2">
    <b-nav class="d-flex justify-content-between shadow-none p-0">
      <b-nav-item
        class="d-flex flex-wrap justify-content-center text-center bg-white shadow text-secondary"
        style="width: 19%"
        v-for="tab in states"
        :key="tab.index"
        :to="tab.state"
        >{{ tab.statePersianName }}

        <br />
        <div class="mt-1 font-weight-bold">{{ tab.count }}</div>
      </b-nav-item>
    </b-nav></b-col
  >
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "TabsOrderStates",
  computed: {
    ...mapGetters({
      states: "orders/states",
    }),
  },
};
</script>

<style scoped>
.nav-item {
  border-radius: 15px !important;
  display: inline-flex;
  align-self: self-end;
  border-bottom: 0 !important;
}
.nav-link {
  padding: 20px 0;
  width: 100%;
  color: #82828e !important;
}
.router-link-exact-active {
  border-radius: 15px !important;
  border: 1px solid #fe6a34 !important;
  color: #fe6a34 !important;
}
.nav-tabs {
  border-bottom: 0 !important;
}
</style>
