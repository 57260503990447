<template>
  <div>
    <b-card v-if="items.supportRejected.length" class="mt-3 border-0 shadow">
      <div class="text-center">وضعیت: عدم تایید توسط پشتیبانی</div>
      <Serial
        v-for="item in items.supportRejected"
        :key="item.id"
        :item="item"
        class="mt-3"
      />
    </b-card>

    <b-card v-if="items.qcNotDelivered.length" class="mt-3 border-0 shadow">
      <div class="text-center">وضعیت: عدم تحویل توسط کنترل کیفیت</div>
      <Serial
        v-for="item in items.qcNotDelivered"
        :key="item.id"
        :item="item"
        class="mt-3"
      />
    </b-card>

    <b-card v-if="items.qcApproved.length" class="mt-3 border-0 shadow">
      <div class="text-center">وضعیت: تایید شده توسط کنترل کیفیت</div>
      <Serial
        v-for="item in items.qcApproved"
        :key="item.id"
        :item="item"
        class="mt-3"
      />
    </b-card>

    <b-card v-if="items.qcNotApproved.length" class="mt-3 border-0 shadow">
      <div class="text-center">وضعیت: عدم تایید توسط کنترل کیفیت</div>
      <Serial
        v-for="item in items.qcNotApproved"
        :key="item.id"
        :item="item"
        class="mt-3"
      />
      <b-button variant="primary" class="mt-3">روش ارسال و زمان برگشت</b-button>
    </b-card>
  </div>
</template>

<script>
import Serial from "@/packages/orders/components/order-detail/returned-orders-detail/step4/items/Serial";
export default {
  name: "Index",
  components: { Serial },
  props: ["items"],
};
</script>

<style scoped></style>
