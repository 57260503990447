<template>
  <div class="w-100">
    <ReturnSubmit @update-table="updateTable++" />
    <ReturnTable class="mt-4" :update="updateTable" />
  </div>
</template>

<script>
import ReturnSubmit from "@/packages/manage/components/return-failure-reason/submit";
import ReturnTable from "@/packages/manage/components/return-failure-reason/table";
export default {
  name: "Index",
  components: {
    ReturnSubmit,
    ReturnTable,
  },
  data() {
    return {
      updateTable: 1,
    };
  },
};
</script>

<style scoped></style>
