import MessageService from "@/tools/Message";
import store from "@/store";
import { getUserProfileAxiosRequest } from "@/packages/authentication/repository/api/get_user_profile.api";
import { logoutUser } from "@/packages/authentication/services/Auth.service";

/**
 * get user profile
 * -----------------------
 * @param query_params {Object}
 * -----------------------
 * Description
 * -----------------------
 * Make api call to send otp to verification
 * -----------------------
 * @return Promise
 * */
export const getUserProfileService = async function (query_params = null) {
  try {
    const { data } = await getUserProfileAxiosRequest(query_params);
    await store.dispatch("authentication/saveUser", data);
  } catch (e) {
    //code 504
    MessageService.error("FETCH_USER_FAILED");
    await logoutUser();
  }
};
