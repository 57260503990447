<template>
  <LipakForm>
    <template slot="body">
      <LipakFormGenerator :schema="schema" :validator="$v.form" />
    </template>
  </LipakForm>
</template>

<script>
import LipakForm from "@/components/app/form/LipakForm";
import LipakFormGenerator from "@/components/app/form/LipakFormInput";
import cloneDeep from "lodash.clonedeep";
import { ReturnReasonSchema } from "@/packages/return-register/schema/return-reason.schema";
export default {
  name: "Reason",
  components: { LipakFormGenerator, LipakForm },
  props: ["isActive"],
  data() {
    return {
      schema: cloneDeep(ReturnReasonSchema.schema),
      form: cloneDeep(ReturnReasonSchema.model),
    };
  },
  validations: {
    form: cloneDeep(ReturnReasonSchema.validations),
  },
  watch: {
    form: {
      deep: true,
      handler(newVal) {
        this.$emit("update:description", newVal.description);
        this.$emit("update:return-reason", newVal.returnReason);
      },
    },
    isActive(newVal) {
      this.schema.forEach((item) => {
        item.disabled = !newVal;
      });
    },
  },
};
</script>

<style scoped></style>
