<template>
  <b-card class="w-100 border-0 shadow-sm">
    <b-skeleton v-if="isFetching" height="300px" />
    <div v-else-if="hasError" class="text-center">
      <b-button variant="primary" @click="getPaymentGateway">
        تلاش مجدد
      </b-button>
    </div>
    <div v-else>
      <h5 class="mb-4">انتخاب بانک‌های فعال</h5>
      <div
        v-for="item in gateways"
        :key="item.gateway"
        class="d-flex align-items-center"
      >
        <b-checkbox v-model="item.isActive" class="ml-2 mt-1" />
        <span class="mt-1 c-pointer" @click="item.isActive = !item.isActive">
          {{ item.persianName }}
        </span>
      </div>
      <hr />
      <h5>انتخاب درگاه پیش‌فرض</h5>
      <div
        v-for="item in gateways"
        :key="`${item.gateway}-default`"
        class="d-flex align-items-center"
      >
        <b-form-radio
          name="default-gateway"
          v-model="defaultGateway"
          :value="item.gateway"
          class="ml-2 mt-1"
        />
        <span class="mt-1 c-pointer" @click="defaultGateway = item.gateway">
          {{ item.persianName }}
        </span>
      </div>
      <div class="text-left mt-5">
        <b-button
          :disabled="isPosting"
          variant="primary"
          class="px-5"
          @click="submit"
          >ثبت</b-button
        >
      </div>
    </div>
  </b-card>
</template>

<script>
import {
  getPaymentGatewayStatus,
  updatePaymentGatewayStatus,
} from "@/packages/manage/api/payoff";
import message from "@/tools/Message";

export default {
  name: "Index",
  data() {
    return {
      isFetching: true,
      hasError: false,
      isPosting: false,
      gateways: [],
      defaultGateway: "",
    };
  },
  created() {
    this.getPaymentGateway();
  },
  methods: {
    async getPaymentGateway() {
      this.isFetching = true;
      this.hasError = false;
      try {
        const gateways = await getPaymentGatewayStatus();
        this.gateways = gateways;
        this.defaultGateway = (
          gateways.find((item) => item.isDefault) || { gateway: "" }
        ).gateway;
      } catch (error) {
        console.error(error);
        message.error("مشکلی پیش آمده");
        this.hasError = true;
      }
      this.isFetching = false;
    },
    async submit() {
      const isValid = this.validate();
      if (!isValid) return;

      this.isPosting = true;
      try {
        await updatePaymentGatewayStatus({
          paymentMethod: "ONLINE",
          activeBanks: this.gateways
            .filter((item) => item.isActive)
            .map((item) => item.gateway),
          defaultGateway: this.defaultGateway,
        });
        message.success("با موفقیت آپدیت شد");
      } catch (err) {
        console.log(err);
        if (
          err?.response?.data?.message ===
          "Default gateway MUST be selected as active gateways!"
        ) {
          return message.error("درگاه پیش‌فرض نمیتواند غیرفعال باشد");
        }
        message.error("مشکلی پیش آمده");
      }
      this.isPosting = false;
    },
    validate() {
      return this.defaultGateway;
    },
  },
};
</script>

<style scoped></style>
