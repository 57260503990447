<template>
  <b-container
    fluid
    :style="{ 'background-image': 'url(' + bg_image + ')' }"
    class="bg-container d-flex justify-content-center align-content-center align-items-center"
  >
    <b-col cols="12" lg="4">
      <GlassCard
        class="card-glass medium d-flex justify-content-center align-items-center text-center px-3"
      >
        <b-icon-shield-lock variant="white" font-scale="5" class="mb-4" />
        <h5 class="text-white">
          {{ $_t("views.authentication.title") }}
        </h5>
        <b-row>
          <router-view />
        </b-row>
      </GlassCard>
    </b-col>
  </b-container>
</template>

<script>
import GlassCard from "@/components/app/GlassCard";
export default {
  name: "Index",
  components: { GlassCard },
  data() {
    return {
      bg_image: require("@/assets/img/bg.png"),
    };
  },
};
</script>

<style scoped>
.bg-container {
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
}
.card-glass.medium {
  min-height: 350px !important;
}
</style>
