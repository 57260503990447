<template>
  <div class="mobile-menu-wrapper">
    <div class="container h-100">
      <div ref="menu-wrapper" class="position-relative overflow-auto h-100">
        <div
          ref="menu-top"
          class="mobile-menu-top"
          :style="{ marginBottom: `${menuTopMarginBottom}px` }"
        >
          <div class="mobile-menu-top-item mb-4">
            <div
              class="header-top-user-image fs-24 text-white d-flex-center font-weight-bold"
            >
              {{ userInfo.userDetail.name.charAt(0) }}
            </div>
            <div class="mx-3">
              <div class="fs-14 font-weight-bold">
                {{ userInfo.userDetail.name }}
                {{ userInfo.userDetail.lastName }}
              </div>
              <div class="fs-12 text-secondary">{{ role.persianTitle }}</div>
            </div>
          </div>
          <template v-for="link in links">
            <router-link
              v-if="link.isAdmin ? isAdmin : true"
              :key="'link-' + link.title"
              :to="link.to"
              :disabled="link.isDisabled"
              active-class=" border-bottom item-active text-primary"
              class="mobile-menu-top-item no-decorate"
            >
              <div class="mobile-menu-top-item-icon">
                <component :is="getIcon(link.icon)" class="fs-12 ml-2" />
              </div>
              <div>{{ $_t(`navigation_links.${link.title}`) }}</div>
            </router-link>
          </template>
        </div>
        <div
          ref="menu-bottom"
          class="mobile-menu-bottom"
          :class="{ 'mt-30': menuTopMarginBottom === 0 }"
        >
          <!--          <div class="mobile-menu-bottom-item">-->
          <!--            <div class="mobile-menu-bottom-item-icon">-->
          <!--              <LIGear icon="gear" />-->
          <!--            </div>-->
          <!--            <div>تنظیمات</div>-->
          <!--          </div>-->
          <div class="mobile-menu-bottom-item" @click="changePassword">
            <div class="mobile-menu-bottom-item-icon">
              <LIKey icon="key" />
            </div>
            <div>تغییر رمز عبور</div>
          </div>
          <div class="mobile-menu-bottom-item" @click="logout">
            <div class="mobile-menu-bottom-item-icon">
              <LISignOut icon="sign-out" />
            </div>
            <div>خروج</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LISignOut from "@lipak/lipak-icon/src/lipak-icon/signOut";
import LICheckGear from "@lipak/lipak-icon/src/lipak-icon/checkGear";
import LIKey from "@lipak/lipak-icon/src/lipak-icon/key";
import LIGroup from "@lipak/lipak-icon/src/lipak-icon/group";
import LITaskCheck from "@lipak/lipak-icon/src/lipak-icon/taskCheck";
import LIPlus from "@lipak/lipak-icon/src/lipak-icon/plus";
import { logoutUser } from "@/packages/authentication/services/Auth.service";
import { mapGetters } from "vuex";
import BOTTOM_HEADER_LINKS from "@/schema/linkes/bottom_header_links.schema";
const icons = {};
BOTTOM_HEADER_LINKS.forEach(async (link) => {
  icons[link.icon] = () =>
    import(`@lipak/lipak-icon/src/lipak-icon/${link.icon}`);
});
export default {
  components: {
    LISignOut,
    LICheckGear,
    LIKey,
    LIGroup,
    LITaskCheck,
    LIPlus,
  },
  data() {
    return {
      menuTopMarginBottom: undefined,
      links: BOTTOM_HEADER_LINKS,
    };
  },
  computed: {
    ...mapGetters({
      user: "authentication/user",
      role: "authentication/role",
    }),
    isAdmin() {
      let roles = this.role.simpleRoles.map((role) => role.title);
      return roles.some(
        (role) =>
          role === (process.env.VUE_APP_PANEL_ADMIN || "COMMERCIAL_ADMIN")
      );
    },
    userInfo() {
      return this.user
        ? this.user
        : {
            userDetail: {
              name: "",
              lastName: "",
            },
          };
    },
  },
  mounted() {
    this.setMenuTopMarginBottom();
    window.scrollTo(0, 0);
    document.body.classList.add("overflow-hidden");
  },
  beforeDestroy() {
    document.body.classList.remove("overflow-hidden");
  },
  methods: {
    getIcon(name) {
      return icons[name];
    },
    setMenuTopMarginBottom() {
      const {
        "menu-wrapper": menuWrapper,
        "menu-top": menuTop,
        "menu-bottom": menuBottom,
      } = this.$refs;

      const menuWrapperHeight = this.getNumberPartOfElementProperty(
        menuWrapper,
        "height"
      );
      const menuTopHeight = this.getNumberPartOfElementProperty(
        menuTop,
        "height"
      );
      const menuBottomHeight = this.getNumberPartOfElementProperty(
        menuBottom,
        "height"
      );
      const menuBottomMarginTop = this.getNumberPartOfElementProperty(
        menuBottom,
        "margin-top"
      );

      const innerMenuHeight =
        menuTopHeight + menuBottomHeight + menuBottomMarginTop;

      if (innerMenuHeight >= menuWrapperHeight) {
        this.menuTopMarginBottom = 0;
      } else {
        this.menuTopMarginBottom = menuWrapperHeight - innerMenuHeight;
      }
    },
    getNumberPartOfElementProperty(element, property) {
      return parseInt(getComputedStyle(element).getPropertyValue(property), 10);
    },
    async logout() {
      await logoutUser();
    },
    changePassword() {
      this.$router.push({ name: "panel.change-password" });
    },
  },
};
</script>

<style lang="scss" scoped>
.mobile-menu {
  &-wrapper {
    position: fixed;
    width: 100vw;
    // header-top height: 72px, header-separator height: 2px;
    height: calc(100vh - 74px);
    max-height: calc(100vh - 74px);
    top: 74px;
    right: 0;
    background-color: #f9f9fc;
    transition: opacity 0.5s;
    padding: 34px 0 24px;
    z-index: 1200;
  }
  &-top {
    &-item {
      background: #fff;
      color: #2c2c54;
      border-radius: 7px;
      margin-bottom: 8px;
      padding: 10px 16px;
      display: flex;
      align-items: center;
      &:last-child {
        margin-bottom: 0;
      }
      &-user {
        width: 44px;
        min-width: 44px;
        max-width: 44px;
        height: 44px;
        min-height: 44px;
        max-height: 44px;
        background: #2c2c54;
        color: #fff;
        border-radius: 5px;
      }
      &-icon {
        width: 25px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
      &:hover {
        background-color: var(--grey);
      }
    }
  }
  &-bottom {
    background: #fff;
    color: #2c2c54;
    border-radius: 7px;
    top: 30px;
    &.mt-30 {
      margin-top: 30px;
    }
    &-item {
      padding: 5px 16px;
      display: flex;
      align-items: center;
      &:first-child {
        padding-top: 10px;
      }
      &:last-child {
        padding-bottom: 10px;
      }
      &-icon {
        width: 25px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
    }
  }
}
</style>
