import api from "@/tools/Api";
import urls from "@/tools/Urls";

/*
 *  fetch Order Detail By IdA
 *  ------------------------
 *  Api address | /api/v1/order/support/customer/orderdetails/%s
 *  ------------------------
 *  @param orderId { String }
 *  ------------------------
 *  method : Get
 *  ------------------------
 *  Description :
 *  ------------------------
 *  fetch  users
 *  ------------------------
 *  @return Promise
 *  ------------------------
 */

export const fetchOrderDetailByIdAxiosRequest = function (orderId) {
  return api.get(urls.api("fetch_order_user_detail", [orderId]));
};
