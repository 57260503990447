import api from "@/tools/Api";
import urls from "@/tools/Urls";

/*
 *  fetch  users
 *  ------------------------
 *  Api address | /api/v1/user-manage
 *  ------------------------
 *  @param query_params { Object }
 *  ------------------------
 *  method : Get
 *  ------------------------
 *  Description :
 *  ------------------------
 *  fetch  users
 *  ------------------------
 *  @return Promise
 *  ------------------------
 */

export const fetchUsersAxiosRequest = function (
  query_params = null,
  cancelToken
) {
  return api.get(urls.api("fetch_users"), {
    params: {
      ...query_params,
    },
    ...cancelToken,
  });
};
