import message from "@/tools/Message";
import store from "@/store";
// import { fetchStatesAxiosRequest,fetchOrdersByStateAxiosRequest,downloadFactorAxiosRequest } from "@/packages/users/api";
import {
  fetchStatesAxiosRequest,
  fetchOrdersByStateAxiosRequest,
  fetchOrderDetailByIdAxiosRequest,
} from "@/packages/users/api";
import { abortApiHandler, AbortSource } from "@/tools/AbortApi";

export default class OrderUser {
  /*
   * fetch states
   * -----------------------
   * @param user_id {String}
   * -----------------------
   * Description
   * -----------------------
   * Make api call to fetch states
   * -----------------------
   * return promise
   * */
  async fetchStates(user_id) {
    try {
      let response = await fetchStatesAxiosRequest(user_id);
      await store.dispatch("orders/setStates", response);
      return response;
    } catch (err) {
      message.error(err);
      return Promise.reject(err);
    }
  }

  /*
   * fetch orders
   * -----------------------
   * @param state {String}
   * @paramuser_id (String)
   * @param pagination {String}
   * -----------------------
   * Description
   * -----------------------
   * Make api call to fetch orders
   * -----------------------
   * return promise
   * */
  async fetchOrders(state, user_id, pagination, cancelPrevApi) {
    try {
      if (cancelPrevApi) {
        abortApiHandler();
      }
      let response = await fetchOrdersByStateAxiosRequest(
        state,
        user_id,
        pagination,
        {
          cancelToken: AbortSource.axiosSource.token,
        }
      );
      await store.dispatch("orders/setOrders", response.content);
      await store.dispatch("pagination/setTotalItems", response.totalElements);
      await store.dispatch("pagination/setTotalIPages", response.totalPages);
      return;
    } catch (err) {
      if (err.response) {
        message.error("خطا در دریافت لیست سفارش‌ها");
      }
      await Promise.reject(err);
    }
  }

  /*  fetch fetchOrderDetailById  */
  async fetchOrderDetailById(orderId) {
    try {
      let response = await fetchOrderDetailByIdAxiosRequest(orderId);
      await store.dispatch("orders/setOrderDetail", response);
      await store.dispatch("order/setHasError", false);
    } catch (err) {
      await store.dispatch("order/setHasError", true);

      message.error(err);
    }
  }

  /*  downloadFactor  */
  async downloadFactor(orderId) {
    try {
      console.log(orderId);
      // await downloadFactorAxiosRequest(OrderId);
      return "https://www.toptal.com/hire-vue-js-developers?adnetwork=search&keyword=vue%20js%20freelancer&creative=535177654201&campaignid=10637620012&adgroupid=125860962900&matchtype=e&network=s&device=c&devicemodel=&adposition=&noun=&gclid=EAIaIQobChMI3_ed-q2O9gIVyfZRCh38xQ2WEAAYASAAEgJdPvD_BwE";
    } catch (err) {
      message.error(err);
    }
  }
}
