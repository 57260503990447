// initial state
const state = () => ({
  personalInfo: {},
  phone: "",
  bankDetails: {
    numbers: {},
    isPaymentByInvoiceActive: null,
  },
  legals: [],
  addresses: [],
  emailEditable: false,
});

// getters
const getters = {
  personalInfo: (state) => state.personalInfo,
  phone: (state) => state.phone,
  bankDetails: (state) => state.bankDetails,
  legals: (state) => state.legals,
  addresses: (state) => state.addresses,
  emailEditable: (state) => state.emailEditable,
};

// mutations
const mutations = {
  ///////start mutate user detail
  mutateUserPersonalInfo(state, payload) {
    state.personalInfo = {
      name: {
        title: "نام ",
        value: payload.name || "",
      },
      lastName: {
        title: "نام خانوادگی ",
        value: payload.lastName || "",
      },
      nationalCode: {
        title: "کد ملی",
        value: payload.nationalCode || "",
      },
      birthday: { title: "تاریخ تولد", value: payload.birthday || "" },
      email: { title: "ایمیل", value: payload.email || "" },
    };
  },
  mutatePhoneUser(state, payload) {
    state.phone = payload;
  },
  mutateBankDetails(state, payload) {
    state.bankDetails.numbers = {
      accountOwner: {
        title: "صاحب کارت",
        value: payload.accountOwner || "",
      },
      cartNumber: {
        title: "شماره کارت",
        value: payload.cartNumber || "",
      },
      shebaNumber: {
        title: "شماره شبا",
        value: payload.shebaNumber || "",
      },
    };
    state.bankDetails.isPaymentByInvoiceActive =
      payload.isPaymentByInvoiceActive;
  },
  mutateLegal(state, payload) {
    if (payload.length) {
      state.legals.length = 0;
      payload.forEach((legal) => {
        state.legals.push({
          legal: {
            companyName: {
              title: "نام شرکت",
              value: legal.companyName,
            },

            nationalId: { title: "شناسه ملی", value: legal.nationalId },
            registerNumber: {
              title: "شماره ثبت",
              value: legal.registerNumber,
            },
          },
          id: legal.id,
        });
      });
    } else {
      state.legals = [];
    }
  },
  mutateAddress(state, payload) {
    state.addresses = payload;
  },

  mutateEmailEditable(state, payload) {
    state.emailEditable = payload;
  },
  ///////end mutate user detail
};

// actions
const actions = {
  setUserDetail({ commit }, payload) {
    commit("mutateUserPersonalInfo", payload.customerDetail);
    commit("mutatePhoneUser", payload.phone);
    commit("mutateBankDetails", {
      ...payload.bankDetails,
      isPaymentByInvoiceActive: payload.isPaymentByInvoiceActive,
    });
    commit("mutateLegal", payload.legal);
    commit("mutateAddress", payload.address);
    commit("mutateEmailEditable", payload.isEmailEditable);
  },
};

export default {
  namespaced: true,
  name: "profile",
  state,
  getters,
  actions,
  mutations,
};
