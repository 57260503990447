import api from "@/tools/Api";
import urls from "@/tools/Urls";

/*
 * get list product group
 *--------------------------
 * Api address / api/v1/contents/productGroups
 *--------------------------
 *--------------------------
 * methods : GET
 * -------------------------
 * description :
 * -------------------------
 * get list product group
 *--------------------------
 * @return promise
 *--------------------------
 */
export const fetchProductGroupsAxiosReq = function () {
  return api.get(urls.api("get_all_product_groups"));
};
