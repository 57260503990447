<template>
  <LipakModal
    @close="$emit('close')"
    :modal="modal"
    :title="
      $_t(
        'components.users.user.profile.bank_information.modal.edit_bank_information_modal.payment_confirmation_title'
      )
    "
    size="md"
  >
    <template v-slot:body>
      <b-col cols="12" class="mx-auto fa-num-font text-center">
        <span v-if="!allowedAccess" class="more--text fs-16 font-weight-bold"
          >پرداخت به صورت حواله برای کاربر فعال شود؟</span
        >
        <span v-else class="more--text fs-16 font-weight-bold"
          >پرداخت به صورت حواله برای کاربر غیرفعال شود؟</span
        ></b-col
      >
    </template>
    <template v-slot:footer>
      <b-row class="w-100 mt-3">
        <b-col cols="12" class="d-flex justify-content-around">
          <b-button
            class="w-25 text-white ml-2"
            size="sm"
            @click="closeModal"
            :disabled="is_loading"
          >
            خیر
          </b-button>
          <b-button
            class="w-25 text-white"
            variant="success"
            size="sm"
            @click="submit"
            :disabled="is_loading"
          >
            <b-spinner v-if="is_loading" small variants="primary"></b-spinner>
            بله
          </b-button>
        </b-col>
      </b-row>
    </template>
  </LipakModal>
</template>

<script>
import { FORM_EDIT_SCHEMA } from "@/packages/users/schema/forms/edit.user.bank_information.schema";
import LipakModal from "@/components/app/Modal/LipakModal";
import { mapGetters } from "vuex";
import Profile from "@/packages/users/services/profile";
export default {
  name: "PaymentConfirmationModal",
  components: {
    LipakModal,
  },
  data: function () {
    return {
      Profile: new Profile(),
    };
  },
  validations: {
    ...FORM_EDIT_SCHEMA.validations,
  },
  props: {
    modal: {
      type: Boolean,
      default: false,
    },
    isPaymentByInvoiceActive: {
      type: Boolean,
      require: true,
    },
    allowedAccess: {
      type: Boolean,
      require: true,
    },
  },

  computed: {
    ...mapGetters({
      is_loading: "loading/is_loading",
    }),
  },

  methods: {
    async submit() {
      try {
        await this.Profile.paymentByRemittance(this.$route.params.user_id, {
          isPaymentByInvoiceActive: !this.isPaymentByInvoiceActive,
        });
        await this.Profile.fetchUserPersonalInfoById(
          this.$route.params.user_id
        );
        this.$emit("close");
      } catch (e) {
        console.log(e);
      }
    },
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped></style>
