<template>
  <b-row no-gutters class="fa-num-font">
    <!---------------start skeletonLodaer ------------------------------->

    <b-col cols="12" v-if="skeletonLoader">
      <ul class="d-flex states p-0 my-0">
        <li v-for="n in 5" :key="n.index" class="px-2 rounded-lg">
          <b-skeleton-img no-aspect height="94px"></b-skeleton-img>
        </li>
      </ul>
    </b-col>

    <!---------------end skeletonLodaer --------------------------------->

    <!---------------start request again for get order list ------------->
    <div v-else-if="hasError" class="w-100 d-block justify-content-center">
      <div class="text-primary text-center">خطای سرور !</div>
      <div class="text-center mt-2">
        <b-button variant="outline-primary" @click="initializeStates">
          درخواست مجدد</b-button
        >
      </div>
    </div>
    <!----------------end request again for get order list -------------->

    <b-col v-else cols="12">
      <TabsOrderStates />
      <router-view :key="$route.path" />
    </b-col>
  </b-row>
</template>

<script>
import TabsOrderStates from "./TabsOrderStates";
import OrderUser from "@/packages/users/services/user.orders.service";
import { mapGetters } from "vuex";
export default {
  name: "OrderLists",
  data() {
    return {
      OrderUser: new OrderUser(),
      skeletonLoader: true,
      hasError: false,
    };
  },
  computed: {
    ...mapGetters({
      states: "orders/states",
    }),
  },
  watch: {},
  components: {
    TabsOrderStates,
  },
  created() {
    this.initializeStates();
  },
  methods: {
    async initializeStates() {
      try {
        this.skeletonLoader = true;
        this.hasError = false;
        await this.OrderUser.fetchStates(this.$route.params.user_id);
        this.skeletonLoader = false;
      } catch (e) {
        this.hasError = true;
        this.skeletonLoader = false;
      }
    },
  },
};
</script>
<style scoped>
.states li {
  flex-basis: 20%;
  list-style: none;
}
</style>
