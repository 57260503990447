<template>
  <b-form-group class="text-right font-weight-bold">
    <hr class="my-4" />

    <span> شیوه ارسال </span>
    <b-form-radio-group
      v-model="shippingMethodSelected"
      class="d-flex flex-wrap mt-4"
    >
      <template v-for="option in shippingMethods">
        <b-col cols="12" lg="3" :key="option.title" class="my-2 mt-lg-0">
          <b-card
            class="shadow border rounded-lg text-right"
            style="height: 100%"
          >
            <div class="d-flex align-content-start fs-14">
              <b-form-radio
                :value="option.shippingMethodId"
                class="ml-3 mr-0"
              />
              {{ option.title }}
            </div>
            <hr />
            <div class="text-left fa-num-font">
              {{ $_currency(option.cost) }} ریال
            </div>
          </b-card>
        </b-col>
      </template>
    </b-form-radio-group>
  </b-form-group>
</template>

<script>
export default {
  name: "shippingMethods",
  props: {
    shippingMethods: {
      type: Array,
      required: true,
    },
    value: { type: Object, required: true },
  },
  data() {
    return {
      shippingMethod: this.value,
      shippingMethodSelected: "",
    };
  },

  watch: {
    shippingMethod(newValue) {
      this.shippingMethodSelected = newValue.shippingMethodId;
    },
    shippingMethodSelected(newValue) {
      let findShippingMethodSelectedInShippingMethods = this.shippingMethods.find(
        (item) => item.shippingMethodId === newValue
      );
      this.$emit("input", findShippingMethodSelectedInShippingMethods);
    },
  },
  mounted() {
    this.shippingMethodSelected = this.shippingMethod.shippingMethodId;
  },
};
</script>

<style scoped></style>
