<template>
  <b-row>
    <template v-if="skeletonLoader">
      <b-col
        cols="12"
        v-for="item in 4"
        :key="item.index"
        class="shadow w-100 border-0 fa-num-font mb-5 py-3"
      >
        <b-skeleton-img no-aspect height="150px"></b-skeleton-img>
      </b-col>
    </template>
    <template v-else-if="hasError">
      <b-col
        cols="12"
        class="shadow w-100 bg-white border-0 fa-num-font py-5 text-center"
      >
        <div class="fs-16 mb-3">خطای سرور</div>
        <b-button variant="primary" @click="initializeUserInformation">
          درخواست مجدد !</b-button
        >
      </b-col>
    </template>

    <template v-else>
      <BaseInformation />

      <BankInformation />

      <LegalInformation
        @fetchAgainUserInformation="initializeUserInformation"
      />

      <AddressInformation
        @fetchAgainUserInformation="initializeUserInformation"
      />
    </template>
  </b-row>
</template>

<script>
import BaseInformation from "@/packages/users/components/user/profile/base-information/BaseInformation";
import BankInformation from "@/packages/users/components/user/profile/bank-information/BankInformation";
import LegalInformation from "@/packages/users/components/user/profile/legal-information/LegalInformation";
import AddressInformation from "@/packages/users/components/user/profile/address-information/AddressInformation";
import Profile from "@/packages/users/services/profile";
export default {
  name: "Profile",
  components: {
    AddressInformation,
    LegalInformation,
    BankInformation,
    BaseInformation,
  },
  data() {
    return {
      Profile: new Profile(),
      skeletonLoader: true,
      hasError: false,
    };
  },
  mounted() {
    this.initializeUserInformation();
  },
  methods: {
    async initializeUserInformation() {
      try {
        this.skeletonLoader = true;
        this.hasError = false;
        await this.Profile.fetchUserPersonalInfoById(
          this.$route.params.user_id
        );
      } catch (e) {
        this.hasError = true;
      } finally {
        this.skeletonLoader = false;
      }
    },
  },
};
</script>

<style scoped></style>
