<template>
  <b-card no-body class="w-100 px-5 py-4 mb-3 rounded-lg shadow border-1">
    <!------------start image of product and name product ------------------------------------>
    <b-row>
      <b-col cols="10" class="text-right">
        <ul class="d-block list-unstyled pr-0">
          <li class="fs-16 font-weight-bold more-text">
            {{ productComment.productName }}
          </li>
        </ul>
      </b-col>
    </b-row>
    <!------------end image of product and name product -------------------------------------->

    <hr />

    <!------------start comment user --------------------------------------------------------->
    <div
      class="text-right fs-19 font-weight-bold d-flex justify-content-between"
    >
      <span v-if="productComment.title" class="fs-18 font-weight-bold">
        {{ productComment.title }}</span
      >
      <div class="mr-auto" v-if="productComment.productCommentStatus">
        <span
          :class="productComment.productCommentStatus.class"
          class="fs-14 py-1 px-3 rounded-pill"
          >{{ productComment.productCommentStatus.title }}</span
        >
      </div>
    </div>
    <div class="text-right fs-17 pt-3 fs-18 font-weight-medium">
      {{ productComment.content }}
    </div>
    <!------------end comment user ----------------------------------------------------------->

    <!------------start reply lipak ---------------------------------------------------------->
    <div class="text-right mt-4 d-flex align-items-center">
      <!------------start image defult lipak ---->
      <img
        width="40"
        :height="40"
        :src="require('@/assets/img/LIPAKd.svg')"
        alt="23"
      />
      <!------------end image defult lipak ------>

      <!------------start reply lipak ----------->
      <div class="d-block justify-content-center" v-if="productComment.reply">
        <div class="px-3 fs-14">پاسخ لیپک</div>

        <div class="text-center pt-2">
          <span
            :class="productComment.productCommentStatus.class"
            class="fs-12 py-1 px-3 rounded-pill"
          >
            {{ productComment.productCommentStatus.title }}
          </span>
        </div>
      </div>
      <div v-if="productComment.reply" class="d-block justify-content-center">
        <div
          v-if="productComment.reply.title"
          class="px-3 text-right fs-18 font-weight-bold"
        >
          {{ productComment.reply.title }}
        </div>

        <div
          v-if="productComment.reply.content"
          class="px-3 fs-17 font-weight-medium"
        >
          {{ productComment.reply.content }}
        </div>
      </div>
      <!------------end reply lipak ------------->
    </div>
    <!------------end reply lipak ------------------------------------------------------------>
  </b-card>
</template>

<script>
export default {
  name: "UserComment",
  props: {
    comment: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      productComment: {},
      status: {
        waiting: {
          title: "در انتظار",
          value: "waiting",
          class: "bg-light-success text-success",
        },
        accepted: {
          title: "تایید شده",
          value: "accepted",
          class: "bg-light-success text-success",
        },
        rejected: {
          title: "رد شده",
          value: "rejected",
          class: "bg-light-danger text-danger",
        },
        edited: {
          title: "ویرایش شده",
          value: "edited",
          class: "bg-light-primary text-primary",
        },
        editedAndAccepted: {
          title: "ویرایش و تایید شده",
          value: "editedAndAccepted",
          class: "bg-light-primary text-primary",
        },
      },
    };
  },
  mounted() {
    this.productComment = { ...this.comment };
    this.productComment.productCommentStatus = this.setStatus(
      this.productComment.productCommentStatus
    );
  },
  methods: {
    setStatus(status) {
      return this.status[status];
    },
  },
};
</script>

<style scoped></style>
