<template>
  <b-form>
    <!--Start Form content    ------------------------------------------------------------------------>
    <b-row class="mt-n3">
      <!--Start input         ----------------------------------->
      <b-col
        v-for="item in schema"
        :key="'input-' + item.label ? item.label : item.id"
        :cols="item.cols"
        :lg="item.lg"
        :md="item.md"
        :sm="item.sm"
      >
        <lipak-input
          style="text-wrap: none !important"
          v-model="form[item.id]"
          :id="'id-' + item.label ? item.label : item.id"
          :type="item.type"
          :label="$_t(`attribute.${item.label}`) || ''"
          :placeholder="$_t(`placeholder.${item.id}`) || ''"
          :data="item.data || null"
          :show-message="false"
          @input="addCustomerMobileOrProductName"
        ></lipak-input>
      </b-col>
      <!--End   input         ----------------------------------->
    </b-row>
    <!--End   Form content    ------------------------------------------------------------------------>
  </b-form>
</template>

<script>
import { TEXT_SEARCH_FILTER_SCHEMA } from "@/packages/comments/schema/filters/product-managment/text_search.filter";

export default {
  name: "TextSearch",
  data() {
    return {
      schema: TEXT_SEARCH_FILTER_SCHEMA.schema,
      form: TEXT_SEARCH_FILTER_SCHEMA.model,
    };
  },
  methods: {
    addCustomerMobileOrProductName(customerMobileOrProductName) {
      this.$emit("addMobileOrProductNameToForm", customerMobileOrProductName);
    },
  },
};
</script>

<style scoped></style>
