<template>
  <div>
    <b-card v-if="items.supportRejected.length" class="mt-3 border-0 shadow">
      <div class="text-center">وضعیت: عدم تایید توسط پشتیبانی</div>
      <Serial
        v-for="item in items.supportRejected"
        :key="`supportRejected-${item.id}`"
        :item="item"
        class="mt-3"
      />
    </b-card>

    <b-card v-if="items.qcNotDelivered.length" class="mt-3 border-0 shadow">
      <div class="text-center">وضعیت: عدم تحویل توسط کنترل کیفیت</div>
      <Serial
        v-for="item in items.qcNotDelivered"
        :key="`qcNotDelivered-${item.id}`"
        :item="item"
        class="mt-3"
      />
    </b-card>

    <b-card v-if="items.qcApproved.length" class="mt-3 border-0 shadow">
      <div class="text-center">وضعیت: تایید شده توسط کنترل کیفیت</div>
      <Serial
        v-for="item in items.qcApproved"
        :key="`qcApproved-${item.id}`"
        :item="item"
        class="mt-3"
      />
    </b-card>

    <b-card v-if="items.qcNotApproved.length" class="mt-3 border-0 shadow">
      <div class="text-center">وضعیت: عدم تایید توسط کنترل کیفیت</div>
      <Serial
        v-for="item in items.qcNotApproved"
        :key="`qcNotApproved-${item.id}-${item.approvedItem.selectedSerialNumber}`"
        :item="item"
        class="mt-3"
      />

      <div class="p-3 text-right" v-if="shipping || address">
        <div class="mb-3">اطلاعات روش ارسال و زمان برگشت</div>
        <div class="mt-2" v-if="address">
          آدرس:

          {{ address.province.name }} ، {{ address.city.name }}،
          {{ address.quarter ? address.quarter.name + ", " : "" }}
          {{ address.addressDetails }}
        </div>
        <div class="d-flex mt-2">
          <div>شیوه ارسال: {{ shipping.shippingSettingTitle }}</div>
          <div class="mr-3" v-if="shipping.shippingCost">
            هزینه ارسال: {{ shipping.shippingCost.toLocaleString() }} ریال
          </div>
        </div>
        <div class="mt-2" v-if="shipping.dayDate">
          تاریخ تحویل سفارش: {{ $_date(shipping.dayDate) }}
        </div>
        <div class="mt-2" v-if="shipping.startShift && shipping.endShift">
          زمان تحویل سفارش:
          <span dir="ltr">
            {{ shipping.startShift }} -
            {{ shipping.endShift }}
          </span>
        </div>
      </div>
      <b-button @click="showSendModal = true" variant="primary" class="mt-3">
        {{ shipping ? "ویرایش" : "ثبت" }} روش ارسال و زمان برگشت
      </b-button>
      <SendModal
        :order-id="orderUuid"
        :user-id="userId"
        :current-address="{}"
        @close="showSendModal = false"
        :modal="showSendModal"
        :items="modalItems"
      />
    </b-card>
  </div>
</template>

<script>
import Serial from "@/packages/orders/components/order-detail/returned-orders-detail/step4/items/Serial";
import SendModal from "@/packages/orders/components/order-detail/returned-orders-detail/step4/send-modal/Index";
import { mapGetters } from "vuex";
export default {
  name: "Index",
  components: { Serial, SendModal },
  props: ["items"],
  data() {
    return {
      showSendModal: false,
      currentAddress: {},
    };
  },
  computed: {
    ...mapGetters({
      userId: "returned/userId",
      orderUuid: "returned/orderUuid",
      shipping: "returned/shipping",
      address: "returned/address",
    }),
    modalItems() {
      return [
        ...new Set(
          this.items.qcNotApproved.map((item) => item.approvedItem.id)
        ),
      ];
    },
  },
};
</script>

<style scoped></style>
