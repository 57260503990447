"use strict";
import { _t } from "@/tools/Utils";

export default [
  {
    path: "tracking-code",
    name: "tracking-code",
    component: () =>
      import(
        /* webpackChunkName: "tracking-code" */ "@/packages/tracking-code/views/Index"
      ),

    meta: {
      title: _t("route_titles.tracking_code_routes.tracking_code.title"),
    },
  },
];
