<template>
  <LipakModal
    @close="$emit('close')"
    :modal="dialog"
    :title="
      $_t(
        'components.users.user.profile.address_information.modal.edit_address_information_modal.title'
      )
    "
    size="xl"
  >
    <template v-slot:body>
      <b-col cols="12" class="mx-auto fa-num-font">
        <LipakForm>
          <!--Start form body    ------------------------------------------------------------------>

          <template v-slot:body>
            <!--Start form wrapper for catch errors  ------------------------>

            <LipakFormGenerator
              lg="6"
              :schema="schema"
              :validator="$v.form"
            ></LipakFormGenerator>

            <!--End   form wrapper for catch errors  ------------------------>
          </template>

          <!--End   form body    ------------------------------------------------------------------>

          <!--Start form actions ------------------------------------------------------------------>

          <template v-slot:action>
            <b-col cols="=12" lg="4" md="4" sm="6">
              <b-form-group class="form-group inputBox">
                <b-button
                  variant="outline-secondary"
                  class="w-100"
                  @click="$emit('close')"
                  :disabled="is_loading"
                >
                  {{
                    $_t(
                      "components.users.user.profile.address_information.modal.edit_address_information_modal.buttons.close"
                    )
                  }}
                </b-button>
              </b-form-group>
            </b-col>

            <b-col cols="=12" lg="4" md="4" sm="6">
              <b-form-group class="form-group inputBox">
                <b-button
                  variant="success"
                  class="w-100"
                  @click="submit"
                  :disabled="is_loading || $v.$error"
                >
                  <b-spinner
                    v-if="is_loading"
                    small
                    variants="success"
                  ></b-spinner>
                  {{
                    $_t(
                      "components.users.user.profile.address_information.modal.edit_address_information_modal.buttons.submit_text"
                    )
                  }}
                </b-button>
              </b-form-group>
            </b-col>
          </template>

          <!--End   form actions ------------------------------------------------------------------>
        </LipakForm>
      </b-col>
    </template>

    <template v-slot:footer>
      <b-row class="w-100 flex-row-reverse"> </b-row>
    </template>
  </LipakModal>
</template>

<script>
import { FORM_EDIT_SCHEMA } from "@/packages/users/schema/forms/edit.user.address_information.schema";
import LipakModal from "@/components/app/Modal/LipakModal";
import LipakFormGenerator from "@/components/app/form/LipakFormInput";
import LipakForm from "@/components/app/form/LipakForm";
import { mapGetters } from "vuex";
import Profile from "@/packages/users/services/profile";
import { requiredIf } from "vuelidate/lib/validators";

export default {
  name: "EditAddressInformationModal",
  components: {
    LipakModal,
    LipakFormGenerator,
    LipakForm,
  },
  props: {
    modal: {
      type: Boolean,
      default: false,
    },
    address: {
      required: true,
      type: Object,
    },
  },
  data: function () {
    return {
      Profile: new Profile(),
      schema: FORM_EDIT_SCHEMA.schema,
      form: { ...FORM_EDIT_SCHEMA.model },
      isQuarterRequired: false,
      dialog: false,
    };
  },

  validations() {
    return {
      form: {
        ...FORM_EDIT_SCHEMA.validations.form,
        quarter: {
          required: requiredIf(() => {
            return this.isQuarterRequired;
          }),
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      is_loading: "loading/is_loading",
      cities: "order/cities",
    }),
  },
  watch: {
    modal: {
      handler(newValue) {
        this.dialog = newValue;

        // if (!newValue) return;
        this.schema[3].disabled = !this.address.city.isQuarter;
        this.isQuarterRequired = this.address.city.isQuarter;
        this.form = {
          province: this.address.province.id,
          city: this.address.city.id,
          addressDetails: this.address.addressDetails,
          receiverName: this.address.receiverName,
          receiverLastName: this.address.receiverLastName,
          receiverPhone: this.address.receiverPhone,
          postalCode: this.address.postalCode,
          provinceSearch: "",
          citySearch: "",
          quarterSearch: "",
          quarter: this.address.quarter ? this.address.quarter.id : "",
          provinceId: "",
          cityId: "",
        };
      },
      deep: true,
      immediate: true,
    },
    "form.city": {
      handler(newValue) {
        let city = this.cities.find((item) => {
          if (item.id === String(newValue)) {
            return item;
          } else {
            return false;
          }
        });
        if (city) {
          this.schema[3].disabled = !city.isQuarter;
          this.isQuarterRequired = city.isQuarter;
        } else if (this.form.quarter) {
          this.schema[3].disabled = false;
          this.isQuarterRequired = true;
        } else {
          this.schema[3].disabled = true;
          this.isQuarterRequired = false;
        }
      },
      deep: true,
      immediate: false,
    },
  },

  methods: {
    async submit() {
      if (!this.isFormValidate()) return;
      this.form.receiverPhone = this.$_faToEn(this.form.receiverPhone);
      this.form.postalCode = this.$_faToEn(this.form.postalCode);
      delete this.form.provinceId;
      delete this.form.cityId;
      delete this.form.provinceSearch;
      delete this.form.citySearch;
      delete this.form.quarterSearch;

      await this.Profile.updateAddressUser(this.form, this.address.id);
      await this.Profile.fetchUserPersonalInfoById(this.$route.params.user_id);
      this.$emit("close");
    },
    isFormValidate() {
      this.$v.$touch();
      return !this.$v.$invalid;
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped></style>
