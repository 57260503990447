import api from "@/tools/Api";
import urls from "@/tools/Urls";

/*
 *  fetch comments
 *  ------------------------
 *  Api address |
 *  ------------------------
 *  @param customerMobile{String}
 *  ------------------------
 *  method : Get
 *  ------------------------
 *  Description :
 *  ------------------------
 *  fetch comments
 *  ------------------------
 *  @return Promise
 *  ------------------------
 */

export const fetchCommentsAxiosRequest = function (customerMobile) {
  return api.get(urls.api("fetch_comments_by_id_user"), {
    params: {
      customerMobile: customerMobile,
    },
  });
};
