<template>
  <b-form>
    <!--Start Form content    ------------------------------------------------------------------------>
    <b-row class="phone-number-box">
      <!--Start input         ----------------------------------->
      <b-col
        v-for="item in schema"
        :key="'input-' + item.label ? item.label : item.id"
        :cols="item.cols"
        :lg="item.lg"
        :md="item.md"
        :sm="item.sm"
      >
        <lipak-input
          style="text-wrap: none !important"
          v-model="form[item.id]"
          :id="'id-' + item.label ? item.label : item.id"
          :type="item.type"
          :label="$_t(`attribute.${item.label}`) || ''"
          :placeholder="$_t(`placeholder.${item.id}`) || ''"
          :data="item.data || null"
          :show-message="false"
          @input="filterWidthPhoneAndEmail"
        ></lipak-input>
      </b-col>
      <!--End   input         ----------------------------------->
    </b-row>
    <!--End   Form content    ------------------------------------------------------------------------>
  </b-form>
</template>

<script>
import { MOBILE_EMAIL_SEARCH_FILTER_SCHEMA } from "@/packages/users/schema/filters/product-managment/mobile_email_text.filter";

export default {
  name: "MobileAndEmailTextSearch",
  data() {
    return {
      schema: MOBILE_EMAIL_SEARCH_FILTER_SCHEMA.schema,
      form: MOBILE_EMAIL_SEARCH_FILTER_SCHEMA.model,
    };
  },
  methods: {
    filterWidthPhoneAndEmail() {
      this.$emit("phoneAndEmail", this.form.search_text);
    },
  },
};
</script>

<style scoped>
.phone-number-box .lipak-input-title {
  display: none;
}
</style>
<style></style>
