<template>
  <ul class="list d-flex flex-column justify-content-end h-100">
    <!--Start Phone Info ------------------------------------------------------>
    <li>
      <div class="w-100 fa-num-font">
        {{ phone }}
        <LIPhone class="mx-1 fs-12 h-auto" />
      </div>
    </li>
    <!--End   Phone Info ------------------------------------------------------>

    <!--Start Email Info ------------------------------------------------------>
    <li>
      <div class="w-100">
        {{ personalInfo.email.value }}
        <LIEmail class="mx-1 fs-18 h-auto" />
      </div>
    </li>
    <!--End   Email Info ------------------------------------------------------>
  </ul>
</template>

<script>
export default {
  name: "ContactInfo",
  props: {
    personalInfo: {
      type: Object,
      required: true,
    },
    phone: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped></style>
