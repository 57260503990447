<template>
  <b-container fluid class="px-0">
    <div class="d-flex text-right">
      <b-col cols="2" class="pr-0">
        <b-card class="shadow-sm border-0">
          <h5 class="mb-4 font-weight-bold">تنظیمات مرجوعی</h5>
          <b-button
            :to="{ name: 'manage.return-reason' }"
            block
            variant="outline-primary"
            class="px-4 font-weight-bold border-1"
            active-class="bg-primary text-white"
            >ثبت دلایل مرجوعی</b-button
          >
          <b-button
            :to="{ name: 'manage.return-failure-reason' }"
            block
            variant="outline-primary"
            active-class="bg-primary text-white"
            class="px-4 font-weight-bold"
            >ثبت دلایل رد مرجوعی</b-button
          >
        </b-card>
      </b-col>
      <b-col cols="10" class="px-0">
        <transition name="fade" mode="out-in"> <router-view /> </transition>
      </b-col>
    </div>
  </b-container>
</template>
<script>
export default {};
</script>
