import api from "@/tools/ApiService.service";
import urls from "@/tools/Urls";

/**
 *  get user profile
 *  ------------------------
 *  Api address| /user/detail
 *  ------------------------
 *  Method| GET
 *  ------------------------
 *  Description| get user profile
 *  ------------------------
 *  @param query_params {Object}
 *  ------------------------
 *  @return  type {Promise}
 *  ------------------------
 **/
export const getUserProfileAxiosRequest = function (query_params) {
  return api.get(urls.api("user_get_profile"), {
    ...query_params,
  });
};
