import api from "@/tools/Api";
import urls from "@/tools/Urls";

/*
 *  fetch province
 *  ------------------------
 *  Api address | /api/v1/
 *  ------------------------
 *  @param
 *  ------------------------
 *  method : Get
 *  ------------------------
 *  Description :
 *  ------------------------
 *  fetch province
 *  ------------------------
 *  @return Promise
 *  ------------------------
 */

export const fetchProvinceAxiosRequest = function () {
  return api.get(urls.api("fetch_provinc"));
};
