export default {
  //Auth routes
  auth_login: "auth/init",
  auth_forget_password: "auth/forget-password",
  auth_verify_otp: "auth/otp/validate",
  auth_change_password: "auth/reset-password",
  auth_reset_password: "stuff/change/password",
  auth_logout: "auth/logout",

  //User routes
  user_get_profile: "user/detail",
};
