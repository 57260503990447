<template>
  <LipakModal
    @close="closeModal"
    @ok="$emit('ok')"
    :modal="showModal"
    :title="title"
    size="lg"
    :ok-button-text="okButtonText"
    :cancel-button-text="cancelButtonText"
  >
  </LipakModal>
</template>

<script>
import LipakModal from "@/components/app/Modal/LipakModal";
export default {
  components: { LipakModal },
  props: {
    modal: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "آیا مطمئنید؟",
    },
    okButtonText: {
      type: String,
      default: "تایید",
    },
    cancelButtonText: {
      type: String,
      default: "لغو",
    },
  },
  data() {
    return {
      showModal: false,
    };
  },
  watch: {
    modal(val) {
      this.showModal = val;
    },
  },
  methods: {
    closeModal() {
      this.showModal = false;
      this.$emit("close");
    },
  },
};
</script>
