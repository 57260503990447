<template>
  <PayoffInPlace />
</template>

<script>
import PayoffInPlace from "@/packages/manage/components/payoff-in-place/Index";
export default {
  components: { PayoffInPlace },
};
</script>

<style scoped></style>
