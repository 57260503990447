<template>
  <div v-if="isLoading">
    <b-skeleton width="100%" height="400px" />
  </div>
  <b-card v-else-if="hasError" class="text-center">
    <div class="primary--text text-lg-body-1 font-weight-bold mb-4">
      خطای سرور !
    </div>
    <b-button variant="primary" large @click="init"> درخواست مجدد</b-button>
  </b-card>
  <b-card v-else>
    <b-table-simple responsive small dir="rtl" class="text-right">
      <b-container fluid tag="thead" class="d-none d-lg-flex px-0">
        <b-row no-gutters tag="tr" class="w-100">
          <b-col cols="12" lg="1" tag="b-th" class="border-0">تصویر کالا</b-col>
          <b-col cols="12" lg="3" tag="b-th" class="text-center border-0"
            >مشخصات کالا</b-col
          >
          <b-col cols="12" lg="2" tag="b-th" class="text-center border-0"
            >تعداد مرجوعی</b-col
          >
          <b-col cols="12" lg="3" tag="b-th" class="border-0"
            >دلیل مرجوعی</b-col
          >
          <b-col cols="12" lg="3" tag="b-th" class="text-center border-0"
            >بارگذاری تصویر</b-col
          >
        </b-row>
      </b-container>
      <b-container fluid tag="b-tbody" class="px-0 mx-0">
        <b-row
          no-gutters
          tag="tr"
          v-for="(item, index) in items"
          :key="item.itemId + index"
          class="w-100 px-0 mb-4"
        >
          <b-col
            cols="12"
            lg="1"
            tag="td"
            class="d-flex align-items-center justify-start align-center border-bottom"
          >
            <div class="py-2 d-flex align-items-center">
              <b-checkbox
                v-if="!item.returnable"
                v-model="itemDefaultActive"
                :disabled="true"
                color="secondary"
              />
              <b-checkbox v-else v-model="item.active" color="primary" />

              <b-avatar
                rounded
                :src="imageUrlBuilder(item.lpvImageUrl)"
                class="p-1 mr-3"
                variant="transparent"
                size="lg"
              />
            </div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            tag="td"
            class="d-flex justify-content-center align-items-center border-bottom px-2"
          >
            <div>
              <div>{{ item.lpvName }}</div>
              <div class="mt-2">
                <b-avatar
                  size="sm"
                  text=" "
                  :style="{ backgroundColor: item.colorHex }"
                  class="ml-1"
                />
                {{ item.color }}
              </div>
              <div class="mt-2">{{ item.guarantee }}</div>
              <div class="d-flex justify-content-between mt-2">
                <div>
                  تعداد کالا:
                  {{ item.count }}
                </div>
                <div>
                  قیمت:
                  <strong>
                    {{ (item.price / 10).toLocaleString() }}
                  </strong>
                  تومان
                </div>
              </div>
            </div>
          </b-col>
          <b-col cols="12" lg="2" tag="b-td" class="border-bottom border-right">
            <div class="w-75 mt-5 mx-auto recieved-count-input">
              <LipakInput
                type="number"
                v-model="item.returnCount"
                :disabled="!item.active"
                :min="1"
                :max="item.highestCountForRegisterReturns"
                :is-editable="false"
                :show-message="false"
              />
            </div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            tag="b-td"
            class="border-bottom border-right px-3"
          >
            <Reason
              :is-active="item.active"
              :description.sync="item.description"
              :return-reason.sync="item.returnReason"
            />
          </b-col>
          <b-col
            cols="12"
            lg="3"
            tag="b-td"
            class="d-flex justify-content-center align-items-center border-bottom border-right"
          >
            <div class="w-75 mx-auto">
              <b-file
                accept="image/*"
                variant="primary"
                multiple
                flat
                :disabled="!item.active"
                v-model="item.files"
                class="text-left"
                dir="ltr"
              >
                <template #placeholder> بارگزاری تصویر </template>
              </b-file>

              <div class="d-flex mt-2 flex-wrap">
                <b-avatar
                  v-for="(file, fileIndex) in item.files"
                  :key="`file-${fileIndex}`"
                  :src="toBlob(file)"
                  rounded
                  class="mr-2"
                ></b-avatar>
              </div>
            </div>
          </b-col>
          <b-col
            v-if="!item.returnable"
            cols="12"
            lg="12"
            tag="b-td"
            class="bg-light-danger py-2 px-4 rounded fs-15 text-danger"
          >
            کالا قبلا مرجوع شده است. امکان ثبت مرجوعی برای این کالا وجود ندارد.
            اگر نیاز به ثبت مرجوعی دارید با پشتیبانی لیپک تماس بگیرید.
          </b-col>
          <!--          <hr />-->
        </b-row>
      </b-container>
    </b-table-simple>
    <b-button
      class="mt-2"
      variant="primary"
      :disabled="isExitActive"
      @click="submit"
    >
      ثبت مرجوعی
    </b-button>
  </b-card>
</template>

<script>
import {
  approveImageSaveAxiosRequest,
  fetchOrderItemByIdForReturnAxiosRequest,
  registerReturnedAxiosRequest,
  uploadImageForSubmitReturnedAxiosReq,
} from "@/packages/return-register/api/index.api";
import message from "@/tools/Message";
import imgUrl from "@/mixins/mixin.image_url_builder";
import Reason from "@/packages/return-register/components/detail/Reason";

export default {
  name: "Index",
  components: { Reason },
  mixins: [imgUrl],
  data() {
    return {
      isLoading: false,
      hasError: true,
      items: [],
      itemDefaultActive: true,
    };
  },
  created() {
    this.init();
  },
  computed: {
    isExitActive() {
      let actived = this.items.filter((item) => item.active === true);
      return !actived.length;
    },
  },
  methods: {
    async init() {
      try {
        this.isLoading = true;
        this.hasError = false;
        const { orderItems } = await fetchOrderItemByIdForReturnAxiosRequest(
          this.$route.params.id,
          "SIZE_84_84"
        );
        if (orderItems.length) {
          this.items = orderItems.map((item) => {
            return {
              ...item,
              highestCountForRegisterReturns: item.returnCount,
            };
          });
        }
      } catch (err) {
        console.log(err);
        this.hasError = true;
        message.error("مشکلی پیش آمده");
      }
      this.isLoading = false;
    },
    toBlob(file) {
      return URL.createObjectURL(file);
    },
    async submit() {
      /* Separation of selected products in list */
      let selectedProducts = this.items.filter((item) => item.active);
      if (!selectedProducts.length) {
        return message.error("کالای مورد نظر را جهت مرجوع کردن انتخاب کنید");
      }
      for (let i = 0; i < selectedProducts.length; i++) {
        const {
          returnCount,
          returnReason,
          description,
          files,
        } = selectedProducts[i];
        if (!returnCount) {
          return message.error("تعداد مرجوعی را مشخص کنید");
        }
        if (!returnReason) {
          return message.error("دلیل مرجوعی را انتخاب کنید");
        }
        if (!description) {
          return message.error("توضیحات مرجوعی را بنویسید");
        }
        // if (!files || !files.length) {
        //   return message.error("عکس را انتخاب کنید");
        // }
        console.log(returnCount, returnReason, description, files);
      }
      const payload = {
        orderId: this.$route.params.id,
        returnItem: [],
      };
      selectedProducts.forEach((item) =>
        payload.returnItem.push({
          orderItemId: item.itemId,
          count: item.returnCount,
          returnReason: item.returnReason,
          description: item.description,
          files: [],
          returnItemId: item.returnItemId,
        })
      );
      try {
        const returnId = await registerReturnedAxiosRequest(payload);
        console.log(returnId, "returnId");
        /* upload image */
        const promises = [];
        let form = new FormData();
        let existImageFile = false;
        selectedProducts.forEach((item) => {
          console.log(item);
          item.files &&
            item.files.forEach((file) => {
              existImageFile = true;
              form.append("mediaFiles", file, file.name);
            });
          if (existImageFile) {
            promises.push(
              uploadImageForSubmitReturnedAxiosReq(item.returnItemId, form)
            );
          }
        });
        if (existImageFile) {
          await Promise.all(promises);
          await approveImageSaveAxiosRequest(returnId);
        }

        message.success("ثبت مرجوعی با موفقیت انجام شد");
        await this.$router.push({
          name: "order.details-returned-orders",
          params: { id: returnId },
        });
      } catch (e) {
        console.log(e);
        message.error(e.response.data.message);
      }
    },
  },
};
</script>
<style scoped></style>

<style>
.lipak-input-number-changer {
  display: flex !important;
  justify-content: center !important;
  white-space: normal !important;
}
</style>
