var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form-wrapper',{staticClass:"w-100",attrs:{"validator":_vm.validator.form}},[(_vm.$slots['form-body'])?_vm._t("form-body"):_c('b-row',[_vm._l((_vm.schema),function(input){return _c('b-col',_vm._b({key:'key-' + input.id,attrs:{"cols":input.cols || 12,"lg":input.lg || _vm.$attrs.lg,"md":input.md || 6}},'b-col',_vm.$attrs,false),[_c('form-group',{attrs:{"name":input.id,"attribute":_vm.$_t(("attribute." + (input.id)))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
var listeners = ref.listeners;
return (input.type.toLowerCase() === 'currency')?_c('LipakCurrencyInput',_vm._g(_vm._b({attrs:{"place-holder":input.placeholder,"title":input.title,"current-price":_vm.validator.form[input.id].$model,"max-price":input.maxPrice || 0},model:{value:(_vm.validator.form[input.id].$model),callback:function ($$v) {_vm.$set(_vm.validator.form[input.id], "$model", $$v)},expression:"validator.form[input.id].$model"}},'LipakCurrencyInput',attrs,false),listeners)):_c('LipakInput',_vm._g(_vm._b({staticClass:"p-1 w-100",attrs:{"id":input.id,"date-type":input.type2,"type":input.type,"title":_vm.$_t(("attribute." + (input.id))),"rows":input.type === 'textarea'
              ? _vm.validator.form[input.id].$model.split(/\r*\n/).length
              : input.rows,"placeholder":Boolean(input.placeholder)
              ? _vm.$_t(("placeholder." + (input.placeholder)))
              : _vm.$_t(("placeholder." + (input.id))),"options":input.options || null,"show-message":false,"readonly":input.disabled,"disabled":input.disabled,"default-option-disabled":input.defaultOptionDisabled,"required":input.required || false},model:{value:(_vm.validator.form[input.id].$model),callback:function ($$v) {_vm.$set(_vm.validator.form[input.id], "$model", $$v)},expression:"validator.form[input.id].$model"}},'LipakInput',attrs,false),listeners))}}],null,true)})],1)}),_vm._t("inline-action")],2)],2)}
var staticRenderFns = []

export { render, staticRenderFns }