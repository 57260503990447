<template>
  <b-container fluid class="px-0 py-0 fa-num-font">
    <b-row no-gutters>
      <b-col cols="12" class="px-0">
        <table
          width="100%"
          cellpadding="0"
          cellspacing="0"
          class="ss02 fs-11 font-bold table-caption-top font-weight-bold"
        >
          <caption class="text-center bg-header-table py-2">
            <span class="font-weight-bold">مشخصات فروشنده</span>
          </caption>
          <tr class="w-100 d-flex">
            <td width="50%" class="border-external align-center px-1 py-2">
              <span class=" "> نام شخص حقیقی / حقوقی : </span>
              <span class="pr-2">
                اندیشه راه آگاه آیندگان کنگان(سهامی خاص)
              </span>
            </td>
            <td width="25%" class="border-external align-center px-1 py-2 w-25">
              <span class=" "> شناسه ملی :</span>
              <span class="pr-2 fa-num-font">14009243346</span>
            </td>
            <td width="25%" class="border-external align-center px-1 py-2 w-25">
              <span class=" ">شماره ثبت :</span>
              <span class="pr-2">2649</span>
            </td>
          </tr>

          <tr class="w-100 d-flex">
            <td
              width="25%"
              class="border-external align-center px-1 py-2 w-25"
              style="width: 25%"
            >
              <span class=" "> استان : </span>
              <span class="pr-2"> تهران </span>
            </td>
            <td class="border-external align-center px-1 py-2 w-25">
              <span class=" "> شهر : </span>
              <span class="pr-2"> تهران </span>
            </td>
            <td class="border-external align-center px-1 py-2 w-25">
              <span class=" "> کد پستی ۱۰ رقمی : </span>
              <span class="pr-2"> 1594658916 </span>
            </td>
            <td class="border-external align-center px-1 py-2 w-25">
              <span class=" "> تلفن : </span>
              <span class="pr-2"> 02158256000 </span>
            </td>
          </tr>
          <tr class="w-100 d-flex">
            <td class="border-external align-center px-1 py-2 w-100">
              <span class=" "> نشانی : </span>
              <span class="pr-2">
                تهران - خیابان ولی‌عصر - بالاتر از میدان ولی‌عصر - کوچه شهید
                شهامتی - پلاک ۲۱
              </span>
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "Seller",
};
</script>

<style scoped>
.border-card {
  border: 2px solid black;
}
</style>
