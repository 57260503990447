<template>
  <b-table
    class="tableBox m-0 fa-num-font"
    tbody-tr-class="align-middle fs-14 border-0"
    thead-class="text-secondary fs-12 fw-lighter border-0"
    responsive
    hover
    :busy.sync="is_loading"
    :items="orders"
    :fields="tableHeader"
    primary-key="1"
    show-empty
    :empty-text="$_t('table.default.empty_text')"
    :empty-filtered-text="$_t('table.default.empty_text')"
  >
    <template #cell(row)="{ index }">
      <span>{{ index + 1 }}</span>
    </template>

    <template #cell(price)="{ item }">
      <span>{{ $_currency(Number(item.price) / 10) }}</span>
    </template>
    <template #cell(date)="row">
      <span
        v-if="$_date(row.item.date, 'jalali') !== $_date(new Date(), 'jalali')"
      >
        {{ $_date(row.item.date, "jalali") }}</span
      >

      <span v-else>
        {{ $_date(row.value, "fromNow") }}
      </span>
    </template>
    <template #cell(state)="{ item }">
      <span class="rounded-pill text-danger bg-light-primary px-3 fs-14 py-1">{{
        item.state.second
      }}</span>
    </template>
    <template #cell(paymentStatus)="{ item }">
      <div
        class="pa-1 text-center text-body-1 font-weight-medium letter-spacing"
        :class="checkStatus(item.paymentStatus).color"
      >
        {{ checkStatus(item.paymentStatus).title }}
      </div>
    </template>
    <template #cell(actions)="{ item }">
      <b-button
        size="sm"
        @click="
          $router.push({ name: 'orders.details', params: { id: item.id } })
        "
        class="mr-1 text-info w-100 border-0 w-100"
        variant="accent"
      >
        جزئيات
      </b-button>
    </template>
  </b-table>

  <!----------------------start table of receipts-detail received----------------->

  <!----------------------end table of receipts-detail received------------------->
</template>

<script>
import { mapGetters } from "vuex";
import { USER_ORDER_TABEL_HEEADER } from "@/packages/users/schema/tables/USER_ORDER_TABEL_HEEADER";

export default {
  name: "InpaymentTable",
  data: () => {
    return {
      tableHeader: USER_ORDER_TABEL_HEEADER,
    };
  },
  computed: {
    ...mapGetters({
      is_loading: "loading/is_loading",
      orders: "orders/orders",
    }),
  },
  methods: {
    checkStatus(value) {
      switch (value) {
        case "waiting":
          return {
            color: "text-primary",
            title: "در انتظار پرداخت",
          };
        case "success":
          return {
            color: "text-success",
            title: "موفق",
          };
        case "failure":
          return {
            color: "text-danger",
            title: "ناموفق",
          };
        case "inPlace":
          return {
            color: "text-secondary",
            title: "پرداخت در محل",
          };
      }
    },
  },
};
</script>
