import { render, staticRenderFns } from "./LipakForm.vue?vue&type=template&id=764f3e9a&scoped=true&"
import script from "./LipakForm.vue?vue&type=script&lang=js&"
export * from "./LipakForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "764f3e9a",
  null
  
)

export default component.exports