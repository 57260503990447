<template>
  <b-card class="w-100 border-0 shadow-sm">
    <b-skeleton v-if="isFetching" height="300px" />
    <div v-else-if="hasError" class="text-center">
      <b-button variant="primary" @click="getShippingMethods">
        تلاش مجدد
      </b-button>
    </div>
    <div v-else>
      <h5 class="mb-4">روش‌های ارسال</h5>
      <div v-for="item in shippingDtoList" :key="item.id">
        <b-row>
          <b-col cols="12" lg="6">
            <div class="d-flex align-items-center">
              <b-checkbox v-model="item.isActive" class="ml-2 mt-1" />
              <span
                class="mt-1 c-pointer"
                @click="item.isActive = !item.isActive"
              >
                {{ item.title }}
              </span>
            </div>
          </b-col>
          <b-col cols="12" lg="6">
            <div v-if="item.isActive">
              <LipakCurrencyInput
                type="text"
                title="حداکثر مقدار (ریال)"
                placeholder="مقدار را وارد کنید."
                v-model="item.maxPaymentAllowed"
                :current-price="item.maxPaymentAllowed"
              ></LipakCurrencyInput>
            </div>
          </b-col>
        </b-row>
        <hr />
      </div>

      <div class="text-left mt-5">
        <b-button
          :disabled="isPosting"
          variant="primary"
          class="px-5"
          @click="submit"
          >ثبت</b-button
        >
      </div>
    </div>
  </b-card>
</template>

<script>
import {
  getPaymentMethodShipments,
  updateMappingPaymentMethodShipmentMethod,
} from "@/packages/manage/api/payoff";
import message from "@/tools/Message";
// import LipakForm from "@/components/app/form/LipakForm";
import { PAYOFF_IN_PLACE_SCHEMA } from "@/packages/manage/schema/payoffInPlace.schema";
import cloneDeep from "lodash.clonedeep";
import LipakCurrencyInput from "@/components/app/form/LipakCurrencyInput.vue";
// import LipackFormGenerator from "@/components/app/form/LipackFormGenerator";

export default {
  name: "Index",
  components: { LipakCurrencyInput },
  // components: { LipackFormGenerator, LipakForm },
  data() {
    return {
      isFetching: true,
      hasError: false,
      isPosting: false,
      shippingDtoList: [],
      schema: cloneDeep(PAYOFF_IN_PLACE_SCHEMA.schema),
      form: cloneDeep(PAYOFF_IN_PLACE_SCHEMA.model),
    };
  },
  validations: {
    form: cloneDeep(PAYOFF_IN_PLACE_SCHEMA.validations),
  },
  created() {
    this.getShippingMethods();
  },
  methods: {
    async getShippingMethods() {
      this.isFetching = true;
      this.hasError = false;
      try {
        const {
          maxPaymentAllowed,
          shippingDtoList,
        } = await getPaymentMethodShipments();
        this.shippingDtoList = shippingDtoList;
        this.form.maxPaymentAllowed = maxPaymentAllowed;
      } catch (error) {
        console.error(error);
        message.error("مشکلی پیش آمده");
        this.hasError = true;
      }
      this.isFetching = false;
    },
    async submit() {
      try {
        this.isPosting = true;
        await updateMappingPaymentMethodShipmentMethod({
          paymentMethod: "INPLACE",
          activeShippingSettings: this.shippingDtoList
            .filter((item) => item.isActive)
            .map((item) => item.id),
          shippingPaymentMap: this.maxPaymentMapper(
            this.shippingDtoList.filter((item) => item.isActive)
          ),
        });
        message.success("با موفقیت آپدیت شد");
      } catch (err) {
        console.log(err);
        message.error("مشکلی پیش آمده");
      }
      this.isPosting = false;
    },
    maxPaymentMapper(arr) {
      let mappedObj = {};
      arr.forEach((item) => {
        mappedObj[item.id] = item.maxPaymentAllowed;
      });
      return mappedObj;
    },
  },
};
</script>

<style scoped></style>
