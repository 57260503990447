<template>
  <div
    class="message-box text-right d-flex justify-content-between align-items-center w-100 h-auto p-3"
  >
    <!--Start Right side border/icon ---------------------------------------------------------------------->
    <div class="messageAlert d-flex justify-content-center align-items-center">
      <!--Start Right Border ----------------------------------------------------------->
      <div class="hr-vertical ml-lg-5 ml-1" :class="`border-${variant}`"></div>
      <!--End   Right Border ----------------------------------------------------------->
      <component
        :is="getIcon(name)"
        class="fs-36"
        :class="`text-${variant}`"
      ></component>
    </div>
    <!--End   Right side border/icon ---------------------------------------------------------------------->

    <!--Start Content                ---------------------------------------------------------------------->
    <div class="text-justify message mx-1">
      <p class="font-weight-bold mb-5">{{ title }}</p>
      <slot name="content"></slot>
    </div>
    <!--End   Content                ---------------------------------------------------------------------->

    <!--Start Close icon             ---------------------------------------------------------------------->
    <div class="deleteMessage pointer" @click="close">
      <LITimes icon="times" class="fs-12" />
    </div>
    <!--Start Close icon             ---------------------------------------------------------------------->
  </div>
</template>

<script>
import { mapActions } from "vuex";
import LITimes from "@lipak/lipak-icon/src/lipak-icon/times";
const icons = {
  checkCircle: () => import("@lipak/lipak-icon/src/lipak-icon/checkCircle"),
  timesSquare: () => import("@lipak/lipak-icon/src/lipak-icon/timesSquare"),
  exclamationSquare: () =>
    import("@lipak/lipak-icon/src/lipak-icon/exclamationSquare"),
  star: () => import("@lipak/lipak-icon/src/lipak-icon/star"),
};

export default {
  name: "LipakAlert",
  components: {
    LITimes,
  },
  props: {
    title: {
      type: String,
      default: "",
    },

    /* [success, primary, warning, info] */
    variant: {
      type: String,
      default: "info",
    },
    onCloseRedirectLink: {
      type: Object,
      default: null,
    },
  },

  computed: {
    name() {
      if (this.variant && this.variant.trim() === "success")
        return "checkCircle";
      if (this.variant && this.variant.trim() === "primary")
        return "timesSquare";
      if (this.variant && this.variant.trim() === "info")
        return "exclamationSquare";
      if (this.variant && this.variant.trim() === "warning")
        return "exclamationSquare";

      return "star";
    },
  },

  methods: {
    ...mapActions({
      closeDialog: "dialog/closeDialog",
    }),
    getIcon(name) {
      return icons[name];
    },

    async close() {
      this.closeDialog();
      if (this.onCloseRedirectLink)
        await this.$router.push(this.onCloseRedirectLink);
    },
  },
};
</script>

<style lang="scss" scoped>
// make fontsize class from 8px to 36px

.hr-vertical {
  height: 100px;
  border-radius: 10px;
  border-right: 5px solid;
  align-items: center;
  display: flex;
  justify-content: center;
}
.message-box {
  min-height: 200px;
}
.message {
  font-style: normal;
  color: #000000;
}
</style>
