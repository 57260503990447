const { required, email } = require("vuelidate/lib/validators");

export const FORM_EDIT_SCHEMA = {
  /* Form SCHEMA */
  schema: [
    {
      id: "name",
      cols: 12,
      lg: 6,
      md: 6,
      sm: 6,
      type: "text",
      required: false,
    },
    {
      id: "lastName",
      cols: 12,
      lg: 6,
      md: 6,
      sm: 6,
      type: "text",
      required: false,
    },
    {
      id: "birthday",
      cols: 12,
      lg: 6,
      md: 6,
      sm: 6,
      type: "date",
      required: false,
    },
    {
      id: "nationalCode",
      cols: 12,
      lg: 6,
      md: 6,
      sm: 6,
      type: "text",
      required: false,
    },
    {
      id: "phone",
      cols: 12,
      lg: 6,
      md: 6,
      sm: 6,
      type: "text",
      required: false,
      disabled: true,
    },
    {
      id: "email",
      cols: 12,
      lg: 6,
      md: 6,
      sm: 6,
      type: "text",
      required: false,
      disabled: false,
    },
  ],

  /* Form MODEL */
  model: {
    name: "",
    lastName: "",
    birthday: "",
    nationalCode: "",
    phone: "",
    email: "",
  },

  /* VALIDATION SCHEMA */
  validations: {
    form: {
      name: { required },
      lastName: { required },
      birthday: { required },
      nationalCode: { required },
      phone: { required },
      email: { required, email },
    },
  },
};
