import api from "@/tools/Api";
import urls from "@/tools/Urls";

/*
 *  payment by remittance
 *  ------------------------
 *  Api address |
 *  ------------------------
 *  @param
 *  ------------------------
 *  method : patch
 *  ------------------------
 *  Description :
 *  ------------------------
 *  payment by remittance
 *  ------------------------
 *  @return Promise
 *  ------------------------
 */

export const paymentByRemittanceAxiosReq = function (user_id, payload) {
  console.log(payload, "payload");
  return api.patch(urls.api("payment_by_remittance", [user_id]), null, {
    params: {
      ...payload,
    },
  });
};
