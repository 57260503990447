// Handler
// handle server or connection errors

import api_route_managerHelper from "./Urls";
import store from "@/store/index";
import router from "@/router/index";

const handlerHelper = {
  // handle errors
  error(result) {
    try {
      let error = new ServerError(
        result.response.status,
        result.response.data.message,
        result.response.data.error
      );
      if (error.status === 401) {
        store.dispatch("authentication/logout").then(() => {});
        router.push({ name: "authentication" }).then(() => {});
      }
      return Promise.reject(result);
    } catch (e) {
      return Promise.reject(result);
    }
  },

  // handle response
  response(result) {
    try {
      let request_url = result.config.url;
      if (request_url.includes(api_route_managerHelper.url("base_url")))
        return {
          data: result.data,
          header: result.headers,
        };
      return Promise.reject(result);
    } catch (e) {
      return Promise.reject(result);
    }
  },
};

class ServerError {
  status;
  message;
  errors;
  validationErrors;
  constructor(status, message, errors, validationErrors = []) {
    try {
      this.status = status;
      this.message = message;
      this.errors = errors;
      this.validationErrors = validationErrors;
    } catch (e) {
      this.status = null;
      this.message = null;
      this.errors = null;
      this.validationErrors = [];
    }
  }
}

export default handlerHelper;
