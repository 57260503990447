<template>
  <b-row
    no-gutters
    class="d-flex justify-content-center justify-content-lg-between align-content-center mt-4 rounded-lg w-100"
    style="background: #fcede8"
  >
    <SearchByOrderNumber />

    <SearchByNationalCode />

    <SearchByMobileNumber />

    <SearchByUserName />
  </b-row>
</template>

<script>
import SearchByOrderNumber from "./SearchByOrderNumber";
import SearchByNationalCode from "./SearchByNationalCode";
import SearchByMobileNumber from "./SearchByMobileNumber";
import SearchByUserName from "./SearchByUserName";
export default {
  name: "OrderSearch",
  components: {
    SearchByOrderNumber,
    SearchByNationalCode,
    SearchByMobileNumber,
    SearchByUserName,
  },
};
</script>
