<template>
  <b-card class="border-0 shadow-sm">
    <Title title="لیست دلایل رد مرجوعی" />
    <b-table
      tbody-tr-class="align-middle fs-12 text-center"
      thead-class="text-secondary fs-12 fw-lighter text-center"
      responsive
      hover
      :items="items"
      :fields="tableHeaders"
      primary-key="preInvoiceList"
      show-empty
      empty-text="هیچ دلیلی پیدا نشد"
      empty-filtered-text="هیچ دلیلی پیدا نشد"
      :busy.sync="isBusy"
    >
      <!--Start Loading Viewer    ------------------------------------>
      <template #table-busy>
        <b-skeleton-table :columns="3" :rows="8" />
      </template>
      <!--End   Loading Viewer    ------------------------------------>

      <template #cell(isActive)="row">
        <IsActive :row="row" />
      </template>

      <!--Start row         ------------------------------------>
      <template #cell(row)="data">
        {{ data.index + 1 }}
      </template>
      <!--End   row         ------------------------------------>

      <!--Start Empty states      ------------------------------------>
      <template #empty="scope">
        <p v-if="hasError" class="text-center">عدم دریافت پاسخ از سرور</p>
        <p v-else class="text-center">{{ scope.emptyText }}</p>
      </template>

      <template #emptyfiltered="scope">
        <p class="text-center">{{ scope.emptyFilteredText }}</p>
      </template>
      <!--End   Empty states      ------------------------------------>
    </b-table>
  </b-card>
</template>

<script>
import Title from "@/components/app/Title";
import IsActive from "@/packages/manage/components/return-failure-reason/IsActive";
import { returnRejectReasonAllAxiosRequest } from "@/packages/manage/api/returnRejectReason.api";
export default {
  components: {
    IsActive,
    Title,
  },
  props: ["update"],
  data() {
    return {
      isBusy: false,
      hasError: false,
      items: [],
      tableHeaders: [
        {
          key: "row",
          label: "ردیف",
          sortable: false,
        },
        {
          key: "title",
          label: "عنوان",
          sortable: false,
        },
        { key: "x1", label: "" },
        { key: "x2", label: "" },
        { key: "x3", label: "" },
        { key: "x4", label: "" },
        { key: "x5", label: "" },
        { key: "x6", label: "" },
        {
          key: "isActive",
          label: "وضعیت",
          sortable: false,
        },
      ],
    };
  },
  watch: {
    update() {
      this.getReasons();
    },
  },
  created() {
    this.getReasons();
  },
  methods: {
    async getReasons() {
      this.isBusy = true;
      this.hasError = false;
      try {
        const response = await returnRejectReasonAllAxiosRequest();
        this.items = response;
      } catch (error) {
        console.log(error);
        this.hasError = true;
      }
      this.isBusy = false;
    },
  },
};
</script>

<style scoped></style>
