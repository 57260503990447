<template>
  <b-row class="w-100 mx-0 py-5 mt-0 d-flex justify-content-between fa-num-font"
    ><div
      class="text-center w-100 text-primary"
      v-if="subStateFlow.length === 1 && subStateFlow[0].time === null"
    >
      عدم تکمیل مرحله ی {{ state }}
    </div>
    <b-col
      v-else
      cols="12"
      md="6"
      lg="6"
      xl="3"
      v-for="item in subStateFlow"
      :key="item.index"
      class="px-4 mt-3 mt-xl-0"
    >
      <b-card
        class="card text-center justify-content-center border-0 rounded px-0 px-2 px-xl-2"
        style="height: 100%"
      >
        <p
          v-if="item.state"
          :class="{
            'text-grey': item.time === null,
            'text-main': item.time !== null,
          }"
        >
          {{ item.state.second }}
        </p>
        <hr
          :class="{
            'bg-accent': item.time === null,
            'bg-primary': item.time !== null,
          }"
        />

        <div v-if="item.time !== null" class="text-right px-0">
          <LICalendar class="pl-2" />
          <span
            class="fs-14 font-weight-bold"
            :class="{ 'text-accent': item.time, 'text-more': item.time }"
          >
            {{ $_date(item.time, "jalali") }}</span
          >
        </div>
        <div v-if="item.time !== null" class="text-right px-0">
          <LIClock class="pl-2" />
          <span class="fs-14 font-weight-bold text-main">
            {{ $_date(item.time, "time") }}</span
          >
        </div>
      </b-card></b-col
    >
  </b-row>
</template>

<script>
export default {
  name: "CartBasket",
  props: {
    subStateFlow: {
      type: Array,
      required: true,
    },
    state: {
      type: String,
      required: true,
    },
  },
};
</script>
<style scoped>
.card {
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
}
</style>
