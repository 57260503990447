export const FILTER_CONTACT_US_SCHEMA = {
  schema: [
    {
      id: "name",
      type: "text",
      title: "نام",
      placeholder: "name",
      cols: 12,
      lg: 3,
      md: 4,
      sm: 6,
    },
    {
      id: "phoneNumber",
      type: "text",
      title: "شماره موبایل",
      placeholder: "phoneNumber",
      cols: 12,
      lg: 3,
      md: 4,
      sm: 6,
    },
    {
      id: "from",
      type: "date",
      title: "از تاریخ",
      placeholder: "from",
      cols: 12,
      lg: 3,
      md: 4,
      sm: 6,
    },
    {
      id: "to",
      type: "date",
      title: "تا تاریخ",
      placeholder: "to",
      cols: 12,
      lg: 3,
      md: 4,
      sm: 6,
    },
  ],
  model: {
    name: "",
    phoneNumber: "",
    from: "",
    to: "",
  },
  validations: {
    name: {},
    phoneNumber: {},
    from: {},
    to: {},
  },
};
