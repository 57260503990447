<template>
  <b-container fluid>
    <div
      class="card-height d-flex flex-column justify-content-center align-items-center"
    >
      <b-col
        cols="12"
        lg="4"
        xl="3"
        class="d-flex flex-column justify-content-center align-items-center"
      >
        <b-card
          no-body
          class="border-1 d-flex flex-column align-items-center px-4 py-3 pb-5 rounded rounded-lg"
        >
          <LIBarcode class="fs-32"></LIBarcode>
          <h1 class="fs-20">ثبت کد رهگیری</h1>
          <LipakForm class="position-relative">
            <!------------------ start body of form --------------->
            <template v-slot:body>
              <LipackFormGenerator :schema="schema" :validator="$v" />
            </template>

            <!------------------ end body of form ----------------->
            <!------------------- start action of form ------------>
            <template v-slot:action>
              <b-col cols="12">
                <b-button
                  variant="primary"
                  class="w-100 border-0"
                  @click="submit"
                  :disabled="is_loading"
                >
                  <b-spinner
                    v-if="is_loading"
                    small
                    variants="info"
                  ></b-spinner>
                  تایید
                </b-button>
              </b-col>
            </template>
            <!------------------ end action of form ---------------->
          </LipakForm>
        </b-card>
      </b-col>
    </div>
  </b-container>
</template>

<script>
import { TRACKING_CODE_REGISTER_SCHEMA } from "@/packages/tracking-code/schema/ORDER_TRACKING_REGISTER.SCHEMA";
import LipakForm from "@/components/form/LipakForm";
import LipackFormGenerator from "@/components/form/LipackFormGenerator";
import { registerOrderTrackingCodeService } from "@/packages/tracking-code/services/register-order-tracking-code.service";
import { mapGetters } from "vuex";
export default {
  name: "SubmitTrackingOrder",
  components: { LipackFormGenerator, LipakForm },
  data() {
    return {
      form: { ...TRACKING_CODE_REGISTER_SCHEMA.model },
      schema: [...TRACKING_CODE_REGISTER_SCHEMA.schema],
    };
  },
  computed: {
    ...mapGetters({
      is_loading: "loading/is_loading",
    }),
  },
  validations: {
    ...TRACKING_CODE_REGISTER_SCHEMA.validations,
  },
  methods: {
    async submit() {
      if (!this.isFormValidate()) return;
      await registerOrderTrackingCodeService(this.form);
    },
    isFormValidate() {
      this.$v.$touch();
      return !this.$v.$invalid;
    },
  },
};
</script>

<style scoped></style>
