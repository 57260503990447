<template>
  <b-col cols="12" class="mt-5 px-0"> </b-col>
</template>

<script>
export default {
  name: "FinalInformation",
  data: () => {
    return {};
  },
  components: {},
};
</script>
<style scoped lang="scss"></style>
