import { TAXONOMY } from "@/packages/comments/schema/TAXONOMY";
import {
  fetchProductGroupsAxiosReq,
  fetchBrandsAxiosReq,
  fetchModelsAxiosReq,
  fetchConfigsAxiosReq,
} from "@/packages/comments/api";
export default class OptionService {
  async GetOptionsContentByTaxonomy(taxonomy) {
    try {
      let result = [];
      let response = [];
      switch (taxonomy) {
        case TAXONOMY.productGroups:
          result = await fetchProductGroupsAxiosReq();
          response.content = result?.content;
          break;
        case TAXONOMY.brands:
          result = await fetchBrandsAxiosReq();
          response.content = result?.content;
          break;
        case TAXONOMY.models:
          result = await fetchModelsAxiosReq();
          response.content = result?.content;
          break;
        case TAXONOMY.configs:
          result = await fetchConfigsAxiosReq();
          response.content = result?.content;
          break;
      }
      return response;
    } catch (err) {
      console.log(err, "err");
    }
  }
}
