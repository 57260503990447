<template>
  <b-container
    fluid
    class="bg-container d-flex justify-content-center align-content-center align-items-center"
  >
    <b-col cols="12" lg="4">
      <GlassCard
        class="p-4 card-glass medium d-flex justify-content-center align-items-center text-center px-3"
      >
        <b-icon-shield-lock variant="white" font-scale="5" class="mb-4" />
        <h5 class="text-white">
          {{ $_t("views.change_password.title") }}
        </h5>
        <b-row>
          <ChangePassword />
        </b-row>
      </GlassCard>
    </b-col>
  </b-container>
</template>

<script>
import ChangePassword from "@/packages/change-password/presentation/components/ChangePassword";
import GlassCard from "@/components/app/GlassCard";
export default {
  name: "ChangePasswordInPanel",
  components: { GlassCard, ChangePassword },
  data() {
    return {
      bg_image: require("@/assets/img/bg.png"),
    };
  },
};
</script>

<style scoped>
.bg-container {
  height: calc(100vh - 125px);
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.card-glass.medium {
  min-height: 350px !important;
  background-color: var(--dark-purple);
}
</style>
