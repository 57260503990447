<template>
  <b-col cols="12" class="p-0 mt-5 pt-5 fa-num-font">
    <!----------------------start table of receipts-detail received----------------->
    <b-table
      :tbody-tr-class="rowClass"
      thead-class="text-secondary fs-12 fw-lighter text-center "
      responsive
      hover
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :no-local-sorting="true"
      @sort-changed="sortChange"
      :items="orders"
      :fields="tableHeader"
      primary-key="1"
      :busy.sync="skeletonLoader"
      :empty-text="$_t('table.default.empty_text')"
      :empty-filtered-text="$_t('table.default.empty_text')"
    >
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle mx-1" small></b-spinner>
          <strong>{{ $_t("table.default.busy_text") }}</strong>
        </div>
      </template>
      <template #cell(row)="{ index }">
        <span>
          {{ index + 1 + (currentPage - 1) * pageSize }}
        </span>
      </template>
      <template #cell(orderDate)="row">
        <span
          v-if="
            $_date(row.item.orderDate, 'jalali') !==
            $_date(new Date(), 'jalali')
          "
        >
          {{ $_date(row.item.orderDate, "jalali") }}</span
        >

        <span v-else>
          {{ $_date(row.value, "fromNow") }}
        </span>
      </template>
      <template #cell(state)="{ item }">
        <div
          :class="[`text-${item.state.color}`, `bg-${item.state.bgColor}`]"
          class="rounded-pill py-1 px-3 w-75 d-flex justify-content-center mr-auto ml-auto"
        >
          {{ item.state.second }}
        </div>
      </template>
      <template #cell(paymentType)="{ item }">
        <div
          class="rounded-pill py-1 d-flex justify-content-center"
          :class="
            item.paymentType === 'inPlace'
              ? 'text-more bg-light-warning rounded-pill text-nowrap'
              : ''
          "
        >
          {{ checkType(item.paymentType) }}
        </div>
      </template>
      <template #cell(paymentStatus)="{ item }">
        <div
          class="rounded-pill py-1 d-flex justify-content-center"
          :class="
            item.paymentStatus === 'success'
              ? 'text-success'
              : item.paymentStatus === 'failure'
              ? 'text-danger'
              : 'text-warning'
          "
        >
          {{ checkStatus(item.paymentStatus) }}
        </div>
      </template>

      <template #cell(price)="{ item }">
        <span> {{ $_currency(Number(item.price)) }}</span>
      </template>
      <template #cell(detail)="{ item }">
        <b-button
          size="sm"
          @click="navigateToOrderDetail(item.id)"
          class="mr-1 text-info w-100 border-0"
          block
          variant="accent"
        >
          جزئیات
        </b-button>
      </template>
    </b-table>
    <!----------------------end table of receipts-detail received------------------->
  </b-col>
</template>

<script>
import { PRICE_OF_ORDERS_ABOVE_THE_SPECIFIED_LIMIT } from "@/packages/orders/config/PRICE_OF_ORDERS_ABOVE_THE_SPECIFIED_LIMIT";
import { TOTAL_ORDER_TABLE_LIST_HEADER } from "@/packages/orders/schema/table/TOTAL_ORDER_TABLE_LIST_HEADER";
import { mapGetters } from "vuex";
export default {
  name: "OrderTable",
  props: {
    skeletonLoader: {
      type: Boolean,
      require: true,
    },
    hasError: {
      type: Boolean,
      require: true,
    },
  },
  data: () => {
    return {
      sortBy: "createdAt",
      sortDesc: false,
      tableHeader: TOTAL_ORDER_TABLE_LIST_HEADER,
    };
  },
  computed: {
    ...mapGetters({
      is_loading: "loading/is_loading",
      orders: "order/orders",
      currentPage: "pagination/current_page",
      pageSize: "pagination/size",
    }),
  },

  methods: {
    rowClass(item) {
      let classes = "align-middle fs-14 text-center";
      if (!item) return classes;
      if (
        Number(item.price) > Number(PRICE_OF_ORDERS_ABOVE_THE_SPECIFIED_LIMIT)
      ) {
        classes += " order-above";
      }
      if (item.state.first === "CANCELED" && item.payment) {
        classes += " border-right border-danger";
      }
      if (item.postalTrackingCode) {
        classes += " tracking-code-style";
      }
      return classes;
    },

    sortChange(e) {
      if (!e.sortBy) return;
      this.$emit("input", e);
    },

    checkType(value) {
      switch (value) {
        case "receipt":
          return "حواله";
        case "online":
          return "آنلاین";
        case "inPlace":
          return "پرداخت در محل";
        case "baloan":
          return "بالون";
        case "credit":
          return "اعتباری بالون";
      }
    },

    checkStatus(value) {
      switch (value) {
        case "waiting":
          return "در انتظار پرداخت";
        case "success":
          return "موفق";
        case "failure":
          return "ناموفق";
        case "inPlace":
          return "پرداخت در محل";
      }
    },

    navigateToOrderDetail(id) {
      let routeData = this.$router.resolve({
        name: "order.detail-all-order",
        params: { id: id, type: "all" },
      });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>

<style>
.order-above {
  background-color: #dedeec;
}
.tracking-code-style td:nth-child(1) {
  background-color: yellow !important;
}
.vertical-hr {
  border-left: 2px solid lightgray;
  height: 20px;
}
</style>
