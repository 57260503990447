import api from "@/tools/Api";
import urls from "@/tools/Urls";

/*
 *  fetch  users
 *  ------------------------
 *  Api address | /api/v1/user-manage
 *  ------------------------
 *  @param user_id { String }
 *  ------------------------
 *  method : Get
 *  ------------------------
 *  Description :
 *  ------------------------
 *  fetch  users
 *  ------------------------
 *  @return Promise
 *  ------------------------
 */

export const fetchStatesAxiosRequest = function (user_id) {
  return api.get(urls.api("fetch_states", [user_id, "states"]));
};
