// initial state
const state = () => ({
  states: [],
  orders: [],
  orderDetail: {},
  basketOrderItems: [],
  customerDetail: {},
  deliveryDetail: {},
  paymentDetail: {},
});

// getters
const getters = {
  states: (state) => state.states,
  orders: (state) => state.orders,
  orderDetail: (state) => state.orderDetail,
  basketOrderItems: (state) => state.basketOrderItems,
  customerDetail: (state) => state.customerDetail,
  deliveryDetail: (state) => state.deliveryDetail,
  paymentDetail: (state) => state.paymentDetail,
};

// mutations
const mutations = {
  mutateStates(state, payload) {
    let data = [];
    payload.forEach((element) => {
      switch (element.state) {
        case "INPAYMENT":
          data.push({ ...element, icon: "mdi-vanish", color: "primary" });
          return;
        case "INPROCESS":
          data.push({
            ...element,
            icon: "mdi-checkbox-marked-circle-outline",
            color: "warning",
          });
          return;
        case "DELIVERED":
          data.push({ ...element, icon: "mdi-weight", color: "success" });
          return;
        case "RETURNED":
          data.push({ ...element, icon: "mdi-reload", color: "blue" });
          return;
        case "CANCELED":
          data.push({
            ...element,
            icon: "mdi-package-variant-closed-remove",
            color: "error",
          });
          return;
      }
    });
    state.states = data;
  },

  mutateOrders(state, payload) {
    state.orders = payload;
  },

  ///////start user Order detail
  mutateOrderDetail(state, payload) {
    state.orderDetail = payload;
  },
  mutateBasketOrderItems(state, payload) {
    state.basketOrderItems = payload;
  },
  mutateCustomerDetail(state, payload) {
    state.customerDetail = payload;
  },
  mutateDeliveryDetail(state, payload) {
    state.deliveryDetail = payload;
  },
  mutatePaymentDetail(state, payload) {
    state.paymentDetail = payload;
  },
  ///////end user Order detail
};

// actions
const actions = {
  setStates({ commit }, payload) {
    commit("mutateStates", payload);
  },

  setOrders({ commit }, payload) {
    commit("mutateOrders", payload);
  },

  // setOrderDetail({ commit }, payload) {
  //   commit("mutateOrderDetail", {
  //     id: payload.id,
  //     code: payload.code,
  //     state: payload.state,
  //     fulllName: payload.name + " " + payload.lastName,
  //     nationalCode: "545454545454",
  //     statusOrder: "hjk",
  //     phone: payload.phone,
  //   });
  //   commit("mutateBasketOrderItems", payload.orderItems);
  //   commit("mutateCustomerDetail", payload.customerDetail);
  //   commit("mutateDeliveryDetail", payload.deliveryDetail);
  //   commit("mutatePaymentDetail", payload.paymentDetail);
  // },
  setOrderDetail({ commit }, payload) {
    commit("mutateOrderDetail", payload);
  },
};

export default {
  namespaced: true,
  name: "orders",
  state,
  getters,
  actions,
  mutations,
};
