<template>
  <div>
    <Items class="mt-5" />
    <Description class="mt-5 mb-4" :description="description" />
  </div>
</template>

<script>
import Items from "@/packages/orders/components/order-detail/returned-orders-detail/step3/items/Index";
import Description from "@/packages/orders/components/order-detail/returned-orders-detail/step3/Description";
import { mapGetters } from "vuex";
export default {
  name: "Index",
  components: { Items, Description },
  computed: {
    ...mapGetters({
      description: "returned/description",
    }),
  },
};
</script>

<style scoped></style>
