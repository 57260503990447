var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-table',{staticClass:"tableBox m-0 fa-num-font",attrs:{"tbody-tr-class":"align-middle fs-14 border-0","thead-class":"text-secondary fs-12 fw-lighter border-0","responsive":"","hover":"","busy":_vm.is_loading,"items":_vm.orders,"fields":_vm.tableHeader,"primary-key":"1","show-empty":"","empty-text":_vm.$_t('table.default.empty_text'),"empty-filtered-text":_vm.$_t('table.default.empty_text')},on:{"update:busy":function($event){_vm.is_loading=$event}},scopedSlots:_vm._u([{key:"cell(row)",fn:function(ref){
var index = ref.index;
return [_c('span',[_vm._v(_vm._s(index + 1))])]}},{key:"cell(price)",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$_currency(Number(item.price) / 10)))])]}},{key:"cell(date)",fn:function(row){return [(_vm.$_date(row.item.date, 'jalali') !== _vm.$_date(new Date(), 'jalali'))?_c('span',[_vm._v(" "+_vm._s(_vm.$_date(row.item.date, "jalali")))]):_c('span',[_vm._v(" "+_vm._s(_vm.$_date(row.value, "fromNow"))+" ")])]}},{key:"cell(state)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"rounded-pill text-danger bg-light-primary px-3 fs-14 py-1"},[_vm._v(_vm._s(item.state.second))])]}},{key:"cell(paymentStatus)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"pa-1 text-center text-body-1 font-weight-medium letter-spacing",class:_vm.checkStatus(item.paymentStatus).color},[_vm._v(" "+_vm._s(_vm.checkStatus(item.paymentStatus).title)+" ")])]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('b-button',{staticClass:"mr-1 text-info w-100 border-0 w-100",attrs:{"size":"sm","variant":"accent"},on:{"click":function($event){return _vm.$router.push({ name: 'orders.details', params: { id: item.id } })}}},[_vm._v(" جزئيات ")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }