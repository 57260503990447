<template>
  <TransferDetails />
</template>

<script>
import TransferDetails from "@/packages/contact-us/components/details/Index.vue";

export default {
  components: { TransferDetails },
};
</script>
