<template>
  <LipakModal
    @close="$emit('close')"
    :modal="modal"
    :title="
      $_t(
        'components.users.user.profile.legal_information.modal.edit_legal_information_modal.title'
      )
    "
    size="md"
  >
    <template v-slot:body>
      <b-col cols="12" class="mx-auto fa-num-font">
        <LipakForm>
          <!--Start form body    ------------------------------------------------------------------>
          <template v-slot:body>
            <!--Start form wrapper for catch errors  ------------------------>
            <LipackFormGenerator
              lg="6"
              :schema="schema"
              :validator="$v"
            ></LipackFormGenerator>
            <!--End   form wrapper for catch errors  ------------------------>
          </template>
          <!--End   form body    ------------------------------------------------------------------>

          <!--Start form actions ------------------------------------------------------------------>
          <template v-slot:action>
            <b-col cols="=12" lg="4" md="4" sm="6">
              <b-form-group class="form-group inputBox">
                <b-button
                  variant="outline-secondary"
                  class="w-100"
                  @click="$emit('close')"
                  :disabled="is_loading"
                >
                  {{
                    $_t(
                      "components.users.user.profile.legal_information.modal.edit_legal_information_modal.buttons.close"
                    )
                  }}
                </b-button>
              </b-form-group>
            </b-col>
            <b-col cols="=12" lg="4" md="4" sm="6">
              <b-form-group class="form-group inputBox">
                <b-button
                  variant="success"
                  class="w-100"
                  @click="submit"
                  :disabled="is_loading || $v.$error"
                >
                  <b-spinner
                    v-if="is_loading"
                    small
                    variants="success"
                  ></b-spinner>
                  {{
                    $_t(
                      "components.users.user.profile.legal_information.modal.edit_legal_information_modal.buttons.submit_text"
                    )
                  }}
                </b-button>
              </b-form-group>
            </b-col>
          </template>
          <!--End   form actions ------------------------------------------------------------------>
        </LipakForm>
      </b-col>
    </template>
    <template v-slot:footer>
      <b-row class="w-100 flex-row-reverse"> </b-row>
    </template>
  </LipakModal>
</template>

<script>
import { FORM_EDIT_SCHEMA } from "@/packages/users/schema/forms/edit.user.legal_information.schema";
import LipakModal from "@/components/app/Modal/LipakModal";
import LipackFormGenerator from "@/components/app/form/LipackFormGenerator";
import LipakForm from "@/components/app/form/LipakForm";
import { mapGetters } from "vuex";
import Profile from "@/packages/users/services/profile";

export default {
  name: "EditLegaInformationModal",
  components: {
    LipakModal,
    LipackFormGenerator,
    LipakForm,
  },
  props: {
    modal: {
      type: Boolean,
      default: false,
    },
    legalDetail: {
      type: Object,
      default: null,
    },
  },
  data: function () {
    return {
      Profile: new Profile(),
      schema: FORM_EDIT_SCHEMA.schema,
      form: { ...FORM_EDIT_SCHEMA.model },
    };
  },
  validations: {
    ...FORM_EDIT_SCHEMA.validations,
  },
  watch: {
    legalDetail: {
      handler(newValue) {
        this.form = {
          companyName: newValue.legal.companyName.value,
          nationalId: newValue.legal.nationalId.value,
          registerNumber: newValue.legal.registerNumber.value,
        };
      },
      deep: true,
      immediate: true,
    },
  },

  computed: {
    ...mapGetters({
      is_loading: "loading/is_loading",
    }),
  },
  methods: {
    isFormValidate() {
      this.$v.$touch();
      return !this.$v.$invalid;
    },
    async submit() {
      if (!this.isFormValidate()) return;
      try {
        await this.Profile.updateLegalUser(this.form, this.legalDetail.id);
        await this.Profile.fetchUserPersonalInfoById(
          this.$route.params.user_id
        );
        await this.$emit("close");
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style scoped></style>
