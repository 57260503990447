<template>
  <div>Discount Codes</div>
</template>

<script>
export default {
  name: "DiscountCodes",
};
</script>

<style scoped></style>
