<template>
  <div>
    <b-card
      v-if="skeletonLoader"
      class="rounded-lg shadow border-0 justify-content-center px-1 px-lg-5 mb-3 fa-num-font"
    >
      <b-skeleton height="65px"></b-skeleton>
      <b-skeleton height="80px" class="mt-3"></b-skeleton>
      <b-skeleton height="80px" class="mt-3"></b-skeleton>
      <b-skeleton-table
        no-aspect
        height="150px"
        class="mt-5"
      ></b-skeleton-table>
      <b-skeleton
        v-for="i in 3"
        :key="i.index"
        height="160px"
        class="mt-3"
      ></b-skeleton>
    </b-card>
    <b-card
      v-else-if="hasError"
      class="rounded-lg shadow border-0 d-block justify-content-space-between px-1 px-lg-5 mb-3"
    >
      <div class="text-primary text-center">خطای سرور !</div>
      <div class="text-center mt-2">
        <b-button variant="outline-primary" @click="initOrderDetail">
          درخواست مجدد</b-button
        >
      </div>
    </b-card>
    <template v-else>
      <b-overlay :show="overly" class="mt-15"> </b-overlay>
      <b-card
        class="rounded-lg shadow border-0 justify-content-space-between px-1 px-lg-5 mb-3 fa-num-font"
        v-show="!overly"
      >
        <b-row>
          <b-col cols="12" class="d-flex justify-content-end">
            <b-button
              v-if="orderDetail.hasTrackingCode"
              @click="isShowRegisterTrackingCode = true"
              variant="primary"
              class="align-items-center ml-2 fs-14"
              style="width: 130px"
            >
              ثبت کد رهگیری
            </b-button>
            <b-button
              variant="primary"
              class="d-flex justify-content-between align-items-center fs-14"
              v-b-toggle.sidebar-variant
              style="width: 130px"
            >
              <span class="text-white pl-3"> ثبت نظر</span>
              <div
                class="d-flex justify-content-center align-items-center bg-white rounded-lg px-2"
              >
                <div class="text-more pt-1 font-weight-bold">
                  {{ orderDetail.orderCommentDtoList.length }}
                </div>
              </div>
            </b-button>
            <b-sidebar id="sidebar-variant" title=" " bg-variant="light" shadow>
              <div class="px-3 py-2">
                <CommentsSupport
                  :comments.sync="orderDetail.orderCommentDtoList"
                />
              </div>
            </b-sidebar>
          </b-col>
        </b-row>

        <!---------------------------start personal info buyer ---------------------------->
        <OrderPersonInfo
          :state="orderDetail.stateFlow.state"
          :price="orderDetail.paymentDetailsDto.paymentPrice"
          :personal-info="orderDetail.personalInfoDto"
          :shipping-method="orderDetail.deliveryDetailsDto.shippingMethod"
          @updateOrderDetail="initOrderDetail"
          :payment-method="orderDetail.paymentDetailsDto.paymentType"
          :enabledRegisteringSuccessfulDelivery="
            orderDetail.isAutoDeliveryEnabled
          "
        />

        <!--      :userId="orderDetail.userID"-->
        <!---------------------------end personal info buyer ------------------------------>

        <!---------------------------start orders info ------------------------------------>
        <OrderFactor
          :id="orderDetail.id"
          :code="String(orderDetail.code)"
          :state="orderDetail.stateFlow.state"
          @updateOverly="updateOverly($event)"
        />
        <!---------------------------end orders info -------------------------------------->

        <div class="d-flex align-items-center justify-content-between">
          <Title
            class="title font-weight-bold mt-5"
            title="وضعیت سفارش"
            variant="danger"
          ></Title>
          <b-button
            v-if="orderDetail.returnable"
            variant="danger"
            :to="{
              name: 'return-register.detail',
              params: { id: orderDetail.id },
            }"
          >
            ثبت مرجوعی
          </b-button>
        </div>

        <!-----------------------------start orders tab ---------------------------------->
        <StepsOfOrder :state-flow="orderDetail.stateFlow" />
        <!-----------------------------end orders tab------------------------------------->

        <b-col class="d-flex justify-content-between align-items-end px-0 mt-5"
          ><div>
            <Title
              class="title font-weight-bold pa-0"
              title="کالای خریداری شده"
              variant="danger"
            ></Title>
          </div>

          <b-button
            :disabled="!orderDetail.cancelable"
            @click="openCancelOrder"
            class="d-flex justify-content-center align-center border-0 text-white bg-primary font-weight-bold mb-3"
            >لغو سفارش
          </b-button>
        </b-col>

        <!-----------------------------start orders detail table ---------------------------------->
        <OrderDetailTable
          :order-list="orderDetail.orderItemPages.content"
          :discount="orderDetail.paymentDetailsDto.discount"
        />
        <!-----------------------------start orders detail table----------------------------------->

        <!-----------------------------start info about payment way , sending way ----------------->
        <RecipientDeliveryInfo
          :order-id="orderDetail.id"
          :user-id="orderDetail.personalInfoDto.userId"
          :recipient-delivery-info="orderDetail.userDetailDto"
          :delivery-detail="orderDetail.deliveryDetailsDto"
          :state="orderDetail.stateFlow"
          :hasTrackingCode="orderDetail.hasTrackingCode"
        />

        <CourierInfo
          v-if="orderDetail.courierDetailsDto"
          :courier-info="orderDetail.courierDetailsDto"
        />

        <PaymentInfo :payment-detail="orderDetail.paymentDetailsDto" />
        <!-----------------------------end info about payment way , sending way-------------------->

        <LegalInfo :legal-detail="orderDetail.userLegal" />

        <!-----------------------------start comment ---------------------------------------------->
        <Comment :comment="orderDetail.comment" />
        <!-----------------------------end comment ------------------------------------------------>

        <!-----------------------------start cancel order ----------------------------------------->
        <CancelOrder
          :modal="isShowModal"
          @close="isShowModal = false"
          @updateOrderDetail="initOrderDetail"
        />
        <!-----------------------------end cancel order ------------------------------------------->

        <!-----------------------------statr RegisterTrackingCode --------------------------------->
        <RegisterTrackingCode
          :modal="isShowRegisterTrackingCode"
          @close="isShowRegisterTrackingCode = false"
          :order-code="String(orderDetail.code)"
        />
        <!-----------------------------end RegisterTrackingCode ----------------------------------->
      </b-card>
    </template>
  </div>
</template>

<script>
import OrderPersonInfo from "./OrderPersonInfo";
import OrderFactor from "./OrderFactor";
import Title from "@/components/app/Title";
import OrderDetailTable from "./OrderDetailTable";
import StepsOfOrder from "./steps-of-order/StepsOfOrder";
import OrderService from "@/packages/orders/services/order.service";
import RecipientDeliveryInfo from "./final-information/RecipientDeliveryInfo";
import PaymentInfo from "./final-information/PaymentInfo";
import LegalInfo from "./LegalInfo";
import Comment from "./Comment";
import { mapActions, mapGetters } from "vuex";
import CancelOrder from "./CancelOrder";
import CommentsSupport from "./comments-support/index";
import { getPaymentGatewayStatus } from "@/packages/manage/api/payoff";
import RegisterTrackingCode from "@/packages/orders/components/order-detail/all-order-detail/tracking-code/RegisterTrackingCode";
import CourierInfo from "./CourierInfo";
export default {
  name: "AllOrderDetail",
  data() {
    return {
      isShowModal: false,
      skeletonLoader: true,
      Order: new OrderService(),
      hasError: false,
      isShowRegisterTrackingCode: false,
      overly: false,
    };
  },
  components: {
    OrderPersonInfo,
    OrderFactor,
    Title,
    OrderDetailTable,
    RecipientDeliveryInfo,
    PaymentInfo,
    LegalInfo,
    StepsOfOrder,
    CancelOrder,
    Comment,
    CommentsSupport,
    RegisterTrackingCode,
    CourierInfo,
  },
  mounted() {
    this.initOrderDetail();
    this.initGateways();
  },

  computed: {
    ...mapGetters({
      orderDetail: "order/orderDetail",
    }),
  },

  watch: {
    hasError(newValue) {
      this.skeletonLoader = !newValue;
    },
  },
  methods: {
    ...mapActions({
      setGateways: "order/setGateways",
    }),

    async initOrderDetail() {
      try {
        this.skeletonLoader = true;
        this.hasError = false;
        await this.Order.fetchOrderDetailById(this.$route.params.id, true);
        this.skeletonLoader = false;
      } catch (e) {
        this.hasError = true;
        this.skeletonLoader = false;
      }
    },

    openCancelOrder() {
      this.isShowModal = true;
    },

    async initGateways() {
      const gateways = await getPaymentGatewayStatus();
      await this.setGateways(gateways);
    },

    updateOverly($event) {
      this.overly = $event;
    },
  },
};
</script>
