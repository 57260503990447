<template>
  <LipakModal
    @close="close(false)"
    :modal="modal"
    :title="'افزودن آدرس جدید'"
    size="xl"
  >
    <template v-slot:body>
      <b-col cols="12" class="mx-auto fa-num-font">
        <LipakForm>
          <!--Start form body    ------------------------------------------------------------------>
          <template v-slot:body>
            <!--Start form wrapper for catch errors  ------------------------>
            <LipakFormGenerator
              lg="6"
              :schema="schema"
              :validator="$v.form"
            ></LipakFormGenerator>
            <!--End   form wrapper for catch errors  ------------------------>
          </template>
          <!--End   form body    ------------------------------------------------------------------>

          <!--Start form actions ------------------------------------------------------------------>
          <template v-slot:action>
            <b-col cols="=12" lg="4" md="4" sm="6">
              <b-form-group class="form-group inputBox">
                <b-button
                  variant="outline-secondary"
                  class="w-100"
                  @click="close(false)"
                  :disabled="is_loading"
                >
                  {{
                    $_t(
                      "components.users.user.profile.address_information.modal.edit_address_information_modal.buttons.close"
                    )
                  }}
                </b-button>
              </b-form-group>
            </b-col>
            <b-col cols="=12" lg="4" md="4" sm="6">
              <b-form-group class="form-group inputBox">
                <b-button
                  variant="success"
                  class="w-100"
                  @click="submit"
                  :disabled="is_loading || $v.$error"
                >
                  <b-spinner
                    v-if="is_loading"
                    small
                    variants="success"
                  ></b-spinner>
                  ثبت آدرس جدید
                </b-button>
              </b-form-group>
            </b-col>
          </template>
          <!--End   form actions ------------------------------------------------------------------>
        </LipakForm>
      </b-col>
    </template>
    <template v-slot:footer>
      <b-row class="w-100 flex-row-reverse"> </b-row>
    </template>
  </LipakModal>
</template>

<script>
import { NEW_ADDRESS_FORM } from "@/packages/users/schema/forms/NEW_ADDRESS_FORM";
import LipakModal from "@/components/app/Modal/LipakModal";
import LipakFormGenerator from "@/components/app/form/LipakFormInput";
import LipakForm from "@/components/app/form/LipakForm";
import { mapGetters } from "vuex";
import Users from "@/packages/users/services/users.service";
import { requiredIf } from "vuelidate/lib/validators";

export default {
  name: "CreateNewAddress",
  components: {
    LipakModal,
    LipakFormGenerator,
    LipakForm,
  },
  props: {
    modal: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      userService: new Users(),
      schema: NEW_ADDRESS_FORM.schema,
      form: { ...NEW_ADDRESS_FORM.model },
      isQuarterRequired: false,
    };
  },

  validations() {
    return {
      form: {
        ...NEW_ADDRESS_FORM.validations.form,
        quarter: {
          required: requiredIf(() => {
            return this.isQuarterRequired;
          }),
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      is_loading: "loading/is_loading",
      cities: "order/cities",
    }),
  },
  watch: {
    value: {
      handler() {
        this.schema[3].disabled = true;
        this.isQuarterRequired = false;
      },
    },
    "form.city": {
      handler(newValue) {
        if (!this.cities.length) {
          this.schema[3].disabled = true;
          this.isQuarterRequired = false;
          return;
        }
        let city = this.cities.find((item) => {
          if (item.id === String(newValue)) {
            return item;
          } else {
            return false;
          }
        });

        if (!city) {
          this.schema[3].disabled = true;
          this.isQuarterRequired = false;
        } else {
          this.schema[3].disabled = !city.isQuarter;
          this.isQuarterRequired = city.isQuarter;
        }
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    isFormValidate() {
      this.$v.$touch();
      return !this.$v.$invalid;
    },
    async submit() {
      if (!this.isFormValidate()) return;
      try {
        this.form.receiverPhone = this.$_faToEn(this.form.receiverPhone);
        this.form.postalCode = this.$_faToEn(this.form.postalCode);
        await this.userService.addNewAddress(
          this.form,
          this.$route.params.user_id
        );
        await this.close("success");
      } catch (e) {
        console.log(e);
      }
    },

    async close(dialog) {
      this.$nextTick(() => {
        this.$v.form.$reset();
        this.form.receiverName = "";
        this.form.receiverLastName = "";
        this.form.receiverPhone = "";
        this.form.addressDetails = "";
        this.form.province = "";
        this.form.provinceSearch = "";
        this.form.city = "";
        this.form.citySearch = "";
        this.form.quarter = "";
        this.form.quarterSearch = "";
        this.form.postalCode = "";
        this.form.provinceId = "";
        this.form.cityId = "";
      });
      await this.$emit("close", dialog);
    },
  },
};
</script>

<style scoped></style>
