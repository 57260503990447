var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-col',{staticClass:"fa-num-font",attrs:{"cols":"12"}},[_c('Title',{attrs:{"title":_vm.$_t('components.comments.comments.comments_table.title'),"variant":"danger"}}),_c('b-table',{attrs:{"tbody-tr-class":"align-middle fs-14 text-right","thead-class":"text-secondary fs-12 fw-lighter text-right column-max-width","clas":"","responsive":"","hover":"","items":_vm.comments,"fields":_vm.tableHeaders,"busy":_vm.isLoading,"primary-key":"1","show-empty":"","empty-text":_vm.$_t('table.default.empty_text'),"empty-filtered-text":_vm.$_t('table.default.empty_text')},on:{"update:busy":function($event){_vm.isLoading=$event},"sort-changed":_vm.sortChange},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-danger my-2"},[_c('b-spinner',{staticClass:"align-middle mx-1",attrs:{"small":""}}),_c('strong',[_vm._v(_vm._s(_vm.$_t("table.default.busy_text")))])],1)]},proxy:true},{key:"cell(index)",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1 + (_vm.currentPage - 1) * _vm.pageSize)+" ")]}},{key:"cell(createdAt)",fn:function(data){return [(Boolean(data.value))?_c('span',[_vm._v(_vm._s(_vm.$_date(data.value)))]):_vm._e()]}},{key:"cell(productName)",fn:function(data){return [_c('a',{staticClass:"text-decoration-none",attrs:{"href":_vm.productUrl(data.item.slug)}},[_vm._v(_vm._s(data.item.productName)+" ")])]}},{key:"cell(reply)",fn:function(data){return [_c('p',{staticClass:"text-wrap",staticStyle:{"max-width":"350px"}},[_vm._v(" "+_vm._s(_vm.getShortReply(data.item.content))+" ")])]}},{key:"cell(status)",fn:function(data){return [_c('div',{staticClass:"d-flex justify-content-end align-items-center"},[_c('div',{staticClass:"w-50 text-center mt-2 py-1 px-2 text-nowrap rounded-lg font-weight-bold",class:data.item.productCommentStatus.color},[_vm._v(" "+_vm._s(data.item.productCommentStatus.persianName)+" ")]),_c('div',{staticClass:"rounded mr-3 border-0 text-purple-light px-1"},[_vm._v(" "+_vm._s(data.item.numberOfLike)+" ")]),_c('div',{staticClass:"rounded border-0 text-primary-light px-1"},[_vm._v(" "+_vm._s(data.item.numberOfDislike)+" ")]),_c('b-button',{staticClass:"rounded border-0",attrs:{"id":'btn-' + data.item.id,"size":"sm","pill":"","text":"","variant":"outline-danger"},on:{"click":function($event){return _vm.rejectionComment(data.item.id)}}},[_c('LITimesCircle',{staticClass:"fs-16"})],1),_c('b-button',{staticClass:"rounded border-0",attrs:{"id":("target-" + (data.item.id)),"size":"sm","pill":"","text":"","variant":"outline-success"},on:{"click":function($event){return _vm.approveComment(data.item.id)}}},[_c('LICheckCircleFill',{staticClass:"fs-16"})],1),_c('b-button',{staticClass:"rounded border-0",attrs:{"size":"sm","pill":"","text":"","variant":data.item.reply && data.item.reply.content
              ? 'outline-info'
              : 'outline-primary'},on:{"click":function($event){return _vm.openModalReply(data.item)}}},[_c('LIRecycleMessage',{staticClass:"fs-16"})],1),_c('b-button',{staticClass:"rounded border-0",attrs:{"size":"sm","pill":"","text":"","variant":"outline-info"},on:{"click":function($event){return _vm.openModalEditComment(
              data.item.id,
              data.item.content,
              data.item.title
            )}}},[_c('LIEdit',{staticClass:"fs-16"})],1)],1)]}},{key:"empty",fn:function(scope){return [_c('p',{staticClass:"text-center"},[_vm._v(_vm._s(scope.emptyText))])]}},{key:"emptyfiltered",fn:function(scope){return [_c('p',{staticClass:"text-center"},[_vm._v(_vm._s(scope.emptyFilteredText))])]}}])}),_c('ReplayCommentModal',{attrs:{"modal":_vm.isShowModal,"comment-id":_vm.commentId,"replySupport":_vm.replySupport},on:{"close":function($event){_vm.isShowModal = false}}}),_c('EditComment',{attrs:{"modal":_vm.isShowModalEditComment,"commentUser":_vm.commentUser},on:{"close":function($event){_vm.isShowModalEditComment = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }