<template>
  <b-card class="shadow border-0 px-lg-3 mb-3 fa-num-font">
    <CommentsFilters v-model="filters" />
    <CommentsTable
      v-model="sort"
      @fetchAgain="fetchAgain"
      @changeStatusOrLike="initialize"
    />
    <!--Start pagination -------------------------------------------->
    <Pagination
      class="mt-5"
      v-model="paginationParams"
      key="product-pagination"
    />
    <!--End   pagination -------------------------------------------->
  </b-card>
</template>

<script>
import CommentsFilters from "@/packages/comments/components/comments/filters/CommentsFilters";
import CommentsTable from "@/packages/comments/components/comments/CommentsTable";
import CommentsService from "@/packages/comments/services/comments.service";
import Pagination from "@/components/app/Pagination";

export default {
  name: "Comments",
  components: { CommentsTable, CommentsFilters, Pagination },
  data() {
    return {
      paginationParams: null,
      filters: null,
      sort: {
        sortBy: "createdAt",
        sortDesc: true,
      },
      Comment: new CommentsService(),
    };
  },
  watch: {
    paginationParams: {
      // This will let Vue know to look inside the object
      deep: true,

      // We have to move our method to a handler field
      async handler(value) {
        if (value) await this.initialize(value);
      },
    },

    sort: {
      // This will let Vue know to look inside the object
      deep: true,

      // We have to move our method to a handler field
      async handler(value) {
        let { sortBy, sortDesc } = value;
        if (value)
          await this.initialize({
            ...this.filters,
            ...this.paginationParams,
            sortBy: sortBy,

            /* force server to sort items asc/desc */
            sortDirection: sortDesc ? "DESC" : "ASC",
          });
      },
    },
  },
  methods: {
    fetchAgain() {
      this.initialize({
        ...this.filters,
        ...this.paginationParams,
      });
    },
    async initialize(paginationParams) {
      let { sortBy, sortDesc } = this.sort;
      await this.Comment.fetchComments(
        {
          ...paginationParams,
          ...this.filters,
          sortBy: sortBy,

          /* force server to sort items asc/desc */
          sortDirection: sortDesc ? "DESC" : "ASC",
        },
        true
      );
    },
  },
};
</script>

<style scoped></style>
