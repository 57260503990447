const { required } = require("vuelidate/lib/validators");

export const SUBMIT_COMMENT = {
  /* Form SCHEMA */
  schema: [
    {
      title: "comment",
      id: "comment",
      label: "comment",
      placeholder: "comment",
      show_details: true,
      cols: 12,
      lg: 12,
      xl: 12,
      type: "textarea",
      class: "py-0 px-0",
    },
  ],

  /* Form MODEL */
  model: {
    comment: "",
  },

  /* VALIDATION SCHEMA */
  validations: {
    comment: { required },
  },
};
