<template>
  <!---------------start filter receipts-detail-received-------------------------------->
  <b-row class="d-flex justify-content-center"
    ><b-col cols="12" class="p-0 m-0"
      ><b-tabs content-class="mt-3" fill>
        <b-tab title="سبد خرید" active class="d-flex justify-content-center"
          ><CartBasket />
        </b-tab>
        <b-tab title="در انتظار پرداخت"> </b-tab>
        <b-tab title="در حال پردازش"> </b-tab>
        <b-tab title="پایان پردازش و تحویل ارسال"> </b-tab>
        <b-tab title="تکمیل شده"> </b-tab>
        <b-tab title="لغو شده"> </b-tab>
        <b-tab title="مرجوع شده"> </b-tab
        ><b-tab title="موافق"> </b-tab> </b-tabs></b-col
  ></b-row>
</template>

<script>
import CartBasket from "./CartBasket";
export default {
  name: "OrderConditionTab",
  components: {
    CartBasket,
  },
};
</script>

<style>
.nav {
  border-bottom: 0 !important;
}
.nav-item {
  border-bottom: 0 !important;
  display: inline-flex;
  align-self: self-end;
}
.nav-link {
  width: 100%;
  color: rgba(0, 0, 0, 0.49) !important;
}
.nav-link.active {
  border: 0px !important;
  /* border-bottom: 2px solid #fe6a34 !important; */
  background-color: #e5e5e5;
  color: #fe6a34 !important;
}
</style>
