import api from "@/tools/Api";
import urls from "@/tools/Urls";

/*
 *  edit comment
 *  ------------------------
 *  Api address | /api/v1/comments
 *  ------------------------
 *  @param payload { Object }
 *  @param commentID { string }
 *  ------------------------
 *  method : PUT
 *  ------------------------
 *  Description :
 *  ------------------------
 *  edit comment
 *  ------------------------
 *  @return Promise
 *  ------------------------
 */

export const editCommentAxiosRequest = function (payload) {
  return api.put(urls.api("edit_comment"), payload);
};
