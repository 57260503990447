/* Header navigation links */
module.exports = [
  {
    title: "users",
    to: { name: "users" },
    icon: "userGroup",
    isDisabled: false,
    isLink: true,
    isAdmin: false,
  },
  // {
  //   title: "orders",
  //   to: { name: "orders" },
  //   icon: "basket-check",
  //   isDisabled: false,
  //   isLink: true,
  // },
  // {
  //   title: "referral-orders",
  //   to: { name: "referral-orders" },
  //   icon: "todo",
  //   isDisabled: true,
  //   isLink: true,
  // },
  // {
  //   title: "canceled-orders",
  //   to: { name: "canceled-orders" },
  //   icon: "product-times",
  //   isDisabled: true,
  //   isLink: true,
  // },
  {
    title: "comments",
    to: { name: "comments" },
    icon: "message",
    isDisabled: true,
    isLink: true,
    isAdmin: false,
  },
  // {
  //   title: "feedbacks",
  //   to: { name: "logs" },
  //   icon: "reaction-pack",
  //   isDisabled: true,
  //   isLink: true,
  // },
  // {
  //   title: "survey",
  //   to: { name: "survey" },
  //   icon: "conversation-check-times",
  //   isDisabled: true,
  //   isLink: true,
  // },
  // {
  //   title: "management",
  //   to: { name: "management" },
  //   icon: "check-gear",
  //   isDisabled: true,
  //   isLink: true,
  // },
  {
    title: "orders",
    to: { name: "dashboard.orders" },
    icon: "message",
    isDisabled: false,
    isLink: true,
    isAdmin: false,
  },
  {
    title: "contact_us",
    to: { name: "contact-us" },
    icon: "message",
    isDisabled: false,
    isLink: true,
    isAdmin: false,
  },
  {
    title: "tracking_code",
    to: { name: "tracking-code" },
    icon: "message",
    isDisabled: false,
    isLink: true,
    isAdmin: false,
  },
  {
    title: "manage",
    to: { name: "manage" },
    icon: "message",
    isDisabled: false,
    isLink: true,
    isAdmin: true,
  },
];
