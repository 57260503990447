<template>
  <form-wrapper :validator="validator.form" class="w-100">
    <slot v-if="$slots['form-body']" name="form-body" />
    <b-row v-else>
      <!--Start form inputs  ---------------------->
      <b-col
        v-for="input in schema"
        :key="'key-' + input.id"
        v-bind="$attrs"
        :cols="input.cols || 12"
        :lg="input.lg || $attrs.lg"
        :md="input.md || 6"
      >
        <form-group
          :name="input.id"
          :attribute="$_t(`attribute.${input.id}`)"
          :class="input.class || ''"
        >
          <LipakInput
            v-if="input.auto_complete"
            :disabled="input.disabled || false"
            :readonly="input.readonly || false"
            v-model="validator.form[input.id].$model"
            :search.sync="validator.form.search.$model"
            :type="input.type"
            :required="input.required || false"
            :default-option-disabled="input.default_option_disabled"
            :auto-complete="input.auto_complete"
            :placeholder="
              Boolean(input.placeholder)
                ? $_t(`placeholder.${input.placeholder}`)
                : $_t(`placeholder.${input.id}`)
            "
            :options="input.options || null"
            :title="$_t(`attribute.${input.id}`)"
            :show-message="false"
          />
          <LipakAutoComplete
            v-else-if="input.customAutoComplete"
            v-model="validator.form[input.id].$model"
            :place-holder="
              Boolean(input.placeholder)
                ? $_t(`placeholder.${input.placeholder}`)
                : $_t(`placeholder.${input.id}`)
            "
            :title="input.id"
            :options="input.options"
            :parent-values="input.parentValues"
            :item="input"
            :has-action="input.hasAction || false"
            :type="input.type"
          />
          <LipakInput
            v-else
            class="p-1 w-100"
            :id="input.id"
            :date-type="input.type2"
            :type="input.type"
            :default-option-disabled="input.default_option_disabled"
            :title="$_t(`attribute.${input.id}`)"
            :placeholder="
              Boolean(input.placeholder)
                ? $_t(`placeholder.${input.placeholder}`)
                : $_t(`placeholder.${input.id}`)
            "
            :options="input.options || null"
            v-model="validator.form[input.id].$model"
            :show-message="false"
            :readonly="input.disabled"
          />
        </form-group>
      </b-col>
      <!--End   form inputs  ---------------------->
      <slot name="inline-action" />
    </b-row>
  </form-wrapper>
</template>

<script>
import FormGroup from "@/components/form/FormGroup";
import { templates } from "vuelidate-error-extractor";
import LipakAutoComplete from "@/components/app/form/LipakAutoComplete";

export default {
  name: "LipackFormGenerator",
  components: {
    LipakAutoComplete,
    FormGroup,
    FormWrapper: templates.FormWrapper,
  },
  props: {
    schema: {
      type: Array,
    },
    validator: {
      type: Object,
    },
  },
  data() {
    return {};
  },
};
</script>

<style>
.white-label .lipak-input-title {
  color: white !important;
}
.invalid-feedback {
  text-align: right;
}
</style>
