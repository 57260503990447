<template>
  <!----------------------start table of receipts-detail received----------------->
  <b-table
    class="tableBox m-0 fa-num-font"
    tbody-tr-class="align-middle fs-14 border-0"
    thead-class="text-secondary fs-12 fw-lighter border-0"
    responsive
    hover
    :busy.sync="is_loading"
    :items="orders"
    :fields="tableHeader"
    primary-key="1"
    show-empty
    :empty-text="$_t('table.default.empty_text')"
    :empty-filtered-text="$_t('table.default.empty_text')"
  >
    <template #cell(row)="{ index }">
      <span>{{ index + 1 }}</span>
    </template>

    <template #cell(price)="{ item }">
      <span>{{ $_currency(Number(item.price) / 10) }}</span>
    </template>
    <template #cell(returnDate)="{ item }">
      <span>{{ $_date(item.date) }}</span>
    </template>
    <template #cell(returnStatus)="{ item }">
      <span class="px-2 py-2">{{ setStattus(item.returnStatus) }}</span>
    </template>

    <template #cell(actions)="{ item }">
      <b-button
        :to="{
          name: 'orders.details-returned-orders',
          params: { id: item.returnId },
        }"
        class="text-info bg-light-blue fs-13 w-100 mr-1 text-info w-100 border-0"
      >
        جزئیات
      </b-button>
    </template>
  </b-table>
  <!----------------------end table of receipts-detail received------------------->
</template>

<script>
import { mapGetters } from "vuex";
import { USER_ORDER_RETURNED_TABEL_HEEADER } from "@/packages/users/schema/tables/USER_ORDER_RETURNED_TABEL_HEEADER";

export default {
  name: "ReturnedTable",
  data: () => {
    return {
      tableHeader: USER_ORDER_RETURNED_TABEL_HEEADER,
      status: {
        waiting_for_qc_approve: "تحویل کنترل کیفیت",
        waiting_for_return_product_or_payment: "تأیید کنترل کیفیت",
        waiting_for_support: "ثبت اولیه",
        waiting_for_qc_deliver: "تایید پشتبانی",
        cancel_by_support: "لغو شده توسط پشتیبان",
        cancel_by_customer: "لغو شده توسط کاربر",
      },
    };
  },
  computed: {
    ...mapGetters({
      is_loading: "loading/is_loading",
      orders: "orders/orders",
    }),
  },

  methods: {
    setStattus(item) {
      return this.status[item];
    },
  },
};
</script>
