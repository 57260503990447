import api from "@/tools/Api";
import urls from "@/tools/Urls";

/*
 *  fetch City
 *  ------------------------
 *  Api address | /api/v1/
 *  ------------------------
 *  @param provinceId {String}
 *  ------------------------
 *  method : Get
 *  ------------------------
 *  Description :
 *  ------------------------
 *  fetch City
 *  ------------------------
 *  @return Promise
 *  ------------------------
 */

export const fetchCityAxiosRequest = function (provinceId) {
  return api.get(urls.api("fetch_city", [provinceId]));
};
