<template>
  <b-form-group class="text-right font-weight-bold">
    <hr class="my-4" />

    <div class="d-flex justify-content-between">
      <span> زمان تحویل سفارش </span>
      <div v-if="currentAddress.shippingDate" class="pl-3 mr-auto">
        <span class="pl-2"> روز ثبت شده : </span>
        <span> {{ $_date(currentAddress.shippingDate, "jalali") }}</span>
      </div>
      <br />
    </div>
    <div class="pl-3 d-flex justify-content-end mt-3">
      <span v-if="currentAddress.shippingShift" class="pl-2">
        {{ currentAddress.shippingShift }}:</span
      >
      <span v-if="currentAddress.shippingTime">
        {{ currentAddress.shippingTime }}</span
      >
    </div>
    <b-form-radio-group v-model="shippingTime" class="d-flex flex-wrap mt-4">
      <template v-for="item in shippingTimes">
        <b-col cols="12" lg="3" :key="item.title" class="my-2 mt-lg-0">
          <b-card
            class="shadow border rounded-lg text-right"
            style="height: 100%"
          >
            <div class="d-flex align-content-start fs-14">
              <b-form-radio :value="item.dayId" class="ml-3 mr-0" />

              {{ $_date(item.day, "jalali") }}
            </div>
          </b-card>
        </b-col>
      </template>
    </b-form-radio-group>
  </b-form-group>
</template>

<script>
export default {
  name: "ShippingTimes",
  props: {
    shippingTimes: {
      type: Array,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    currentAddress: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      shippingTime: this.value,
    };
  },
  watch: {
    shippingTime(newValue) {
      if (newValue === this.value) return;
      this.$emit("input", newValue);
    },
    value(newValue) {
      this.shippingTime = newValue;
    },
  },
};
</script>

<style scoped></style>
