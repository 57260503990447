module.exports = {
  components: {
    auth: {
      buttons: {
        submit: "تایید",
      },
      forgot_password_link_title: "فراموشی رمز عبور",
    },
    confirm_password: {
      buttons: {
        submit: "تایید",
      },
    },
    first_login_change_password: {
      buttons: {
        submit: "تایید",
      },
    },
    send_email: {
      buttons: {
        submit: "تایید",
      },
    },
    otp: {
      buttons: {
        submit: "تایید",
      },
    },
  },
  views: {
    authentication: {
      title: "پنل پشتیبانی",
    },
  },
  route_titles: {
    login: {
      title: "صفحه ورود",
    },
    verify_otp: {
      title: "تایید کد امنیتی",
    },
    forget_password: {
      title: "فراموشی رمز عبور",
    },
    reset_password: {
      title: "رمز عبور جدید",
    },
    change_password: {
      title: "تغییر رمز عبور",
    },
  },
  attribute: {
    username: "نام کاربری",
    password: "رمز عبور",
    confirm: "تکرار رمز عبور",
    otp: "کد تایید",
    email: "ایمیل",
  },
  placeholder: {
    username: "نام کاربری خود را وارد کنید.",
    password: "رمز عبور خود را وارد کنید.",
    confirm: "تکرار رمز عبور خود را وارد کنید.",
    otp: "کد تایید را وارد کنید.",
    email: "ایمیل خود را وارد کنید.",
  },
};
