<template>
  <ReturnFailureReason />
</template>

<script>
import ReturnFailureReason from "@/packages/manage/components/return-failure-reason/Index";
export default {
  components: { ReturnFailureReason },
};
</script>

<style scoped></style>
