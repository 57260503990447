<template>
  <b-container fluid class="shadow border-0 bg-white p-4 m-3 mt-lg-2">
    <b-row>
      <!--Start address title  -------------------------------------------------------------->
      <b-col cols="12" class="d-flex justify-content-start align-items-center">
        <b-avatar
          variant="purple-20"
          size="70"
          class="d-flex justify-content-center align-items-center"
        >
          <LILocationAlt class="fs-20 text-purple" />
        </b-avatar>
        <span class="text-purple mx-3">{{
          $_t(`components.users.user.profile.address_information.title`)
        }}</span>
      </b-col>
      <!--End   address title   ------------------------------------------------------------->

      <!--Start modal for create new address  ----------------------------------------------->
      <b-col cols="12">
        <b-button
          class="text-white px-3 py-2 mr-auto"
          variant="info"
          size="sm"
          @click="openCreateNewAddressModal"
        >
          افزودن آدرس جدید
        </b-button>

        <CreateNewAddress
          :modal="isShowCreateNewAddressModal"
          @close="closeCreateNewAddressModal($event)"
        />
      </b-col>
      <!--End modal for create new address  ------------------------------------------------->

      <!--Start address  -------------------------------------------------------------------->
      <b-col
        cols="12"
        v-for="(address, addressIndex) in addresses"
        :key="'address-container' + address.addressDetails + addressIndex"
        class="d-block"
      >
        <Address
          :address="address"
          :key="'address-' + address.addressDetails"
        />
      </b-col>
      <!--End address   --------------------------------------------------------------------->

      <!--Start address chips  -------------------------------------------------------------->
      <b-col cols="12" lg="4"> </b-col>
      <!--End address chips  ---------------------------------------------------------------->
    </b-row>
  </b-container>
</template>

<script>
import Address from "@/packages/users/components/user/profile/address-information/Address";
import CreateNewAddress from "@/packages/users/components/user/profile/address-information/modal/CreateNewAddress.vue";
import { mapGetters } from "vuex";
export default {
  name: "AddressInformation",
  components: {
    CreateNewAddress,
    Address,
  },
  data() {
    return {
      isShowModal: false,
      isShowCreateNewAddressModal: false,
    };
  },

  computed: {
    ...mapGetters({
      addresses: "profile/addresses",
    }),
  },
  methods: {
    openCreateNewAddressModal() {
      this.isShowCreateNewAddressModal = true;
    },
    closeCreateNewAddressModal(state) {
      if (state === "success") {
        this.$emit("fetchAgainUserInformation");
        this.isShowCreateNewAddressModal = false;
      } else {
        this.isShowCreateNewAddressModal = false;
      }
    },
  },
};
</script>

<style scoped></style>
