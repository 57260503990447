<template>
  <FirstLoginChangePassword />
</template>

<script>
import FirstLoginChangePassword from "@/packages/authentication/presentation/components/FirstLoginChangePassword";
export default {
  name: "ChangePassword",
  components: { FirstLoginChangePassword },
};
</script>

<style scoped></style>
