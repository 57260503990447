<template>
  <b-row
    no-gutters
    class="d-flex justify-space-between align-content-end align-bottom pt-4"
  >
    <b-col
      cols="6"
      class="font-weight-bold text-right secondary--text text--lighten-2"
    >
      صورتحساب فروش
    </b-col>
    <b-col cols="6" class="d-flex justify-content-end">
      <img :src="require('@/assets/img/logo/logoLipak.png')" alt="" />
    </b-col>
    <b-col cols="12" class="pb-4">
      <hr />
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "InvoiceHeader",
};
</script>

<style scoped></style>
