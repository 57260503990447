import { fetchAllCustomersService } from "@/packages/orders/services/customer.service";

export const SEARCH_FORM_USER_NAME = {
  /*SCHEMA */
  schema: [
    {
      id: "username",
      cols: "12",
      lg: "12",
      md: "12",
      sm: "12",
      placeholder: "username",
      options: [],
      default_option_disabled: false,
      type: "select",
      customAutoComplete: true,
      parentValues: [],
      hasAction: true,
      doAction: async function (query_params = null) {
        return await fetchAllCustomersService(query_params);
      },
    },
  ],

  /* VALIDATION SCHEMA */
  validations: {
    form: {
      username: {},
      usernameSearch: {},
    },
  },

  /*MODEL SCHEMA*/
  model: {
    username: "",
    usernameSearch: "",
  },
};
