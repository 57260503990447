import { maxLength, minLength } from "vuelidate/lib/validators";
export const SEARCH_FORM_NATIONAL_CODE = {
  /*SCHEMA */
  schema: [
    {
      id: "ssn",
      type: "text",
      placeholder: "ssn",
      required: false,
      cols: "12",
      lg: "12",
      md: "12",
    },
  ],

  /* VALIDATION SCHEMA */
  validations: {
    form: {
      ssn: {
        maxLength: maxLength(10),
        minLength: minLength(10),
      },
    },
  },

  /*MODEL SCHEMA*/
  model: {
    ssn: "",
  },
};
