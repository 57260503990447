import api from "@/tools/Api";
import urls from "@/tools/Urls";

/*
 *  fetch order states
 *  ------------------------
 *  Api address | /api/v1/comments
 *  ------------------------
 *  @param
 *  ------------------------
 *  method : Get
 *  ------------------------
 *  Description :
 *  ------------------------
 *  fetch Orders by orderNember | nationalCde | phoneNumber | state | date
 *  ------------------------
 *  @return Promise
 *  ------------------------
 */

export const fetchStateDetailAxiosRequest = function (stateName, orderId) {
  return api.get(urls.api("fetch_state_detail", [orderId]), {
    params: {
      ...stateName,
    },
  });
};
