<template>
  <b-container fluid class="mt-5">
    <b-skeleton v-if="loading" height="400px"></b-skeleton>
    <b-card v-else class="bg-white fa-num-font border-0 shadow text-right">
      <div class="mt-3">
        نام: <span class="font-weight-bold">{{ data.name }}</span>
      </div>
      <div class="mt-3">
        تلفن همراه: <span class="font-weight-bold">{{ data.phoneNumber }}</span>
      </div>
      <div class="mt-3">
        ایمیل: <span class="font-weight-bold">{{ data.email || "-" }}</span>
      </div>
      <div class="mt-3">
        تاریخ ثبت:
        <span class="font-weight-bold">{{ $_date(data.createdAt) }}</span>
      </div>
      <div class="mt-3">
        توضیحات:
        <div
          class="font-weight-bold"
          v-for="(description, index) in data.description.split('\n')"
          :key="`${description}-${index}`"
        >
          {{ description }}
        </div>
      </div>
    </b-card>
  </b-container>
</template>

<script>
import { fetchContactUsItemRequest } from "@/packages/contact-us/api/index.api";

export default {
  data() {
    return {
      data: null,
      loading: true,
    };
  },
  created() {
    if (!this.$route.params.contactUsId) {
      return this.$router.push({ name: "contact-us.list" });
    }
    this.getContactUsDetails();
  },
  methods: {
    async getContactUsDetails() {
      try {
        this.loading = true;
        const data = await fetchContactUsItemRequest(
          this.$route.params.contactUsId
        );
        this.data = data;
      } catch {
        return this.$router.push({ name: "contact-us.list" });
      }
      this.loading = false;
      // this.details = {
      //   transferCode: "564153",
      //   fromStock: "انبار مبدا",
      //   toStock: "انبار مقصد",
      //   employee: "محمد عزیزی",
      //   registerDate: new Date(),
      // };
      // this.items = [
      //   {
      //     id: "45646",
      //     title: "عنوان",
      //     lpvCode: "45678",
      //     colorName: "آبی",
      //     guarantee: "۱۸ ماهه",
      //     count: 5,
      //     unit: "کیلو",
      //     costPrice: 2000,
      //     netPrice: 1500,
      //     totalPrice: 20000,
      //   },
      //   {
      //     id: "2313165",
      //     title: "عنوان1",
      //     lpvCode: "878",
      //     colorName: "قرمز",
      //     guarantee: "۱۸ ماهه",
      //     count: 10,
      //     unit: "متر",
      //     costPrice: 10000,
      //     netPrice: 5000,
      //     totalPrice: 50000,
      //   },
      //   {
      //     id: "878978",
      //     title: "عنوان2",
      //     lpvCode: "489652",
      //     colorName: "زرد",
      //     guarantee: "۱۸ ماهه",
      //     count: 3,
      //     unit: "نیوتون",
      //     costPrice: 8000,
      //     netPrice: 7000,
      //     totalPrice: 30000,
      //   },
      // ];
    },
  },
};
</script>
