import api from "@/tools/Api";
import urls from "@/tools/Urls";

/*
 *  update legal user
 *  ------------------------
 *  Api address | /api/v1/user-manage/legal/:user_id
 *  ------------------------
 *  @param payload { object }
 *  @param user_id { string }
 *  ------------------------
 *  method : put
 *  ------------------------
 *  Description :
 *  ------------------------
 *   update legal user
 *  ------------------------
 *  @return Promise
 *  ------------------------
 */

export const updateLegalUserAxiosRequest = function (patyload, legal_id) {
  return api.put(urls.api("update_legal_user", [legal_id]), patyload);
};
