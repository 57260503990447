<template>
  <b-row
    class="d-block d-lg-flex mt-3 pb-4 bg-white rounded-lg shadow fa-num-font py-4 px-3 px-lg-4"
  >
    <b-col
      cols="12"
      lg="9"
      class="d-block d-lg-flex justify-content-between justify-content-lg-start d-lg-flex px-0"
    >
      <div class="text-right">
        <span class="fs-16 font-weight-bold">جزئیات سفارش :</span>
        <span class="fs-18 font-weight-bold"> {{ code }}</span>
      </div>
      <div>
        <span
          :class="[`bg-${innerState.bgColor}`, `text-${innerState.color}`]"
          class="rounded-pill py-1 px-3 mr-5 fs-14 font-weight-bold"
          >{{ innerState.persianName }}
        </span>
      </div>
    </b-col>

    <b-col cols="12" lg="3">
      <div class="d-flex justify-content-end mr-auto">
        <a
          target="_blank"
          class="d-flex align-items-center bg-transparent border-0 fs-16 text-primary font-weight-bold mr-auto pointer mt-4 mt-lg-0"
        >
          مشاهده فاکتور
        </a>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import OrderUser from "@/packages/users/services/user.orders.service";
export default {
  name: "OrderFactor",
  data() {
    return {
      OrderUser: new OrderUser(),
      factorPDF: "",
    };
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    code: {
      type: String,
      required: true,
    },
    state: {
      type: Object,
      required: true,
    },
  },
  computed: {
    innerState() {
      if (!this.state) return;
      let handlerState = {
        INPAYMENT: {
          doAction: () => {
            return {
              name: this.state.first,
              persianName: this.state.second,
              color: "primary",
              bgColor: "light-primary",
            };
          },
        },
        INPROCESS: {
          doAction: () => {
            return {
              name: this.state.first,
              persianName: this.state.second,
              color: "primary",
              bgColor: "light-primary",
            };
          },
        },
        DELIVERED: {
          doAction: () => {
            return {
              name: this.state.first,
              persianName: this.state.second,
              color: "primary",
              bgColor: "light-primary",
            };
          },
        },
        RETURNED: {
          doAction: () => {
            return {
              name: this.state.first,
              persianName: this.state.second,
              color: "blue",
              bgColor: "light-blue",
            };
          },
        },
        CANCELED: {
          doAction: () => {
            return {
              name: this.state.first,
              persianName: this.state.second,
              color: "danger",
              bgColor: "light-danger",
            };
          },
        },
      };
      return handlerState[this.state.first].doAction();
    },
  },

  methods: {
    async showFactor() {
      this.factorPDF = await this.OrderUser.downloadFactor(this.orderDetail.id);
    },
  },
};
</script>
