<template>
  <b-container fluid>
    <!--Start Page breadcrumb    ------------------------------------------------>
    <Breadcrumb :items="$route.matched"></Breadcrumb>
    <!--End   Page breadcrumb    ------------------------------------------------>

    <transition name="fade" mode="out-in">
      <SubmitTrackingOrder />
    </transition>
  </b-container>
</template>

<script>
import SubmitTrackingOrder from "@/packages/tracking-code/components/SubmitTrackingOrder";
import Breadcrumb from "@/components/app/Breadcrumb";
export default {
  name: "Index",
  components: { Breadcrumb, SubmitTrackingOrder },
};
</script>

<style scoped></style>
