<template>
  <b-card class="border-0 shadow text-right">
    <div class="lipak-input-title">توضیح پشتیبانی</div>
    <b-textarea
      :disabled="isDisabled"
      v-model="innerDescription"
      placeholder="توضیح پشتیبانی را وارد کنید"
      rows="4"
    ></b-textarea>
    <div v-if="isConfirmed && !isEditing" class="text-left">
      <b-button class="mt-3" variant="info" @click="activeEditing">
        ویرایش
      </b-button>
    </div>
  </b-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: ["description"],
  data() {
    return {
      innerDescription: this.description || "",
      isEditing: false,
    };
  },
  computed: {
    ...mapGetters({
      state: "returned/state",
      step2Editing: "returned/step2Editing",
    }),
    isConfirmed() {
      return this.state === "SUPPORT_CONFIRMED";
    },
    isDisabled() {
      return this.isConfirmed && !this.isEditing;
    },
  },
  watch: {
    innerDescription(newValue) {
      this.setDescription(newValue);
    },
    step2Editing(newValue) {
      if (newValue === false) this.isEditing = false;
    },
  },
  methods: {
    ...mapActions({
      setDescription: "returned/setDescription",
      setStep2Editing: "returned/setStep2Editing",
    }),
    activeEditing() {
      this.isEditing = true;
      this.setStep2Editing(true);
    },
  },
};
</script>

<style scoped></style>
