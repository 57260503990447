import api from "@/tools/Api";
import urls from "@/tools/Urls";

/*
 *  fetch payment methods
 *  ------------------------
 *  Api address | /api/v1/comments
 *  ------------------------
 *  @param
 *  ------------------------
 *  method : Get
 *  ------------------------
 *  Description :
 *  ------------------------
 *  fetch payment methods
 *  ------------------------
 *  @return Promise
 *  ------------------------
 */

export const fetchPaymentMethodsAxiosReq = function () {
  return api.get(urls.api("fetch_payment_methods"));
};
