"use strict";
import { _t } from "@/tools/Utils";

export const dashboardRoutes = [
  {
    path: "welcome",
    name: "dashboard.welcome",
    component: () =>
      import(
        /* webpackChunkName: "dashboard-main" */ "@/views/dashboard/Welcome"
      ),
    meta: {
      title: _t("router.routes.dashboard_routes.welcome"),
    },
  },
];
