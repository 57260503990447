const { required, email } = require("vuelidate/lib/validators");

export const SCHEMA = {
  /* Form SCHEMA */
  schema: [
    {
      id: "email",
      label: "email",
      placeholder: "email",
      class: "white-label",
      cols: 12,
      lg: 12,
      md: 12,
      sm: 12,
      type: "text",
    },
  ],

  /* Form MODEL */
  model: {
    email: null,
  },

  /* VALIDATION SCHEMA */
  validations: {
    form: {
      email: { required, email },
    },
  },
};
