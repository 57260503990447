"use strict";
import { _t } from "@/tools/Utils";
import { userManagement } from "@/packages/users/routes/user.management";

export default [
  {
    path: "users",
    name: "users",
    component: () =>
      import(/* webpackChunkName: "users" */ "@/packages/users/views/Index"),

    meta: {
      title: _t("route_titles.users_routes.users.title"),
      link: false,
    },
    redirect: { name: "users.list" },
    children: [
      {
        path: "list",
        name: "users.list",

        component: () =>
          import(
            /* webpackChunkName: "users" */ "@/packages/users/views/List.view"
          ),
        meta: {
          title: _t("route_titles.users_routes.users.list"),
          link: true,
        },
      },
      {
        path: "user/:user_id",
        name: "user",
        component: () =>
          import(
            /* webpackChunkName: "user" */ "@/packages/users/views/UserManager.view"
          ),
        meta: {
          title: _t("route_titles.users_routes.users.user.title"),
          link: true,
        },
        redirect: { name: "user.profile" },
        children: [...userManagement],
      },
    ],
  },
];
