import api from "@/tools/Api";
import urls from "@/tools/Urls";

/*
 *  fetch  personal user info
 *  ------------------------
 *  Api address | /api/v1/user-manage/:user_id
 *  ------------------------
 *  @param user_id { string }
 *  ------------------------
 *  method : Get
 *  ------------------------
 *  Description :
 *  ------------------------
 *  fetch  personal user info
 *  ------------------------
 *  @return Promise
 *  ------------------------
 */

export const fetchUserPersonalInfoAxiosRequest = function (user_id) {
  return api.get(urls.api("fetch_user_personal_info", [user_id]));
};
