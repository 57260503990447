import api from "@/tools/Api";
import urls from "@/tools/Urls";

/*
 *  fetch  users
 *  ------------------------
 *  Api address | /api/v1/user-manage
 *  ------------------------
 *  @param state { String }
 *  @param user_id {String}
 *  @param pagination { Object }
 *  ------------------------
 *  method : Get
 *  ------------------------
 *  Description :
 *  ------------------------
 *  fetch  users
 *  ------------------------
 *  @return Promise
 *  ------------------------
 */

export const fetchOrdersByStateAxiosRequest = function (
  state,
  user_id,
  pagination,
  cancelToken
) {
  return api.get(urls.api("fetch_orders", [user_id, state]), {
    params: {
      ...pagination,
    },
    ...cancelToken,
  });
};
