import api from "@/tools/Api";
import urls from "@/tools/Urls";

/*
 *   update address and shippingMethods
 *  ------------------------
 *  Api address | /api/v1/comments
 *  ------------------------
 *  @param cityId { String }
 *  ------------------------
 *  method : Get
 *  ------------------------
 *  Description :
 *  ------------------------
 *  fetch shipping methods
 *  ------------------------
 *  @return Promise
 *  ------------------------
 */

export const updateAddressAndShippingMethodAxiosRequest = function (
  orderId,
  addressId,
  payload
) {
  return api.put(
    urls.api("update_address_and_shippingMethod", [orderId, addressId]),
    payload
  );
};
