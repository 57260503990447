import api from "@/tools/Api";
import urls from "@/tools/Urls";

export const returnRejectReasonRecordAxiosRequest = function (payload) {
  return api.post(urls.api("return_reject_reason_record"), payload);
};

export const returnRejectReasonAllAxiosRequest = function () {
  return api.get(urls.api("return_reject_reason_all"));
};

export const returnRejectReasonUpdateAxiosRequest = function (id, body) {
  return api.put(urls.api("return_reject_reason_update", [id]), body);
};
