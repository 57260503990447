<template>
  <b-row>
    <b-col cols="12">
      <b-card no-body class="bg-white shadow rounded-lg border-0 p-5 mb-3">
        <template v-if="skeletonLoader" style="height: 313px !important">
          <b-skeleton height="313px" class="mt-3"></b-skeleton>
        </template>
        <div v-else-if="hasError">
          <div
            class="d-flex justify-content-center text-center text-primary font-weight-bold fs-18"
          >
            خطا از طرف سرور
          </div>
          <div class="d-flex justify-content-center mt-4">
            <b-button
              class="bg-primary text-white"
              @click="fetchListComments"
              variant="outline"
              >درخواست مجدد</b-button
            >
          </div>
        </div>

        <template v-else-if="userComments.length">
          <UserComment
            v-for="comment in userComments"
            :key="comment.index"
            :comment="comment"
          />
        </template>
        <div v-else class="w-100 text-center">
          <span class="text-primary"> عدم وجود نظرات ثبت شده! </span>
        </div>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import OrderComments from "@/packages/users/services/user.comments.service";
import UserComment from "./UserComment";
import { mapGetters } from "vuex";
export default {
  name: "Comments",
  data() {
    return {
      OrderComments: new OrderComments(),
      skeletonLoader: true,
      hasError: false,
    };
  },
  components: {
    UserComment,
  },
  mounted() {
    this.fetchListComments();
  },
  computed: {
    ...mapGetters({
      userComments: "users/userComments",
      customerMobile: "profile/phone",
    }),
  },

  methods: {
    async fetchListComments() {
      this.skeletonLoader = true;
      try {
        await this.OrderComments.fetchComments(this.customerMobile);
      } catch (e) {
        this.hasError = true;
      }
      this.skeletonLoader = false;
    },
  },
};
</script>

<style scoped></style>
