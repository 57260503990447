<template>
  <b-container fluid>
    <!-------------start breadcrumb ------------------->
    <Breadcrumb :items="$route.matched"></Breadcrumb>
    <!-------------end breadcrumb   ------------------->

    <!----------start store-inventory content --------------->

    <router-view :key="$route.path"></router-view>

    <!----------end store-inventory content ---------------->
  </b-container>
</template>

<script>
import Breadcrumb from "@/components/app/Breadcrumb";
export default {
  name: "Index",
  components: {
    Breadcrumb,
  },
};
</script>
<style scoped></style>
