import { fetchAllUsersAxiosReq } from "@/packages/manage/api/fetch_users.api";
import { fetchAllOperationTypesAxiosReq } from "@/packages/manage/api/fetch_operation_types.api";
import { fetchAllUsersActivitiesAxiosReq } from "@/packages/manage/api/fetch_activities.api";
import store from "@/store";
import message from "@/tools/Message";

/**
 *  fetch filtered activities
 *  ------------------------
 *  Description| filtered activities
 *  ------------------------
 *  @param query_params {Object}
 *  @return  type {Promise}
 *  ------------------------
 **/
export const fetchFilteredActivitiesService = async function (
  query_params = null
) {
  try {
    const response = await fetchAllUsersActivitiesAxiosReq(query_params);
    await store.dispatch("activities/setFilteredActivities", response.content);
    await store.dispatch("pagination/setTotalItems", response.totalElements);
    await store.dispatch("pagination/setTotalIPages", response.totalPages);
    return response.content;
  } catch (error) {
    message.error("FAILED_FETCH_ACTIVITIES");
    return Promise.reject(error);
  }
};

/**
 *  fetch all users
 *  ------------------------
 *  Description| Make api call to fetch all users
 *  ------------------------
 *  @param query_params {Object}
 *  ------------------------
 *  @return  type {Promise}
 *  ------------------------
 **/
export const fetchAllUsersService = async function (query_params = null) {
  try {
    const users = await fetchAllUsersAxiosReq(query_params);
    return Promise.resolve({
      name: usersMapper(users),
    });
  } catch (error) {
    message.error("ERROR_FETCH_USERS");
    return Promise.reject(error);
  }
};

/**
 *  fetch all operation types
 *  ------------------------
 *  Description| Make api call to fetch all operation types
 *  ------------------------
 *  @param query_params {Object}
 *  ------------------------
 *  @return  type {Promise}
 *  ------------------------
 **/
export const fetchOperationTypesService = async function (query_params = null) {
  try {
    const operationTypes = await fetchAllOperationTypesAxiosReq(query_params);
    return Promise.resolve({
      operationType: operationTypesMapper(operationTypes),
    });
  } catch (error) {
    message.error("ERROR_FETCH_OPERATION_TYPES");
    return Promise.reject(error);
  }
};

/**
 *  fetch all filter options as parallel promise
 *  ------------------------
 *  Description| fetch all filter as options parallel promise
 *  ------------------------
 *  @return  type {Promise}
 *  ------------------------
 **/
export const fetchFilters = function () {
  try {
    return Promise.allSettled([
      fetchAllUsersService(),
      fetchOperationTypesService(),
    ]);
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 *  map users array
 *  ------------------------
 *  Description| map users array to compatible format for lipak-input select options
 *  ------------------------
 *  @param users {array}
 *  @return  type {array}
 *  ------------------------
 **/
const usersMapper = function (users) {
  return users.map((user) => {
    return {
      title: user,
      value: user,
    };
  });
};

/**
 *  map operationTypes array
 *  ------------------------
 *  Description| map operationTypes array to compatible format for lipak-input select options
 *  ------------------------
 *  @param operationTypes {array}
 *  @return  type {array}
 *  ------------------------
 **/
const operationTypesMapper = function (operationTypes) {
  return operationTypes.map((operationType) => {
    return {
      title: operationType,
      value: operationType,
    };
  });
};
