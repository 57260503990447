import api from "@/tools/Api";
import urls from "@/tools/Urls";

/*
 *  download factor
 *  ------------------------
 *  Api address | /api/v1/
 *  ------------------------
 *  @param
 *  ------------------------
 *  method : Get
 *  ------------------------
 *  Description :
 *  ------------------------
 *  download factor
 *  ------------------------
 *  @return Promise
 *  ------------------------
 */

export const downloadFactorAxiosRequest = function (orderId) {
  return api.get(urls.api("fetch_order_detail_for_factore", [orderId]));
};
