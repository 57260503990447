<template>
  <b-card class="shadow border-0 px-0 px-lg-3 fa-num-font">
    <!--Start user filters ------------------------------------------>
    <UserFilters v-model="filters" @fetchUsers="initialize(paginationParams)" />
    <!--end user filters -------------------------------------------->

    <!--Start user list --------------------------------------------->
    <UsersTable v-model="sort" @fetchUsers="initialize(paginationParams)" />
    <!--end user list ----------------------------------------------->

    <!--Start pagination -------------------------------------------->
    <Pagination v-model="paginationParams" key="user-pagination" />
    <!--End   pagination -------------------------------------------->
  </b-card>
</template>

<script>
import UserFilters from "@/packages/users/components/users/filters/UserFilters";
import UsersTable from "@/packages/users/components/users/UsersTable";
import Pagination from "@/components/app/Pagination";
import Users from "@/packages/users/services/users.service";

export default {
  name: "UsersWithFilters",
  data() {
    return {
      paginationParams: null,
      filters: null,
      sort: {
        sortBy: "createdAt",
        sortDesc: true,
      },
      User: new Users(),
    };
  },
  watch: {
    paginationParams: {
      // This will let Vue know to look inside the object
      deep: true,

      // We have to move our method to a handler field
      async handler(value) {
        if (value) await this.initialize(value);
      },
    },

    sort: {
      // This will let Vue know to look inside the object
      deep: true,

      // We have to move our method to a handler field
      async handler(value) {
        let { sortBy, sortDesc } = value;
        if (value)
          await this.initialize({
            ...this.filters,
            ...this.paginationParams,
            sortBy: sortBy,

            /* force server to sort items asc/desc */
            sortDirection: sortDesc ? "DESC" : "ASC",
          });
      },
    },
  },
  methods: {
    async initialize(paginationParams) {
      let { sortBy, sortDesc } = this.sort;
      await this.User.fetchUsers(
        {
          ...paginationParams,
          ...this.filters,
          sortBy: sortBy,
          /* force server to sort items asc/desc */
          sortDirection: sortDesc ? "DESC" : "ASC",
        },
        true
      );
    },
  },
  components: { UsersTable, UserFilters, Pagination },
};
</script>

<style scoped></style>
