<template>
  <LipakModal
    @close="closeModal"
    :modal="modal"
    title="ثبت کد رهگیری"
    size="md"
  >
    <template v-slot:body>
      <b-col cols="12" class="mx-auto justify-content-between px-0 fa-num-font">
        <LipakForm class="position-relative">
          <!------------------ start body of form --------------->
          <template v-slot:body>
            <LipackFormGenerator :schema="schema" :validator="$v" />
          </template>

          <!------------------ end body of form ----------------->
          <!------------------- start action of form ------------>
          <template v-slot:action> </template>
          <!------------------ end action of form ---------------->
        </LipakForm>
      </b-col>
    </template>

    <template v-slot:footer>
      <b-row class="w-100 mb-4">
        <b-col cols="6">
          <b-button
            variant="outline-secondary "
            class="w-100"
            @click="closeModal"
          >
            بستن
          </b-button> </b-col
        ><b-col cols="6">
          <b-button
            variant="success"
            class="w-100 border-0"
            @click="submit"
            :disabled="is_loading"
          >
            <b-spinner v-if="is_loading" small variants="info"></b-spinner>
            ثبت
          </b-button>
        </b-col>
      </b-row>
    </template>
  </LipakModal>
</template>

<script>
import LipakModal from "@/components/app/Modal/LipakModal";
import { mapGetters } from "vuex";
import LipackFormGenerator from "@/components/form/LipackFormGenerator";
import LipakForm from "@/components/form/LipakForm";
import { TRACKING_CODE_REGISTER_SCHEMA } from "@/packages/orders/schema/form/TRACKING_CODE_REGISTER_SCHEMA";
import OrderService from "@/packages/orders/services/order.service";
export default {
  name: "RegisterTrackingCode",
  components: { LipakModal, LipackFormGenerator, LipakForm },
  data: function () {
    return {
      form: { ...TRACKING_CODE_REGISTER_SCHEMA.model },
      schema: [...TRACKING_CODE_REGISTER_SCHEMA.schema],
      Order: new OrderService(),
    };
  },
  validations: {
    ...TRACKING_CODE_REGISTER_SCHEMA.validations,
  },
  props: {
    modal: {
      type: Boolean,
      default: false,
    },
    orderCode: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      is_loading: "loading/is_loading",
    }),
  },
  watch: {
    modal() {
      this.form.orderNum = this.orderCode;
    },
  },
  methods: {
    async submit() {
      if (!this.isFormValidate()) return;

      try {
        let data = {
          orderNum: this.orderCode,
          ...this.form,
        };
        await this.Order.registerOrderTrackingCodeService(data);
        await this.initOrderDetail();
        this.closeModal();
      } catch (e) {
        console.log(e);
      }
    },
    isFormValidate() {
      this.$v.$touch();
      return !this.$v.$invalid;
    },
    async initOrderDetail() {
      try {
        this.skeletonLoader = true;
        await this.Order.fetchOrderDetailById(this.$route.params.id);
        this.skeletonLoader = false;
      } catch (e) {
        this.hasError = true;
        this.skeletonLoader = false;
      }
    },
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>
<style scoped></style>
