<template>
  <b-card class="bg-white fa-num-font border-0 shadow">
    <ContactUsListFilter v-model="filters" />
    <ContactUsListTable />
    <div class="p-2 p-md-5">
      <Pagination v-model="paginationParams" key="product-pagination" />
    </div>
  </b-card>
</template>

<script>
import ContactUsListFilter from "@/packages/contact-us/components/list/Filter.vue";
import ContactUsListTable from "@/packages/contact-us/components/list/Table.vue";
import Pagination from "@/components/app/Pagination";
import { mapActions } from "vuex";

export default {
  components: {
    ContactUsListFilter,
    ContactUsListTable,
    Pagination,
  },
  data() {
    return {
      filters: null,
      paginationParams: null,
    };
  },
  watch: {
    paginationParams: {
      // This will let Vue know to look inside the object
      deep: true,

      // We have to move our method to a handler field
      async handler(value) {
        if (value) await this.fetchAllContactUs(value);
      },
    },
  },
  methods: {
    ...mapActions({
      getAllContactUs: "contactUs/filter/searchByFilters",
    }),
    async fetchAllContactUs(paginationParams) {
      await this.getAllContactUs({
        filters: { ...paginationParams, ...this.filters },
        cancelPrevApi: true,
      });
    },
  },
};
</script>
