module.exports = {
  router: {},
  views: {},
  route_titles: {
    tracking_code_routes: {
      tracking_code: {
        title: "ثبت کد رهگیری",
      },
    },
  },
  attribute: {
    orderNum: "شماره سفارش",
    postalTrackingCode: "کد رهگیری",
  },
  placeholder: {
    orderNum: "شماره سفارش را وارد کنید.",
    postalTrackingCode: "کد رهگیری را وارد کنید.",
  },
};
