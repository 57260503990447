<template>
  <b-card
    class="bg-grey-light text-start d-flex justify-content-start border-0 card-rounded p-lg-3 mt-4 fa-num-font"
  >
    <div
      class="d-flex justify-content-start align-items-center text-more font-weight-bold mt-2 my-2 fs-18"
    >
      <p
        class="bg-primary rounded-pill ml-2 mt-2"
        style="width: 10px; height: 10px"
      ></p>
      اطلاعات تحویل گیرنده
    </div>

    <b-row
      class="list-info d-block d-lg-flex justify-content-start list-unstyled w-100 px-lg-2 px-0 mx-0 mb-0 mt-lg-4"
    >
      <template v-for="item in innerRecipientDeliveryInfo">
        <b-col
          class="text-right d-flex flex-wrap d-lg-block pt-4 pt-lg-0 px-lg-3 px-0 px-lg-3 pb-lg-5"
          :cols="item.col"
          :md="item.md"
          :lg="item.lg"
          :xl="item.xl"
          :key="item.index"
          v-if="item.show && item.key !== 'nationalCode'"
        >
          <div
            class="d-flex align-items-center fs-15 font-weight-bold text-secondary flex-nowrap pb-2 pl-1 pl-lg-0"
          >
            {{ item.title }}:
          </div>
          <div class="fs-17 font-weight-bold text-secondary pb-2 pt-lg-2">
            {{ item.value }}
          </div>
        </b-col>
      </template>
    </b-row>

    <hr class="mt-4" />

    <div
      class="d-flex justify-content-start align-items-center text-more font-weight-bold mt-2 my-2 fs-18"
    >
      <p
        class="bg-primary rounded-pill ml-2 mt-2"
        style="width: 10px; height: 10px"
      ></p>
      اطلاعات ارسال
    </div>
    <b-row
      class="list-info d-block d-lg-flex justify-content-start list-unstyled w-100 px-lg-2 px-0 mx-0 mb-0 mt-lg-4"
    >
      <template v-for="item in innerDeliveryDetail">
        <b-col
          class="text-right d-flex flex-wrap d-lg-block pt-4 pt-lg-0 px-0 px-lg-3 pb-lg-5"
          :cols="item.col"
          :md="item.md"
          :lg="item.lg"
          :xl="item.xl"
          :key="item.index"
          v-if="item.show"
        >
          <div
            class="d-flex flex-nowrap align-items-center fs-15 font-weight-bold text-secondary pb-2 pl-1 pl-lg-0"
          >
            {{ item.title }}:
          </div>

          <div
            v-if="item.key === 'price'"
            class="fs-17 font-weight-bold text-secondary pb-2 pt-lg-2"
          >
            {{ $_currency(item.value) }} ریال
          </div>
          <div
            v-else
            class="fs-17 font-weight-bold text-secondary pb-2 pt-lg-2"
          >
            {{ item.value }}
          </div>
        </b-col>
      </template>
      <div
        v-if="
          state.state.first === 'INPAYMENT' ||
          state.state.first === 'INPROCESS' ||
          state.state.first === 'UNDELIVERED'
        "
        class="text-primary mr-auto d-flex align-items-end justify-content-end font-weight-bold d-flex pointer"
        @click="isShowModal = true"
      >
        ویرایش <LIArrowRight class="arrow-right pb-2 mr-2" />
      </div>
    </b-row>

    <EditAddress
      :current-address="{
        address: recipientDeliveryInfo.address,
        addressId: recipientDeliveryInfo.addressId,
        cityId: recipientDeliveryInfo.cityId,
        shippingDate: deliveryDetail.shippingDate,
        shippingTime: deliveryDetail.shippingTime,
        shippingShift: deliveryDetail.shippingShift,
      }"
      :user-id="userId"
      :order-id="orderId"
      :state="state"
      :modal="isShowModal"
      @close="isShowModal = false"
    />
  </b-card>
</template>

<script>
import EditAddress from "./EditAddress";
import { mapGetters } from "vuex";
export default {
  name: "RecipientDeliveryInfo",
  data() {
    return {
      isShowModal: false,
    };
  },
  props: {
    recipientDeliveryInfo: {
      type: Object,
      required: true,
    },
    deliveryDetail: {
      type: Object,
      required: true,
    },
    userId: { type: String, required: true },
    orderId: { type: String, required: true },
    state: {
      type: Object,
      required: true,
    },
    hasTrackingCode: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      currentShippingMethodInfo: "order/currentShippingMethodInfo",
    }),
    innerRecipientDeliveryInfo() {
      return [
        {
          title: "نام و نام خانوادگی",
          value:
            this.recipientDeliveryInfo.firstName +
            " " +
            this.recipientDeliveryInfo.lastName,
          show: true,
          col: "12",
          md: "4",
          lg: "6",
          xl: "3",
        },
        {
          title: "نام",
          value: this.recipientDeliveryInfo.firstName,
          key: "firstName",
          show: false,
          col: "12",
          md: "4",
          lg: "6",
          xl: "3",
        },
        {
          title: "نام خانوادگی",
          value: this.recipientDeliveryInfo.lastName,
          key: "lastName",
          show: false,
          col: "12",
          md: "4",
          lg: "6",
          xl: "3",
        },
        {
          title: "شماره تماس",
          value: this.recipientDeliveryInfo.mobileNum,
          key: "mobileNum",
          show: true,
          col: "12",
          md: "4",
          lg: "6",
          xl: "3",
        },
        {
          title: "آدرس",
          value: this.recipientDeliveryInfo.address,
          key: "address",
          show: true,
          col: "12",
          md: "4",
          lg: "6",
          xl: "6",
        },
        {
          title: "کد پستی",
          value: this.recipientDeliveryInfo.postalCode,
          key: "address",
          show: true,
          col: "12",
          md: "4",
          lg: "6",
          xl: "3",
        },

        {
          title: "کد ملی",
          value: this.recipientDeliveryInfo.nationalCode,
          key: "nationalCode",
          show: true,
          col: "12",
          md: "4",
          lg: "6",
          xl: "3",
        },
      ];
    },
    innerDeliveryDetail() {
      return [
        {
          title: "روش ارسال",
          value: this.deliveryDetail.shippingMethod,
          col: "12",
          md: "4",
          lg: "6",
          xl: "3",
          key: "shippingMethod",
          show: true,
        },
        {
          title: "هزینه ارسال",
          value: Number(this.deliveryDetail.shippingCost),
          col: "12",
          md: "4",
          lg: "6",
          xl: "3",
          key: "price",
          show: true,
        },
        {
          title: "کد رهگیری",
          value: this.deliveryDetail.postalTrackingCode,
          col: "12",
          md: "4",
          lg: "6",
          xl: "3",
          key: "postalTrackingCode",
          show: this.hasTrackingCode,
        },
        {
          title: "تاریخ تحویل سفارش",
          value: this.deliveryDetail.shippingDate
            ? this.$_date(this.deliveryDetail.shippingDate, "jalali") +
              " - " +
              this.deliveryDetail.shippingShift +
              " - ساعت " +
              this.deliveryDetail.shippingTime
            : null,
          col: "12",
          md: "4",
          lg: "6",
          xl: "3",
          key: "shippingDate",
          show: this.deliveryDetail.shippingDate,
        },
      ];
    },
  },

  components: {
    EditAddress,
  },
  methods: {},
};
</script>

<style scoped>
.arrow-right {
  transform: rotate(180deg);
}
</style>
