<template>
  <div v-if="loading" class="lipak-custom-toggle">
    <b-spinner small variant="success" class="mx-auto" />
  </div>
  <div
    v-else
    class="lipak-custom-toggle c-pointer"
    @click="$emit('input', !value)"
  >
    <div
      class="lipak-custom-toggle-container"
      :class="{ 'lipak-custom-toggle-container-on': value }"
    >
      <div
        class="lipak-custom-toggle-circle"
        :class="{ 'lipak-custom-toggle-circle-on': value }"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: { type: Boolean, required: true },
    loading: { type: Boolean, default: () => false },
  },
};
</script>

<style lang="scss" scoped>
.lipak-custom-toggle {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  max-width: 39px;
  &-container {
    width: 100%;
    height: 16px;
    background: #85858580;
    border-radius: 9999px;
    display: flex;
    align-items: center;
    transition: all 0.2s;
    &-on {
      background: #0acf8380;
    }
  }
  &-circle {
    width: 24px;
    min-width: 24px;
    max-width: 24px;
    height: 24px;
    min-height: 24px;
    max-height: 24px;
    border-radius: 50%;
    background: #858585;
    margin-right: -4px;
    transition: all 0.2s;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24);
    &-on {
      margin-right: calc(100% - 20px);
      background: #0acf83;
    }
  }
}
</style>
