<template>
  <form-wrapper :validator="validator" class="w-100">
    <slot v-if="$slots['form-body']" name="form-body" />
    <b-row class="mx-0 w-100" v-else>
      <!--Start form inputs  ---------------------->
      <b-col
        v-for="(input, index) in schema"
        :key="`key-${input.id}-${index}`"
        v-bind="$attrs"
        :cols="input.cols || $attrs.cols || 12"
        :sm="input.sm || $attrs.sm"
        :md="input.md || $attrs.md || 6"
        :lg="input.lg || $attrs.lg || 4"
        :xl="input.xl || $attrs.xl || 3"
      >
        <form-group
          :name="input.id"
          :attribute="$_t(`attribute.${input.id}`) || input.title || input.id"
        >
          <LipakInput
            slot-scope="{ attrs, listeners }"
            v-bind="attrs"
            v-on="listeners"
            v-if="input.autoComplete"
            v-model="validator[input.id].$model"
            :type="input.type || 'text'"
            :title="$_t(`attribute.${input.id}`) || input.title"
            :disabled="input.disabled"
            :readonly="input.readonly"
            :required="input.required || false"
            :placeholder="$_t(`placeholder.${input.id}`) || input.placeholder"
            :show-message="false"
            :options="input.options || null"
            :default-option-disabled="input.defaultOptionDisabled"
            :auto-complete="input.autoComplete"
            :search.sync="validator[input.search].$model"
            :search-placeholder="input.searchPlaceholder"
            :min-date="input.minDate"
            :max-date="input.maxDate"
          />
          <LipakInput
            slot-scope="{ attrs, listeners }"
            v-bind="attrs"
            v-on="listeners"
            v-else-if="input.type === 'double'"
            v-model="validator[input.id].$model"
            type="double"
            :disabled="input.disabled"
            :type-main="input.typeMain || 'text'"
            :type-side="input.typeSide || 'text'"
            :title="$_t(`attribute.${input.id}`) || input.title"
            :disabled-main="input.disabledMain"
            :disabled-side="input.disabledSide"
            :readonly-main="input.readonlyMain"
            :readonly-side="input.readonlySide"
            :required="input.required"
            :placeholder-main="input.placeholderMain"
            :placeholder-side="input.placeholderSide"
            :show-message="false"
            :options-main="input.optionsMain"
            :options-side="input.optionsSide"
            :default-option-disabled-main="input.defaultOptionDisabledMain"
            :default-option-disabled-side="input.defaultOptionDisabledSide"
            :id="`${input.id}-${index}`"
          />
          <LipakInput
            v-else
            slot-scope="{ attrs, listeners }"
            v-bind="attrs"
            v-on="listeners"
            v-model="validator[input.id].$model"
            :type="input.type || 'text'"
            :title="$_t(`attribute.${input.id}`) || input.title"
            :disabled="input.disabled"
            :readonly="input.readonly"
            :required="input.required"
            :placeholder="
              $_t(`placeholder.${input.placeholder}`) || input.placeholder
            "
            :show-message="false"
            :rows="
              input.type === 'textarea'
                ? validator[input.id].$model.split(/\r*\n/).length
                : input.rows
            "
            :options="input.options || null"
            :default-option-disabled="input.defaultOptionDisabled"
            :id="`${input.id}-${index}`"
            :date-type="input.dateType"
            :min-date="input.minDate"
            :max-date="input.maxDate"
            :data="input.data"
            :label="input.label"
            :min="input.min"
            :max="input.max"
          />
        </form-group>
      </b-col>
      <!--End   form inputs  ---------------------->
      <slot name="inline-action" />
    </b-row>
  </form-wrapper>
</template>

<script>
import FormGroup from "@/components/app/form/FormGroup";
import { templates } from "vuelidate-error-extractor";
import { fetchAllCustomers } from "@/api/customer.api";
import {
  fetchAllStockrooms,
  fetchAllSuppliers,
  fetchBrands,
  fetchConfigs,
  fetchGuarantees,
  fetchModels,
  fetchProductGroups,
  fetchReturnReasonAxiosRequest,
  fetchUnits,
  fetchProvince,
  fetchCitiesByProvinceId,
  fetchQuarterByCityId,
} from "@/api/contents.api";
import { fetchShippingMethodListAxiosReq } from "@/packages/orders/api/fetch-shipping_method_list.api";
import Store from "../../../store";
export default {
  name: "LipakFormGenerator",
  components: {
    FormGroup,
    FormWrapper: templates.FormWrapper,
  },
  props: {
    schema: {
      type: Array,
    },
    validator: {
      type: Object,
    },
  },

  created() {
    this.getOptions();
    this.setWatcher();
  },
  methods: {
    setWatcher() {
      this.schema.forEach((input) => {
        if (input.provinceId) {
          this.$watch(`validator.province.$model`, function (newProvinceValue) {
            this.validator.city.$model = "";
            this.makeRequest(input, null, newProvinceValue);
          });
        }
        if (input.cityId) {
          this.$watch(`validator.city.$model`, function (newCityValue) {
            this.validator.quarter.$model = "";
            this.makeRequest(input, null, newCityValue);
          });
        }
        if (input.autoComplete) {
          this.$watch(
            `validator.${input.search}.$model`,
            function (newSearchValue) {
              this.makeRequest(input, newSearchValue);
            }
          );
        }
      });
    },
    getOptions() {
      this.schema.forEach((item) => {
        if (item.service) {
          this.makeRequest(item);
        }
      });
    },
    makeRequest(item, searchValue) {
      switch (item.service) {
        case "suppliers":
          fetchAllSuppliers({ supplierName: searchValue }).then((data) => {
            item.options = data.content.map(
              ({ id: supplierId, supplierName }) => ({
                value: supplierId,
                title: supplierName,
              })
            );
          });
          break;
        case "stockroom":
          fetchAllStockrooms({ name: searchValue }).then((data) => {
            item.options = data.elements.map(
              ({ id: stockroomId, name: stockroomName }) => ({
                value: stockroomId,
                title: stockroomName,
              })
            );
          });
          break;
        case "brands":
          fetchBrands({ name: searchValue }).then((data) => {
            item.options = data.content.map(({ id, name }) => ({
              value: id,
              title: name,
            }));
          });
          break;
        case "models":
          fetchModels({ name: searchValue }).then((data) => {
            item.options = data.content.map(({ id, name }) => ({
              value: id,
              title: name,
            }));
          });
          break;
        case "configs":
          fetchConfigs({ name: searchValue }).then((data) => {
            item.options = data.content.map(({ id, name }) => ({
              value: id,
              title: name,
            }));
          });
          break;
        case "productGroups":
          fetchProductGroups({ name: searchValue }).then((data) => {
            item.options = data.content.map(({ id, name }) => ({
              value: id,
              title: name,
            }));
          });
          break;
        case "guarantees":
          fetchGuarantees({ name: searchValue }).then((data) => {
            item.options = data.content.map(({ id, name }) => ({
              value: id,
              title: name,
            }));
          });
          break;
        case "units":
          fetchUnits({ name: searchValue }).then((data) => {
            item.options = data.content.map(({ id, name }) => ({
              value: id,
              title: name,
            }));
          });
          break;
        case "customer":
          fetchAllCustomers({ lastName: searchValue }).then((data) => {
            item.options = data.content.map(
              ({ id: customerId, name, lastName }) => ({
                value: customerId,
                title: `${name} ${lastName}`,
              })
            );
          });
          break;
        case "return-reason":
          fetchReturnReasonAxiosRequest().then((data) => {
            item.options = data.map(({ id, title }) => ({
              value: id,
              title,
            }));
          });
          break;
        case "province":
          fetchProvince({ name: searchValue }).then((data) => {
            if (!data.content.length) {
              this.validator.form.city.$model = "";
            } else {
              item.options = data.content.map(({ id, name }) => ({
                value: id,
                title: name,
              }));
            }
          });
          break;
        case "cityByProvince":
          if (!this.validator.province.$model) return;
          fetchCitiesByProvinceId({
            province: this.validator.province.$model,
            name: searchValue,
          }).then((data) => {
            Store.dispatch("order/setCities", data.content);
            item.options = data.content.map(({ id, name }) => ({
              value: id,
              title: name,
            }));
          });
          break;
        case "quarterSearch":
          if (!this.validator.city.$model) return;
          fetchQuarterByCityId({
            cityId: this.validator.city.$model,
            name: searchValue,
            pageNumber: 0,
            pageSize: 100,
          }).then((data) => {
            item.options = data.map((item) => {
              return { title: item.name, value: item.id };
            });
          });
          break;
        case "shippingMethods":
          fetchShippingMethodListAxiosReq({
            province: this.validator.province.$model,
            name: searchValue,
          }).then((data) => {
            item.options = data.map(({ id, title }) => ({
              value: id,
              title: title,
            }));
          });
          break;
        case "paymentMethods":
          fetchShippingMethodListAxiosReq({
            province: this.validator.province.$model,
            name: searchValue,
          }).then((data) => {
            item.options = data.map(({ id, title }) => ({
              value: id,
              title: title,
            }));
          });
          break;
        default:
          break;
      }
    },
    updateOptions(item) {
      console.log("update", item);
    },
  },
};
</script>

<style>
textarea.lipak-input-main {
  min-height: 130px;
  height: auto;
}
</style>
