<template>
  <b-row>
    <!--Start Filter show/hide handler    --------------------------------------------------------------->
    <b-col cols="12" class="d-flex flex-row-reverse mb-3 px-3">
      <b-button
        @click.stop="filtersShowHideToggle"
        variant="primary"
        size="sm"
        class="text-white px-4"
      >
        <LIWifiFlat class="fs-8" />
        {{ $_t("components.users.users.filters.user_filters.buttons.filter") }}
      </b-button>
    </b-col>
    <!--Start Filter show/hide handler    --------------------------------------------------------------->

    <!--Start Filter content              --------------------------------------------------------------->
    <b-col cols="12" class="px-0">
      <transition name="fade">
        <b-card v-if="isShowFilters" class="border-0">
          <b-card class="border-0 bg-light-primary rounded mb-4 px-2 pb-3">
            <MobileAndEmailTextSearch @phoneAndEmail="phoneAndEmail" />
          </b-card>

          <b-card class="border">
            <b-form>
              <!--Start Form content    ------------------------------------------------------------------------>
              <b-row>
                <!--Start input         ----------------------------------->
                <b-col
                  cols="12"
                  md="2"
                  v-for="item in schema"
                  :key="'input-' + item.id"
                >
                  <lipak-input
                    style="text-wrap: none !important"
                    v-model="form[item.id]"
                    :id="'id-' + item.id"
                    :type="item.type"
                    :title="$_t(`attribute.${item.label}`)"
                    :placeholder="$_t(`placeholder.${item.id}`)"
                    :show-message="false"
                    :default-option-disabled="item.defaultOptionDisabled"
                  ></lipak-input>
                </b-col>
                <!--End   input         ----------------------------------->
              </b-row>
              <!--End   Form content    ------------------------------------------------------------------------>

              <!--Start Form Actions    ------------------------------------------------------------------------>
              <b-row class="d-flex flex-row-reverse">
                <b-col cols="12" md="4" lg="2" class="mt-4">
                  <b-button
                    @click.stop="filterUsers"
                    class="w-100 text-white"
                    block
                    variant="primary"
                    size="sm"
                  >
                    {{
                      $_t(
                        "components.users.users.filters.user_filters.buttons.submit"
                      )
                    }}
                  </b-button>
                </b-col>
                <b-col cols="12" md="4" lg="2" class="mt-4">
                  <b-button
                    class="w-100"
                    block
                    variant="outline-secondary"
                    size="sm"
                    @click.stop="closeFilters"
                  >
                    {{
                      $_t(
                        "components.users.users.filters.user_filters.buttons.close"
                      )
                    }}
                  </b-button>
                </b-col>
              </b-row>
              <!--End   Form Actions    ------------------------------------------------------------------------>
            </b-form>
          </b-card>
        </b-card>
      </transition>
    </b-col>
    <!--Start Filter content              --------------------------------------------------------------->
  </b-row>
</template>

<script>
import MobileAndEmailTextSearch from "@/packages/users/components/users/filters/MobileAndEmailTextSearch";
import { USERS_FILTER_SCHEMA } from "@/packages/users/schema/filters/product-managment/users.filter";
import filter from "@/mixins/mixin.filter-sort";
export default {
  name: "UserFilters",
  mixins: [filter],
  components: { MobileAndEmailTextSearch },
  data() {
    return {
      isShowFilters: false,
      schema: USERS_FILTER_SCHEMA.schema,
      form: { ...USERS_FILTER_SCHEMA.model },
    };
  },
  watch: {
    /* v-model implementation on form */
    form: {
      // This will let Vue know to look inside the object
      deep: true,
      // We have to move our method to a handler field
      async handler(value) {
        let filters = this.filterEmptyItems(value);
        if (filters.fromDate) {
          filters.fromDate = filters.fromDate.replace(" 00:00", "");
        }
        if (filters.toDate) {
          filters.toDate = filters.toDate.replace(" 00:00", "");
        }
        this.$emit("input", filters);
      },
    },
  },
  methods: {
    filtersShowHideToggle() {
      this.isShowFilters = !this.isShowFilters;
    },
    closeFilters() {
      this.isShowFilters = false;
    },
    phoneAndEmail(phoneNumber) {
      this.form.phoneNumber = phoneNumber;
    },
    async filterUsers() {
      this.$emit("fetchUsers", this.filters);
    },
  },
};
</script>

<style scoped></style>
