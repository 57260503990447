import OptionService from "@/packages/comments/services/getOptionContent.service";
import filter from "@/mixins/mixin.filter-sort";

export default {
  mixins: [filter],
  data() {
    return {
      OptionService: new OptionService(),
    };
  },

  created() {},

  methods: {
    async getProductInputOptions(schema) {
      let new_schema = [...schema];
      /* set pagination params for api call */
      let paginationFilter = await this.paginationQueryBuilder(1, 100);

      await Promise.all(
        new_schema.map(async (item) => {
          if (item.taxonomy) {
            /* Get Options from content */
            let response = await this.OptionService.GetOptionsContentByTaxonomy(
              item.taxonomy,
              paginationFilter
            );
            /* Set Options */
            new_schema[
              new_schema.indexOf(item)
            ].options = await this.selectInputOptionsFormatterFromContent(
              response.content
            );
          }
        })
      );
      /*Return update schema*/
      return new_schema;
    },
    // async getInputs(schema) {
    //   return await this.OptionService.GetOptionsContentByTaxonomy(schema, null);
    // },
    selectInputOptionsFormatterFromContent(options) {
      if (!options) return [];
      return options.map((item) => {
        return {
          title: item.name,
          value: item.id,
        };
      });
    },

    selectInputOptionsFormatterFromCategory(options) {
      if (!options) return [];
      return options.map((item) => {
        return {
          title: item.persianName,
          value: item.id.toString(),
        };
      });
    },
  },
};

export class select_options {}
