export default {
  return_reason_record: "order/return/recordReason",
  return_reason_update: "order/return/updateReason/%s",
  return_reason_all: "order/return/recordReason/all",
  return_reject_reason_record: "order/return/recordRejectReason",
  return_reject_reason_update: "order/return/recordRejectReason/%s",
  return_reject_reason_all: "order/return/recordRejectReason/all",
  user_activities: "user_activity",
  user_activities_users: "user_activity/users",
  user_activities_operation_types: "user_activity/operation_types",
  get_payment_method_shipments:
    "orderSetting/getPaymentMethodShipments?paymentMethod=INPLACE",
  update_mapping_payment_method_shipment_method:
    "orderSetting/updateMappingPaymentMethodShipmentMethod",
  get_payment_gateway_status:
    "orderSetting/getPaymentGatewayStatus?paymentMethod=ONLINE",
  update_payment_gateway_status: "orderSetting/updatePaymentGatewayStatus",
};
