<template>
  <LipakModal
    @close="closeModal"
    :modal="modal"
    title="آیا از لغو سفارش اطمینان دارید؟"
    size="md"
  >
    <template v-slot:body>
      <b-col cols="12" class="mx-auto justify-content-between px-0 fa-num-font">
      </b-col>
    </template>

    <template v-slot:footer>
      <b-row class="w-100 flex-row-reverse mt-3">
        <b-col cols="12">
          <b-button class="w-25 text-white ml-2" size="sm" @click="closeModal">
            خیر
          </b-button>
          <b-button
            class="w-25 text-white"
            variant="success"
            size="sm"
            @click="cancelOrder"
          >
            بله
          </b-button>
        </b-col>
      </b-row>
    </template>
  </LipakModal>
</template>

<script>
import LipakModal from "@/components/app/Modal/LipakModal";
import OrderService from "@/packages/orders/services/order.service";
export default {
  name: "CancelOrder",
  data: function () {
    return {
      Order: new OrderService(),
    };
  },
  props: {
    modal: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    async cancelOrder() {
      try {
        let data = { isCanceled: true };
        await this.Order.cancelOrders(this.$route.params.id, data);
        this.closeModal();
        await this.$emit("updateOrderDetail");
      } catch (e) {
        this.closeModal();
      }
    },
    closeModal() {
      this.$emit("close");
    },
  },
  components: {
    LipakModal,
  },
};
</script>
<style scoped></style>
