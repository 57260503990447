export default [
  {
    path: "manage",
    name: "manage",
    component: () =>
      import(/* webpackChunkName: "manage" */ "@/packages/manage/views/Index"),

    redirect: { name: "manage.return" },
    meta: {
      title: "مدیریت",
    },
    children: [
      {
        path: "payoff",
        name: "manage.payoff",
        component: () =>
          import(
            /* webpackChunkName: "manage" */ "@/packages/manage/views/payoff/PayoffIndex"
          ),
        meta: {
          title: "پرداخت",
        },
        redirect: { name: "manage.payoff.in-place" },
        children: [
          {
            path: "in-place",
            name: "manage.payoff.in-place",

            component: () =>
              import(
                /* webpackChunkName: "manage" */ "@/packages/manage/views/payoff/PayoffInPlace"
              ),
            meta: {
              title: "پرداخت در محل",
            },
          },
          {
            path: "internet",
            name: "manage.payoff.internet",

            component: () =>
              import(
                /* webpackChunkName: "manage" */ "@/packages/manage/views/payoff/PayoffInternet"
              ),
            meta: {
              title: "پرداخت اینترنتی",
            },
          },
        ],
      },
      {
        path: "return",
        name: "manage.return",
        component: () =>
          import(
            /* webpackChunkName: "manage" */ "@/packages/manage/views/return/ReturnIndex"
          ),
        meta: {
          title: "مرجوعی",
        },
        redirect: { name: "manage.return-reason" },
        children: [
          {
            path: "return-reason",
            name: "manage.return-reason",

            component: () =>
              import(
                /* webpackChunkName: "manage" */ "@/packages/manage/views/return/ReturnReason"
              ),
            meta: {
              title: "دلایل مرجوعی",
            },
          },
          {
            path: "return-failure-reason",
            name: "manage.return-failure-reason",

            component: () =>
              import(
                /* webpackChunkName: "manage" */ "@/packages/manage/views/return/ReturnFailureReason"
              ),
            meta: {
              title: "دلایل رد مرجوعی",
            },
          },
        ],
      },
      {
        path: "activities",
        name: "manage.activities",
        component: () =>
          import(
            /* webpackChunkName: "manage" */ "@/packages/manage/views/Activities.vue"
          ),
        meta: {
          title: "فعالیت‌ها",
        },
      },
    ],
  },
];
