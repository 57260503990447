<template>
  <b-container fluid class="mt-4 fa-num-font">
    <b-row class="d-flex justify-content-end">
      <OrderSearch />

      <OrderFilter />

      <OrderTable
        v-model="sort"
        :skeletonLoader="skeletonLoader"
        :hasError="hasError"
      />

      <!--Start pagination -------------------------------------------->
      <Pagination
        v-if="orders"
        class="my-5"
        v-model="paginationParams"
        key="order-pagination"
      />
      <!--End   pagination -------------------------------------------->
    </b-row>
  </b-container>
</template>

<script>
import OrderSearch from "../../common-components/OrderSearch";
import OrderFilter from "./OrderFilter";
import OrderTable from "./OrderTable";
import OrderService from "@/packages/orders/services/order.service";
import Pagination from "@/components/app/Pagination";
import { mapGetters } from "vuex";
import filterContainer from "@/mixins/filter-container";

export default {
  name: "AllOrders",
  mixins: [filterContainer],
  components: {
    OrderSearch,
    OrderFilter,
    OrderTable,
    Pagination,
  },
  data() {
    return {
      paginationParams: null,
      sort: {
        sortBy: "createdAt",
        sortDesc: true,
      },
      Order: new OrderService(),
      skeletonLoader: true,
      hasError: false,
    };
  },
  computed: {
    ...mapGetters({
      orders: "order/orders",
      pageNumber: "pagination/current_page",
      pageSize: "pagination/size",
      is_loading: "loading/is_loading",
      formForSubmit: "filter/form",
    }),
  },

  watch: {
    formForSubmit: {
      deep: true,
      handler(newValue) {
        if (!newValue.queryParams) return;
        this.initializeOrders();
      },
    },
    paginationParams: {
      deep: true,
      async handler(value) {
        if (!value) return;
        await this.initializeOrders();
      },
    },
    sort: {
      deep: true,
      async handler(value) {
        this.sort = value;
        if (value) await this.initializeOrders();
      },
    },
  },

  methods: {
    async initializeOrders() {
      try {
        this.skeletonLoader = true;
        let { sortBy, sortDesc } = this.sort;
        await this.Order.fetchAllOrders(
          {
            ...this.paginationParams,
            ...this.formForSubmit.queryParams,
            sortBy: sortBy,

            /* force server to sort items asc/desc */
            sortDirection: sortDesc ? "DESC" : "ASC",
          },
          "all",
          true
        );
        this.skeletonLoader = false;
      } catch (e) {
        console.log(e);
        this.hasError = true;
        this.skeletonLoader = false;
      }
    },
  },
};
</script>

<style scoped></style>
