<template>
  <b-col cols="12" class="fa-num-font">
    <!--Start Filters -------------------------------------------->

    <Title
      :title="$_t('components.comments.comments.comments_table.title')"
      variant="danger"
    ></Title>

    <!--Start Filters -------------------------------------------->

    <b-table
      tbody-tr-class="align-middle fs-14 text-right"
      thead-class="text-secondary fs-12 fw-lighter text-right column-max-width"
      clas
      responsive
      hover
      :items="comments"
      :fields="tableHeaders"
      :busy.sync="isLoading"
      @sort-changed="sortChange"
      primary-key="1"
      show-empty
      :empty-text="$_t('table.default.empty_text')"
      :empty-filtered-text="$_t('table.default.empty_text')"
    >
      <!--Start Loading Viewer    ------------------------------------>
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle mx-1" small></b-spinner>

          <strong>{{ $_t("table.default.busy_text") }}</strong>
        </div>
      </template>
      <!--End   Loading Viewer    ------------------------------------>

      <!--Start Index             ------------------------------------>
      <template #cell(index)="{ index }">
        {{ index + 1 + (currentPage - 1) * pageSize }}
      </template>
      <!--End   Index             ------------------------------------>

      <!--Start createdAt         ------------------------------------>
      <template #cell(createdAt)="data">
        <span v-if="Boolean(data.value)">{{ $_date(data.value) }}</span>
      </template>
      <!--End   createdAt         ------------------------------------>

      <!--Start productName         ---------------------------------->
      <template #cell(productName)="data">
        <a class="text-decoration-none" :href="productUrl(data.item.slug)"
          >{{ data.item.productName }}
        </a>
      </template>
      <!--End   productName         ---------------------------------->

      <!--Start createdAt         ------------------------------------>
      <template #cell(reply)="data">
        <p class="text-wrap" style="max-width: 350px">
          {{ getShortReply(data.item.content) }}
        </p>
      </template>
      <!--End   createdAt         ------------------------------------>

      <!--Start createdAt         ------------------------------------>
      <template #cell(status)="data">
        <div class="d-flex justify-content-end align-items-center">
          <div
            class="w-50 text-center mt-2 py-1 px-2 text-nowrap rounded-lg font-weight-bold"
            :class="data.item.productCommentStatus.color"
          >
            {{ data.item.productCommentStatus.persianName }}
          </div>

          <div class="rounded mr-3 border-0 text-purple-light px-1">
            {{ data.item.numberOfLike }}
            <!--            <LIThumbsUp class="fs-16" />-->
          </div>

          <div class="rounded border-0 text-primary-light px-1">
            {{ data.item.numberOfDislike }}
            <!--            <LIThumbsDown class="fs-16" />-->
          </div>

          <b-button
            :id="'btn-' + data.item.id"
            size="sm"
            @click="rejectionComment(data.item.id)"
            class="rounded border-0"
            pill
            text
            variant="outline-danger"
          >
            <LITimesCircle class="fs-16" />
          </b-button>

          <b-button
            :id="`target-${data.item.id}`"
            size="sm"
            @click="approveComment(data.item.id)"
            class="rounded border-0"
            pill
            text
            variant="outline-success"
          >
            <LICheckCircleFill class="fs-16" />
          </b-button>

          <b-button
            size="sm"
            class="rounded border-0"
            pill
            text
            :variant="
              data.item.reply && data.item.reply.content
                ? 'outline-info'
                : 'outline-primary'
            "
            @click="openModalReply(data.item)"
          >
            <LIRecycleMessage class="fs-16" />
          </b-button>

          <b-button
            size="sm"
            class="rounded border-0"
            pill
            text
            variant="outline-info"
            @click="
              openModalEditComment(
                data.item.id,
                data.item.content,
                data.item.title
              )
            "
          >
            <LIEdit class="fs-16" />
          </b-button>
        </div>
      </template>
      <!--End   createdAt         ------------------------------------>

      <!--Start Empty states      ------------------------------------>
      <template #empty="scope">
        <p class="text-center">{{ scope.emptyText }}</p>
      </template>

      <template #emptyfiltered="scope">
        <p class="text-center">{{ scope.emptyFilteredText }}</p>
      </template>
      <!--End   Empty states      ------------------------------------>
    </b-table>

    <ReplayCommentModal
      :modal="isShowModal"
      :comment-id="commentId"
      @close="isShowModal = false"
      :replySupport="replySupport"
    />

    <EditComment
      :modal="isShowModalEditComment"
      :commentUser="commentUser"
      @close="isShowModalEditComment = false"
    />
  </b-col>
</template>

<script>
import CommentsService from "@/packages/comments/services/comments.service";
import { mapActions, mapGetters } from "vuex";
import Title from "@/components/app/Title";
import { TABLE_HEADER_SCHEMA } from "@/packages/comments/schema/tables/COMMENTS_TABLE.SCHEMA";
import ReplayCommentModal from "./ReplayCommentModal";
import EditComment from "./EditComment";
export default {
  name: "CommentsTable",
  components: { Title, ReplayCommentModal, EditComment },
  data() {
    return {
      tableHeaders: TABLE_HEADER_SCHEMA,
      Comment: new CommentsService(),
      isShowModal: false,
      isShowModalEditComment: false,
      commentId: "",
      commentUser: "",
      replySupport: {},
    };
  },
  computed: {
    ...mapGetters({
      isLoading: "loading/is_loading",
      comments: "comments/comments",
      currentPage: "pagination/current_page",
      pageSize: "pagination/size",
    }),
  },

  methods: {
    ...mapActions({
      updateComments: "comments/setComments",
    }),

    async rejectionComment(comment_id) {
      let data = {
        id: comment_id,
        status: "rejected",
      };
      await this.Comment.rejectionComment(data);
      this.getAgainComment();
    },
    async approveComment(comment_id) {
      let data = {
        id: comment_id,
        status: "accepted",
      };
      await this.Comment.approveComment(data);
      this.getAgainComment();
    },
    openModalReply(item) {
      this.commentId = item.id;
      this.replySupport = {
        content: item.reply && item.reply.content ? item.reply.content : "",
        title: item.reply && item.reply.title ? item.reply.title : "",
      };
      this.isShowModal = true;
    },
    openModalEditComment(commentId, commentContent, commentTitle) {
      this.commentUser = {
        id: commentId,
        title: commentTitle,
        content: commentContent,
      };
      this.isShowModalEditComment = true;
    },
    getAgainComment() {
      this.$emit("fetchAgain");
    },
    sortChange(e) {
      if (!e.sortBy) return;
      this.$emit("input", e);
    },
    getShortReply(reply) {
      const maxLength = 40;
      if (reply.length <= maxLength) {
        return reply;
      }
      return reply.slice(0, maxLength) + "...";
    },

    productUrl(slug) {
      let base = process.env.VUE_APP_SHOP_DOMAIN || "https://lipak.com/";
      return base + "product/" + slug;
    },
  },
};
</script>

<style scoped>
.circle-btn-size {
  width: 30px;
  height: 30px;
}
</style>
