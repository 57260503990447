<template>
  <LipakModal
    @close="$emit('close')"
    :modal="modal"
    title="
ویرایش پاسخ کاربر
    "
  >
    <template v-slot:body>
      <LipakForm class="w-100">
        <!------------------ start body of form --------------->

        <template v-slot:body>
          <LipackFormGenerator :schema="schema" :validator="$v" />
        </template>

        <!------------------ end body of form ----------------->

        <!------------------- start action of form ------------>

        <template v-slot:action> </template>

        <!------------------ end action of form ---------------->
      </LipakForm>
    </template>

    <template v-slot:footer>
      <b-row class="w-100 flex-row-reverse">
        <b-button
          class="w-25 text-white"
          variant="success"
          size="sm"
          @click="edit"
        >
          ویرایش
        </b-button>

        <b-button
          class="w-25 text-black-50 bg-light ml-2"
          size="sm"
          @click="closeModal"
        >
          بستن
        </b-button>
      </b-row>
    </template>
  </LipakModal>
</template>

<script>
import LipakForm from "@/components/app/form/LipakForm";
import LipackFormGenerator from "@/components/app/form/LipackFormGenerator";
import LipakModal from "@/components/app/Modal/LipakModal";
import { EDIT_COMMENT } from "@/packages/comments/schema/form/EDIT_COMMENT";
import CommentsService from "@/packages/comments/services/comments.service";

export default {
  name: "EditComment",
  components: {
    LipakModal,
    LipakForm,
    LipackFormGenerator,
  },
  props: {
    modal: {
      type: Boolean,
      default: false,
    },
    commentUser: { type: [Object, String], required: true },
  },
  data() {
    return {
      schema: EDIT_COMMENT.schema,
      form: { ...EDIT_COMMENT.model },
      Comment: new CommentsService(),
    };
  },
  validations: {
    ...EDIT_COMMENT.validations,
  },
  watch: {
    commentUser: {
      handler: function (value) {
        if (value) {
          this.form.content = value.content;
          this.form.title = value.title;
        }
      },
      deep: true,
    },
  },
  mounted() {},
  methods: {
    isFormValidate() {
      this.$v.$touch();
      return !this.$v.$invalid;
    },
    async edit() {
      if (!this.isFormValidate()) return;
      let data = {
        ...this.form,
        id: this.commentUser.id,
      };
      await this.Comment.editComment(data);
      this.closeModal();
      await this.Comment.fetchComments();
    },
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped></style>
