<template>
  <UserManagement />
</template>

<script>
import UserManagement from "@/packages/users/components/user/container/UserManagement";
export default {
  name: "UserManager.view",
  components: { UserManagement },
};
</script>

<style scoped></style>
