import LIArticle from "@lipak/lipak-icon/src/lipak-icon/article";
import LIBuilding from "@lipak/lipak-icon/src/lipak-icon/building";
import LICheckCircle from "@lipak/lipak-icon/src/lipak-icon/checkCircle";
import LICheckCircleCut from "@lipak/lipak-icon/src/lipak-icon/checkCircleCut";
import LIChevronDown from "@lipak/lipak-icon/src/lipak-icon/chevronDown";
import LIChevronRight from "@lipak/lipak-icon/src/lipak-icon/chevronRight";
import LIChevronLeft from "@lipak/lipak-icon/src/lipak-icon/chevronLeft";
import LICloudGear from "@lipak/lipak-icon/src/lipak-icon/cloudGear";
import LIEarth from "@lipak/lipak-icon/src/lipak-icon/earth";
import LIEdit from "@lipak/lipak-icon/src/lipak-icon/edit";
import LIExclamationSquare from "@lipak/lipak-icon/src/lipak-icon/exclamationSquare";
import LIGear from "@lipak/lipak-icon/src/lipak-icon/gear";
import LIGroup from "@lipak/lipak-icon/src/lipak-icon/group";
import LIKey from "@lipak/lipak-icon/src/lipak-icon/key";
import LILayers from "@lipak/lipak-icon/src/lipak-icon/layers";
import LIMulticolor from "@lipak/lipak-icon/src/lipak-icon/multicolor";
import LIPercentPaper from "@lipak/lipak-icon/src/lipak-icon/percentPaper";
import LIPercentTag from "@lipak/lipak-icon/src/lipak-icon/percentTag";
import LIPlus from "@lipak/lipak-icon/src/lipak-icon/plus";
import LIPrinter from "@lipak/lipak-icon/src/lipak-icon/printer";
import LIProduct from "@lipak/lipak-icon/src/lipak-icon/product";
import LIProductGroup from "@lipak/lipak-icon/src/lipak-icon/productGroup";
import LIProductList from "@lipak/lipak-icon/src/lipak-icon/productList";
import LISignOut from "@lipak/lipak-icon/src/lipak-icon/signOut";
import LIStar from "@lipak/lipak-icon/src/lipak-icon/star";
import LIState from "@lipak/lipak-icon/src/lipak-icon/state";
import LIStorehouse from "@lipak/lipak-icon/src/lipak-icon/storehouse";
import LITag from "@lipak/lipak-icon/src/lipak-icon/tag";
import LITaskCheck from "@lipak/lipak-icon/src/lipak-icon/taskCheck";
import LITaskList from "@lipak/lipak-icon/src/lipak-icon/taskList";
import LITaskPlus from "@lipak/lipak-icon/src/lipak-icon/taskPlus";
import LITimes from "@lipak/lipak-icon/src/lipak-icon/times";
import LITimesCircle from "@lipak/lipak-icon/src/lipak-icon/timesCircle";
import LITimesSquare from "@lipak/lipak-icon/src/lipak-icon/timesSquare";
import LITrash from "@lipak/lipak-icon/src/lipak-icon/trash";
import LIUser from "@lipak/lipak-icon/src/lipak-icon/user";
import LIWifiFlat from "@lipak/lipak-icon/src/lipak-icon/wifiFlat";
import LIMenuB from "@lipak/lipak-icon/src/lipak-icon/menuB";
import LITodoChecked from "@lipak/lipak-icon/src/lipak-icon/todoChecked";
import LITaskListAlt from "@lipak/lipak-icon/src/lipak-icon/taskListAlt";
import LIRecycleCoin from "@lipak/lipak-icon/src/lipak-icon/recycleCoin";
import LIRecycleMessage from "@lipak/lipak-icon/src/lipak-icon/recycleMessage";
import LICapture from "@lipak/lipak-icon/src/lipak-icon/capture";
import LITodo from "@lipak/lipak-icon/src/lipak-icon/todo";
import LIRecycleUser from "@lipak/lipak-icon/src/lipak-icon/recycleUser";
import LISearch from "@lipak/lipak-icon/src/lipak-icon/search";
import LICheckCircleFill from "@lipak/lipak-icon/src/lipak-icon/checkCircleFill";
import LIUserAlt from "@lipak/lipak-icon/src/lipak-icon/userAlt";
import LIBasketCheck from "@lipak/lipak-icon/src/lipak-icon/basketCheck";
import LICreditCard from "@lipak/lipak-icon/src/lipak-icon/creditCard";
import LIShow from "@lipak/lipak-icon/src/lipak-icon/show";
import LIConversation from "@lipak/lipak-icon/src/lipak-icon/conversation";
import LIwalletAlt from "@lipak/lipak-icon/src/lipak-icon/walletAlt";
import LILocationAlt from "@lipak/lipak-icon/src/lipak-icon/locationAlt";
import LIUserInfo from "@lipak/lipak-icon/src/lipak-icon/userInfo";
import LIEmail from "@lipak/lipak-icon/src/lipak-icon/email";
import LIPhone from "@lipak/lipak-icon/src/lipak-icon/phone";
import LIScroll from "@lipak/lipak-icon/src/lipak-icon/scroll";
// import LIThumbsDown from "@lipak/lipak-icon/src/lipak-icon/thumbsDown";
// import LIThumbsUp from "@lipak/lipak-icon/src/lipak-icon/thumbsUp";
import LICalendar from "@lipak/lipak-icon/src/lipak-icon/calendar";
import LIClock from "@lipak/lipak-icon/src/lipak-icon/clock";
import LIArrowRight from "@lipak/lipak-icon/src/lipak-icon/arrowRight";
import LICheckGear from "@lipak/lipak-icon/src/lipak-icon/checkGear";
import LIBars from "@lipak/lipak-icon/src/lipak-icon/bars";
import { LIBarcode } from "@lipak/lipak-icon";

export function initLipakIcon(vue) {
  vue.component("LIPercentPaper", LIPercentPaper);
  vue.component("LIMenuB", LIMenuB);
  vue.component("LIPercentTag", LIPercentTag);
  vue.component("LIStorehouse", LIStorehouse);
  vue.component("LIGroup", LIGroup);
  vue.component("LIPlus", LIPlus);
  vue.component("LITaskPlus", LITaskPlus);
  vue.component("LICheckCircleCut", LICheckCircleCut);
  vue.component("LIGear", LIGear);
  vue.component("LIKey", LIKey);
  vue.component("LISignOut", LISignOut);
  vue.component("LITaskCheck", LITaskCheck);
  vue.component("LIChevronDown", LIChevronDown);
  vue.component("LIChevronRight", LIChevronRight);
  vue.component("LIChevronLeft", LIChevronLeft);
  vue.component("LIGroup", LIGroup);
  vue.component("LIPrinter", LIPrinter);
  vue.component("LITimes", LITimes);
  vue.component("LICheckCircle", LICheckCircle);
  vue.component("LITimesSquare", LITimesSquare);
  vue.component("LIExclamationSquare", LIExclamationSquare);
  vue.component("LIStar", LIStar);
  vue.component("LIProductGroup", LIProductGroup);
  vue.component("LITag", LITag);
  vue.component("LIProduct", LIProduct);
  vue.component("LICloudGear", LICloudGear);
  vue.component("LILayers", LILayers);
  vue.component("LITaskList", LITaskList);
  vue.component("LIArticle", LIArticle);
  vue.component("LIProductList", LIProductList);
  vue.component("LIEarth", LIEarth);
  vue.component("LIState", LIState);
  vue.component("LIBuilding", LIBuilding);
  vue.component("LIMulticolor", LIMulticolor);
  vue.component("LITimesCircle", LITimesCircle);
  vue.component("LIEdit", LIEdit);
  vue.component("LITrash", LITrash);
  vue.component("LIWifiFlat", LIWifiFlat);
  vue.component("LIUser", LIUser);
  vue.component("LITodoChecked", LITodoChecked);
  vue.component("LITaskListAlt", LITaskListAlt);
  vue.component("LICapture", LICapture);
  vue.component("LITodo", LITodo);
  vue.component("LIRecycleUser", LIRecycleUser);
  vue.component("LIRecycleCoin", LIRecycleCoin);
  vue.component("LIRecycleMessage", LIRecycleMessage);
  vue.component("LICheckCircleFill", LICheckCircleFill);
  vue.component("LISearch", LISearch);
  vue.component("LIUserAlt", LIUserAlt);
  vue.component("LIBasketCheck", LIBasketCheck);
  vue.component("LICreditCard", LICreditCard);
  vue.component("LIShow", LIShow);
  vue.component("LIConversation", LIConversation);
  vue.component("LIwalletAlt", LIwalletAlt);
  vue.component("LILocationAlt", LILocationAlt);
  vue.component("LIUserInfo", LIUserInfo);
  vue.component("LIEmail", LIEmail);
  vue.component("LIPhone", LIPhone);
  vue.component("LIScroll", LIScroll);
  // vue.component("LIThumbsDown", LIThumbsDown);
  // vue.component("LIThumbsUp", LIThumbsUp);
  vue.component("LICalendar", LICalendar);
  vue.component("LIClock", LIClock);
  vue.component("LIArrowRight", LIArrowRight);
  vue.component("LICheckGear", LICheckGear);
  vue.component("LIBars", LIBars);
  vue.component("LIBarcode", LIBarcode);
}
