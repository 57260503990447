<template>
  <Otp />
</template>

<script>
import Otp from "@/packages/authentication/presentation/components/Otp";
export default {
  name: "VerifyOtp",
  components: { Otp },
};
</script>

<style scoped></style>
