<template>
  <b-col cols="12" class="fa-num-font">
    <!--Start Filters -------------------------------------------->
    <Title
      :title="$_t('components.users.users.users_table.title')"
      variant="danger"
    ></Title>
    <!--Start Filters -------------------------------------------->

    <b-table
      tbody-tr-class="align-middle fs-12 text-center"
      thead-class="text-secondary fs-12 fw-lighter text-center"
      responsive
      hover
      :items="users"
      :fields="tableHeaders"
      :busy.sync="isLoading"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :no-local-sorting="true"
      @sort-changed="sortChange"
      primary-key="1"
      show-empty
      :empty-text="$_t('table.default.empty_text')"
      :empty-filtered-text="$_t('table.default.empty_text')"
    >
      <template #empty="scope">
        <div v-if="hasError" class="text-center">
          <div class="text-primary">عدم دریافت پاسخ از سرور</div>
          <b-button
            variant="outline-primary"
            class="mt-3"
            @click="fetchAgainUsers"
            >درخواست مجدد</b-button
          >
        </div>
        <p v-else class="text-center">{{ scope.emptyText }}</p>
      </template>
      <!--Start Loading Viewer    ------------------------------------>
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle mx-1" small></b-spinner>
          <strong>{{ $_t("table.default.busy_text") }}</strong>
        </div>
      </template>
      <!--End   Loading Viewer    ------------------------------------>

      <!--Start Index             ------------------------------------>
      <template #cell(index)="{ index }">
        {{ index + 1 + (currentPage - 1) * pageSize }}
      </template>
      <!--End   Index             ------------------------------------>

      <!--Start createdAt         ------------------------------------>
      <template #cell(createdAt)="data">
        <span v-if="Boolean(data.value)">{{ $_date(data.value) }}</span>
      </template>
      <!--End   createdAt         ------------------------------------>

      <!--Start Actions           ------------------------------------>
      <template #cell(actions)="row">
        <b-button
          size="sm"
          @click="navigateToUserDetail(row.item.id)"
          class="mr-1 text-info w-100 border-0"
          block
          variant="accent"
        >
          جزئیات
        </b-button>
      </template>
      <!--End   Actions           ------------------------------------>

      <!--Start Empty states      ------------------------------------>
      <template #empty="scope">
        <p class="text-center">{{ scope.emptyText }}</p>
      </template>

      <template #emptyfiltered="scope">
        <p class="text-center">{{ scope.emptyFilteredText }}</p>
      </template>
      <!--End   Empty states      ------------------------------------>
    </b-table>
  </b-col>
</template>

<script>
import Users from "@/packages/users/services/users.service";
import { mapGetters } from "vuex";
import Title from "@/components/app/Title";
import { TABLE_HEADER_SCHEMA } from "@/packages/users/schema/tables/USERS_TABLE.SCHEMA";

export default {
  name: "UsersTable",
  components: { Title },
  data() {
    return {
      tableHeaders: TABLE_HEADER_SCHEMA,
      User: new Users(),
      sortBy: "createdAt",
      sortDesc: true,
    };
  },
  computed: {
    ...mapGetters({
      isLoading: "loading/is_loading",
      users: "users/users",
      currentPage: "pagination/current_page",
      pageSize: "pagination/size",
    }),
  },

  methods: {
    navigateToUserDetail(id) {
      let routeData = this.$router.resolve({
        name: "user.profile",
        params: { user_id: id },
      });
      window.open(routeData.href, "_blank");
    },
    sortChange(e) {
      if (!e.sortBy) return;
      this.$emit("input", e);
    },
    fetchAgainUsers() {
      this.$emit("search");
    },
  },
};
</script>

<style scoped></style>
