import api from "@/tools/Api";
import urls from "@/tools/Urls";

/*
 * get all operation types
 *--------------------------
 * Api address /api/v1/user_activity/operation_types
 *--------------------------
 * @param query_params  {Object}
 *--------------------------
 * methods : GET
 * -------------------------
 * description :
 * -------------------------
 * get all operation types
 *--------------------------
 * @return promise
 *--------------------------
 */
export const fetchAllOperationTypesAxiosReq = function (query_params = null) {
  return api.get(urls.api("user_activities_operation_types"), {
    params: {
      panel: process.env.VUE_APP_PANEL_SECRET || "support",
      ...query_params,
    },
  });
};
