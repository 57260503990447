<template>
  <b-row
    class="mt-3 d-flex justify-content-center align-items-center bg-white rounded-lg shadow fa-num-font"
  >
    <b-col cols="12" lg="8" class="d-flex">
      <ul class="list-info d-block d-lg-flex list-unstyled w-100 m-0 py-4 px-2">
        <template v-for="item in innerPersonalInfoUser">
          <li
            class="text-right fs-16 font-weight-bold d-flex mb-3 mb-lg-0"
            :key="item.index"
            v-if="item.show"
          >
            <div class="text-more">{{ item.title }}:</div>
            <div class="text-more">{{ item.value }}</div>
          </li>
        </template>
      </ul>
    </b-col>
    <b-col
      cols="12"
      lg="4"
      class="d-flex flex-wrap justify-content-end justify-content-lg-end align-bottom py-3 py-lg-0"
    >
      <b-button
        v-if="paymentMethod === 'online' && state.first === 'INPAYMENT'"
        class="fs-14 text-nowrap mb-2"
        @click="openChangePaymentMethodModal"
        variant="primary"
        style="width: 160px"
      >
        تغییر روش پرداخت به حواله</b-button
      >
      <b-button
        v-if="isPayed"
        class="fs-14 mr-1 mb-2"
        @click.prevent="openAcceptPaymentModal"
        variant="primary"
        style="width: 130px"
        >تایید پرداخت</b-button
      >
      <b-button
        v-if="enabledRegisteringSuccessfulDelivery"
        class="fs-14 mr-1 mb-2"
        @click="openSuccessfulDeliveryModal"
        variant="primary"
        style="width: 130px"
      >
        تحویل موفق</b-button
      >
      <b-button
        class="fs-14 mr-1 mt-lg-0 mb-2"
        variant="primary"
        style="width: 130px"
        @click="navigationToProfile"
      >
        مشاهده پروفایل</b-button
      >
    </b-col>
    <AcceptPayment :modal="isShowModal" @close="isShowModal = false" />
    <SuccessfulDelivery
      :modal="successfulDeliveryModal"
      @close="successfulDeliveryModal = false"
    />
    <ChangePaymentMethodModal
      :modal="isShowChangePaymentMethod"
      @close="closeChangePaymentModal($event)"
    />
  </b-row>
</template>

<script>
import { MAXIMUM_PRICE_ALLOWED_ONLINE_PAY } from "@/configs/MAXIMUM_PRICE_ALLOWED_LOCAL_PAY";
import AcceptPayment from "@/packages/orders/components/order-detail/all-order-detail/AcceptPayment";
import SuccessfulDelivery from "./SuccessfulDelivery";
import ChangePaymentMethodModal from "./ChangePaymentMethodModal";
export default {
  name: "OrderPersonInfo",
  components: { AcceptPayment, SuccessfulDelivery, ChangePaymentMethodModal },
  props: {
    personalInfo: {
      type: Object,
      required: true,
    },
    price: {
      type: Number,
      required: false,
    },
    state: {
      type: Object,
      required: false,
    },
    shippingMethod: {
      type: String,
      required: false,
    },
    paymentMethod: {
      type: String,
      required: false,
    },

    enabledRegisteringSuccessfulDelivery: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      isShowModal: false,
      successfulDeliveryModal: false,
      isShowChangePaymentMethod: false,
    };
  },
  computed: {
    innerPersonalInfoUser() {
      return [
        {
          title: "نام و نام خانوادگی",
          value: this.personalInfo.fullName || "",
          show: true,
        },
        {
          title: "شماره موبایل",
          value: this.personalInfo.mobileNum || "",
          show: true,
        },

        {
          title: "کد ملی",
          value: this.personalInfo.ssn || "",
          show: true,
        },
      ];
    },
    isBiggerThanMaximumOnlinePayAllowed() {
      return this.price > MAXIMUM_PRICE_ALLOWED_ONLINE_PAY;
    },
    isPayed() {
      return (
        this.isBiggerThanMaximumOnlinePayAllowed &&
        this.state.first.toUpperCase() === "INPAYMENT"
      );
    },
  },
  methods: {
    openAcceptPaymentModal() {
      this.isShowModal = true;
    },
    openSuccessfulDeliveryModal() {
      this.successfulDeliveryModal = true;
    },
    navigationToProfile() {
      let routeData = this.$router.resolve({
        name: "user.profile",
        params: { user_id: this.personalInfo.userId },
      });
      window.open(routeData.href, "_blank");
    },
    openChangePaymentMethodModal() {
      this.isShowChangePaymentMethod = true;
    },
    closeChangePaymentModal(state) {
      if (state === "success") {
        this.$emit("updateOrderDetail");
      }
      this.isShowChangePaymentMethod = false;
    },
  },
};
</script>
<style>
.list-info > li {
  flex-basis: 25%;
}
</style>
