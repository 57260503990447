export default {
  /* users Routes********************/
  fetch_comments: "product_comment",
  product_comment: "product_comment/response",
  edit_comment: "product_comment/edit",
  comment: "comments/%s",
  like_comment: "product_comment/like/%s",
  disLike_comments: "product_comment/dislike/%s",
  change_status_comment: "product_comment/change_status",

  ///
  get_all_product_groups: "contents/productGroups",
  get_all_brands: "contents/brands",
  get_all_models: "contents/models",
  get_all_configs: "contents/configs",

  ///comments for user
  fetch_comments_by_id_user: "product_comment",
};
