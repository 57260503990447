import axios from "axios";

let axiosSource;
export const AbortSource = {
  axiosSource: axios.CancelToken.source(),
  request: axiosSource ? axiosSource.cancel : null,
};
export const cancelApi = function () {
  if (AbortSource.request) AbortSource.request();
};

export const abortApiHandler = function () {
  cancelApi();
  AbortSource.axiosSource = axios.CancelToken.source();
  AbortSource.request = AbortSource.axiosSource.cancel;
};
