<template>
  <b-form inlines class="d-flex flex-column justify-content-between">
    <b-row>
      <b-col cols="12" class="mx-auto">
        <b-row class="px-3">
          <slot name="body" />
          <slot name="inline-action"></slot>
        </b-row>
        <b-row class="justify-content-end mt-3">
          <slot name="action" />
        </b-row>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
export default {
  name: "LipakForm",
};
</script>

<style scoped></style>
