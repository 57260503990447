<template>
  <div>
    <component :is="stepComponent" :key="`${stepComponent}`"></component>
  </div>
</template>

<script>
export default {
  name: "Item.view",
  computed: {
    stepComponent() {
      let component_dir = this.$route.meta.component_dir;
      return () =>
        import(
          `@/packages/users/components/user/${component_dir}/${this.capitalizeFirstLettersOfSentence(
            component_dir
          )}`
        );
    },
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    capitalizeFirstLettersOfSentence(string) {
      let arr = string.split("-");
      arr.forEach((str, index) => {
        arr[index] = this.capitalizeFirstLetter(str);
      });
      return arr.join("");
    },
  },
};
</script>

<style scoped></style>
