import api from "@/tools/Api";
import urls from "@/tools/Urls";

export const fetchOrderItemByIdForReturnAxiosRequest = function (
  orderId,
  imageSize
) {
  return api.get(urls.api("fetch_order_item_by_id_for_return", [orderId]), {
    params: {
      imageSize: imageSize,
    },
  });
};

export const registerReturnedAxiosRequest = function (payload) {
  return api.post(urls.api("submit_returned"), payload);
};

export const uploadImageForSubmitReturnedAxiosReq = function (
  itemId,
  mediaFiles
) {
  return api.post(urls.api("upload_image_for_returned", [itemId]), mediaFiles);
};

export const approveImageSaveAxiosRequest = function (return_id) {
  return api.put(urls.api("approve_image_save", [return_id]));
};
