<template>
  <div>Wallet</div>
</template>

<script>
export default {
  name: "Wallet",
};
</script>

<style scoped></style>
