<template>
  <b-row class="m-0 mt-4">
    <b-col cols="12" class="p-0">
      <!----------------------start table of receipts-detail received----------------->
      <b-table
        class="w-100 fa-num-font"
        tbody-tr-class="align-middle fs-14"
        thead-class="text-secondary fs-12 fw-lighter w-100"
        responsive
        hover
        :busy.sync="isLoading"
        :items="orderList"
        :fields="tableHeader"
        primary-key="1"
        show-empty
        :empty-text="$_t('table.default.empty_text')"
        :empty-filtered-text="$_t('table.default.empty_text')"
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle mx-1" small></b-spinner>
            <strong>{{ $_t("table.default.busy_text") }}</strong>
          </div>
        </template>
        <template #cell(row)="{ index }">
          <span>{{ index + 1 }}</span>
        </template>
        <template #cell(discount)="data">
          <span>{{ $_currency(Number(data.item.discount)) }}</span>
        </template>
        <template #cell(lpvName)="data">
          <a
            class="text-decoration-none"
            :href="`${shopDomain}product/${data.item.slug}`"
            >{{ data.item.lpvName }}
          </a>
        </template>
        <template #cell(serials)="data">
          <div
            v-for="(serial, serialIndex) in data.item.serials"
            :key="serialIndex"
            class="mb-2"
          >
            <span>
              {{ serial }}
            </span>
          </div>
        </template>
        <template #cell(price)="data">
          <span>{{ $_currency(Number(data.item.price)) }}</span>
        </template>
        <template #cell(finalPrice)="data">
          <span>{{
            $_currency(Number(data.item.price) - Number(data.item.discount))
          }}</span>
        </template>
        <template #cell(lpvImageUrl)="data">
          <div class="d-flex justify-content-center">
            <b-img
              width="50"
              height="50"
              :src="imageUrlBuilder(data.item.lpvImageUrl)"
              class="rounded float-right"
            />
          </div>
        </template>
        <template #cell(total)="data">
          <div class="d-flex justify-content-center">
            <span>{{
              $_currency(
                (Number(data.item.price) - Number(data.item.discount)) *
                  Number(data.item.count)
              )
            }}</span>
          </div>
        </template>

        <template #custom-foot="data">
          <b-tr class="text-center bg-main">
            <b-th
              colspan="6"
              class="text-left fs-12"
              style="text-align: left !important"
            >
              مجموع:
            </b-th>

            <b-th class="bg-secondary bg-opacity-50 fs-12 bg-accent">
              {{ totalCount(data.items) }}
            </b-th>
            <b-th class="fs-12"> </b-th>
            <b-th class="fs-12"> </b-th>
            <b-th class="fs-12"> </b-th> <b-th class="fs-12"> </b-th>
            <b-th class="fs-12 bg-accent">
              {{ $_currency(Number(totalAllPrice(data.items))) }}
            </b-th>
          </b-tr>
          <b-tr v-if="discount" class="text-center bg-main">
            <b-th
              colspan="6"
              class="text-left fs-12"
              style="text-align: left !important"
            >
              مبلغ تخفیف:
            </b-th>

            <b-th class=" "> </b-th>
            <b-th class="fs-12"> </b-th>
            <b-th class="fs-12"> </b-th>
            <b-th class="fs-12"> </b-th> <b-th class="fs-12"> </b-th>
            <b-th class="fs-12 bg-accent">
              {{ $_currency(Number(discount)) }}
            </b-th>
          </b-tr>
        </template>
      </b-table>
      <!----------------------end table of receipts-detail received------------------->
    </b-col>
  </b-row>
</template>

<script>
import { ORDER_DETAIL_TABEL_HEEADER } from "@/packages/orders/schema/table/ORDER_DETAIL_TABEL_HEEADER";
import { mapGetters } from "vuex";
import imgUrl from "@/mixins/mixin.image_url_builder";
export default {
  name: "OrderDetailTable",
  mixins: [imgUrl],
  data: () => {
    return {
      tableHeader: ORDER_DETAIL_TABEL_HEEADER,
      shopDomain: process.env.VUE_APP_SHOP_DOMAIN || "https://lipak.com/",
    };
  },
  props: {
    orderList: {
      type: Array,
      required: true,
    },
    discount: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      isLoading: "loading/is_loading",
    }),
  },
  methods: {
    totalCount(items) {
      return items.reduce((accumulator, current) => {
        return accumulator + parseInt(current.count ? current.count : 0);
      }, 0);
    },
    totalDiscount(items) {
      return items.reduce((accumulator, current) => {
        return (
          accumulator +
          parseInt(
            current.discount
              ? Number(current.discount) * Number(current.count)
              : 0
          )
        );
      }, 0);
    },
    totalPrice(items) {
      return items.reduce((accumulator, current) => {
        return accumulator + parseInt(current.price ? current.price : 0);
      }, 0);
    },
    totalAllPrice(items) {
      return items.reduce((accumulator, current) => {
        return (
          accumulator +
          parseInt(
            current.price
              ? (current.price - current.discount) * current.count
              : 0
          )
        );
      }, 0);
    },
  },
};
</script>
