<template>
  <LipakModal
    @close="$emit('close')"
    :modal="modal"
    title="
متن پاسخ
    "
  >
    <template v-slot:body>
      <LipakForm class="w-100">
        <!------------------ start body of form --------------->

        <template v-slot:body>
          <LipackFormGenerator :schema="schema" :validator="$v" />
        </template>

        <!------------------ end body of form ----------------->

        <!------------------- start action of form ------------>

        <template v-slot:action> </template>

        <!------------------ end action of form ---------------->
      </LipakForm>
    </template>

    <template v-slot:footer>
      <b-row class="w-100 flex-row-reverse">
        <b-button
          class="w-25 text-white"
          variant="success"
          size="sm"
          @click="submit"
        >
          ثبت پاسخ
        </b-button>

        <b-button
          class="w-25 text-black-50 bg-light ml-2"
          size="sm"
          @click="closeModal"
        >
          بستن
        </b-button>
      </b-row>
    </template>
  </LipakModal>
</template>

<script>
import LipakForm from "@/components/app/form/LipakForm";
import LipackFormGenerator from "@/components/app/form/LipackFormGenerator";
import LipakModal from "@/components/app/Modal/LipakModal";
import { REPLAY_COMMENT } from "@/packages/comments/schema/form/REPLAY_COMMENT";
import CommentsService from "@/packages/comments/services/comments.service";
import cloneDeep from "lodash.clonedeep";

export default {
  name: "ReplayCommentModal",
  components: {
    LipakModal,
    LipakForm,
    LipackFormGenerator,
  },
  props: {
    modal: {
      type: Boolean,
      default: false,
    },
    commentId: { type: String, required: true },
    replySupport: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      schema: cloneDeep(REPLAY_COMMENT.schema),
      form: cloneDeep(REPLAY_COMMENT.model),
      Comment: new CommentsService(),
    };
  },
  validations: {
    ...cloneDeep(REPLAY_COMMENT.validations),
  },
  watch: {
    modal() {
      this.form = cloneDeep(REPLAY_COMMENT.model);
      this.$v.$reset();
    },
    replySupport(newValue) {
      this.form.content = newValue.content;
      this.form.title = newValue.title;
    },
  },
  methods: {
    isFormValidate() {
      this.$v.$touch();
      return !this.$v.$invalid;
    },
    async submit() {
      if (!this.isFormValidate()) return;
      let data = {
        id: this.commentId,
        responseContent: this.form.content,
        title: this.form.title,
      };
      await this.Comment.submitAnswer(data);
      this.closeModal();
    },
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped></style>
