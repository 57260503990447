<template>
  <b-container fluid class="header-top row mx-auto">
    <!-- shows on desktop (right) -->
    <div
      class="header-top-user col-4 pr-0 d-none d-lg-flex align-items-center"
      @click="showUserMenu = !showUserMenu"
      @blur="showUserMenu = false"
      tabindex="0"
    >
      <div
        class="header-top-user-image fs-24 text-white d-flex-center font-weight-bold"
      >
        {{ userInfo.userDetail.name.charAt(0) }}
      </div>
      <div class="mx-3">
        <div class="fs-14 font-weight-bold">
          {{ userInfo.userDetail.name }} {{ userInfo.userDetail.lastName }}
        </div>
        <div class="fs-12 text-secondary">{{ role.persianTitle }}</div>
      </div>
      <LIChevronDown class="user-icon-down fs-8" />
      <transition name="fade">
        <div v-if="showUserMenu" class="header-top-user-dropdown-container">
          <!--          <div class="header-top-user-dropdown-item">-->
          <!--            <div class="header-top-user-dropdown-item-icon">-->
          <!--              <LIGear icon="gear" />-->
          <!--            </div>-->
          <!--            <div>تنظیمات</div>-->
          <!--          </div>-->
          <div class="header-top-user-dropdown-item" @click="changePassword">
            <div class="header-top-user-dropdown-item-icon">
              <LIKey icon="key" />
            </div>
            <div>تغییر رمز عبور</div>
          </div>
          <div class="header-top-user-dropdown-item pointer" @click="logout">
            <div class="header-top-user-dropdown-item-icon">
              <LISignOut />
            </div>
            <div>خروج</div>
          </div>
        </div>
      </transition>
    </div>

    <!-- shows on desktop (middle) -->
    <div
      class="col-4 d-none d-lg-flex align-items-center justify-content-center font-weight-bold fs-18"
    >
      پنل پشتیبانی
    </div>

    <!-- shows on mobile (right) -->
    <div class="col-8 pr-0 d-flex d-lg-none align-items-center">
      <img
        class="header-top-menu-icon c-pointer"
        alt="menu icon"
        :src="require('@/assets/img/logo/menu-icon.png')"
        @click="showMobileMenu = !showMobileMenu"
      />
    </div>

    <!-- shows on mobile (mobile menu) -->
    <transition name="fade">
      <HeaderMobileMenu v-if="showMobileMenu" class="d-lg-none" />
    </transition>

    <!-- shows on desktop & mobile (left) -->
    <div
      class="col-4 col-lg-4 pl-0 d-flex flex-column align-items-end justify-content-center justify-content-lg-center"
    >
      <img class="header-top-logo" alt="lipak logo" :src="logo" />

      <!-- shows on mobile (left-bottom) -->
      <div class="d-block d-lg-none fs-12 mt-1">پنل پشتیبانی</div>
    </div>
  </b-container>
</template>

<script>
import HeaderMobileMenu from "@/components/app/header/Mobile-Menu";
import LIChevronDown from "@lipak/lipak-icon/src/lipak-icon/chevronDown";
import LISignOut from "@lipak/lipak-icon/src/lipak-icon/signOut";
import LIKey from "@lipak/lipak-icon/src/lipak-icon/key";
import { logoutUser } from "../../../packages/authentication/services/Auth.service";
import { mapGetters } from "vuex";
export default {
  components: {
    HeaderMobileMenu,
    LIChevronDown,
    LISignOut,
    LIKey,
  },
  data() {
    return {
      showMobileMenu: false,
      showUserMenu: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "authentication/user",
      role: "authentication/role",
    }),
    userInfo() {
      return this.user
        ? this.user
        : {
            userDetail: {
              name: "",
              lastName: "",
            },
          };
    },
    logo() {
      return process.env.VUE_APP_NODE_ENV === "prod"
        ? require("@/assets/img/app/logo.png")
        : process.env.VUE_APP_NODE_ENV === "dev"
        ? require("@/assets/img/ZOFT01.png")
        : require("@/assets/img/HOJI.png");
    },
  },
  methods: {
    async logout() {
      await logoutUser();
    },
    changePassword() {
      this.$router.push({ name: "panel.change-password" });
    },
  },
  watch: {
    "$route.path": function () {
      this.showMobileMenu = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.header-top {
  height: 54px;
  @media screen and (max-width: 991.98px) {
    height: 72px;
  }
  &-user {
    position: relative;
    &-dropdown {
      &-container {
        position: absolute;
        width: 130px;
        top: 60%;
        right: 42px;
        background: #fff;
        border-radius: 4px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.32);
      }
      &-item {
        display: flex;
        align-items: center;
        padding: 6px 12px;
        font-size: 14px;
        font-weight: bold;
        &:first-child {
          padding-top: 12px;
        }
        &:last-child {
          padding-bottom: 12px;
        }
        &-icon {
          width: 20px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
        }
        &:hover {
          background-color: var(--grey);
        }
      }
    }
    &-image {
      width: 32px;
      min-width: 32px;
      max-width: 32px;
      height: 32px;
      min-height: 32px;
      max-height: 32px;
      background-color: #2c2c54;
      border-radius: 5px;
    }
    &:hover {
      cursor: pointer;
    }
  }
  &-logo {
    height: 27px;
    width: auto;
    @media screen and (max-width: 991.98px) {
      height: 20px;
    }
  }
  &-menu-icon {
    // real height = 72 - 26 * 2 = 20px
    height: 72px;
    width: auto;
    padding: 26px 15px;
    margin-right: -15px;
  }
}
.user-icon-down {
  margin-top: -11px;
  position: relative;
}

.pointer {
  cursor: pointer;
}
</style>
