<template>
  <div class="text-right">
    <div class="d-flex">
      <b-card
        border-variant="secondary"
        class="rounded-lg"
        style="width: 130px; height: 130px"
      >
        <img
          class="w-100"
          :src="imageUrlFormatter(item.imageUrl)"
          :alt="item.imageAlt"
        />
      </b-card>
      <div class="mr-5 text-secondary fs-12">
        <div class="fs-14 text-more font-weight-bold">{{ item.title }}</div>
        <div class="d-flex align-items-center mt-3">
          <div class="color-circle-wrapper">
            <div
              class="color-circle"
              :style="{ backgroundColor: item.colorHex }"
            ></div>
          </div>
          <div class="mr-2">{{ item.colorName }}</div>
        </div>
        <div class="d-flex align-items-center mt-3">
          <BIconShieldCheck variant="secondary" class="fs-22" />
          <div class="mr-2">{{ item.guarantee }}</div>
        </div>
        <div class="d-flex mt-3">
          <div>
            تعداد در سفارش:
            <span class="fs-14">{{ item.orderItemsCount }}</span>
          </div>
          <div class="mr-3">
            تعداد مرجوعی:
            <span class="fs-14">{{ item.returnedItemsCount }}</span>
          </div>
          <div class="mr-3">
            دلیل مرجوعی:
            <span class="fs-14">{{ item.returnedReason }}</span>
          </div>
        </div>
      </div>
      <b-card
        class="mr-auto shadow-sm rounded-lg text-more fs-14 h-100"
        style="width: 200px"
        no-body
      >
        <div class="py-2 px-3 font-weight-bold d-flex justify-content-between">
          <div>کد کالا:</div>
          <div>{{ item.lpv }}</div>
        </div>
      </b-card>
    </div>
    <div class="mt-4 text-secondary fs-12">
      توضیحات:
      <p class="fs-14 mt-2">{{ item.customerDescription }}</p>
    </div>
    <div
      class="mt-4 text-secondary fs-12"
      v-if="item.files && item.files.length"
    >
      تصاویر:
      <div class="d-flex flex-wrap mt-2">
        <div v-for="(file, index) in item.files" :key="file" class="ml-4 fs-14">
          فایل {{ index + 1 }}:
          <a
            class="btn btn-info py-1 px-5 mr-2 fs-12"
            :href="imageUrlFormatter(file)"
            download
          >
            دانلود فایل
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BIconShieldCheck } from "bootstrap-vue";
export default {
  components: { BIconShieldCheck },
  props: ["item"],
  methods: {
    imageUrlFormatter(url) {
      return process.env.VUE_APP_IMAGE_BASE_URL + url;
    },
  },
};
</script>

<style scoped lang="scss">
.color-circle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 16px;
  height: 16px;
  border-radius: 50%;
  overflow: hidden;
  &-wrapper {
    position: relative;
    width: 22px;
    height: 22px;
    border: 1px solid var(--secondary);
    border-radius: 50%;
  }
}
</style>
