<template>
  <div
    v-if="updateExists && !closed"
    class="p-3 alert-info text-right col-12 col-lg-4 position-fixed fixed-bottom pwa-updater-container mb-2 mx-2 rounded-lg text-info"
  >
    <div class="item">
      نسخه جدید سیستم در دسترس می باشد.مایل به، به روزسانی هستید؟
    </div>
    <div class="item">
      <b-button variant="outline-info" @click="closeUpdateAlert">خیر</b-button>
      <b-button variant="info" @click="refreshApp">بلی</b-button>
    </div>
  </div>
</template>

<script>
import update from "../../mixins/mixin.update";

export default {
  name: "PwaUpdaterAlert",
  mixins: [update],
  data() {
    return {
      closed: false,
    };
  },
  watch: {
    updateExists: function (val) {
      if (val) this.closed = false;
    },
  },
  methods: {
    closeUpdateAlert() {
      this.closed = true;
    },
  },
};
</script>

<style scoped>
.pwa-updater-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.item:nth-child(1) {
  flex: 3 1 auto;
  display: flex;
  align-items: center;
}

.item .btn {
  min-width: 90px !important;
  margin: 0 5px;
}
</style>
