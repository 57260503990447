<template>
  <Activities />
</template>

<script>
import Activities from "@/packages/manage/components/activities/Index";
export default {
  components: {
    Activities,
  },
};
</script>
