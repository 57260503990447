<template>
  <LipakModal
    @close="close(false)"
    :modal="modal"
    :title="'افزودن اطلاعات حقوقی جدید'"
    size="md"
  >
    <template v-slot:body>
      <b-col cols="12" class="mx-auto fa-num-font">
        <LipakForm>
          <!--Start form body    ------------------------------------------------------------------>
          <template v-slot:body>
            <!--Start form wrapper for catch errors  ------------------------>
            <LipackFormGenerator
              lg="6"
              :schema="schema"
              :validator="$v"
            ></LipackFormGenerator>
            <!--End   form wrapper for catch errors  ------------------------>
          </template>
          <!--End   form body    ------------------------------------------------------------------>

          <!--Start form actions ------------------------------------------------------------------>
          <template v-slot:action>
            <b-col cols="=12" lg="4" md="4" sm="6">
              <b-form-group class="form-group inputBox">
                <b-button
                  variant="outline-secondary"
                  class="w-100"
                  @click="close(false)"
                  :disabled="is_loading"
                >
                  {{
                    $_t(
                      "components.users.user.profile.address_information.modal.edit_address_information_modal.buttons.close"
                    )
                  }}
                </b-button>
              </b-form-group>
            </b-col>
            <b-col cols="=12" lg="4" md="4" sm="6">
              <b-form-group class="form-group inputBox">
                <b-button
                  variant="success"
                  class="w-100"
                  @click="submit"
                  :disabled="is_loading || $v.$error"
                >
                  <b-spinner
                    v-if="is_loading"
                    small
                    variants="success"
                  ></b-spinner>
                  ثبت
                </b-button>
              </b-form-group>
            </b-col>
          </template>
          <!--End   form actions ------------------------------------------------------------------>
        </LipakForm>
      </b-col>
    </template>
    <template v-slot:footer>
      <b-row class="w-100 flex-row-reverse"> </b-row>
    </template>
  </LipakModal>
</template>

<script>
import { NEW_LEGAL_FORM } from "@/packages/users/schema/forms/NEW_LEGAL_FORM";
import LipakModal from "@/components/app/Modal/LipakModal";
import LipackFormGenerator from "@/components/app/form/LipackFormGenerator";
import LipakForm from "@/components/app/form/LipakForm";
import { mapGetters } from "vuex";
import Users from "@/packages/users/services/users.service";

export default {
  name: "CreateLegal",
  components: {
    LipakModal,
    LipackFormGenerator,
    LipakForm,
  },
  props: {
    modal: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      userService: new Users(),
      schema: NEW_LEGAL_FORM.schema,
      form: { ...NEW_LEGAL_FORM.model },
    };
  },
  validations: {
    ...NEW_LEGAL_FORM.validations,
  },
  computed: {
    ...mapGetters({
      is_loading: "loading/is_loading",
    }),
  },

  methods: {
    isFormValidate() {
      this.$v.$touch();
      return !this.$v.$invalid;
    },
    async submit() {
      if (!this.isFormValidate()) return;
      try {
        await this.userService.createNewLegal(
          this.form,
          this.$route.params.user_id
        );
        await this.close("success");
      } catch (e) {
        console.log(e);
      }
    },

    async close(dialog) {
      this.$nextTick(() => {
        this.$v.form.$reset();
        this.form.companyName = "";
        this.form.nationalId = "";
        this.form.registerNumber = "";
      });
      await this.$emit("close", dialog);
    },
  },
};
</script>

<style scoped></style>
