import api from "@/tools/Api";
import urls from "@/tools/Urls";

export const getRejectedReasonsAxiosRequest = function () {
  return api.get(urls.api("returned_order_rejected_reason"));
};

export const getReturnedOrderDetailAxiosRequest = function (id) {
  return api.get(urls.api("returned_order_detail", [id]));
};
export const deleteReturnedOrderDetailAxiosRequest = function (id) {
  return api.delete(urls.api("returned_order_detail", [id]));
};

export const postReturnedOrderDetailVerifySupportAxiosRequest = function (
  id,
  body
) {
  return api.post(urls.api("returned_order_detail", [id]), body);
};

export const postBackToCustomer = function (id, body) {
  return api.put(urls.api("returned_order_back_to_customer", [id]), body);
};
