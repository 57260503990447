<template>
  <ul class="list d-flex flex-column justify-content-between h-100">
    <!--Start Name/Gender   ------------------------------------------------------>
    <li>
      <div>
        {{ personalInfo.name.value }} {{ personalInfo.lastName.value }}
        <LICheckCircle class="mx-1 text-success" />
      </div>
    </li>
    <!--End   Name/Gender   ------------------------------------------------------>

    <!--Start Date of birth ------------------------------------------------------>
    <li>
      <div>
        <span class="fa-num-font"
          >{{ $_t(`components.users.user.profile.personal_info.dob`) }}:</span
        >
        <span v-if="personalInfo.birthday.value">
          {{ $_date(personalInfo.birthday.value.replaceAll("-", "/")) }}</span
        >
      </div>
    </li>
    <!--End   Date of birth ------------------------------------------------------>

    <!--Start National ID   ------------------------------------------------------>
    <li>
      <div>
        <span
          >{{
            $_t(`components.users.user.profile.personal_info.id_code`)
          }}:</span
        >
        <span>{{ personalInfo.nationalCode.value }}</span>
      </div>
    </li>
    <!--End   National ID   ------------------------------------------------------>
  </ul>
</template>

<script>
// import Chips from "@/components/app/Chips";
export default {
  name: "PersonalInfo",
  components: {
    // Chips,
  },
  props: {
    personalInfo: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped></style>
