import api from "@/tools/ApiService.service";
import urls from "@/tools/Urls";

/**
 *  logout user
 *  ------------------------
 *  Api address| api/v1/auth/logout
 *  ------------------------
 *  Method| Get
 *  ------------------------
 *  Description| call api to logout user
 *  ------------------------
 *  @param
 *  ------------------------
 *  @return  type {Promise}
 *  ------------------------
 **/
export const logoutUserAxiosRequest = function () {
  return api.get(urls.api("auth_logout"));
};
