import api from "@/tools/Api";
import urls from "@/tools/Urls";

/*
 *  add new address
 *  ------------------------
 *  Api address | /api/v1/
 *  ------------------------
 *  @param payload {Object}
 *  ------------------------
 *  method : POST
 *  ------------------------
 *  Description :
 *  ------------------------
 *  add new address
 *  ------------------------
 *  @return Promise
 *  ------------------------
 */

export const addNewAddressAxiosRequest = function (payload, userId) {
  return api.post(urls.api("create_addreses", [userId]), payload);
};
