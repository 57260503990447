<template>
  <b-card class="border-0" v-if="isLoading">
    <b-skeleton height="70px" />
    <b-skeleton height="500px" class="mt-5" />
    <b-skeleton height="150px" class="mt-5" />
  </b-card>
  <b-card v-else-if="hasError" class="border-0 text-center">
    <div>خطا در دریافت جزئیات مرجوعی</div>
    <b-button class="mt-4" variant="primary" @click="getReturnedOrder">
      تلاش مجدد
    </b-button>
  </b-card>
  <b-card v-else class="border-0 fa-num-font">
    <HeaderDetail />
    <State class="mt-4" />
    <div v-if="showCompleteBtn" class="my-5">
      <b-button
        :disabled="isComplete"
        class="text-white rounded px-4 py-2 border-0"
        @click="completeReturn"
        :class="isComplete ? 'bg-success' : 'bg-primary'"
      >
        <b-spinner
          v-if="skeletonLoader"
          small
          class="text-white ml-2"
        ></b-spinner>
        <span>
          {{ isComplete ? "بسته ‌شده" : "بستن مرجوعی" }}
        </span>
      </b-button>
    </div>

    <component v-if="comp" :is="comp" />
    <div v-else>
      <Items />
    </div>
  </b-card>
</template>

<script>
import HeaderDetail from "@/packages/orders/components/order-detail/returned-orders-detail/HeaderDetail";
import State from "@/packages/orders/components/order-detail/returned-orders-detail/State";
import Items from "@/packages/orders/components/order-detail/returned-orders-detail/cancelled-items/Index";
import { mapActions, mapGetters } from "vuex";
import OrderService from "../../../services/order.service";
const COMPONENTS = {
  RETURNED_BY_CUSTOMER: () =>
    import(
      "@/packages/orders/components/order-detail/returned-orders-detail/step1-2/Index"
    ),
  SUPPORT_CONFIRMED: () =>
    import(
      "@/packages/orders/components/order-detail/returned-orders-detail/step1-2/Index"
    ),
  QC_DELIVERED: () =>
    import(
      "@/packages/orders/components/order-detail/returned-orders-detail/step3/Index"
    ),
  QC_CONFIRMED: () =>
    import(
      "@/packages/orders/components/order-detail/returned-orders-detail/step4/Index"
    ),
};

export default {
  components: { State, HeaderDetail, Items },
  data() {
    return {
      Orders: new OrderService(),
      skeletonLoader: false,
    };
  },
  computed: {
    ...mapGetters({
      isLoading: "returned/isLoading",
      hasError: "returned/hasError",
      state: "returned/state",
      isComplete: "returned/isComplete",
    }),
    comp() {
      return COMPONENTS[this.state];
    },
    showCompleteBtn() {
      return (
        this.state !== "CANCEL_BY_SUPPORT" &&
        this.state !== "CANCEL_BY_CUSTOMER"
      );
    },
  },

  created() {
    this.getReturnedOrder();
  },
  methods: {
    ...mapActions({
      setStep2Editing: "returned/setStep2Editing",
      setReturnedOrderDetail: "returned/setReturnedOrderDetail",
    }),

    async completeReturn() {
      try {
        this.skeletonLoader = true;
        await this.Orders.returnCompletion(this.$route.params.id);
        await this.getReturnedOrder();
      } catch (e) {
        console.log(e);
      }
      this.skeletonLoader = false;
    },
    async getReturnedOrder() {
      await this.setReturnedOrderDetail(this.$route.params.id);
    },
  },
};
</script>

<style scoped></style>
