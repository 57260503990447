import { _t } from "@/tools/Utils";
export const USER_ORDER_RETURNED_TABEL_HEEADER = [
  {
    key: "row",
    label: _t("components.users.user.orders.orders_user_tabel.row"),
    sortable: false,
    class: "text-center",
  },
  {
    key: "code",
    label: _t("components.users.user.orders.orders_user_tabel.code"),
    sortable: false,
    class: "text-center",
  },
  {
    key: "returnDate",
    label: _t("components.users.user.orders.orders_user_tabel.returnDate"),
    sortable: false,
    class: "text-center",
  },

  {
    key: "returnCode",
    label: _t("components.users.user.orders.orders_user_tabel.returnCode"),
    sortable: false,
    class: "text-center",
  },
  {
    key: "price",
    label: _t("components.users.user.orders.orders_user_tabel.price"),
    sortable: false,
    class: "text-center",
  },
  {
    key: "returnStatus",
    label: _t("components.users.user.orders.orders_user_tabel.returnStatus"),
    sortable: false,
    class: "text-center",
  },
  {
    key: "actions",
    label: _t("components.users.user.orders.orders_user_tabel.actions"),
    sortable: false,
    class: "text-center",
  },
];
