import { resetPasswordAxiosRequest } from "@/packages/authentication/repository/api/reset_password.api";
import MessageService from "@/tools/Message";

/**
 * reset password
 * -----------------------
 * @param payload {Object}
 * @param token {String}
 * -----------------------
 * Description
 * -----------------------
 * Make api call to reset password
 * -----------------------
 * @return users {Array}
 * */
export const resetPasswordService = async function (payload, token) {
  try {
    const { data } = await resetPasswordAxiosRequest(payload, token);

    MessageService.success("CREATE_SUCCESS");
    return data;
  } catch (e) {
    //code 504
    MessageService.error("CREATE_FAILED");
    await Promise.reject(e);
  }
};
