import api from "@/tools/Api";
import urls from "@/tools/Urls";

/*
 *  fetch addreses
 *  ------------------------
 *  Api address | /api/v1/
 *  ------------------------
 *  @param userId {String}
 *  ------------------------
 *  method : Get
 *  ------------------------
 *  Description :
 *  ------------------------
 *  fetch addreses
 *  ------------------------
 *  @return Promise
 *  ------------------------
 */

export const fetchAddressesAxiosRequest = function (userId) {
  return api.get(urls.api("fetch_addreses", [userId]));
};
