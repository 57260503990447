<template>
  <b-row class="w-100">
    <div class="w-100"><hr /></div>
    <template v-for="item in innerList">
      <b-col
        class="text-right d-flex d-lg-block pt-4 pt-lg-0 px-0 px-lg-3 py-lg-4"
        :cols="item.col"
        :md="item.md"
        :lg="item.lg"
        :xl="item.xl"
        :key="item.index"
        v-if="item.show"
      >
        <div
          class="d-flex align-items-center fs-15 font-weight-bold text-secondary pb-2 pl-1 pl-lg-0"
        >
          {{ item.title }}:
        </div>
        <div
          v-if="item.key === 'price'"
          class="fs-17 font-weight-bold text-secondary pb-2 pt-lg-2"
        >
          {{ $_currency(Number(item.value)) }} ریال
        </div>
        <div v-else class="fs-17 font-weight-bold text-secondary pb-2 pt-lg-2">
          {{ item.value }}
        </div>
      </b-col>
    </template>
  </b-row>
</template>

<script>
export default {
  name: "PaymentDetailList",
  props: {
    list: {
      type: Object,
      required: true,
    },
    paymentType: {
      type: String,
      required: true,
    },
    paymentStatus: {
      type: String,
      required: true,
    },
    canceledBy: {
      type: String,
      default: null,
    },
    discount: {
      type: Number,
      default: null,
    },
    balance: {
      type: Number,
      required: true,
    },
  },
  computed: {
    innerList() {
      return [
        {
          title: "درگاه بانک",
          value: this.list.paymentGateway.persian,
          col: "12",
          md: "4",
          lg: "6",
          xl: "3",
          show: this.list.paymentType !== "inPlace",

          key: "paymentGateway",
        },
        {
          title: "کد مرجع تراکنش",
          value: this.list.paymentRefId,
          col: "12",
          md: "4",
          lg: "6",
          xl: "3",
          show:
            this.list.paymentGateway.english === "MELLI" &&
            this.paymentType === "online" &&
            this.paymentStatus === "success",
          key: "refId",
        },
        {
          title: "نام صاحب حساب",
          value: this.list.ownerName,
          col: "12",
          md: "4",
          lg: "6",
          xl: "3",
          show: this.paymentType === "receipt",
          key: "ownerName",
        },
        {
          title: "بانک مبدأ",
          value: this.list.sourceBank,
          col: "12",
          md: "4",
          lg: "6",
          xl: "3",
          show: this.paymentType === "receipt",
          key: "sourceBank",
        },
        {
          title: this.list.sourceAccount
            ? "شماره حساب مبدأ"
            : this.list.sourceCart
            ? "شماره کارت مبدأ"
            : "شماره شبا مبدأ",
          value:
            this.list.sourceAccount ||
            this.list.sourceCart ||
            this.list.sourceSheba,
          col: "12",
          md: "4",
          lg: "6",
          xl: "3",
          show: this.paymentType === "receipt",
          key: "sourceNumber",
        },
        {
          title: "کد پیگیری پرداخت",
          value: this.list.paymentTrackingCode,
          col: "12",
          md: "4",
          lg: "6",
          xl: "3",
          show: this.paymentType !== "inPlace",
          key: "paymentTrackingCode",
        },
        {
          title: "تاریخ و زمان پرداخت",
          value: this.list.paymentDate
            ? this.$_date(this.list.paymentDate, "time") +
              "   |   " +
              this.$_date(this.list.paymentDate, "jalali")
            : "",

          col: "12",
          md: "4",
          lg: "6",
          xl: "3",
          show: this.paymentType !== "inPlace",
          key: "paymentDate",
        },
        {
          title: "مبلغ پرداخت",
          value:
            (this.paymentType === "credit" || this.paymentType === "baloan") &&
            this.balance > 0
              ? this.balance
              : this.list.paymentPrice,
          col: "12",
          md: "4",
          lg: "6",
          xl: "3",
          show: this.paymentStatus !== "failure",
          key: "price",
        },
      ];
    },
  },
};
</script>

<style scoped></style>
