const PRINT_CONFIG = {
  name: "print",
  specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
  timeout: 1000, // default timeout before the print window appears
  autoClose: false, // if false, the window will not close after printing
  windowTitle: window.document.title, // override the window title
};
const PRINT_STYLES = {
  bootstrap1: "https://unpkg.com/bootstrap/dist/css/bootstrap.min.css",
  bootstrap2:
    "https://unpkg.com/bootstrap-vue@latest/dist/bootstrap-vue.min.css",
  script1: "https://unpkg.com/vue@latest/dist/vue.min.js",
  script2: "https://unpkg.com/bootstrap-vue@latest/dist/bootstrap-vue.min.js",
  VALID_ORDER: "printInvoice.css",
};

export const ORDER_INVOICE = {
  PRINT_CONFIG,
  styles: [
    { link: PRINT_STYLES.bootstrap1, type: "css" },
    { link: PRINT_STYLES.bootstrap2, type: "css" },
    { link: PRINT_STYLES.script1, type: "script" },
    { link: PRINT_STYLES.script2, type: "script" },
    {
      link: `${process.env.VUE_APP_IMAGE_BASE_URL}/${PRINT_STYLES.VALID_ORDER}`,
      type: "css",
    },
    // { link: `http://localhost:8080/${PRINT_STYLES.VALID_ORDER}`, type: "css" },
  ],
};
