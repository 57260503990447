<template>
  <b-row>
    <!--Start Table  --------------------------------------------------------------->
    <b-col cols="12" class="d-flex mt-3">
      <b-table
        tbody-class="fa-num-font"
        tbody-tr-class="align-middle fs-12 text-center fs-14 font-weight-bold text-right"
        thead-class="text-secondary fs-12 fw-lighter text-center fs-14 font-weight-bold text-right"
        responsive
        hover
        :items="items"
        :fields="fields"
        :busy.sync="is_loading"
        primary-key="1"
        show-empty
        :empty-text="$_t('table.default.empty_text')"
        :empty-filtered-text="$_t('table.default.empty_text')"
      >
        <!--Start row index        ------------------------------------>
        <template #cell(index)="data">
          {{ data.index + 1 + (currentPage - 1) * pageSize }}
        </template>
        <!--End   row index         ------------------------------------>

        <!--Start Loading Viewer    ------------------------------------>
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mx-1" small></b-spinner>
            <strong>{{ $_t("table.default.busy_text") }}</strong>
          </div>
        </template>
        <!--End   Loading Viewer    ------------------------------------>

        <!--Start createdAt         ------------------------------------>
        <template #cell(requestDate)="data">
          <span
            v-if="Boolean(data.value)"
            style="font-family: yekan-bakh-num-fa"
            >{{ $_date(data.value) }}</span
          >
        </template>
        <!--End   createdAt         ------------------------------------>

        <!--Start createdAt         ------------------------------------>
        <template #cell(operation)="data">
          <span
            v-if="Boolean(data.value)"
            style="font-family: yekan-bakh-num-fa"
            >{{ operations[data.value] }}</span
          >
        </template>
        <!--End   createdAt         ------------------------------------>

        <!--Start Empty states      ------------------------------------>
        <template #empty="scope">
          <p class="text-center">{{ scope.emptyText }}</p>
        </template>

        <template #emptyfiltered="scope">
          <p class="text-center">{{ scope.emptyFilteredText }}</p>
        </template>
        <!--End   Empty states      ------------------------------------>
      </b-table>
    </b-col>
    <!--End   Table  --------------------------------------------------------------->
  </b-row>
</template>

<script>
import { mapGetters } from "vuex";
import filter from "@/mixins/mixin.filter-sort";
import { ACTIVITIES_TABLE_HEADER } from "@/packages/manage/schema/activitiesTable.schema";
import { fetchFilteredActivitiesService } from "@/packages/manage/services/activities.service";

export default {
  name: "Activities",
  mixins: [filter],
  data() {
    return {
      sortBy: "variantCreatedAt",
      sortDesc: false,

      /* Table Headers */
      fields: [...ACTIVITIES_TABLE_HEADER],
      operations: {
        POST: "ایجاد",
        PUT: "به روز رسانی",
        DELETE: "حذف",
        GET: "دریافت",
        PATCH: "به روز رسانی",
      },
    };
  },
  computed: {
    ...mapGetters({
      is_loading: "loading/is_loading",

      /* Table Items */
      items: "activities/filtered_activities",

      currentPage: "pagination/current_page",

      pageSize: "pagination/size",
    }),
  },
  methods: {
    async initialize() {
      let paginationFilter = await this.paginationQueryBuilder();
      await fetchFilteredActivitiesService(paginationFilter);
    },
  },
  async mounted() {
    // await this.initialize();
  },
};
</script>

<style scoped></style>
