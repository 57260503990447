<template>
  <div class="text-right">
    <img
      v-if="bank && bank.imageUrl"
      :src="require(`@/assets/img/bank-logo/${bank.imageUrl}`)"
      :alt="bank ? bank.name : 'نامشخص'"
      width="50"
      height="50"
    />
    <strong>{{ bank ? bank.name : "" }}</strong>
  </div>
</template>

<script>
import { IRANIAN_BANKS } from "@/configs/IRANIAN_BANKS";

export default {
  name: "BankNameBasedOnBankCardNumber",
  props: {
    cartNumber: {
      type: [String, Number],
    },
  },
  data() {
    return {
      bank: null,
    };
  },
  watch: {
    cartNumber: {
      handler: function (value) {
        this.bank = IRANIAN_BANKS[value.substring(0, 6)];
      },
      immediate: true,
    },
  },
};
</script>

<style scoped></style>
