import api from "@/tools/Api";
import urls from "@/tools/Urls";

/*
 *  fetch Orders by orderNumber | nationalCde | phoneNumber | state | date
 *  ------------------------
 *  Api address | /api/v1/comments
 *  ------------------------
 *  @param query_params { Object }
 *  ------------------------
 *  method : Get
 *  ------------------------
 *  Description :
 *  ------------------------
 *  fetch Orders by orderNumber | nationalCde | phoneNumber | state | date
 *  ------------------------
 *  @return Promise
 *  ------------------------
 */

export const fetchAllOrdersAxiosRequest = function (
  query_params = null,
  cancelToken
) {
  return api.get(urls.api("fetch_all_orders"), {
    params: {
      ...query_params,
    },
    ...cancelToken,
  });
};
