<template>
  <div class="p-2 p-md-5 d-flex flex-column">
    <div class="d-flex justify-content-end">
      <div
        @click="showFilter = !showFilter"
        class="filter-list-btn btn btn-primary text-white px-4 fs-12"
      >
        فیلتر
      </div>
    </div>
    <transition name="fade">
      <div v-if="showFilter" class="d-flex flex-column">
        <div class="filter-list-container border mt-3 mx-0 row px-4">
          <LipakForm>
            <template slot="body">
              <LipakFormGenerator :schema="filterSchema" :validator="$v.form" />
            </template>
          </LipakForm>
          <div class="mt-3 mr-auto">
            <div
              @click="showFilter = false"
              class="btn px-5 border border-secondary ml-2"
            >
              بستن
            </div>
            <div @click="search" class="btn px-4 btn-primary text-white">
              جستجو و فیلتر
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import LipakForm from "@/components/app/form/LipakForm";
import LipakFormGenerator from "@/components/app/form/LipakFormInput";
import filter from "@/mixins/mixin.filter-sort";
import cloneDeep from "lodash.clonedeep";
import { FILTER_CONTACT_US_SCHEMA } from "@/packages/contact-us/schema/forms/list.filter";
import { mapActions } from "vuex";

export default {
  components: { LipakForm, LipakFormGenerator },
  mixins: [filter],
  data() {
    return {
      showFilter: false,
      filterSchema: cloneDeep(FILTER_CONTACT_US_SCHEMA.schema),
      form: cloneDeep(FILTER_CONTACT_US_SCHEMA.model),
    };
  },
  validations: {
    form: cloneDeep(FILTER_CONTACT_US_SCHEMA.validations),
  },
  methods: {
    ...mapActions({
      getContactUs: "contactUs/filter/searchByFilters",
      resetPagination: "pagination/reset",
    }),
    async search() {
      await this.resetPagination();
      const paginationParams = await this.paginationQueryBuilder();
      const query_params = this.filterEmptyItems(this.form);
      this.$emit("input", cloneDeep(query_params));
      Object.assign(query_params, paginationParams);
      await this.getContactUs({
        filters: { ...query_params },
        cancelPrevApi: true,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-list {
  &-container {
    padding: 16px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  }
  &-btn {
    width: fit-content;
    align-self: flex-end;
  }
}
</style>
