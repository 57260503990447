<template>
  <b-container fluid>
    <!--Start Page breadcrumb    ------------------------------------------------>
    <Breadcrumb :items="$route.matched"></Breadcrumb>
    <!--End   Page breadcrumb    ------------------------------------------------>

    <transition name="fade" mode="out-in">
      <router-view :key="$route.path" />
    </transition>
  </b-container>
</template>
<script>
import Breadcrumb from "@/components/app/Breadcrumb";
export default {
  name: "Index",
  components: {
    Breadcrumb,
  },
};
</script>
