const { required, sameAs, minLength } = require("vuelidate/lib/validators");
import { strongPassword } from "@/plugins/vuelidate-custom-validators";

export const SCHEMA = {
  /* Form SCHEMA */
  schema: [
    {
      id: "password",
      label: "password",
      placeholder: "password",
      class: "white-label",
      cols: 12,
      lg: 12,
      md: 12,
      sm: 12,
      type: "password",
    },
    {
      id: "confirm",
      label: "confirm",
      placeholder: "confirm",
      class: "white-label",
      cols: 12,
      lg: 12,
      md: 12,
      sm: 12,
      type: "password",
    },
  ],

  /* Form MODEL */
  model: {
    password: null,
    confirm: null,
  },

  /* VALIDATION SCHEMA */
  validations: {
    form: {
      password: { required, minLength: minLength(6), strongPassword },
      confirm: { required, sameAsPassword: sameAs("password") },
    },
  },
};
