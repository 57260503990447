<template>
  <span
    class="px-3 mt-1 mb-1 rounded text-center d-inline-block text-nowrap"
    :style="`width: ${width} !important`"
    :class="[`bg-${variant}-20 text-${variant} ${classes}`]"
  >
    <slot />
  </span>
</template>

<script>
export default {
  name: "Chips",
  props: {
    variant: {
      type: String,
      default: "primary",
    },
    width: {
      type: [Number, String],
      default: "auto",
    },
    classes: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped></style>
