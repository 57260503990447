module.exports = {
  components: {
    panel_change_password: {
      buttons: {
        submit: "تایید",
      },
    },
  },
  views: {
    change_password: {
      title: "تغییر رمز عبور",
    },
  },
  route_titles: {
    change_password: {
      title: "تغییر رمز عبور",
    },
  },
  attribute: {
    password: "رمز عبور",
    confirm: "تکرار رمز عبور",
  },
  placeholder: {
    password: "رمز عبور خود را وارد کنید.",
    confirm: "تکرار رمز عبور خود را وارد کنید.",
  },
};
