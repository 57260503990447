import api from "@/tools/Api";
import urls from "@/tools/Urls";

/*
 *  fetch Sending times
 *  ------------------------
 *  Api address | /api/v1/
 *  ------------------------
 *  @param queryParams { String }
 *  ------------------------
 *  method : Get
 *  ------------------------
 *  Description :
 *  ------------------------
 *  fetch fetch Sending times
 *  ------------------------
 *  @return Promise
 *  ------------------------
 */

export const fetchSendingTimesAxiosRequest = function (queryParams) {
  return api.get(
    urls.api("fetch_sending_times_by_shipping_methods_id", [
      queryParams.settingId,
    ]),
    {
      params: { ...queryParams },
    }
  );
};
