<template>
  <b-card class="border-0 shadow text-right">
    <div class="lipak-input-title">توضیح پشتیبانی</div>
    <b-textarea
      :disabled="true"
      v-model="innerDescription"
      placeholder="بدون توضیح"
      rows="4"
    ></b-textarea>
  </b-card>
</template>

<script>
export default {
  props: ["description"],
  data() {
    return {
      innerDescription: this.description || "",
    };
  },
  watch: {
    innerDescription(newValue) {
      this.setDescription(newValue);
    },
  },
};
</script>

<style scoped></style>
