<template>
  <b-container fluid class="fa-num-font">
    <SubLinks />
    <Breadcrumb :items="$route.matched" />
    <router-view :key="$route.path" />
  </b-container>
</template>

<script>
import SubLinks from "@/packages/manage/components/SubLinks";
import Breadcrumb from "@/components/app/Breadcrumb";
export default {
  components: { Breadcrumb, SubLinks },
};
</script>
