<template>
  <div>
    <div class="d-flex justify-content-center">
      <b-spinner v-if="skeletonLoader" variant="primary"></b-spinner>
    </div>

    <div v-if="hasError" class="w-100 text-center">
      <span class="text-primary fs-14">خطایی رخ داده است. </span><br />
      <b-button class="mt-2" variant="outline-primary" @click="initAddresses">
        درخواست مجدد</b-button
      >
    </div>

    <b-form-group v-else class="text-right pt-4 font-weight-bold">
      <b-form-radio-group stacked v-model="address" class="d-block">
        <template v-for="option in addresses.customerAddressDtoList">
          <b-col cols="12" :key="option.title">
            <div class="d-flex align-content-start fs-15 py-1">
              <b-form-radio
                :value="{
                  cityId: option.cityId,
                  addressId: option.addressId,
                }"
                class="ml-3 mr-0"
              />
              {{ option.address }}
            </div>
          </b-col>
        </template>
      </b-form-radio-group>
    </b-form-group>
  </div>
</template>

<script>
import OrderService from "@/packages/orders/services/order.service";
import { mapActions, mapGetters } from "vuex";
import cloneDeep from "clone-deep";
export default {
  name: "Addresses",
  data() {
    return {
      OrderService: new OrderService(),
      address: {},
      skeletonLoader: true,
      hasError: false,
    };
  },
  props: {
    currentAddress: {
      type: Object,
      required: true,
    },
    orderId: {
      type: String,
      required: true,
    },
  },
  async mounted() {
    await this.initAddresses();
  },
  watch: {
    address(newValue) {
      this.$emit("input", newValue);
    },
    currentAddress(newValue) {
      this.address = cloneDeep(newValue);
    },
  },
  computed: {
    ...mapGetters({
      addresses: "order/addressList",
    }),
  },
  methods: {
    ...mapActions({
      setNewAddress: "order/setAddressList",
      setCurrentShippingMethodInfo: "order/setCurrentShippingMethodInfo",
    }),
    async initAddresses() {
      try {
        this.skeletonLoader = true;
        this.hasError = false;

        const response = await this.OrderService.fetchAddresses(this.orderId);
        await this.setNewAddress(response);
        this.address = {
          cityId: this.currentAddress.cityId,
          addressId: this.currentAddress.addressId,
        };
        let data = {
          shippingShift: response.shiftSetting
            ? response.shiftSetting.assignmentId
            : null,
          shippingMethod: {
            id: response.shippingMethodV2.id,
            title: response.shippingMethodV2.originalName,
            englishTitle: response.shippingMethodV2.displayName,
            cost: response.shippingMethodV2.defaultPrice,
            active: response.shippingMethodV2.active,
            deleted: false,
            createdDate: "",
            updatedDate: "",
          },
        };
        await this.setCurrentShippingMethodInfo(data);
      } catch (e) {
        this.hasError = true;
      }
      this.skeletonLoader = false;
    },
  },
};
</script>

<style scoped></style>
