<template>
  <form-wrapper :validator="validator.form" class="w-100">
    <slot v-if="$slots['form-body']" name="form-body" />
    <b-row v-else>
      <!--Start form inputs  ---------------------->
      <b-col
        v-for="input in schema"
        :key="'key-' + input.id"
        v-bind="$attrs"
        :cols="input.cols || 12"
        :lg="input.lg || $attrs.lg"
        :md="input.md || 6"
      >
        <form-group :name="input.id" :attribute="$_t(`attribute.${input.id}`)">
          <LipakCurrencyInput
            slot-scope="{ attrs, listeners }"
            v-bind="attrs"
            v-on="listeners"
            v-if="input.type.toLowerCase() === 'currency'"
            v-model="validator.form[input.id].$model"
            :place-holder="input.placeholder"
            :title="input.title"
            :current-price="validator.form[input.id].$model"
            :max-price="input.maxPrice || 0"
          ></LipakCurrencyInput>
          <LipakInput
            v-else
            class="p-1 w-100"
            slot-scope="{ attrs, listeners }"
            v-bind="attrs"
            v-on="listeners"
            :id="input.id"
            :date-type="input.type2"
            :type="input.type"
            :title="$_t(`attribute.${input.id}`)"
            :rows="
              input.type === 'textarea'
                ? validator.form[input.id].$model.split(/\r*\n/).length
                : input.rows
            "
            :placeholder="
              Boolean(input.placeholder)
                ? $_t(`placeholder.${input.placeholder}`)
                : $_t(`placeholder.${input.id}`)
            "
            :options="input.options || null"
            v-model="validator.form[input.id].$model"
            :show-message="false"
            :readonly="input.disabled"
            :disabled="input.disabled"
            :default-option-disabled="input.defaultOptionDisabled"
            :required="input.required || false"
          />
        </form-group>
      </b-col>
      <!--End   form inputs  ---------------------->
      <slot name="inline-action" />
    </b-row>
  </form-wrapper>
</template>

<script>
import FormGroup from "@/components/app/form/FormGroup";
import { templates } from "vuelidate-error-extractor";
import LipakCurrencyInput from "@/components/app/form/LipakCurrencyInput";

export default {
  name: "LipackFormGenerator",
  props: {
    schema: {
      type: Array,
    },
    validator: {
      type: Object,
    },
  },
  components: {
    LipakCurrencyInput,
    FormGroup,
    FormWrapper: templates.FormWrapper,
  },
};
</script>

<style>
.invalid-feedback {
  text-align: right;
}
textarea.lipak-input-main {
  min-height: 130px;
  height: auto;
}
</style>
