// initial state
const state = () => ({
  comments: [],
  comment: null,
});

// getters
const getters = {
  comments: (state) => state.comments,
  comment: (state) => state.comment,
};

// mutations
const mutations = {
  mutateComments(state, value) {
    value.forEach((item) => {
      switch (item.productCommentStatus) {
        case "waiting":
          item.productCommentStatus = {
            name: item.productCommentStatus,
            persianName: "در انتظار",
            color: "text-warning bg-warning-light",
          };
          return;
        case "accepted":
          item.productCommentStatus = {
            name: item.productCommentStatus,
            persianName: "تاییدشده",
            color: "text-success bg-light-success",
          };
          return;
        case "rejected":
          item.productCommentStatus = {
            name: item.productCommentStatus,
            persianName: "ردشده",
            color: "text-danger bg-light-danger",
          };
          return;
        case "editedAndAccepted":
          item.productCommentStatus = {
            name: item.productCommentStatus,
            persianName: "ویرایش و تاییدشده",
            color: "text-success bg-light-success",
          };
          return;
      }
    });
    state.comments = value;
  },
  mutateComment(state, value) {
    state.comment = value;
  },
};

// actions
const actions = {
  setComments({ commit }, data) {
    commit("mutateComments", data);
  },

  setComment({ commit }, data) {
    commit("mutateComment", data);
  },
};

export default {
  namespaced: true,
  name: "comments",
  state,
  getters,
  actions,
  mutations,
};
