import api from "@/tools/Api";
import urls from "@/tools/Urls";

/*
 *  cancel order
 *  ------------------------
 *  Api address | /api/v1/
 *  ------------------------
 *  @param
 *  ------------------------
 *  method : Get
 *  ------------------------
 *  Description :
 *  ------------------------
 *  cancel order
 *  ------------------------
 *  @return Promise
 *  ------------------------
 */

export const cancelOrderAxiosRequest = function (order_id, payload) {
  return api.put(urls.api("cencele_order", [order_id], "v2/"), payload);
};
