export default {
  /* users Routes********************/
  fetch_users: "customer-manage",
  fetch_user_personal_info: "customer-manage/%s",
  update_bank_account_detail: "customer-manage/bank/%s",
  update_base_info_user: "customer-manage/detail/%s",
  update_legal_user: "customer-manage/legal/%s",
  update_address_user: "customer-manage/address/%s",
  fetch_provinc: "contents/provinces",
  fetch_city: "contents/provinces/%s",

  /* orders Routes *****************/
  fetch_orders: "order/support/customer/%s/%s",
  fetch_states: "order/support/customer/%s/%s",
  fetch_order_user_detail: "order/support/order-details/%s",

  payment_by_remittance: "customer-manage/invoice-pay/%s",

  create_addreses: "order/support/customer/%s/addresses/createAddress",
  create_new_legal: "support/legal/%s",
};
