<template>
  <!---------------start filter receipts-detail-received-------------------------------->
  <b-row class="d-flex justify-content-center">
    <!-------start tabs in orders ---------------------------------------------->
    <b-col cols="12" class="order-tabs p-0">
      <b-card no-body class="border-0 p-0 m-0">
        <b-tabs v-model="tabIndex" pills class="p-0">
          <b-tab
            v-for="tab in tabs"
            :key="tab.index"
            :title="tab.statePersianName"
            title-link-class="text-secondary bg-white fs-14"
            @click="changeStatus(tab.stateName)"
          >
            <b-card
              class="bg-light border-0 rounded-lg"
              style="min-height: 150px"
            >
              <b-card-text>
                <div
                  class="d-flex justify-content-center mt-5"
                  v-if="skeletonLoader"
                >
                  <b-spinner variant="primary"></b-spinner>
                </div>

                <div
                  v-else-if="hasError"
                  class="d-flex justify-content-center mt-5"
                >
                  <b-button
                    @click="changeStatus(tab.stateName)"
                    variant="primary"
                    >درخواست مجدد !</b-button
                  >
                </div>
                <CartBasket
                  v-else
                  :subStateFlow="subStateFlow"
                  :state="tab.statePersianName" /></b-card-text></b-card
          ></b-tab>
        </b-tabs>
      </b-card>
    </b-col>

    <!-------end tabs in orders ------------------------------------------------>
  </b-row>
</template>

<script>
import CartBasket from "./CartBasket";
import OrderService from "@/packages/orders/services/order.service";

export default {
  name: "OrderConditionTab",
  props: {
    stateFlow: {
      type: Object,
      required: true,
    },
  },

  components: {
    CartBasket,
  },

  data() {
    return {
      OrderService: new OrderService(),
      tabIndex: 0,
      tabs: [
        { stateName: "INPAYMENT", statePersianName: "در انتظار پرداخت" },
        { stateName: "INPROCESS", statePersianName: "در انتظار پردازش" },
        {
          stateName: "LOGISTIC",
          statePersianName: "پایان پردازش و تحویل ارسال",
        },
        { stateName: "UNDELIVERED", statePersianName: "تحویل ناموفق" },
        { stateName: "CANCELED", statePersianName: "لغو شده" },
        { stateName: "RETURNED", statePersianName: "مرجوع شده" },
        { stateName: "DELIVERED", statePersianName: "موفق" },
      ],
      stateChanged: {},
      subStateFlow: [],
      skeletonLoader: false,
      hasError: false,
    };
  },

  watch: {
    "stateFlow.state.first"() {
      this.findCurrentState();
    },
  },
  methods: {
    findCurrentState() {
      this.tabIndex = this.tabs.findIndex(
        (item) => item.stateName === this.stateFlow.state.first
      );
      this.subStateFlow = this.stateFlow.subStateFlow;
    },
    async changeStatus(stateName) {
      try {
        this.skeletonLoader = true;
        this.hasError = false;
        this.subStateFlow = [];
        let response = await this.OrderService.fetchStateDetail(
          { state: stateName },
          this.$route.params.id
        );
        this.subStateFlow = response.subStateFlow;
        this.skeletonLoader = false;
      } catch (e) {
        this.skeletonLoader = false;
        this.hasError = true;
      }
    },
  },

  created() {
    this.findCurrentState();
  },
};
</script>

<style scoped>
.steps > li {
  list-style: none;
  /*flex-basis: 14%;*/
}
</style>
<style>
.order-tabs .nav {
  padding: 0 !important;
  justify-content: space-between !important;
}
.order-tabs .nav-link {
  padding: 20px 15px;
}
.order-tabs .nav-link.active {
  color: #fe6a34 !important;
  font-weight: bold;
  background-color: #f8f9fa !important;
}
</style>
