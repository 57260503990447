import api from "@/tools/Api";
import urls from "@/tools/Urls";

/*
 * get list brands
 *--------------------------
 * Api address / api/v1/contents/brands
 *--------------------------
 *--------------------------
 * methods : GET
 * -------------------------
 * description :
 * -------------------------
 * get list brands
 *--------------------------
 * @return promise
 *--------------------------
 */
export const fetchBrandsAxiosReq = function () {
  return api.get(urls.api("get_all_brands"));
};
