export const SEARCH_FORM_MOBILE_NUMBER = {
  /*SCHEMA */
  schema: [
    {
      id: "mobileNum",
      type: "text",
      placeholder: "mobileNum",
      required: false,
      cols: "12",
      lg: "12",
      md: "12",
    },
  ],

  /* VALIDATION SCHEMA */
  validations: {
    form: {
      mobileNum: {},
    },
  },

  /*MODEL SCHEMA*/
  model: {
    mobileNum: "",
  },
};
