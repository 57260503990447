<template>
  <b-row>
    <b-col cols="12" lg="3" class="mt-sm-3 mt-md-3 mt-3 mt-lg-0 mt-xl-0">
      <UserManagementSideNavigation />
    </b-col>

    <b-col cols="12" lg="9" class="mt-sm-3 mt-md-3 mt-3 mt-lg-0 mt-xl-0">
      <Content />
    </b-col>
  </b-row>
</template>

<script>
import Content from "@/packages/users/components/user/container/Content";
import UserManagementSideNavigation from "@/packages/users/components/user/container/UserManagementSideNavigation";

export default {
  name: "UserManagement",
  components: { UserManagementSideNavigation, Content },
};
</script>

<style scoped></style>
