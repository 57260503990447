import api from "@/tools/ApiService.service";
import urls from "@/tools/Urls";

/**
 *  login
 *  ------------------------
 *  Api address| /auth/init
 *  ------------------------
 *  Method| GET
 *  ------------------------
 *  Description| login
 *  ------------------------
 *  @param payload {Object}
 *  ------------------------
 *  @return  type {Promise}
 *  ------------------------
 **/
export const loginAxiosRequest = function (payload) {
  return api.get(urls.api("auth_login"), {
    headers: {
      panel: process.env.VUE_APP_PANEL_SECRET || "support",
      email: payload.username,
      password: payload.password,
    },
  });
};
