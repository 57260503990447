<template>
  <div class="w-100 mt-5">
    <b-row no-gutters class="depotFilter d-flex justify-content-center mt-5">
      <!-----------------------start button of show filter------------------------------>
      <b-col cols="12" class="d-block justify-content-end p-0">
        <b-button
          @click="isShowFilters = true"
          class="bg-primary text-white border-0 fs-13 px-4"
          size="md"
        >
          <LIWifiFlat class="ml-2 text-white" />
          {{
            $_t(
              "components.orders.total_orders.total_order_filter.filter_button"
            )
          }}
        </b-button>
      </b-col>
      <!-----------------------end button of show filter-------------------------------->

      <!------------------------start filter inputs of receipts-detail-received --------------->
      <b-row
        v-if="isShowFilters"
        class="filterBox mt-3 d-block justify-content-center w-100 pa-0 ma-0"
      >
        <b-col cols="12"
          ><LipakForm>
            <!--Start form body    ------------------------------------------------------------------>
            <template v-slot:body>
              <!--Start form wrapper for catch errors  ------------------------>
              <LipackFormGenerator
                :schema="schema"
                :validator="$v"
              ></LipackFormGenerator>
              <!--End   form wrapper for catch errors  ------------------------>
            </template>
            <!--End   form body    ------------------------------------------------------------------>
          </LipakForm></b-col
        >

        <!-----------start button search and filter ------------------------------------>
        <b-row class="d-flex flex-row justify-content-end mt-5">
          <b-col cols="12" md="4" lg="1">
            <b-button
              class="bg-light text-dark w-100"
              size="sm"
              @click="isShowFilters = false"
            >
              {{
                $_t(
                  "components.orders.total_orders.total_order_filter.close_button"
                )
              }}
            </b-button>
          </b-col>

          <b-col cols="12" md="4" lg="1">
            <b-button
              class="bg-primary text-white w-100 border-0"
              size="sm"
              @click="searchOrder"
              >{{
                $_t(
                  "components.orders.total_orders.total_order_filter.search_button"
                )
              }}
            </b-button>
          </b-col>
        </b-row>
        <!-----------end button search and filter -------------------------------------->
      </b-row>
      <!------------------------end filter inputs of receipts-detail-received ----------------->
    </b-row>
  </div>
</template>

<script>
import { CANCELED_ORDERS_FILTER } from "@/packages/orders/schema/filter/CANCELED_ORDERS_FILTER";
import LipackFormGenerator from "@/components/app/form/LipackFormGenerator";
import LipakForm from "@/components/app/form/LipakForm";
import OrderService from "@/packages/orders/services/order.service";
import filterContainer from "@/mixins/filter-container";

// import filter from "@/mixins/mixin.filter-sort";
export default {
  name: "ReceiptsReceivedFilter",
  mixins: [filterContainer],
  data: () => {
    return {
      schema: CANCELED_ORDERS_FILTER.schema,
      isShowFilters: false,
      form: CANCELED_ORDERS_FILTER.model,
      Order: new OrderService(),
      filterName: "filter2",
    };
  },
  validations: {
    ...CANCELED_ORDERS_FILTER.validations,
  },
  mounted() {
    this.fetchShippingMethods();
  },
  components: {
    LipakForm,
    LipackFormGenerator,
  },

  methods: {
    async fetchShippingMethods() {
      try {
        this.shippingSetting = (await this.Order.fetchShippingMethodList()).map(
          (element) => {
            return {
              title: element.displayName,
              value: element.id,
            };
          }
        );
        let index = this.schema.findIndex(
          (element) => element.id === "shippingSettingId"
        );
        this.schema[index].options = [...this.shippingSetting];
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style lang="scss">
.depotFilter {
  .filterButton {
    border: none;
    background: #fe6a34;
    box-shadow: 0 3px 16px -4px rgba(0, 0, 0, 0.13);
    border-radius: 4px;
  }
  .closeFilterButton {
    border: 1px solid #c3c3c3 !important;
    color: #c3c3c3;
  }
  .filterBox {
    border: 1px solid #f2f2f2;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
    padding: 12px 26px;
  }
}
.row {
  --bs-gutter-x: 0 !important;
}
</style>
