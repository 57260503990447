<template>
  <LipakModal
    @close="closeModal"
    :modal="modal"
    title="ویرایش آدرس و زمان ارسال"
    size="xl"
  >
    <template v-slot:body>
      <b-col cols="12" class="mx-auto justify-content-between px-0 fa-num-font">
        <!--------------------start modal for create new address -------------->
        <NewAddress :user-id="userId" :order-id="orderId" />
        <!--------------------start modal for create new address -------------->

        <!--------------------start address list ------------------------------>
        <Addresses
          v-model="address"
          :order-id="orderId"
          :current-address="currentAddress"
        />
        <!--------------------end address list -------------------------------->

        <!--------------------start list shipping methods --------------------->
        <div v-if="address">
          <div
            v-if="shippingMethodSkeletonLoader"
            class="d-flex justify-content-center"
          >
            <b-spinner variant="primary"></b-spinner>
          </div>
          <div v-if="shippingMethodHasError" class="w-100">
            <span class="text-primary fs-14">خطایی رخ داده است. </span><br />
            <b-button
              class="btn-outline text-primary"
              variant="outline"
              @click="fetchShippingMethods"
            >
              درخواست مجدد</b-button
            >
          </div>

          <ShippingMethods
            v-else
            v-model="shippingMethod"
            :shipping-methods="shippingMethods"
          />
        </div>
        <!--------------------end shipping methods list------------------------>

        <!--------------------start sendingTimes list ------------------------->
        <div v-if="shippingMethod && shippingMethod.shippingMethodCityId">
          <div
            v-if="shippingTimeSkeletonLoader"
            class="d-flex justify-content-center"
          >
            <b-spinner variant="primary"></b-spinner>
          </div>
          <div v-if="shippingTimeHasError" class="w-100">
            <span class="text-primary fs-14">خطایی رخ داده است. </span><br />
            <b-button
              class="btn-outline text-primary"
              variant="outline"
              @click="fetchSippingTimes"
            >
              درخواست مجدد</b-button
            >
          </div>
          <ShippingTimes
            v-else
            :current-address="currentAddress"
            v-model="shippingTime"
            :shipping-times="shippingTimes"
          />
        </div>
        <!--------------------start sendingTimes list ------------------------->

        <!--------------------start shift list -------------------------------->
        <div
          v-if="
            shippingMethod &&
            shippingMethod.shippingMethodCityId &&
            shippingTime
          "
        >
          <div
            v-if="shippingShiftSkeletonLoader"
            class="d-flex justify-content-center"
          >
            <b-spinner variant="primary"></b-spinner>
          </div>
          <div v-if="shippingShiftHasError" class="w-100">
            <span class="text-primary fs-14">خطایی رخ داده است. </span><br />
            <b-button
              class="btn-outline text-primary"
              variant="outline"
              @click="fetchShippingShiftsFromTimes"
            >
              درخواست مجدد</b-button
            >
          </div>

          <ShippingShift
            v-else
            v-model="shippingShift"
            :shipping-shifts="shippingShifts"
          />
        </div>
        <!--------------------start shift list -------------------------------->
      </b-col>
    </template>

    <template v-slot:footer>
      <b-row class="w-100 flex-row-reverse mt-3">
        <b-col cols="12">
          <b-button class="w-25 text-white ml-2" size="sm" @click="closeModal">
            بستن
          </b-button>
          <b-button
            class="w-25 text-white"
            variant="success"
            size="sm"
            @click="submit"
          >
            ثبت
          </b-button>
        </b-col>
      </b-row>
    </template>
  </LipakModal>
</template>

<script>
import LipakModal from "@/components/app/Modal/LipakModal";
import OrderService from "@/packages/orders/services/order.service";
import NewAddress from "./NewAddress";
import Addresses from "./Addresses";
import ShippingMethods from "./ShippingMethods";
import ShippingTimes from "./ShippingTimes";
import ShippingShift from "./ShippingShift";
import message from "@/tools/Message";
import { mapActions, mapGetters } from "vuex";
import { postBackToCustomer } from "@/packages/orders/api/returned-order-detail.api";
export default {
  name: "EditAddress",
  data: function () {
    return {
      OrderService: new OrderService(),
      address: {},
      shippingMethod: "",
      shippingMethods: [],
      shippingTime: "",
      shippingTimes: [],
      shippingShift: null,
      shippingShifts: [],

      shippingMethodSkeletonLoader: false,
      shippingMethodHasError: false,

      shippingTimeSkeletonLoader: false,
      shippingTimeHasError: false,

      shippingShiftSkeletonLoader: false,
      shippingShiftHasError: false,
    };
  },
  props: {
    modal: {
      type: Boolean,
      default: false,
    },
    userId: { type: String, required: true },
    orderId: { type: String, required: true },
    currentAddress: { type: Object, required: true },
    items: { type: Array, required: true },
  },
  computed: {
    selectaddressDefult() {
      return this.address.addressId === this.currentAddress.addressId;
    },
    ...mapGetters({
      addresses: "order/addressList",
    }),
  },
  watch: {
    modal(val) {
      if (!val) {
        this.shippingMethod = "";
        this.shippingTime = "";
        this.shippingShift = "";
      }
    },
    address: async function (newValue) {
      this.shippingMethod = "";
      this.shippingTime = "";
      this.shippingShift = "";
      //fetch shippingMethods by addressId
      if (newValue.addressId) await this.fetchShippingMethods();
    },
    shippingMethod: {
      handler(val) {
        this.shippingTime = "";
        this.shippingShift = "";
        if (val && val.shippingMethodCityId) {
          this.fetchSippingTimes();
        }
      },
      deep: true,
    },
    shippingTime() {
      this.fetchShippingShiftsFromTimes();
    },
  },
  methods: {
    ...mapActions({
      setReturnedOrderDetail: "returned/setReturnedOrderDetail",
    }),
    async fetchShippingMethods() {
      try {
        this.shippingMethods = [];
        this.shippingMethodSkeletonLoader = true;
        this.shippingMethodHasError = false;
        this.shippingMethods = await this.OrderService.fetchShippingMethodsForEditAddress(
          this.address.addressId
        );
        if (this.selectaddressDefult) {
          this.shippingMethod = this.addresses.shippingMethodV2.id;
        }
        // else {
        //   this.shippingMethod = "";
        //   this.shippingTime = "";
        //   this.shippingShift = "";
        // }
        this.shippingMethodSkeletonLoader = false;
      } catch (e) {
        this.shippingMethodSkeletonLoader = false;
        this.shippingMethodHasError = true;
      }
    },

    async fetchSippingTimes() {
      try {
        this.shippingTimes = [];
        this.shippingTimeHasError = false;
        this.shippingTimeSkeletonLoader = true;
        this.shippingTimes = await this.OrderService.fetchSendingTimes({
          settingId: this.shippingMethod.shippingMethodCityId,
          addressId: this.address.addressId,
        });

        let exitShippingTimes = this.shippingTimes.find((item) => {
          let exitShippingTime =
            this.addresses.shiftSetting !== null
              ? this.addresses.shiftSetting.shiftDayId
              : null;
          return item.dayId === exitShippingTime;
        });
        if (this.selectaddressDefult && exitShippingTimes) {
          this.shippingTime = this.addresses.shiftSetting.shiftDayId;
        }
        this.shippingTimeSkeletonLoader = false;
      } catch (e) {
        this.shippingTimeSkeletonLoader = false;
        this.shippingTimeHasError = true;
      }
    },

    fetchShippingShiftsFromTimes() {
      this.shippingShifts = this.shippingTimes.find((item) => {
        return item.dayId === this.shippingTime;
      });
      if (this.shippingTime === this.addresses.shiftSetting?.shiftDayId) {
        this.shippingShift = this.addresses.shiftSetting?.shiftId;
      } else {
        this.shippingShift = "";
      }
    },

    formValidate() {
      let m = "";
      if (
        !this.shippingMethod ||
        Object.keys(this.shippingMethod).length === 0
      ) {
        m = "لطفا‌ روش ارسال را انتخاب کنید";
      } else if (
        this.shippingMethod &&
        this.shippingMethod.shippingMethodCityId &&
        !this.shippingTime
      ) {
        m = "لطفا زمان تحویل سفارش را انتخاب کنید";
      } else if (
        this.shippingMethod &&
        this.shippingMethod.shippingMethodCityId &&
        !this.shippingShift
      ) {
        m = "لطفا شیفت ارسال را انتخاب کنید";
      }
      if (m) {
        message.error(m);
      }
      return m;
    },
    async getReturnedOrder() {
      await this.setReturnedOrderDetail(this.$route.params.id);
    },
    async submit() {
      let formIsValid = this.formValidate();
      if (formIsValid) return;
      let data = {
        ids: this.items,
        addressId: this.address.addressId,
        shippingMethodId: this.shippingMethod.shippingMethodId,
        // shiftId: this.shippingShift || undefined,
        shiftId: this.shippingShift || undefined,
        dayId: this.shippingTime || undefined,
      };
      try {
        await postBackToCustomer(this.$route.params.id, data);
        message.success("با موفقیت ثبت شد");
        await this.getReturnedOrder();
        this.closeModal();
      } catch (err) {
        console.log(err);
        message.error("مشکلی پیش آمده");
      }
    },

    closeModal() {
      this.$emit("close");
    },
  },
  components: {
    LipakModal,
    NewAddress,
    Addresses,
    ShippingMethods,
    ShippingTimes,
    ShippingShift,
  },
};
</script>
<style scoped></style>
