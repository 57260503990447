<template>
  <header>
    <!-- shows on desktop & mobile -->
    <HeaderTop />
    <!-- shows on desktop & mobile -->
    <div class="header-separator" />
    <!-- shows on desktop -->
    <HeaderBottom />
  </header>
</template>

<script>
import HeaderTop from "@/components/app/header/HeaderTop";
import HeaderBottom from "@/components/app/header/HeaderBottom";

export default {
  name: "Header",
  components: {
    HeaderTop,
    HeaderBottom,
  },
};
</script>

<style lang="scss">
// make fontsize class from 8px to 36px
@for $size from 8 through 36 {
  .fs-#{$size} {
    font-size: #{$size}px !important;
  }
}
.d-flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

a.no-decorate {
  &,
  &:hover,
  &:visited,
  &:focus {
    color: inherit;
    text-decoration: none;
  }
}

// support bootstrap ml mr pl pr
$spacers: (
  0: 0rem,
  1: 0.25rem,
  2: 0.5rem,
  3: 1rem,
  4: 1.5rem,
  5: 3rem,
  auto: auto,
);
@each $key, $val in $spacers {
  .ml-#{$key} {
    margin-left: $val !important;
  }
  .mr-#{$key} {
    margin-right: $val !important;
  }
  .pl-#{$key} {
    padding-left: $val !important;
  }
  .pr-#{$key} {
    padding-right: $val !important;
  }
  @media (min-width: 576px) {
    .ml-sm-#{$key} {
      margin-left: $val !important;
    }
    .mr-sm-#{$key} {
      margin-right: $val !important;
    }
    .pl-sm-#{$key} {
      padding-left: $val !important;
    }
    .pr-sm-#{$key} {
      padding-right: $val !important;
    }
  }
  @media (min-width: 768px) {
    .ml-md-#{$key} {
      margin-left: $val !important;
    }
    .mr-md-#{$key} {
      margin-right: $val !important;
    }
    .pl-md-#{$key} {
      padding-left: $val !important;
    }
    .pr-md-#{$key} {
      padding-right: $val !important;
    }
  }
  @media (min-width: 992px) {
    .ml-lg-#{$key} {
      margin-left: $val !important;
    }
    .mr-lg-#{$key} {
      margin-right: $val !important;
    }
    .pl-lg-#{$key} {
      padding-left: $val !important;
    }
    .pr-lg-#{$key} {
      padding-right: $val !important;
    }
  }
  @media (min-width: 1200px) {
    .ml-xl-#{$key} {
      margin-left: $val !important;
    }
    .mr-xl-#{$key} {
      margin-right: $val !important;
    }
    .pl-xl-#{$key} {
      padding-left: $val !important;
    }
    .pr-xl-#{$key} {
      padding-right: $val !important;
    }
  }
}
.c-pointer {
  cursor: pointer;
}
.font-weight-bold {
  font-weight: bold;
}
</style>

<style lang="scss" scoped>
header {
  background-color: #fff;
  box-shadow: 0px 3px 16px -4px rgba(0, 0, 0, 0.13);
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1020 !important;
}
.header-separator {
  border: 1px solid #e2e7ec;
}
</style>
