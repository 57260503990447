"use strict";
import { _t } from "@/tools/Utils";

export const userManagement = [
  {
    path: "profile",
    name: "user.profile",
    component: () =>
      import(
        /* webpackChunkName: "user" */ "@/packages/users/components/user/profile/Profile"
      ),
    meta: {
      title: _t("route_titles.users_routes.users.user.profile"),
      component_dir: "profile",
      link: true,
    },
  },
  {
    path: "orders",
    name: "user.orders",
    component: () =>
      import(
        /* webpackChunkName: "user" */ "@/packages/users/components/user/orders/Orders"
      ),
    meta: {
      title: _t("route_titles.users_routes.users.user.orders"),
      component_dir: "orders",
      link: true,
    },
    redirect: { name: "orders.lists" },
    children: [
      {
        path: "lists",
        name: "orders.lists",
        component: () =>
          import(
            /* webpackChunkName: "user" */ "@/packages/users/components/user/orders/orders/OrderLists"
          ),
        meta: {
          title: _t("route_titles.users_routes.users.user.order_detail"),
          link: false,
        },
        redirect: { name: "lists.INPAYMENT" },
        children: [
          {
            path: "INPAYMENT",
            name: "lists.INPAYMENT",
            component: () =>
              import(
                /* webpackChunkName: "user" */ "@/packages/users/components/user/orders/orders/all-states/AllStates"
              ),
            meta: {
              title: _t("route_titles.users_routes.users.user.inpayments"),
              link: true,
              state: "INPAYMENT",
            },
          },
          {
            path: "INPROCESS",
            name: "lists.INPROCESS",
            component: () =>
              import(
                /* webpackChunkName: "user" */ "@/packages/users/components/user/orders/orders/all-states/AllStates"
              ),
            meta: {
              title: _t("route_titles.users_routes.users.user.inproces"),
              link: true,
              state: "INPROCESS",
            },
          },
          {
            path: "DELIVERED",
            name: "lists.DELIVERED",
            component: () =>
              import(
                /* webpackChunkName: "user" */ "@/packages/users/components/user/orders/orders/all-states/AllStates"
              ),
            meta: {
              title: _t("route_titles.users_routes.users.user.deliverd"),
              link: true,
              state: "DELIVERED",
            },
          },
          {
            path: "RETURNED",
            name: "lists.RETURNED",
            component: () =>
              import(
                /* webpackChunkName: "user" */ "@/packages/users/components/user/orders/orders/returned-state/ReturnedState"
              ),
            meta: {
              title: _t("route_titles.users_routes.users.user.returned"),
              link: true,
              state: "RETURNED",
            },
          },
          {
            path: "CANCELED",
            name: "lists.CANCELED",
            component: () =>
              import(
                /* webpackChunkName: "user" */ "@/packages/users/components/user/orders/orders/all-states/AllStates"
              ),
            meta: {
              title: _t("route_titles.users_routes.users.user.canceled"),
              link: true,
              state: "CANCELED",
            },
          },
        ],
      },
      {
        path: "details/:id",
        name: "orders.details",
        component: () =>
          import(
            /* webpackChunkName: "user" */ "@/packages/users/components/user/orders/order-detail/all-order-detail/Index"
          ),
        meta: {
          title: _t("route_titles.users_routes.users.user.order_detail"),
          link: true,
        },
      },
      {
        path: "detail-returned-order/:id",
        name: "orders.details-returned-orders",
        component: () =>
          import(
            "@/packages/users/components/user/orders/order-detail/returned-orders-detail/Index"
          ),
        meta: {
          title: _t("route_titles.orders.orders_detail"),
          link: true,
        },
      },
    ],
  },
  {
    path: "payments",
    name: "user.payments",
    component: () =>
      import(
        /* webpackChunkName: "user" */ "@/packages/users/components/user/payments/Payments"
      ),
    meta: {
      title: _t("route_titles.users_routes.users.user.payments"),
      component_dir: "payments",
      link: true,
    },
  },
  {
    path: "last_seen",
    name: "user.last_seen",
    component: () =>
      import(/* webpackChunkName: "user" */ "@/packages/users/views/Item.view"),
    meta: {
      title: _t("route_titles.users_routes.users.user.last_seen"),
      component_dir: "last-seen",
      link: true,
    },
  },
  {
    path: "comments",
    name: "user.comments",

    component: () =>
      import(/* webpackChunkName: "user" */ "@/packages/users/views/Item.view"),
    meta: {
      title: _t("route_titles.users_routes.users.user.comments"),
      component_dir: "comments",
      link: true,
    },
  },
  {
    path: "discount_code",
    name: "user.discount_code",

    component: () =>
      import(
        /* webpackChunkName: "user" */ "@/packages/users/components/user/discount-codes/DiscountCodes"
      ),
    meta: {
      title: _t("route_titles.users_routes.users.user.discount_code"),
      component_dir: "discount-codes",
      link: true,
    },
  },
  {
    path: "wallet",
    name: "user.wallet",

    component: () =>
      import(
        /* webpackChunkName: "user" */ "@/packages/users/components/user/wallet/Wallet"
      ),
    meta: {
      title: _t("route_titles.users_routes.users.user.wallet"),
      component_dir: "wallet",
      link: true,
    },
  },
];
