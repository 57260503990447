<template>
  <LipakModal
    @close="$emit('close')"
    :modal="modal"
    :title="
      $_t(
        'components.users.user.profile.base_information.modal.edit_base_information_modal.title'
      )
    "
    size="lg"
  >
    <template v-slot:body>
      <b-col cols="12" class="mx-auto fa-num-font">
        <LipakForm>
          <!--Start form body    ------------------------------------------------------------------>
          <template v-slot:body>
            <!--Start form wrapper for catch errors  ------------------------>
            <LipackFormGenerator
              lg="6"
              :schema="schema"
              :validator="$v"
            ></LipackFormGenerator>
            <!--End   form wrapper for catch errors  ------------------------>
          </template>
          <!--End   form body    ------------------------------------------------------------------>

          <!--Start form actions ------------------------------------------------------------------>
          <template v-slot:action>
            <b-col cols="=12" lg="3" md="3" sm="6">
              <b-form-group class="form-group inputBox">
                <b-button
                  variant="outline-secondary"
                  class="w-100"
                  @click="$emit('close')"
                  :disabled="is_loading"
                >
                  {{
                    $_t(
                      "components.users.user.profile.base_information.modal.edit_base_information_modal.buttons.close"
                    )
                  }}
                </b-button>
              </b-form-group>
            </b-col>
            <b-col cols="=12" lg="3" md="3" sm="6">
              <b-form-group class="form-group inputBox">
                <b-button
                  variant="success"
                  class="w-100"
                  @click="submit"
                  :disabled="is_loading || $v.$error"
                >
                  <b-spinner
                    v-if="is_loading"
                    small
                    variants="success"
                  ></b-spinner>
                  {{
                    $_t(
                      "components.users.user.profile.base_information.modal.edit_base_information_modal.buttons.submit_text"
                    )
                  }}
                </b-button>
              </b-form-group>
            </b-col>
          </template>
          <!--End   form actions ------------------------------------------------------------------>
        </LipakForm>
      </b-col>
    </template>
    <template v-slot:footer>
      <b-row class="w-100 flex-row-reverse"> </b-row>
    </template>
  </LipakModal>
</template>

<script>
import { FORM_EDIT_SCHEMA } from "@/packages/users/schema/forms/edit.user.base_information.schema";
import LipakModal from "@/components/app/Modal/LipakModal";
import LipackFormGenerator from "@/components/app/form/LipackFormGenerator";
import LipakForm from "@/components/app/form/LipakForm";
import { mapGetters } from "vuex";
import Profile from "@/packages/users/services/profile";

export default {
  name: "EditBaseInformationModal",
  components: {
    LipakModal,
    LipackFormGenerator,
    LipakForm,
  },
  props: {
    modal: {
      type: Boolean,
      default: false,
    },
    personalInfo: {
      type: Object,
      default: null,
    },
  },
  data: function () {
    return {
      Profile: new Profile(),
      schema: FORM_EDIT_SCHEMA.schema,
      form: { ...FORM_EDIT_SCHEMA.model },
    };
  },
  validations: {
    ...FORM_EDIT_SCHEMA.validations,
  },

  watch: {
    personalInfo: {
      handler: function (personalInfo) {
        if (personalInfo) {
          this.form = {
            name: personalInfo.name.value,
            lastName: personalInfo.lastName.value,
            birthday: personalInfo.birthday.value,
            nationalCode: personalInfo.nationalCode.value,
            phone: this.phone,
            email: personalInfo.email.value,
          };

          this.schema[5].disabled = this.emailEditable ? false : true;
        }
      },
      deep: true,
      immediate: true,
    },
  },

  computed: {
    ...mapGetters({
      is_loading: "loading/is_loading",
      phone: "profile/phone",
      emailEditable: "profile/emailEditable",
    }),
  },

  methods: {
    async submit() {
      if (!this.emailEditable) {
        delete this.form.email;
      }
      this.form.birthday = this.form.birthday.replaceAll("/", "-");
      this.form.birthday = this.form.birthday.slice(0, 10);
      await this.Profile.updateBaseUserInformation(
        this.form,
        this.$route.params.user_id
      );
      await this.Profile.fetchUserPersonalInfoById(this.$route.params.user_id);
      this.$emit("close");
    },
  },
};
</script>

<style scoped></style>
