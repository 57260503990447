// initial state
import auth from "@/tools/AuthService.service";

const state = () => ({
  token: auth ? auth.getToken() : null,
  first_login_token: auth ? auth.getFirstLoginToken() : null,
  reset_password_token: auth ? auth.getResetPasswordToken() : null,
  check: auth ? auth.check() : null,
  user: auth ? auth.getUser() : null,
});

// getters
export const getters = {
  check: (state) => state.check,
  token: (state) => state.token,
  first_login_token: (state) => state.first_login_token,
  reset_password_token: (state) => state.reset_password_token,
  user: (state) => state.user,
  role: (state) => (state.user ? state.user.userRole : null),
};

// mutations
export const mutations = {
  setToken(state, value) {
    state.token = value;
    auth.setToken(value);
  },

  setFirstLoginToken(state, value) {
    state.first_login_token = value;
    auth.setFirstLoginToken(value);
  },
  seResetPasswordToken(state, value) {
    state.reset_password_token = value;
    auth.setResetPasswordToken(value);
  },

  setUser(state, value) {
    state.user = value;
    auth.setUser(value);
  },

  clearAuth(state) {
    auth.removeToken();
    state.token = auth.getToken();
    state.first_login_token = auth.getFirstLoginToken();
    state.reset_password_token = auth.getResetPasswordToken();
    state.check = auth.check();
    state.user = auth.getUser();
  },
};

// actions
export const actions = {
  saveToken({ commit }, data) {
    commit("setToken", data);
  },

  saveFirstLoginToken({ commit }, data) {
    commit("setFirstLoginToken", data);
  },

  saveResetPasswordToken({ commit }, data) {
    commit("seResetPasswordToken", data);
  },

  saveUser({ commit }, data) {
    commit("setUser", data);
  },

  clear({ commit }) {
    commit("clearAuth");
  },

  logout({ commit }) {
    commit("clearAuth");
  },
};

export default {
  namespaced: true,
  name: "authentication",
  state,
  getters,
  actions,
  mutations,
};
