<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <b-alert
    class="snackbar position-fixed shadow py-3 px-3 d-flex"
    :class="`bg-${data.color}` || 'bg-info'"
    :show="dismissCountDown"
    fade
    :variant="data.color || 'info'"
    @dismissed="dismissCountDown = 0"
    @dismiss-count-down="countDownChanged"
  >
    <!--Start Content ---------------------------------------------------------------------->
    <div
      class="w-100 border-right border-4 border-white px-3 d-flex justify-content-between align-items-center align-content-center flex-row"
    >
      <!--Start Text      ------------------------------------------------------------->
      <div class="snakeBarTitle">
        <!--        <lipak-icon :icon="icon" class="text-white"></lipak-icon>-->
        <span class="fs-14 text-white px-3">{{ data.text || "" }}</span>
      </div>
      <!--End   Text      ------------------------------------------------------------->

      <!--Start Close btn ------------------------------------------------------------->
      <div class="pointer" @click="dismissCountDown = 0">
        <LITimes class="fs-12 text-white" />
      </div>
      <!--End   Close btn ------------------------------------------------------------->
    </div>
    <!--End   Content ---------------------------------------------------------------------->
  </b-alert>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {},
  data() {
    return {
      show: true,
      dismissSecs: 5,
      dismissCountDown: 0,
      showDismissibleAlert: false,
    };
  },
  computed: {
    ...mapGetters({
      data_: "messages/data",
    }),
    data() {
      return this.$store.state.messages.data;
    },
    icon() {
      if (this.data.color && this.data.color === "success")
        return "check-circle";
      else if (this.data.color && this.data.color === "primary")
        return "times-square";
      else return "star";
    },
  },
  watch: {
    "$store.state.messages.data": function () {
      this.dismissCountDown = this.dismissSecs;
    },
  },
  methods: {
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },
  },
};
</script>

<style lang="css">
.snackbar {
  min-height: 71px;
  min-width: 400px;
  right: 10px;
  z-index: 10101010;
  bottom: 0;
}
</style>
