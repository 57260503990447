import { render, staticRenderFns } from "./CreateNewAddress.vue?vue&type=template&id=30af0f50&scoped=true&"
import script from "./CreateNewAddress.vue?vue&type=script&lang=js&"
export * from "./CreateNewAddress.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30af0f50",
  null
  
)

export default component.exports