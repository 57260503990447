import Vue from "vue";

export const TABLE_HEADER_SCHEMA = [
  {
    key: "index",
    label: Vue.prototype.$_t(
      "components.comments.comments.comments_table.table.headers.id"
    ),
    sortable: false,
    class: "text-center",
  },
  {
    key: "customerName",
    label: Vue.prototype.$_t(
      "components.comments.comments.comments_table.table.headers.full_name"
    ),
    sortable: false,
    class: "col-2",
  },
  {
    key: "customerMobile",
    label: Vue.prototype.$_t(
      "components.comments.comments.comments_table.table.headers.phone"
    ),
    sortable: false,
  },

  {
    key: "productName",
    label: Vue.prototype.$_t(
      "components.comments.comments.comments_table.table.headers.product_name"
    ),
    sortable: false,
    class: "col-2",
  },
  {
    key: "title",
    label: "عنوان نظر",
    sortable: false,
    class: "col-2",
  },
  {
    key: "reply",
    label: Vue.prototype.$_t(
      "components.comments.comments.comments_table.table.headers.comment"
    ),
    sortable: false,
    class: "col-3",
  },
  {
    key: "createdAt",
    label: Vue.prototype.$_t(
      "components.comments.comments.comments_table.table.headers.createdAt"
    ),
    sortable: true,
    class: "col-1",
  },
  {
    key: "status",
    label: Vue.prototype.$_t(
      "components.comments.comments.comments_table.table.headers.status"
    ),
    sortable: false,
    class: "col-3 pr-0",
  },
];
