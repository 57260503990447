import store from "@/store";
export default {
  data() {
    return {};
  },

  created() {},

  methods: {
    /**
     *  Set Filters/sort item/pagination/search as user entered
     *  ------------------------
     *  @params:
     *  ------------------------
     *  @param: options       -> Object
     *  @param: searchParams  -> Array
     *  @param search         -> String
     *  @param: filters       -> Array
     *  ------------------------
     *  Description:
     *  ------------------------
     *  Set Filters/sort item/pagination/search as user entered
     *  ------------------------
     */
    setFilterAndSortItemBeforeCallAPI(options, search = "") {
      const { page, itemsPerPage, sortBy, sortDesc } = options;

      return {
        /* set total item will return from server in each page */
        items_per_page: itemsPerPage,
        /* set page number */
        page: page,

        /* force server to sort items based on user selected */

        sort_by: sortBy.length > 0 ? `${sortBy[0]}` : "",

        /* force server to sort items asc/desc */
        sort_direction: sortDesc[0] ? "desc" : "asc",

        /* search keyword */
        search: search,
      };
    },

    filterEmptyItems(payload) {
      let new_payload = {};
      for (let prop in payload) {
        if (payload[prop].length > 0) {
          new_payload[prop] = payload[prop];
          if (prop === "from" || prop === "to" || prop === "receivedDate")
            new_payload[prop] = payload[prop].substring(0, 10);
        }
      }
      return new_payload;
    },

    async paginationQueryBuilder(page, size) {
      page = page
        ? page - 1
        : (await store.getters["pagination/current_page"]) - 1;
      size = size ? size : await store.getters["pagination/size"];
      return {
        pageNumber: page,
        pageSize: size,
      };
    },
  },
};
