import message from "@/tools/Message";
import store from "@/store";
import {
  acceptPaymentAxiosRequest,
  addNewAddressAxiosRequest,
  cancelOrderAxiosRequest,
  downloadFactorAxiosRequest,
  editUndeliveredOrderShipmentAxiosRequest,
  fetchAddressesAxiosRequest,
  fetchAllOrdersAxiosRequest,
  fetchCanceledOrdersAxiosRequest,
  fetchOrderDetailByIdAxiosRequest,
  fetchReturnedOrdersAxiosRequest,
  fetchSendingTimesAxiosRequest,
  fetchShippingMethodListAxiosReq,
  fetchShippingMethodsAxiosRequest,
  fetchStateDetailAxiosRequest,
  registerOrderTrackingCodeAxiosReq,
  submitCommentBySuportAdminAxiosRequest,
  submitDeliveryAxiosRequest,
  updateAddressAndShippingMethodAxiosRequest,
  updateAddressAxiosRequest,
  returnCompletionAxiosReq,
  changePaymentMethodAxiosReq,
} from "@/packages/orders/api";

import { abortApiHandler, AbortSource } from "@/tools/AbortApi";

export default class OrderService {
  /*
   * fetch Orders by orderNumber | nationalCde | phoneNumber | state | date
   * -----------------------
   * @param query_params {Object}
   * @param query_params {String}
   * -----------------------
   * Description
   * -----------------------
   * Make api call to fetch Orders by orderNumber | nationalCde | phoneNumber | state | date
   * -----------------------
   * return promise
   * */
  async fetchAllOrders(query_params = null, order_state, cancelPrevApi) {
    if (cancelPrevApi) {
      abortApiHandler();
    }
    await store.dispatch("order/setAllOrders", []);
    try {
      let requestOrderHandler = {
        all: {
          doAction: async function () {
            let res = await fetchAllOrdersAxiosRequest(query_params, {
              cancelToken: AbortSource.axiosSource.token,
            });
            await store.dispatch("order/setAllOrders", res.content);
            return res;
          },
        },
        canceled: {
          doAction: async function () {
            let res = await fetchCanceledOrdersAxiosRequest(query_params, {
              cancelToken: AbortSource.axiosSource.token,
            });
            await store.dispatch("order/setCanceledOrders", res.content);
            return res;
          },
        },
        returned: {
          doAction: async function () {
            let res = await fetchReturnedOrdersAxiosRequest(query_params, {
              cancelToken: AbortSource.axiosSource.token,
            });
            await store.dispatch("returned/setReturnedOrders", res.content);
            return res;
          },
        },
      };
      let response = await requestOrderHandler[order_state].doAction();
      await store.dispatch("pagination/setTotalItems", response.totalElements);
      await store.dispatch("pagination/setTotalIPages", response.totalPages);
      return response;
    } catch (err) {
      if (err.response) {
        message.error("در دریافت لیست سفارش ها مشکلی پیش آمده است.");
      }
      await Promise.reject(err);
    }
  }

  /*
   * fetch OrderDetail by orderId
   * -----------------------
   * @param order_id{Object}
   * -----------------------
   * Description
   * -----------------------
   * Make api call to OrderDetail by orderId
   * -----------------------
   * return promise
   * */
  async fetchOrderDetailById(order_id = null) {
    try {
      /* call api to fetch users */
      let response = await fetchOrderDetailByIdAxiosRequest(order_id);

      /*Update users in store */
      await store.dispatch("order/setOrderDetail", response);
      return true;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  /*
   * fetch addreses
   * -----------------------
   * @param userId {String}
   * -----------------------
   * Description
   * -----------------------
   * Make api call to fetch addreses
   * -----------------------
   * return promise
   * */
  async fetchAddresses(userId) {
    try {
      /* call api to fetch addreses */
      let data = await fetchAddressesAxiosRequest(userId);
      /*Update address in store */
      await store.dispatch("order/setAddressList", data);
      // await store.dispatch("order/setHasError", false);
      return data;
    } catch (err) {
      // await store.dispatch("order/setHasError", true);
      message.error(err);
      await Promise.reject(err);
    }
  }

  /*
   * add new address
   * -----------------------
   * @param payload {Object}
   * @param userId {String}
   * -----------------------
   * Description
   * -----------------------
   * Make api call to add new address
   * -----------------------
   * return promise
   * */
  async addNewAddress(payload, userId) {
    try {
      /* call api to fetch address */
      await addNewAddressAxiosRequest(payload, userId);
      message.success("آدرس با موفقیت ایجاد شد");
    } catch (err) {
      let messageError = err.response.data.message;
      if (messageError.includes("city and province not match")) {
        message.error("شهر و استان باهم مطابقت ندارند");
      } else {
        message.error("خطا در ایجاد آدرس جدید");
      }
    }
  }

  /*
   * update address
   * -----------------------
   * @param orderId {String}
   * @param addressId {String}
   * -----------------------
   * Description
   * -----------------------
   * Make api call to update address
   * -----------------------
   * return promise
   * */
  async updateAddress(orderId, addressId) {
    try {
      /* call api to update address */
      let response = await updateAddressAxiosRequest(orderId, addressId);
      message.success("آدرس با موفقیت ویرایش شد");
      return response;
    } catch (err) {
      message.success("خطا در ویرایش آدرس");
      message.error(err);
    }
  }

  /*
   * cancel Order
   * -----------------------
   * @param order_id {String}
   * @param payload {Object}
   * -----------------------
   * Description
   * -----------------------
   * Make api call to cancel Order
   * -----------------------
   * return promise
   * */
  async cancelOrders(order_id, payload) {
    try {
      /* call api to cancel Order */
      await cancelOrderAxiosRequest(order_id, payload);
      message.success("لغو سفارش با موفقیت انجام شد");
      return true;
    } catch (err) {
      if (err.response.data.message.includes("is under payment!")) {
        message.error(
          "مشتری در حال پرداخت است ٫ جهت لغو سفارش پرداخت باید انجام شده باشد"
        );
      }
      if (
        err.response.data.message.includes(
          "Not allowed to cancel in less than an hour"
        )
      ) {
        message.error("جهت لغو سفارش باید حداقل یک ساهت گذشته باشد");
      }
      if (
        err.response.data.message.includes(
          "This order has already been cancelled"
        )
      ) {
        message.error("این سفارش قبلا لغو شده است");
      } else {
        message.error("لغو سفارش با خطا مواجه شد");
      }
      await Promise.reject(false);
    }
  }

  /*
   * download factor
   * -----------------------
   * @param orderID {string}
   * -----------------------
   * Description
   * -----------------------
   * Make api call to download factor
   * -----------------------
   * return promise
   * */
  async downloadFactor(orderId) {
    try {
      return await downloadFactorAxiosRequest(orderId);
    } catch (err) {
      message.error(err);
      await Promise.reject(err);
    }
  }

  /*
   * edit post date
   * -----------------------
   * @param payload {Object}
   * -----------------------
   * Description
   * -----------------------
   * Make api call to edit post date
   * -----------------------
   * return promise
   * */
  async editPostDate() {
    try {
      /* call api to edit post date */
      // await editPostDateAxiosRequest();
    } catch (err) {
      message.error(err);
    }
  }

  /*
   * fetch sendingTimes
   * -----------------------
   * @param shippingMethodsId  {Object}
   * -----------------------
   * Description
   * -----------------------
   * Make api call to fetch sendingTimes
   * -----------------------
   * return promise
   * */
  async fetchSendingTimes(queryParams) {
    try {
      /* call api to sending times */
      return await fetchSendingTimesAxiosRequest(queryParams);
    } catch (err) {
      message.error(err);
    }
  }

  /*
   * fetch order states
   * -----------------------
   * @param
   * -----------------------
   * Description
   * -----------------------
   * Make api call to order states
   * -----------------------
   * return promise
   * */
  async fetchStateDetail(stateName, orderId) {
    try {
      /* call api to sending times */
      return await fetchStateDetailAxiosRequest(stateName, orderId);
    } catch (err) {
      message.error(err);
    }
  }

  shippingMethodsWrapper(users) {
    return users.map((user) => {
      return {
        title: user,
        value: user,
      };
    });
  }

  /*
   * fetch shipping methods
   * -----------------------
   * @param cityId {String}
   * -----------------------
   * Description
   * -----------------------
   * Make api call to shipping methods
   * -----------------------
   * return promise
   * */
  async fetchShippingMethods(cityId) {
    try {
      /* call api to fetch shipping methods */
      let shippingMethods = await fetchShippingMethodsAxiosRequest(cityId);
      return Promise.resolve({
        name: this.shippingMethodsWrapper(shippingMethods),
      });
    } catch (err) {
      await message.error(err);
      await Promise.reject(err);
    }
  }

  /*
   * fetch shipping methods for edit address
   * -----------------------
   * @param cityId {String}
   * -----------------------
   * Description
   * -----------------------
   * Make api call to shipping methods for edit address
   * -----------------------
   * return promise
   * */
  async fetchShippingMethodsForEditAddress(cityId) {
    try {
      /* call api to fetch shipping methods */
      return await fetchShippingMethodsAxiosRequest(cityId);
    } catch (err) {
      if (err.response.data.message.includes("address must have quarter")) {
        message.error("برای این آدرس باید محله ثبت شده باشد");
      }

      await Promise.reject(err);
    }
  }

  /*
   * update address ans shipping method in another state
   * -----------------------
   * @param cityId {String}
   * -----------------------
   * Description
   * -----------------------
   * Make api call to update address ans shipping method in another state
   * -----------------------
   * return promise
   * */
  async updateAddressAndShippingMethod(orderId, addressId, payload) {
    try {
      /* call api to update address ans shipping method in another state */
      await updateAddressAndShippingMethodAxiosRequest(
        orderId,
        addressId,
        payload
      );
      message.success("ویرایش آدرس و روش ارسال با موفقیت انجام شد");
    } catch (err) {
      message.error("ویرایش آدرس و روش ارسال با خطا مواجه شد");
      await Promise.reject(err);
    }
  }

  /*
   * update address and shipping method in undelivered state
   * -----------------------
   * @param cityId {String}
   * -----------------------
   * Description
   * -----------------------
   * Make api call to update address ans shipping method in undelivered state
   * -----------------------
   * return promise
   * */
  async editUndeliveredOrderShipment(orderId, addressId, payload) {
    try {
      /* call api to update address and shipping method in undelivered state */
      await editUndeliveredOrderShipmentAxiosRequest(
        orderId,
        addressId,
        payload
      );
      message.success("ویرایش آدرس و روش ارسال با موفقیت انجام شد");
    } catch (err) {
      message.error("ویرایش آدرس و روش ارسال با خطا مواجه شد");
      await Promise.reject(err);
    }
  }

  /*
   * accept payment
   * -----------------------
   * @param orderId {String}
   * -----------------------
   * Description
   * -----------------------
   * Make api call to accept payment
   * -----------------------
   * return promise
   * */
  async acceptPayment(orderId) {
    try {
      /* call api to accept payment */
      let response = await acceptPaymentAxiosRequest(orderId);
      message.success("پرداخت با موفقیت تایید شد.");
      return response;
    } catch (err) {
      message.error("خطا در تایید پرداخت");
      return Promise.reject(err);
    }
  }

  /*
   * submit delivery
   * -----------------------
   * @param orderId {String}
   * -----------------------
   * Description
   * -----------------------
   * Make api call to accept payment
   * -----------------------
   * return promise
   * */
  async submitDelivery(orderId) {
    try {
      /* call api to submit delivery */
      let response = await submitDeliveryAxiosRequest(orderId);
      message.success("تحویل موفق با موفقیت تایید شد.");
      return response;
    } catch (err) {
      message.error("خطا در ثبت تحویل");
      return Promise.reject(err);
    }
  }

  /*
   * submit comment by suportAdmin
   * -----------------------
   * @param orderId {String}
   * @param comment0 {String}
   * -----------------------
   * Description
   * -----------------------
   * Make api call to submit comment by suportAdmin
   * -----------------------
   * return promise
   * */
  async submitCommentBySuportAdmin(payload) {
    try {
      /* submit comment by suportAdmin */
      let response = await submitCommentBySuportAdminAxiosRequest(payload);
      message.success("ثبت نظر با موفقیت انجام شد");
      return response;
    } catch (err) {
      message.error("خطا در ثبت نظر");
      return Promise.reject(err);
    }
  }

  /*
   * submit tracking code
   * -----------------------
   * @param orderId {String}
   * @param tracking code {String}
   * -----------------------
   * Description
   * -----------------------
   * Make api call to tracking code
   * -----------------------
   * return promise
   * */
  async registerOrderTrackingCodeService(payload, query_params = null) {
    try {
      await registerOrderTrackingCodeAxiosReq(payload, query_params);
      message.success("REGISTERED_SUCCESSFULLY");
    } catch (e) {
      message.error(errorObject[e.response.status]);
    }
  }

  /*
   * fetch shipping method list
   * -----------------------
   * @param orderId {String}
   * @param comment0 {String}
   * -----------------------
   * Description
   * -----------------------
   * Make api call to fetch shipping method list
   * -----------------------
   * return promise
   * */
  async fetchShippingMethodList() {
    try {
      return await fetchShippingMethodListAxiosReq();
    } catch (e) {
      console.log(e);
    }
  }

  /*
   * return completion
   * -----------------------
   * @param returnId {String}
   * -----------------------
   * Description
   * -----------------------
   * return completion
   * -----------------------
   * return promise
   * */
  async returnCompletion(returnId) {
    try {
      await returnCompletionAxiosReq(returnId);
      message.success("بستن مرجوعی با موفقیت انجام شد");
    } catch (e) {
      message.error("خطا در بستن مرجوعی");
      await Promise.reject(e);
    }
  }

  /*
   *  change payment method
   * -----------------------
   * @param
   * -----------------------
   * Description
   * -----------------------
   * return
   * -----------------------
   * return promise
   * */
  async changePaymentMethod(payload) {
    try {
      await changePaymentMethodAxiosReq(payload);
      message.success("تغییر روش پرداخت به حواله با موفقیت انجام شد");
    } catch (e) {
      message.error("خطا در تغییر روش پرداخت");
      await Promise.reject(e);
    }
  }
}
const errorObject = {
  400: "IS_NOT_POST_OR_TIPAX_OR_MAHEX",
  403: "INVALID_CREDENTIAL",
  404: "ORDER_NOT_FOUND",
};
