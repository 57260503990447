import Vue from "vue";

export const TABLE_HEADER_SCHEMA = [
  {
    key: "index",
    label: Vue.prototype.$_t(
      "components.users.users.users_table.table.headers.id"
    ),
    sortable: false,
  },
  {
    key: "name",
    label: Vue.prototype.$_t(
      "components.users.users.users_table.table.headers.name"
    ),
    sortable: false,
  },
  {
    key: "lastName",
    label: Vue.prototype.$_t(
      "components.users.users.users_table.table.headers.lastName"
    ),
    sortable: false,
  },

  {
    key: "phone",
    label: Vue.prototype.$_t(
      "components.users.users.users_table.table.headers.phone"
    ),
    sortable: false,
  },
  {
    key: "email",
    label: Vue.prototype.$_t(
      "components.users.users.users_table.table.headers.email"
    ),
    sortable: false,
  },
  {
    key: "nationalCode",
    label: Vue.prototype.$_t(
      "components.users.users.users_table.table.headers.nationalCode"
    ),
    sortable: false,
  },

  {
    key: "createdAt",
    label: Vue.prototype.$_t(
      "components.users.users.users_table.table.headers.createdAt"
    ),
    sortable: true,
  },
  {
    key: "actions",
    label: Vue.prototype.$_t(
      "components.users.users.users_table.table.headers.actions"
    ),
    sortable: false,
  },
];
