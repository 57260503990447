<template>
  <div>
    <b-card class="shadow p-3">
      <!--Start Filters     ----------------------------------------------------------------------------------------------->
      <ActivitiesFilter v-model="filters" />
      <!--End   Filters     ----------------------------------------------------------------------------------------------->

      <!--Start activities  ----------------------------------------------------------------------------------------------->
      <Activities />
      <!--End   activities  ----------------------------------------------------------------------------------------------->

      <!--Start Pagination  ----------------------------------------------------------------------------------------------->
      <Pagination v-model="paginationParams" key="product-pagination" />
      <!--End   Pagination  ----------------------------------------------------------------------------------------------->
    </b-card>
  </div>
</template>

<script>
import Pagination from "@/components/app/Pagination";
// import { fetchFilteredOrdersInProcessingModeService } from "@/packages/order-processing/services/order-processing-filter.service";
import { mapActions } from "vuex";
import ActivitiesFilter from "@/packages/manage/components/filters/ActivitiesFilter";
import Activities from "@/packages/manage/components/activities/Activities";
import { fetchFilteredActivitiesService } from "@/packages/manage/services/activities.service";
export default {
  name: "Index",
  components: {
    Activities,
    ActivitiesFilter,
    Pagination,
  },
  data() {
    return {
      filters: null,
      paginationParams: null,
    };
  },
  watch: {
    paginationParams: {
      // This will let Vue know to look inside the object
      deep: true,

      // We have to move our method to a handler field
      async handler(value) {
        if (value)
          await fetchFilteredActivitiesService({
            ...value,
            ...this.filters,
          });
      },
    },
  },
  methods: {
    ...mapActions({
      resetPagination: "pagination/reset",
    }),
  },
};
</script>

<style scoped></style>
