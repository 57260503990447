import Vue from "vue";
import VueI18n from "vue-i18n";
import app from "../tools/App";

Vue.use(VueI18n);
function loadLocales() {
  const context = require.context("@/packages", true, /locales.js$/i);
  return context
    .keys()
    .map(context) // import module
    .map((m) => m.default); // get `default` export from each resolved module
}
const resourceLocales = loadLocales();
let generalLocales = require("./../locales");

resourceLocales.forEach((locale) => {
  for (const item in locale) {
    generalLocales[item].components = {
      ...generalLocales[item].components,
      ...locale[item].components,
    };
    generalLocales[item].router = {
      ...generalLocales[item].router,
      ...locale[item].router,
    };
    generalLocales[item].route_titles = {
      ...generalLocales[item].route_titles,
      ...locale[item].route_titles,
    };
    generalLocales[item].views = {
      ...generalLocales[item].views,
      ...locale[item].views,
    };
    generalLocales[item].attribute = {
      ...generalLocales[item].attribute,
      ...locale[item].attribute,
    };
    generalLocales[item].placeholder = {
      ...generalLocales[item].placeholder,
      ...locale[item].placeholder,
    };
  }
});

const i18n = new VueI18n({
  locale: app.default_lang || process.env.VUE_APP_I18N_LOCALE,
  fallbackLocale: "en" || process.env.VUE_APP_I18N_FALLBACK_LOCALE,
  silentLocallationWarn: true,
  formatFallbackMessages: true,
  messages: generalLocales,
});

export default i18n;
