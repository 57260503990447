"use strict";
import { _t } from "@/tools/Utils";

export default [
  {
    path: "comments",
    name: "comments",
    component: () =>
      import(
        /* webpackChunkName: "comments" */ "@/packages/comments/views/Index"
      ),

    meta: {
      title: _t("route_titles.comments_routes.comments.title"),
      link: false,
    },
    // redirect: { name: "comments.list" },
    // children: [
    //   {
    //     path: "list",
    //     name: "comments.list",
    //
    //     component: () =>
    //       import(
    //         /* webpackChunkName: "comments" */ "@/packages/comments/views/List.view"
    //       ),
    //     meta: {
    //       title: _t("route_titles.comments_routes.comments.list"),
    //       link: true,
    //     },
    //   },
    // ],
  },
];
