export const SEARCH_FORM_ORDER_NUMBER = {
  /*SCHEMA */
  schema: [
    {
      id: "orderId",
      type: "text",
      placeholder: "orderId",
      required: false,
      cols: "12",
      lg: "12",
      md: "12",
    },
  ],

  /* VALIDATION SCHEMA */
  validations: {
    form: {
      orderId: {},
    },
  },

  /*MODEL SCHEMA*/
  model: {
    orderId: "",
  },
};
