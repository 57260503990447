<template>
  <div>
    <div class="py-3 px-5">
      <b-table
        tbody-tr-class="align-middle fs-12 text-center"
        thead-class="text-secondary fs-12 fw-lighter text-center"
        responsive
        hover
        :items="data"
        :fields="tableHeaders"
        primary-key="preInvoiceList"
        show-empty
        empty-text="هیچ کامنتی پیدا نشد"
        empty-filtered-text="هیچ کامنتی پیدا نشد"
        :busy.sync="isBusy"
      >
        <!--Start Loading Viewer    ------------------------------------>
        <template #table-busy>
          <b-skeleton-table :columns="15" :rows="10" />
        </template>
        <!--End   Loading Viewer    ------------------------------------>
        <template #cell(createdAt)="data">
          {{ $_date(data.value) }}
        </template>
        <template #cell(description)="data">
          {{ data.value.slice(0, 40) }}{{ data.value.length > 40 ? "..." : "" }}
        </template>
        <template #cell(operations)="row">
          <b-button
            size="sm"
            @click="navigateToDetail(row.item.id)"
            class="mr-1 text-info w-100 border-0"
            block
            variant="accent"
          >
            جزئیات
          </b-button>
        </template>

        <!--Start row         ------------------------------------>
        <template #cell(row)="data">
          {{ data.index + 1 + (currentPage - 1) * pageSize }}
        </template>
        <!--End   row         ------------------------------------>

        <!--Start Empty states      ------------------------------------>
        <template #empty="scope">
          <p v-if="hasError" class="text-center">عدم دریافت پاسخ از سرور</p>
          <p v-else class="text-center">{{ scope.emptyText }}</p>
        </template>

        <template #emptyfiltered="scope">
          <p class="text-center">{{ scope.emptyFilteredText }}</p>
        </template>
        <!--End   Empty states      ------------------------------------>
      </b-table>
    </div>
  </div>
</template>

<script>
// import { mapActions, mapGetters } from "vuex";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      tableHeaders: [
        {
          key: "row",
          label: "ردیف",
          sortable: false,
        },
        {
          key: "name",
          label: "نام",
          sortable: false,
        },
        {
          key: "phoneNumber",
          label: "شماره موبایل",
          sortable: false,
        },
        {
          key: "email",
          label: "ایمیل",
          sortable: false,
        },
        {
          key: "description",
          label: "توضیحات",
          sortable: false,
        },
        {
          key: "createdAt",
          label: "تاریخ ثبت",
          sortable: false,
        },
        {
          key: "operations",
          label: "عملیات",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      data: "contactUs/filter/data",
      isBusy: "contactUs/filter/isBusy",
      hasError: "contactUs/filter/hasError",
      currentPage: "pagination/current_page",
      pageSize: "pagination/size",
    }),
  },
  methods: {
    navigateToDetail(id) {
      let routeData = this.$router.resolve({
        name: "contact-us.details",
        params: { contactUsId: id },
      });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-light-blue {
  background: rgba(33, 150, 243, 0.2);
  color: #2196f3;
}
.btn-light-orange {
  background: #fe6a3433;
  color: #fe6a34;
}
.circle-items {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 4px;
}
</style>
