export const COMMENTS_FILTER_SCHEMA = {
  /* Form SCHEMA */
  schema: [
    {
      id: "productGroup",
      title: "گروه کالا",
      placeholder: "productGroup",
      cols: 12,
      lg: 3,
      md: 4,
      sm: 6,
      type: "select",
      defaultOptionDisabled: false,
      options: [],
      service: "productGroups",
      autoComplete: true,
      search: "productGroupSearch",
    },
    {
      id: "brand",
      title: "برند",
      placeholder: "brand",
      cols: 12,
      lg: 3,
      md: 4,
      sm: 6,
      type: "select",
      options: [],
      defaultOptionDisabled: false,
      service: "brands",
      autoComplete: true,
      search: "brandSearch",
    },
    {
      id: "model",
      title: "مدل",
      placeholder: "model",
      cols: 12,
      lg: 3,
      md: 4,
      sm: 6,
      type: "select",
      options: [],
      defaultOptionDisabled: false,
      service: "models",
      autoComplete: true,
      search: "modelSearch",
    },
    {
      id: "config",
      title: "کانفیگ",
      placeholder: "config",
      cols: 12,
      lg: 3,
      md: 4,
      sm: 6,
      type: "select",
      options: [],
      defaultOptionDisabled: false,
      service: "configs",
      autoComplete: true,
      search: "configSearch",
    },
    {
      id: "from",
      title: "از تاریخ",
      placeholder: "from",
      cols: 12,
      lg: 3,
      md: 4,
      sm: 6,
      type: "date",
    },
    {
      id: "to",
      title: "تا تاریخ",
      placeholder: "to",
      cols: 12,
      lg: 3,
      md: 4,
      sm: 6,
      type: "date",
    },
    {
      id: "status",
      title: "وضعیت",
      placeholder: "status",
      cols: 12,
      lg: 3,
      md: 4,
      sm: 6,
      type: "select",
      options: [
        { title: "در انتظار", value: "waiting" },
        { title: "تایید شده", value: "accepted" },
        { title: "رد شده", value: "rejected" },
        { title: "ویرایش و تایید شده", value: "editedAndAccepted" },
      ],
      defaultOptionDisabled: false,
    },
  ],

  /* Form MODEL */
  model: {
    productGroup: "",
    productGroupSearch: "",
    brand: "",
    brandSearch: "",
    model: "",
    modelSearch: "",
    config: "",
    configSearch: "",
    from: "",
    to: "",
    status: "",
  },
  validations: {
    productGroup: {},
    productGroupSearch: {},
    brand: {},
    brandSearch: {},
    model: {},
    modelSearch: {},
    config: {},
    configSearch: {},
    from: {},
    to: {},
    status: {},
  },
};
