export const ReturnReasonSchema = {
  schema: [
    {
      id: "returnReason",
      type: "select",
      placeholder: "returnReason",
      defaultOptionDisabled: false,
      options: [],
      service: "return-reason",
      disabled: true,
      cols: 12,
      sm: 12,
      md: 12,
      lg: 12,
      xl: 12,
    },
    {
      id: "description",
      type: "text",
      placeholder: "description",
      disabled: true,
      cols: 12,
      sm: 12,
      md: 12,
      lg: 12,
      xl: 12,
    },
  ],
  model: { returnReason: "", description: "" },
  validations: { returnReason: {}, description: {} },
};
