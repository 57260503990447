import api from "@/tools/Api";
import urls from "@/tools/Urls";

export const fetchContactUsRequest = function (queryParams, cancelToken) {
  return api.get(urls.api("contact_us"), {
    params: { ...queryParams },
    ...cancelToken,
  });
};

export const fetchContactUsItemRequest = function (contactUsId) {
  return api.get(urls.api("contact_us_item", [`${contactUsId}`]));
};
