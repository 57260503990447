import api from "@/tools/Api";
import urls from "@/tools/Urls";

export const fetchReturnReasonAxiosRequest = function () {
  return api.get(urls.api("fetch_return_reason"));
};

export const fetchAllSuppliers = async (queryParams) => {
  return api.get(urls.api("suppliers"), { params: { ...queryParams } });
};

export const fetchAllStockrooms = async (queryParams) => {
  return api.get(urls.api("stockrooms"), { params: { ...queryParams } });
};

export const fetchAllProducts = async (queryParams) => {
  return api.get(urls.api("products_lpv_search"), {
    params: { ...queryParams },
  });
};

export const fetchBrands = async (queryParams) => {
  return api.get(urls.api("brands"), { params: { ...queryParams } });
};

export const fetchModels = async (queryParams) => {
  return api.get(urls.api("models"), { params: { ...queryParams } });
};

export const fetchConfigs = async (queryParams) => {
  return api.get(urls.api("configs"), { params: { ...queryParams } });
};

export const fetchProductGroups = async (queryParams) => {
  return api.get(urls.api("product_groups"), { params: { ...queryParams } });
};

export const fetchGuarantees = async (queryParams) => {
  return api.get(urls.api("guarantees"), { params: { ...queryParams } });
};

export const fetchUnits = async (queryParams) => {
  return api.get(urls.api("units"), { params: { ...queryParams } });
};

export const fetchProvince = async (queryParams) => {
  return api.get(urls.api("fetch_province"), { params: { ...queryParams } });
};

export const fetchCitiesByProvinceId = async (queryParams) => {
  return api.get(urls.api("fetch_cities_by_province_id"), {
    params: { ...queryParams },
  });
};
export const fetchQuarterByCityId = async (queryParams) => {
  return api.get(urls.api("fetch_quarter_by_city_id", [queryParams.cityId]), {
    params: { ...queryParams },
  });
};
