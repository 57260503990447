module.exports = {
  components: {
    account: {
      auth: {
        title: "WELCOME TO Dashboard",
        subtitle: "APP Panel",
        buttons: {
          login: "Login",
          forgotten_password: "Forget password",
        },
      },
      forgot_password: {
        title: "Forgot Your Password?",
        subtitle: "Enter your email and we will send you a reset link.",
        buttons: {
          submit_text: "Send Reset Link",
        },
      },
      information: {
        title: "Account information",
      },
      reset_password: {
        title: "Enter your new password",
        subtitle: "Please enter a strong password.",
        buttons: {
          submit_text: "Confirm",
        },
      },
    },
    app: {
      page_not_found: {
        title: "404",
        subtitle_part_one: "Sorry!",
        subtitle_part_two: "Page Not Found",
        buttons: {
          go_back: "Go Back",
        },
      },
    },
    dashboard: {},
  },
  router: {
    index: {
      panel_title: "Panel",
    },
    routes: {
      auth_routes: {
        login: "Login",
        forgot_password: "Forgot Password",
        reset_password: "Reset Password",
      },
      panel_routes: {
        dashboard: "Dashboard",
        profile: "Profile",
      },
      page_not_found_routes: {
        not_found: "Page not found",
      },
      dashboard_routes: {
        dashboard: "Dashboard",
        main: "Dashboard Main",
        operations: "Operations",
      },
    },
  },
  views: {
    auth: {
      login: {
        title: "PSP Dashboard",
        body: {
          description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequaunt mollit anim id est laborum.",
        },
        buttons: {
          order_button: "Order",
        },
      },
    },
  },
  validation: {
    required: "{attribute} can not be empty.",
    alpha: "{attribute} Can only contain letters A-Z.",
    alpha_num: "{attribute} Can only contain letters A-Z and numbers 9-0",
    alpha_dash: "{attribute} Can contain of letter, numbers, - and _. ",
    alpha_spaces: "{attribute} Can only contain letter A-Z as well as space.",
    between:
      "{attribute} Can contain minimum {min} characters and maximum {max} characters.",
    confirmed: "{attribute} Password dose not match.",
    digits: "{attribute} Must contain numbers and exactly {length} characters.",
    length:
      "{attribute} Must contain characters and exactly {length} characters.",
    dimensions:
      "{attribute} Dimensions need to be {width} pixels height and {height} pixels width.",
    email: "{attribute} Must be a valid email address.",
    excluded: "{attribute} Must be a valid input.",
    ext: "{attribute} Must be a valid file format.",
    image: "{attribute} Must be an image.",
    max_value: "{attribute} Must be {max} or less than {max}",
    max: "{attribute} Cannot be longer than {length}",
    mimes: "{attribute} Must be a valid format.",
    min_value: "{attribute} Must be {min} or more than {min}",
    min: "{attribute} Must be at least {length} characters.",
    numeric: "{attribute} Can only contain numbers.",
    oneOf: "{attribute} Must be a valid input.",
    regex:
      "{attribute} must contain at least an Upper case letter,lower case letter,special character(# $ @ %) and a number.",
    required_if: "{attribute} Mandatory.",
    size: "{attribute} File size can be {size}KB",
    double: "{attribute} Must be a valid decimal number.",
    isEnglish: "Please fill in {attribute} english.",
    mixCharacterPersianAndEnglishValidation: "{attribute} is invalid",
  },

  welcome: "Welcome",
  FAILED_VALIDATION: "Validation for this particular item failed",
  ACCESS_DENIED: "You are not allowed to do the current action",
  INVALID_GRANT: "Username / password or access token is wrong",
  UNAUTHORIZED: "UNAUTHORIZED",
  INVALID_OTP: "Wrong OTP was provided",
  INVALID_PAYLOAD: "Provided payload is invalid",
  INVALID_QUERY: "The requested query parameters can not be used",
  REQUESTS_EXCEEDED: "Hit rate limit; Too many requests",
  ROUTE_NOT_FOUND: "Endpoint does not exist",
  SERVICE_UNAVAILABLE: "Could not use external service",
  FETCH_DATA_FAILED: "Could not fetch data.",
  CREATE_SUCCESS: "Created successfully.",
  CREATE_FAILED: "Did not create.",
  UPDATE_SUCCESS: "Updated successfully.",
  UPDATE_FAILED: "Did not update.",
  DELETE_SUCCESS: "Deleted successfully.",
  DELETE_FAILED: "Did not delete.",
  DELETE_MESSAGE: "Are you sure you want to delete this address?",
  NO_RESULT: "There is no result for this request.",
};
