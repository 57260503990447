import { getReturnedOrderDetailAxiosRequest } from "@/packages/orders/api/returned-order-detail.api";

const getKeysOfTarget = (target, ...keys) => {
  const obj = {};
  keys.forEach((key) => {
    obj[key] = target[key];
  });
  return obj;
};

// initial state
const state = () => ({
  data: {
    state: "QC_DELIVERED", // RETURNED_BY_CUSTOMER SUPPORT_CONFIRMED
    orderDate: new Date(),
    returnRegisteredDate: new Date(),
    supportConfirmedDate: new Date(),
    username: "علیرضا شالفروش",
    phoneNumber: "09336865363",
    userId: "ddd980ec-a935-4f5a-ab8e-3bccf230447a",
    orderId: "321487",
    returnedId: "879473",
    description: "",
    items: [
      {
        id: "ddd980eb-a935-4f5a-ab8e-3bccf230447a",
        imageUrl:
          "media/2022-03-12_e6bb8594-8bc6-41f4-a3c8-191fd8597ec1_گوشی موبایل شیائومی مدل Poco F3 5G دو سیم‌ کارت ظرفیت 64گیگابایت_SIZE_320_320.png",
        imageAlt: "هندفون",
        title: "عنوان کالا ساعت هوشمند امیزفیت مدل GTR 42mm (گلوبال)",
        colorName: "آبی",
        colorHex: "#341AD5",
        guarantee: "گارانتی 18 ماهه کاوش تیم",
        lpv: "00001640405",
        orderItemsCount: 4,
        returnedItemsCount: 2,
        returnedReason: "مخدوش بودن بسته بندی",
        customerDescription: "توضیحات مشتری درباره دلیل مرجوع کردن کالا",
        files: ["asd", "qwe"],
        status: "APPROVED", //enum(REJECTED, APPROVED)
        rejectReasonId: "",
        rejectDescription: "",
        approvedItems: [
          {
            id: "cab7dc17-fc7f-432d-944e-358ba7ad15da",
            isDeliver: true,
            selectedSerialNumber: "asd",
            status: "DELIVER",
          },
          {
            id: "cab7dc17-fc7f-432d-944e-358ba7ad15db",
            isDeliver: false,
            selectedSerialNumber: "rte",
            status: "DELIVER",
          },
        ],
      },
      {
        id: "ddd980eb-a935-4f5a-ab8e-3bccf230447b",
        imageUrl:
          "media/2022-03-01_13ba2215-18ae-4c52-a9fd-11fd5c2554f6_موبایل شیائومی مدل Poco F3 5G دو سیم‌ کارت ظرفیت 128 گیگابایت آبی.",
        imageAlt: "2هندفون",
        title: "عنوان کالا ساعت هوشمند امیزفیت مدل GTR 42mm (گلوبال)2",
        colorName: "قرمز",
        colorHex: "#f00",
        guarantee: "گارانتی 18 ماهه کاوش تیم",
        lpv: "000016404045",
        orderItemsCount: 1,
        returnedItemsCount: 1,
        returnedReason: "حال نکردم",
        customerDescription: "۲توضیحات مشتری درباره دلیل مرجوع کردن کالا",
        files: ["asd"],
        status: "REJECTED", //enum(REJECTED, APPROVED)
        rejectReasonId: "asdasd",
        rejectDescription: "reject desc",
      },
    ],
    address: {},
    shipping: {
      address: "asdsadasd",
      addressId: "17fe0080-8597-4f20-8b15-f51e70b503e0",
      shippingSettingTitle: "asd",
      shippingSettingId: "e1bf0d52-0c5f-4c2b-91bb-9049fa0a9e56",
      dayId: "31762125-b489-4592-8909-ac38ecefb9fe",
      shiftId: "2f774d2d-5540-49d2-b4a5-c75cb4298c79",
      shippingCost: 20000,
    },
  },
  returnedOrders: [],
  isLoading: false,
  hasError: false,
  step2Editing: false,
});

// getters
const getters = {
  isLoading: (state) => state.isLoading,
  hasError: (state) => state.hasError,
  data: (state) => state.data,
  returnedOrders: (state) => state.returnedOrders,
  userId: (state) => state.data.userId,
  orderUuid: (state) => state.data.orderUuid,
  shipping: (state) => state.data.shipping,
  address: (state) => state.data.address,
  header: (state) =>
    getKeysOfTarget(
      state.data,
      "orderDate",
      "returnRegisteredDate",
      "username",
      "phoneNumber",
      "orderId",
      "returnId"
    ),
  state: (state) => state.data.state,
  dates: (state) =>
    getKeysOfTarget(
      state.data,
      "returnRegisteredDate",
      "supportConfirmedDate",
      "qcDeliverDate",
      "qcApproveDate"
    ),
  items: (state) => state.data.items,
  description: (state) => {
    return state.data.description;
  },
  step2Editing: (state) => state.step2Editing,
  step4Items: (state) => {
    const supportRejected = [];
    const qcNotDelivered = [];
    const qcApproved = [];
    const qcNotApproved = [];
    for (const item of state.data.items) {
      if (item.status === "REJECTED") {
        supportRejected.push(item);
        continue;
      }
      for (const approvedItem of item.approvedItems) {
        if (!approvedItem.isDeliver) {
          qcNotDelivered.push({
            ...item,
            approvedItem,
            approvedItems: undefined,
          });
        } else if (approvedItem.status === "APPROVED") {
          qcApproved.push({ ...item, approvedItem, approvedItems: undefined });
        } else {
          qcNotApproved.push({
            ...item,
            approvedItem,
            approvedItems: undefined,
          });
        }
      }
    }
    return {
      supportRejected,
      qcNotDelivered,
      qcApproved,
      qcNotApproved,
    };
  },
  isComplete: (state) => state.data.isComplete,
};

// mutations
const mutations = {
  mutateData(state, payload) {
    state.data = payload;
  },
  mutateOrders(state, payload) {
    state.returnedOrders = payload;
  },
  mutateIsLoading(state, payload) {
    state.isLoading = payload;
  },
  mutateHasError(state, payload) {
    state.hasError = payload;
  },
  mutateItemForm(state, payload) {
    const itemIndex = state.data.items.findIndex((s) => s.id === payload.id);
    for (const key in payload) {
      state.data.items[itemIndex][key] = payload[key];
    }
  },
  mutateDescription(state, payload) {
    state.data = { ...state.data, description: payload };
  },
  mutateStep2Editing(state, payload) {
    state.step2Editing = payload;
  },
};

// actions
const actions = {
  async setReturnedOrderDetail({ commit }, payload) {
    commit("mutateIsLoading", true);
    commit("mutateHasError", false);
    try {
      const res = await getReturnedOrderDetailAxiosRequest(payload);
      commit("mutateData", res);
    } catch (err) {
      console.log(err);
      commit("mutateHasError", true);
    }
    commit("mutateIsLoading", false);
  },
  setData({ commit }, payload) {
    commit("mutateData", payload);
  },
  setReturnedOrders({ commit }, payload) {
    commit("mutateOrders", payload);
  },
  setItemForm({ commit }, payload) {
    commit("mutateItemForm", payload);
  },
  setDescription({ commit }, payload) {
    commit("mutateDescription", payload);
  },
  setStep2Editing({ commit }, payload) {
    commit("mutateStep2Editing", payload);
  },
};

export default {
  namespaced: true,
  name: "returned",
  state,
  getters,
  actions,
  mutations,
};
