<template>
  <b-card class="border-0" v-if="isLoading">
    <b-skeleton height="70px" />
    <b-skeleton height="500px" class="mt-5" />
    <b-skeleton height="150px" class="mt-5" />
  </b-card>
  <b-card v-else-if="hasError" class="border-0 text-center">
    <div>خطا در دریافت جزئیات مرجوعی</div>
    <b-button class="mt-4" variant="primary" @click="getReturnedOrder">
      تلاش مجدد
    </b-button>
  </b-card>
  <b-card v-else class="border-0 fa-num-font">
    <HeaderDetail />
    <State class="mt-4" />
    <component :is="comp" />
  </b-card>
</template>

<script>
import HeaderDetail from "@/packages/orders/components/order-detail/returned-orders-detail/HeaderDetail";
import State from "@/packages/orders/components/order-detail/returned-orders-detail/State";
import { mapActions, mapGetters } from "vuex";

const COMPONENTS = {
  RETURNED_BY_CUSTOMER: () =>
    import(
      "@/packages/orders/components/order-detail/returned-orders-detail/step1-2/Index"
    ),
  SUPPORT_CONFIRMED: () =>
    import(
      "@/packages/orders/components/order-detail/returned-orders-detail/step1-2/Index"
    ),
  QC_DELIVERED: () =>
    import(
      "@/packages/orders/components/order-detail/returned-orders-detail/step3/Index"
    ),
  QC_CONFIRMED: () =>
    import(
      "@/packages/orders/components/order-detail/returned-orders-detail/step4/Index"
    ),
};

export default {
  components: { State, HeaderDetail },
  computed: {
    ...mapGetters({
      isLoading: "returned/isLoading",
      hasError: "returned/hasError",
      state: "returned/state",
    }),
    comp() {
      return COMPONENTS[this.state];
    },
  },
  created() {
    this.getReturnedOrder();
  },
  methods: {
    ...mapActions({
      setStep2Editing: "returned/setStep2Editing",
      setReturnedOrderDetail: "returned/setReturnedOrderDetail",
    }),
    async getReturnedOrder() {
      await this.setReturnedOrderDetail(this.$route.params.id);
    },
  },
};
</script>

<style scoped></style>
