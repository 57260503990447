<template>
  <div class="mb-4">
    <b-row v-if="boxComment">
      <b-col cols="12">
        <span
          class="text-info fs-14 border p-2 pointer"
          @click="boxComment = !boxComment"
        >
          <LIPlus class="fs-8" />

          <span> ثبت نظر </span>
        </span>
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col cols="12" class="mb-6">
        <LipakForm>
          <template slot="body">
            <LipakFormGenerator :schema="schema" :validator="$v.form" />
          </template>
          <template slot="action">
            <b-row no-gutters>
              <b-col cols="12" class="px-3">
                <b-button
                  class="fs-13"
                  variant="outlined"
                  @click="boxComment = !boxComment"
                >
                  لغو
                </b-button>

                <b-button
                  class="fs-13"
                  variant="primary"
                  @click="submit"
                  :disabled="is_loading || $v.$error"
                >
                  <b-spinner
                    v-if="is_loading"
                    small
                    variants="primary"
                  ></b-spinner>
                  ثبت نظر</b-button
                >
              </b-col></b-row
            >
          </template>
        </LipakForm>
      </b-col>
    </b-row>

    <hr class="solid" />
  </div>
</template>

<script>
import LipakForm from "@/components/app/form/LipakForm";
import LipakFormGenerator from "@/components/app/form/LipakFormInput";
import cloneDeep from "lodash.clonedeep";
import { SUBMIT_COMMENT } from "@/packages/orders/schema/form/SUBMIT_COMMENT";
import OrderService from "@/packages/orders/services/order.service";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "SubmitComment",
  components: { LipakFormGenerator, LipakForm },

  data() {
    return {
      schema: cloneDeep(SUBMIT_COMMENT.schema),
      form: cloneDeep(SUBMIT_COMMENT.model),
      boxComment: true,
      Orders: new OrderService(),
      ok: false,
    };
  },
  validations: {
    form: cloneDeep(SUBMIT_COMMENT.validations),
  },
  computed: {
    ...mapGetters({
      is_loading: "loading/is_loading",
    }),
  },
  methods: {
    ...mapActions({
      updateCommentsInOrderDetail: "order/updateCommentsInOrderDetail",
    }),
    isFormValidate() {
      this.$v.$touch();
      return !this.$v.$invalid;
    },
    async submit() {
      if (!this.isFormValidate) return;
      try {
        let response = await this.Orders.submitCommentBySuportAdmin({
          orderId: this.$route.params.id,
          comment: this.form.comment,
        });
        await this.updateCommentsInOrderDetail(response);
        this.form = cloneDeep(SUBMIT_COMMENT.model);
        await this.$nextTick(() => {
          this.$v.$reset();
        });
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style scoped>
.solid {
  border-top: 1px solid #999;
}
</style>
