const { minLength, maxLength } = require("vuelidate/lib/validators");

export const FORM_EDIT_SCHEMA = {
  /* Form SCHEMA */
  schema: [
    {
      id: "accountOwner",
      cols: 12,
      lg: 12,
      md: 12,
      sm: 12,
      type: "text",
      required: false,
    },
    {
      id: "cartNumber",
      cols: 12,
      lg: 12,
      md: 12,
      sm: 12,
      type: "text",
      required: false,
    },
    {
      id: "shebaNumber",
      cols: 12,
      lg: 12,
      md: 12,
      sm: 12,
      type: "text",
      required: false,
    },
  ],

  /* Form MODEL */
  model: { accountOwner: "", cartNumber: "", shebaNumber: "" },

  /* VALIDATION SCHEMA */
  validations: {
    form: {
      accountOwner: {},
      cartNumber: {
        minLength: minLength(16),
        maxLength: maxLength(16),
      },
      shebaNumber: { minLength: minLength(24), maxLength: maxLength(24) },
    },
  },
};
