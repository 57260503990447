import { mapActions, mapGetters } from "vuex";
import filter from "@/mixins/mixin.filter-sort";
import message from "@/tools/Message";

export default {
  mixins: [filter],
  computed: {
    ...mapGetters({
      formsShouldBeClear: "filter/formsShouldBeClear",
      is_loading: "loading/is_loading",
    }),
  },
  watch: {
    formsShouldBeClear(newValue) {
      if (newValue.includes(this.filterName)) {
        this.clearForm();
      }
    },
  },
  mounted() {
    this.clearForm();
    this.submitForm(this.filterName);
  },
  methods: {
    ...mapActions({
      resetPagination: "pagination/reset",
      submitForm: "filter/submitForm",
      checkFiltersForClearUnselectedForm:
        "filter/checkFiltersForClearUnselectedForm",
      initByFilter: "filter/initByFilter",
      clearFormSavedFromState: "filter/clearForm",
    }),
    async searchOrder() {
      if (!this.isFormValidate()) return;

      ///// reset pagination
      await this.resetPagination();

      ///// check all filter and delete additional filter
      await this.checkFiltersForClearUnselectedForm(this.filterName);
      let filters = this.filterEmptyItems(this.form);
      if (!Object.keys(filters).length) {
        message.error("لطفا فیلد مورد نظر را برای جستجو پر کنید");
        return;
      }
      if (filters.fromDate) {
        filters.fromDate = filters.fromDate.replace(" 00:00", "");
      }
      if (filters.toDate) {
        filters.toDate = filters.toDate.replace(" 00:00", "");
      }

      ///// fetch again api to request
      await this.initByFilter(filters);
    },
    async searchOrderWithoutFilter() {
      ///// reset pagination
      await this.resetPagination();

      ///// check all filter and delete additional filter
      await this.checkFiltersForClearUnselectedForm(this.filterName);

      ///// fetch again api to request
      await this.initByFilter();
    },
    isFormValidate() {
      if (this.$v) {
        this.$v.$touch();
        return !this.$v.$invalid;
      } else {
        return true;
      }
    },

    clearForm() {
      for (let key in this.form) {
        this.form[key] = "";
      }
      this.$nextTick(() => {
        if (this.$v) this.$v.$reset();
      });
      this.clearFormSavedFromState();
    },
  },
  beforeDestroy() {
    this.clearForm();
  },
};
