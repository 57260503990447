import { minValue, numeric, required } from "vuelidate/lib/validators";

export const PAYOFF_IN_PLACE_SCHEMA = {
  /* Form SCHEMA */
  schema: [
    {
      id: "maxPaymentAllowed",
      title: "سقف سفارش (ریال)",
      placeholder: "maxPaymentAllowed",
      required: true,
      cols: 12,
      sm: 6,
      md: 4,
      lg: 3,
      xl: 3,
      type: "currency",
    },
  ],

  /* Form MODEL */
  model: {
    maxPaymentAllowed: 0,
  },
  validations: {
    maxPaymentAllowed: {
      required,
      numeric,
      minValue: minValue(0),
    },
  },
};
