<template>
  <b-row>
    <!--Start Filter show/hide handler    --------------------------------------------------------------->
    <b-col cols="12" class="d-flex flex-row-reverse mb-3">
      <b-button
        @click.stop="filtersShowHideToggle"
        variant="primary"
        size="sm"
        class="text-white px-4"
      >
        <LIWifiFlat icon="wifi-flat" class="fs-8"></LIWifiFlat>
        {{ $_t("components.filters.activities_filter.filter_button") }}
      </b-button>
    </b-col>
    <!--Start Filter show/hide handler    --------------------------------------------------------------->

    <!--Start Filter content              --------------------------------------------------------------->
    <b-col cols="12" class="mb-5">
      <transition name="fade">
        <b-card v-if="isShowFilters" class="border">
          <Form class="p-0">
            <!------------------ start body of form --------------->
            <template v-slot:body
              ><InputGenerator :schema="schema" :validator="$v" />
            </template>
          </Form>
          <!--Start Form Actions    ------------------------------------------------------------------------>
          <b-row class="d-flex flex-row-reverse">
            <b-col cols="12" md="4" lg="2" class="mt-2">
              <b-button
                @click.stop="submit"
                class="w-100 text-white"
                block
                variant="primary"
                size="sm"
              >
                {{ $_t("components.filters.activities_filter.search_button") }}
              </b-button>
            </b-col>
            <b-col cols="12" md="4" lg="2" class="mt-2">
              <b-button
                class="w-100"
                block
                variant="outline-secondary"
                size="sm"
                @click.stop="closeFilters"
              >
                {{ $_t("components.filters.activities_filter.close_button") }}
              </b-button>
            </b-col>
          </b-row>
          <!--End   Form Actions    ------------------------------------------------------------------------>
        </b-card>
      </transition>
    </b-col>
    <!--Start Filter content              --------------------------------------------------------------->
  </b-row>
</template>

<script>
import { mapActions } from "vuex";
import cloneDeep from "lodash.clonedeep";
import InputGenerator from "@/components/form/LipackFormGenerator";
import Form from "@/components/form/LipakForm";
import filter from "@/mixins/mixin.filter-sort";
import { ACTIVITIES_FILTERS_SCHEMA } from "@/packages/manage/schema/activitiesFilters.schema";
import {
  fetchFilteredActivitiesService,
  fetchFilters,
} from "@/packages/manage/services/activities.service";

export default {
  name: "ActivitiesFilter",
  mixins: [filter],
  components: {
    InputGenerator,
    Form,
  },
  data() {
    return {
      isShowFilters: false,
      fetched: false,
      schema: cloneDeep(ACTIVITIES_FILTERS_SCHEMA.schema),
      form: cloneDeep(ACTIVITIES_FILTERS_SCHEMA.model),
    };
  },
  validations: {
    ...ACTIVITIES_FILTERS_SCHEMA.validations,
  },
  watch: {
    isShowFilters: async function (value) {
      if (value && !this.fetched) {
        /* Set Options of select input by fetching from server */
        this.fetched = true;
      }
    },

    form: {
      deep: true,
      handler: function (value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    ...mapActions({
      resetPagination: "pagination/reset",
    }),
    filtersShowHideToggle() {
      this.isShowFilters = !this.isShowFilters;
    },
    closeFilters() {
      this.isShowFilters = false;
    },
    isSuccess(status) {
      return status === "fulfilled";
    },
    hasProperty(obj, prop) {
      return Object.hasOwn(obj, prop);
    },

    assignOptionsToSchema(schema, options) {
      let schemaWithSelectOptions = cloneDeep(schema);
      options.forEach((result) => {
        schemaWithSelectOptions.forEach((schema) => {
          if (
            this.isSuccess(result.status.toLowerCase()) &&
            this.hasProperty(result.value, schema.id)
          ) {
            schema.options = result.value[schema.id];
          }
        });
      });
      return schemaWithSelectOptions;
    },

    async submit() {
      this.resetPagination();

      let paginationParams = await this.paginationQueryBuilder();

      /* Filter empty inputs */
      let query_params = this.filterEmptyItems(this.form);

      Object.assign(query_params, paginationParams);

      /* Call api by filters */
      await fetchFilteredActivitiesService(query_params);
    },
  },
  async mounted() {
    const options = await fetchFilters();
    this.schema = this.assignOptionsToSchema(this.schema, options);
  },
};
</script>

<style scoped></style>
