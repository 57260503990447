import { _t } from "@/tools/Utils";
export const ACTIVITIES_TABLE_HEADER = [
  {
    key: "index",
    label: _t("components.activities_table.table.headers.id"),
    sortable: false,
  },
  {
    key: "name",
    label: _t("components.activities_table.table.headers.name"),
    sortable: false,
  },
  {
    key: "operationType",
    label: _t("components.activities_table.table.headers.operationType"),
    sortable: false,
  },
  {
    key: "operationCode",
    label: _t("components.activities_table.table.headers.operationCode"),
    sortable: false,
  },
  {
    key: "operation",
    label: _t("components.activities_table.table.headers.operation"),
    sortable: false,
  },
  {
    key: "requestDate",
    label: _t("components.activities_table.table.headers.requestDate"),
    sortable: false,
  },
  {
    key: "time",
    label: _t("components.activities_table.table.headers.time"),
    sortable: false,
  },
];
