<template>
  <ConfirmPassword />
</template>

<script>
import ConfirmPassword from "@/packages/authentication/presentation/components/ConfirmPassword";
export default {
  name: "ResetPassword",
  components: { ConfirmPassword },
};
</script>

<style scoped></style>
