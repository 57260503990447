// Handler
// handle server or connection errors

import urls from "./Urls";
import { clearAuth } from "./AuthService.service";

const handler = {
  // handle errors
  error(result) {
    try {
      let error = new ServerError(
        result.response.status,
        result.response.data.message,
        result.response.data.error
      );
      if (error.status === 401) clearAuth();
      return Promise.reject(result);
    } catch (e) {
      return Promise.reject(result);
    }
  },

  // handle response
  response(result) {
    try {
      let request_url = result.config.url;
      if (request_url.includes(urls.url("base_url"))) return result.data;
      return Promise.reject(result);
    } catch (e) {
      return Promise.reject(result);
    }
  },
};

class ServerError {
  status;
  message;
  errors;
  constructor(status, message, errors) {
    try {
      this.status = status;
      this.message = message;
      this.errors = errors;
    } catch (e) {
      this.status = null;
      this.message = null;
      this.errors = null;
    }
  }
}

export default handler;
