// initial state
const state = () => ({
  formList: [],
  formsShouldBeClear: [],
  form: {
    queryParams: {},
    state: false,
  },
});

// getters
const getters = {
  formList: (state) => state.formList,
  formsShouldBeClear: (state) => state.formsShouldBeClear,
  form: (state) => state.form,
};

// mutations
const mutations = {
  mutateSubmitForm(state, payload) {
    if (!payload) return;
    state.formList.push(payload);
  },
  mutateCheckFiltersForClearUnselectedForm(state, payload) {
    state.formsShouldBeClear = state.formList.filter(
      (item) => item !== payload
    );
  },
  mutateInitByFilter(state, payload) {
    state.form.queryParams = payload;
    state.form.state = !state.form.state;
  },
  mutateClearForm(state) {
    state.form = {
      queryParams: null,
      state: false,
    };
  },
};

// actions
const actions = {
  submitForm({ commit }, payload) {
    commit("mutateSubmitForm", payload);
  },
  checkFiltersForClearUnselectedForm({ commit }, filterName) {
    commit("mutateCheckFiltersForClearUnselectedForm", filterName);
  },
  initByFilter({ commit }, payload) {
    commit("mutateInitByFilter", payload);
  },
  clearForm({ commit }) {
    commit("mutateClearForm");
  },
};

export default {
  namespaced: true,
  name: "filter",
  state,
  getters,
  actions,
  mutations,
};
