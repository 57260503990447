module.exports = {
  components: {
    account: {
      auth: {
        title: "به داشتبورد خوش آمدید",
        subtitle: "پنل",
        buttons: {
          login: "Login",
          forgotten_password: "Forget password",
        },
      },
      forgot_password: {
        title: "Forgot Your Password?",
        subtitle: "Enter your email and we will send you a reset link.",
        buttons: {
          submit_text: "Send Reset Link",
        },
      },
      information: {
        title: "اطلاعات حساب کاربری",
      },
      reset_password: {
        title: "Enter your new password",
        subtitle: "Please enter a strong password.",
        buttons: {
          submit_text: "Confirm",
        },
      },
    },
    app: {
      page_not_found: {
        title: "404",
        subtitle_part_one: "متاسفم!",
        subtitle_part_two: "صفحه مورد نظر یافت نشد.",
        buttons: {
          go_back: "برگشت",
        },
      },
    },
    dashboard: {
      welcome: {
        title: "خوش آمدید",
      },
    },
  },
  router: {
    index: {
      panel_title: "داشبورد",
    },
    routes: {
      account_routes: {
        account: "حساب کاربری",
        setting: "تنظیمات",
      },
      auth_routes: {
        login: "Login",
        forgot_password: "Forgot Password",
        reset_password: "Reset Password",
      },
      page_not_found_routes: {
        not_found: "صفحه مورد نظر یافت نشد.",
      },
      dashboard_routes: {
        dashboard: "داشبود",
        main: "داشبورد",
        operations: "عملیات",
        welcome: "خوش آمدید",
      },
    },
  },
  navigation_links: {
    users: "کاربران",
    orders: "سفارش ها",
    "referral-orders": "سفارش های مرجوعی",
    "canceled-orders": "سفارش های لفو شده",
    comments: "نظرات",
    feedbacks: "بازخوردها",
    survey: "نظر سنجی",
    manage: "مدیریت",
    activities: "فعالیت ها",
    contact_us: "تماس با ما",
    tracking_code: "ثبت کد رهگیری",
  },
  views: {
    auth: {
      login: {
        title: "PSP Dashboard",
        body: {
          description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequaunt mollit anim id est laborum.",
        },
        buttons: {
          order_button: "Order",
        },
      },
    },
  },
  table: {
    default: {
      busy_text: "در حال بارگذاری...",
      empty_text: "رکوردی برای نمایش وجود ندارد.",
      empty_filter: "رکوردی برای نمایش وجود ندارد.",
    },
  },
  validations: {
    alpha: "{attribute} فقط می تواند از حروف تشکیل شود",
    alpha_num: "{attribute} فقط میتواند از حروف و اعداد تشکیل شود",
    alpha_dash:
      "{attribute} فقط می تواند از حروف، اعداد، خط فاصله و زیرخط تشکیل شود",
    alpha_spaces: "{attribute} فقط می تواند از حروف و فاصله تشکیل شود",
    between: "{attribute} باید بین {min} و {max} کارکتر باشد",
    confirmed: "{attribute} با رمز عبور مطابقت ندارد",
    digits: "{attribute} باید یک مقدار عددی و دقیقاً {length} رقم باشد",
    dimensions:
      "{attribute} باید در اندازه {width} پیکسل عرض و {height} پیکسل ارتفاع باشد",
    email: "{attribute} باید یک ایمیل(رایانامه) معتبر باشد",
    excluded: "{attribute}باید یک مقدار معتبر باشد",
    ext: "{attribute} باید یک فایل معتبر باشد",
    image: "{attribute} باید یک تصویر باشد",
    max_value: "مقدار {attribute} باید {max} یا کمتر باشد",
    max: "{attribute} نباید بیشتر از {length} کارکتر باشد",
    mimes: "{attribute} باید از نوع معتبر باشد",
    min_value: "مقدار {attribute} باید {min} یا بیشتر باشد",

    min: "{attribute} باید حداقل {length} کارکتر باشد",
    numeric: "{attribute} فقط می تواند عددی باشد",
    oneOf: "{attribute} باید یک مقدار معتبر باشد",
    regex: "قالب {attribute} قابل قبول نیست",
    required_if: "{attribute} الزامی است",
    required: "{attribute} الزامی است",
    size: "حجم {attribute} کمتر از {size}KB باشد",
    double: "قسمت {attribute} باید یک اعشار معتبر باشد",
    minLength: "مقدار {attribute} باید {min} یا بیشتر باشد",
    maxLength: "مقدار {attribute} باید {min} یا کمتر باشد",
    isEnglish: "لطفا {attribute} را به انگلیسی وارد کنید.",
    isPersian: "لطفا {attribute} را به فارسی وارد کنید.",
    sameAsMultiple: "مقدار {attribute} معتبر نمی باشد.",
    sameAsPassword: "مقدار {attribute} با رمز عبور برابر نیست.",
    strongPassword:
      "پسورد باید شامل حروف کوچک و بزرگ و کارکترهای ویژه $#%^&*@ باشد.",

    mixCharacterPersianAndEnglishValidation: "{attribute} معتبر نمیباشد",
    nationalId: "شناسه ملی وارد شده صحیح نمی باشد.",
  },

  CREATE_SUCCESS: "با موفقیت ثبت شد.",
  EDIT_SUCCESS: "ویرایش با موفقیت انجام شد.",
  DELETE_SUCCESS: "حذف با موفقیت انجام شد.",
  ERROR: "خطا",
  ERROR_FETCH_STOCKS: "مشکلی در دریافت لیست انبار ها از سرور به وجود آمده است.",
  ERROR_FETCH_COURIER_SHIFTS:
    "مشکلی در دریافت لیست شیفت های پیک از سرور به وجود آمده است.",
  ERROR_FETCH_SHIPPING_METHODS:
    "مشکلی در دریافت لیست روش های ارسال از سرور به وجود آمده است.",
  ERROR_FETCH_CUSTOMERS:
    "مشکلی در دریافت لیست خریدار‌ها از سرور به وجود آمده است.",
  FAILED_FETCH_ORDERS: "لیست سفارش ها دریافت نشد. لطفا مجدد تلاش نمایید.",

  ERROR_FETCH_ORDER_BY_ID: "اطلاعات سفارش شماره {orderNum} از سرور دریافت نشد.",
  ERROR_UPDATE_ORDER_TO_PACKING_BY_ID:
    "تغییر وضعیت سفارش شماره {orderNum} انجام نشد.",
  ERROR_REGISTER_SINGLE_SERIAL_NUMBER:
    "شماره سریال {serialNumber} وارد شده برای این شماره کالا {itemId} ثبت نشد.",
  FINISHED: "فرآیند این سفارش قبلا تکمیل شده است",
  UPDATED_SUCCESS: "به روز رسانی با موفقیت انجام شد.",
  "Duplicate input": "شما این مقدار را قبلا وارد کرده اید.",
  INVALID_PAYLOAD: "مقادیر ارسال شده صحیح نمی باشد.",
  INVALID_CREDENTIAL: "دسترسی وجود ندارد.",
  UNAUTHORIZED: "دسترسی وجود ندارد.",
  NOT_FOUND: "یافت نشد.",
  " [product_english_title_key] ==>  already exists":
    "عنوان انگلیسی قبلا وارد شده است.",
  " [product_title_key] ==>  already exists": "عنوان فارسی قبلا وارد شده است.",
  " [product_variant_product_id_color_id_guarantee_id_uindex] ==>  already exists":
    "گارانتی و رنگ وارد شده برای این محصول قبلا ثبت شده است.",
  "this image already exist": "یک عکس دوبار نمی‌تواند برای یک محصول اضافه شود.",
  CREATE_FAILED: "ثبت با خطا مواجه شد.لطفا مجددا تلاش نمایید.",
  UPDATED_FAILED: "به روز رسانی با خطا مواجه شد.لطفا مجددا تلاش نمایید.",
  TOKEN_NOT_FOUND: "توکن یافت نشد.",
  LOGIN_FAILED: "ورود انجام نشد.مجددا تلاش نمایید.",
  LOGIN_SUCCESS: "ورود با موفقیت انجام شد.",
  FAILED_FETCH_REALMS: "دریافت لیست گروه ها با خطا مواجه شده است.",
  FAILED_FETCH_ROLES: "دریافت لیست نقش ها با خطا مواجه شده است.",
  SEND_EMAIL_SUCCESS: "کد تایید به ایمیل شما ارسال شد.",
  SEND_EMAIL_FAILED: "ارسال کد تایید با خطا مواجه شده است.",
  VERIFY_SUCCESS: "کد با موفقیت تایید شد.",
  VERIFY_FAILED: "خطا در تایید کد.",
  FAILED_FETCH_ACTIVITIES: "دریافت فعالیت ها از سرور با خطا مواجه شده است.",
  ERROR_FETCH_USERS: "لیست کاربران دریافت نشد.",
  ERROR_FETCH_OPERATION_TYPES: "لیست نوع عملکردها دریافت نشد.",
  IS_NOT_POST_OR_TIPAX_OR_MAHEX: "سفارش پست یا تیپاکس یا ماهکس نیست.",
  ORDER_NOT_FOUND: "شماره سفارش پیدا نشد.",
};
