<template>
  <b-row class="pt-0">
    <b-col
      cols="12"
      class="card text-start d-flex justify-content-start border-0 rounded-lg p-3"
    >
      <p class="text-primary text-right mr-0 mt-2">اطلاعات تحویل گیرنده</p>
      <br />
      <b-row class="d-flex">
        <b-col cols="3" style="text-align: justify"
          ><span>نام و نام خانوادگی:</span><br /><span>هدیه مسعودی</span>
        </b-col>
        <b-col cols="3" style="text-align: justify"
          ><span>شماره تماس:</span><br /><span>0214587485</span>
        </b-col>
        <b-col cols="3" style="text-align: justify"
          ><span>آدرس:</span><br /><span>تهران , ولیعصر ,....</span>
        </b-col></b-row
      >
    </b-col>
    <b-col
      cols="12"
      class="card text-start d-flex justify-content-start border-0 rounded-lg p-3 mt-3"
    >
      <p class="text-primary text-right mr-0 mt-2">اطلاعات تحویل گیرنده</p>
      <br />
      <b-row class="d-flex ma-5">
        <b-col cols="3" style="text-align: justify"
          ><span>نام و نام خانوادگی:</span><br /><span>هدیه مسعودی</span>
        </b-col>
        <b-col cols="3" style="text-align: justify"
          ><span>شماره تماس:</span><br /><span>0214587485</span>
        </b-col>
        <b-col cols="3" style="text-align: justify"
          ><span>آدرس:</span><br /><span>تهران , ولیعصر ,....</span>
        </b-col></b-row
      >
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "DetailedInformation",
  data: () => {
    return {};
  },
};
</script>
<style scoped lang="scss">
.card {
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
  background-color: #dddde0;
  p {
    // border-bottom: 1px solid #fe6a34;
  }
}
</style>
