import localStorageServiceService from "./LocalStorageService.service";
import router from "@/router";
// Auth Class
// this class handle user info and user
// token.

const authService = {
  supper_key: "@auth_user:",

  // Set user info
  setUser(value) {
    localStorageServiceService.set(this.supper_key + "user_info", value);
  },

  // Get user info
  getUser() {
    return localStorageServiceService.get(this.supper_key + "user_info");
  },

  // This method save user token
  // on database
  setToken(token) {
    localStorageServiceService.set(this.supper_key + "token", token);
    localStorageServiceService.set(this.supper_key + "login_time", Date.now());
  },

  setFirstLoginToken(token) {
    localStorageServiceService.set(
      this.supper_key + "first_login_token",
      token
    );
  },

  setResetPasswordToken(token) {
    localStorageServiceService.set(
      this.supper_key + "reset_password_token",
      token
    );
  },

  // This method get user token
  // on database
  getToken() {
    return localStorageServiceService.get(this.supper_key + "token");
  },

  getFirstLoginToken() {
    return localStorageServiceService.get(
      this.supper_key + "reset_password_token"
    );
  },

  getResetPasswordToken() {
    return localStorageServiceService.get(
      this.supper_key + "first_login_token"
    );
  },

  getLoginTime() {
    return localStorageServiceService.get(this.supper_key + "login_time");
  },

  // This method get user token
  // on database
  check() {
    let token = this.getToken();
    return token != null && token !== "" && token !== undefined;
  },

  // This method remove token and all user data
  // of database
  removeToken() {
    localStorageServiceService.remove(this.supper_key + "user_info");
    localStorageServiceService.remove(this.supper_key + "token");
    localStorageServiceService.remove(this.supper_key + "first_login_token");
    localStorageServiceService.remove(this.supper_key + "user_info");
    // window.localStorage.clear();
  },

  // This method remove token and all user data
  // of database
  clear() {
    this.removeToken();
    router.push({ name: "authentication" }).catch(() => {});
  },
};
export function clearAuth() {
  authService.clear();
}
export default authService;
