import api from "@/tools/ApiService.service";
import urls from "@/tools/Urls";
/**
 *  change password
 *  ------------------------
 *  Api address| /user/detail/change_password
 *  ------------------------
 *  Method| PATCH
 *  ------------------------
 *  Description| change password
 *  ------------------------
 *  @param payload {Object}
 *  ------------------------
 *  @return  type {Promise}
 *  ------------------------
 **/
export const changePasswordAxiosRequest = function (payload) {
  return api.put(urls.api("user_detail_change_password"), payload);
};
