export default [
  {
    path: "contact-us",
    name: "contact-us",
    component: () =>
      import(
        /* webpackChunkName: "contact-us" */ "@/packages/contact-us/views/Index.vue"
      ),
    meta: {
      title: "تماس با ما",
    },
    redirect: { name: "contact-us.list" },
    children: [
      {
        path: "list",
        name: "contact-us.list",
        component: () =>
          import(
            /* webpackChunkName: "contact-us" */ "@/packages/contact-us/views/List.vue"
          ),
        meta: {
          title: "لیست تماس با ما",
        },
      },
      {
        path: ":contactUsId",
        name: "contact-us.details",
        component: () =>
          import(
            /* webpackChunkName: "contact-us" */ "@/packages/contact-us/views/Details.vue"
          ),
        meta: {
          title: "جزئیات تماس با ما",
        },
      },
    ],
  },
];
