<template>
  <div>
    <ul class="list-horizontal d-block d-lg-flex">
      <li class="pt-3 pt-lg-0">
        <span
          >{{
            $_t(`components.users.user.profile.address_information.phone`)
          }}:</span
        >
        <span class="mr-lg-1 font-weight-bold overflow-wrap">{{
          addressContact.receiverPhone
        }}</span>
      </li>
      <li class="mr-lg-5 pt-3 pt-lg-0 text-right">
        <span
          >{{
            $_t(
              `components.users.user.profile.address_information.postal_code`
            )
          }}:</span
        >
        <span class="mr-lg-1 font-weight-bold overflow-wrap">{{
          addressContact.postalCode
        }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Contact",
  props: {
    addressContact: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped></style>
