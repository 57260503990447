// initial state
import storage from "@/tools/Storage";
const key = "pagination-";
const state = () => ({
  page: storage.get(key + "page") ? storage.get(key + "page") : 1,
  total_items: storage.get(key + "total") ? storage.get(key + "total") : 0,
  total_pages: storage.get(key + "total_page")
    ? storage.get(key + "total_page")
    : 0,
  size:
    storage.get(key + "size-fix") ||
    process.env.VUE_APP_TOTAL_ITEMS_PER_PAGE ||
    4,
});

// getters
const getters = {
  current_page: (state) => state.page,
  size: (state) => state.size,
  total_items: (state) => state.total_items,
  total_pages: (state) => state.total_pages,
};

// mutations
const mutations = {
  setPage(state, value) {
    state.page = value;
    storage.set(key + "page", value);
  },
  setTotal(state, value) {
    state.total_items = value;
    storage.set(key + "total", value);
  },
  setTotalPage(state, value) {
    state.total_pages = value;
    storage.set(key + "total_page", value);
  },
  setPageSize(state, value) {
    state.size = value || storage.get(key + "size-fix");
    storage.set(key + "size-fix", value);
  },
};

// actions
const actions = {
  setCurrentPage({ commit }, data) {
    commit("setPage", data);
  },
  nextPage({ commit, state }) {
    if (state.total > state.page) commit("setPage", ++state.page);
  },
  previousPage({ commit, state }) {
    if (state.page > 0) commit("setPage", --state.page);
  },
  setTotalItems({ commit }, data) {
    commit("setTotal", data);
  },
  setTotalIPages({ commit }, data) {
    commit("setTotalPage", data);
  },
  setSize({ commit }, data) {
    commit("setPageSize", data);
  },
  reset({ commit }) {
    commit("setPage", 1);
    commit("setTotal", 0);
    commit("setTotalPage", 0);
    commit(
      "setPageSize",
      storage.get(key + "size-fix") ||
        process.env.VUE_APP_TOTAL_ITEMS_PER_PAGE ||
        4
    );
  },
};

export default {
  namespaced: true,
  name: "pagination",
  state,
  getters,
  actions,
  mutations,
};
