import api from "@/tools/Api";
import urls from "@/tools/Urls";

/*
 * register order tracking code
 *--------------------------
 * Api address /
 *--------------------------
 * @param payload  {Object}
 * @param query_params  {Object}
 *--------------------------
 * methods : POST
 * -------------------------
 * description :
 * -------------------------
 * register order tracking code
 *--------------------------
 * @return promise
 *--------------------------
 */
export const registerOrderTrackingCodeAxiosReq = function (
  payload,
  query_params = null
) {
  return api.put(urls.api("register_tracking_order"), payload, {
    params: {
      ...query_params,
    },
  });
};
