import api from "@/tools/Api";
import urls from "@/tools/Urls";

/*
 *  fetch  comments
 *  ------------------------
 *  Api address | /api/v1/comments
 *  ------------------------
 *  @param payload { Object }
 *  ------------------------
 *  method : Get
 *  ------------------------
 *  Description :
 *  ------------------------
 *  fetch  comments
 *  ------------------------
 *  @return Promise
 *  ------------------------
 */

export const rejectionCommentAxiosRequest = function (payload) {
  return api.post(urls.api("change_status_comment"), payload);
};
