<template>
  <b-row class="w-100 mt-5 mx-12">
    <b-col cols="12" class="d-flex p-0">
      <b-col cols="3">
        <b-card
          class="card text-center justify-content-center border-0 rounded"
        >
          <p class="ml-5 mr-5 text-primary">پرداخت موفق</p>
          <span>8آبان 1400</span><br />
          <span>14:20</span>
        </b-card></b-col
      >
      <b-col cols="3">
        <b-card
          class="card text-center justify-content-center border-0 rounded"
        >
          <p class="ml-5 mr-5 text-primary">پرداخت موفق</p>
          <span>8آبان 1400</span><br />
          <span>14:20</span>
        </b-card></b-col
      >
      <b-col cols="3">
        <b-card
          class="card text-center justify-content-center border-0 rounded"
        >
          <p class="ml-5 mr-5 text-primary">پرداخت موفق</p>
          <span>8آبان 1400</span><br />
          <span>14:20</span>
        </b-card></b-col
      >
      <b-col cols="3">
        <b-card
          class="card text-center justify-content-center border-0 rounded"
          border-0
          rounded
        >
          <p class="ml-5 mr-5 text-primary">پرداخت موفق</p>
          <span>8آبان 1400</span><br />
          <span>14:20</span>
        </b-card></b-col
      >
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "CartBasket",
  data: () => {
    return {};
  },
};
</script>
<style scoped lang="scss">
.card {
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
  p {
    border-bottom: 1px solid #fe6a34;
  }
}
</style>
