<template>
  <!---------------start filter receipts-detail-received-------------------------------->
  <b-card class="shadow border-0 mb-3" no-body>
    <b-row no-gutters class="d-flex justify-content-center">
      <!-------start tabs in orders ---------------------------------------------->
      <b-col cols="12" class="order-tabs">
        <b-nav tabs fill style="height: 70px !important">
          <b-nav-item
            class="d-flex justify-content-center align-content-start fs-16 font-weight-bold"
            style="height: 100%; width: 33%"
            v-for="tab in tabs"
            :key="tab.index"
            :to="tab.to"
            @click="fetchAgain"
          >
            <div>{{ tab.name }}</div></b-nav-item
          >
        </b-nav>
      </b-col>
      <!-------end tabs in orders ------------------------------------------------>

      <!-------start childs of tabs ---------------------------------------------->
      <b-col cols="12"> <router-view :path="$route.path" /> </b-col>
      <!-------end childs of tabs ------------------------------------------------>
    </b-row>
  </b-card>
</template>

<script>
import filterContainer from "@/mixins/filter-container";
export default {
  name: "Orders",
  mixins: [filterContainer],
  data() {
    return {
      tabs: [
        {
          name: "کل سفارش ها",
          to: { name: "list.all-orders" },
        },
        {
          name: "سفارش های مرجوعی",
          to: { name: "list.returned-orders" },
        },
        {
          name: " سفارش های لغو شده",
          to: { name: "list.canceled-orders" },
        },
      ],
      filterName: "withOutFilter",
    };
  },

  methods: {
    fetchAgain() {
      this.searchOrderWithoutFilter();
    },
  },
};
</script>

<style scoped>
.nav {
  padding: 0;
  height: 56px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
}
.nav-item {
  display: inline-flex;
  align-self: self-end;
  height: 100%;
}
.nav-link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: rgba(0, 0, 0, 0.49) !important;
}
.router-link-exact-active {
  border: 0 !important;
  border-bottom: 2px solid #fe6a34 !important;
  color: #fe6a34 !important;
}
</style>
