<template>
  <PayoffInternet />
</template>

<script>
import PayoffInternet from "@/packages/manage/components/payoff-internet/Index";
export default {
  components: { PayoffInternet },
};
</script>

<style scoped></style>
