var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-table',{staticClass:"tableBox m-0 fa-num-font",attrs:{"tbody-tr-class":"align-middle fs-14 border-0","thead-class":"text-secondary fs-12 fw-lighter border-0","responsive":"","hover":"","busy":_vm.is_loading,"items":_vm.orders,"fields":_vm.tableHeader,"primary-key":"1","show-empty":"","empty-text":_vm.$_t('table.default.empty_text'),"empty-filtered-text":_vm.$_t('table.default.empty_text')},on:{"update:busy":function($event){_vm.is_loading=$event}},scopedSlots:_vm._u([{key:"cell(row)",fn:function(ref){
var index = ref.index;
return [_c('span',[_vm._v(_vm._s(index + 1))])]}},{key:"cell(price)",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$_currency(Number(item.price) / 10)))])]}},{key:"cell(returnDate)",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$_date(item.date)))])]}},{key:"cell(returnStatus)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"px-2 py-2"},[_vm._v(_vm._s(_vm.setStattus(item.returnStatus)))])]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('b-button',{staticClass:"text-info bg-light-blue fs-13 w-100 mr-1 text-info w-100 border-0",attrs:{"to":{
        name: 'orders.details-returned-orders',
        params: { id: item.returnId },
      }}},[_vm._v(" جزئیات ")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }