<template>
  <div>
    <b-card v-for="item in items" :key="item.id" class="border-0 shadow mt-3">
      <ItemDetail :item="item" />
      <hr />
      <ItemForm :item="item" />
    </b-card>
  </div>
</template>

<script>
import ItemDetail from "@/packages/orders/components/order-detail/returned-orders-detail/step3/items/ItemDetail";
import ItemForm from "@/packages/orders/components/order-detail/returned-orders-detail/step3/items/ItemForm";
import { mapGetters } from "vuex";
export default {
  components: { ItemForm, ItemDetail },
  computed: { ...mapGetters({ items: "returned/items" }) },
};
</script>

<style scoped></style>
