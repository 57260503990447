<template>
  <b-row
    class="mt-3 d-block d-lg-flex mt-3 pb-4 bg-white rounded-lg shadow fa-num-font py-4 px-3 px-lg-4"
  >
    <b-col
      class="fs-16 font-weight-bold text-right d-flex"
      v-for="item in innerPersonalInfoUser"
      :key="item.index"
      cols="12"
      md="6"
      lg="6"
      xl="4"
    >
      <div class="text-more">{{ item.title }}:</div>
      <div class="text-more">{{ item.value }}</div>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "OrderPersonInfo",
  props: {
    personalInfo: {
      type: Object,
      required: true,
    },
  },
  computed: {
    innerPersonalInfoUser() {
      return [
        {
          title: "نام و نام خانوادگی",
          value: this.personalInfo.fullName || "",
          show: true,
        },
        {
          title: "شماره موبایل",
          value: this.personalInfo.mobileNum || "",
          show: true,
        },

        {
          title: "کد ملی",
          value: this.personalInfo.ssn || "",
          show: true,
        },
      ];
    },
  },
};
</script>
