import api from "@/tools/Api";
import urls from "@/tools/Urls";

/*
 *  change payment method
 *  ------------------------
 *  Api address | /api/v1/
 *  ------------------------
 *  @param
 *  ------------------------
 *  method : Get
 *  ------------------------
 *  Description :
 *  ------------------------
 *  change payment method
 *  ------------------------
 *  @return Promise
 *  ------------------------
 */

export const changePaymentMethodAxiosReq = function (payload) {
  return api.put(
    urls.api("change_payment_method_to_receipt", null, "v2/"),
    payload
  );
};
