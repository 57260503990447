import dayjs from "dayjs";
import jalaliday from "jalaliday";
dayjs.extend(jalaliday);
const relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);
require("dayjs/locale/fa");
require("dayjs/locale/en");

import store from "@/store/index";

export default {
  install(Vue) {
    // an instance method
    Vue.prototype.$_date = function (date, type = "short") {
      let lang = store.getters["lang/lang"] || process.env.VUE_APP_I18N_LOCALE;
      let format_type = "DD MMM YYYY";
      if (type === "long") format_type = "DDDD MMM YYYY, h:mm a";
      if (lang === "en") return dayjs(date).locale("en").format(format_type);
      else if (lang === "fa") {
        format_type = "YYYY/MM/DD";
        if (type === "fromNow")
          return dayjs().calendar("jalali").locale("fa").to(dayjs(date));
        if (type === "long") format_type = "YYYY/MM/DD";
        if (type === "jalali") format_type = "DD MMMM YYYY";
        if (type === "day") format_type = "dddd";
        if (type === "time") format_type = "HH:mm:ss";
        if (type === "en")
          return dayjs(date, {
            jalali: true,
          }).format("YYYY-MM-DD");
        return dayjs(date).calendar("jalali").locale("fa").format(format_type);
      }
    };
  },
};
