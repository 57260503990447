<template>
  <b-card class="rounded mt-4 shadow">
    <!--Start Organization   ------------------------------------------------------>
    <div class="d-flex justify-content-end py-2">
      <b-button
        size="sm"
        variant="outline-primary"
        class="border-0"
        @click="isShowModal = true"
      >
        <LIEdit class="fs-12" />
      </b-button>
    </div>
    <!--End   Organization   ------------------------------------------------------>
    <b-row class="list d-flex h-100">
      <!--Start economic info  ------------------------------------------------------>
      <b-col
        cols="12"
        md="6"
        lg="3"
        v-for="legal in legalDetail.legal"
        :key="legal.index"
        class="d-flex justify-content-start pt-3 pt-0"
      >
        <span class="text-secondary">{{ legal.title }}:</span>
        <span class="mr-1 overflow-wrap">{{ legal.value }}</span>
      </b-col>

      <!--End   economic info  ------------------------------------------------------>
    </b-row>

    <EditLegaInformationModal
      :legal-detail="legalDetail"
      :modal="isShowModal"
      @close="isShowModal = false"
    />
  </b-card>
</template>

<script>
import EditLegaInformationModal from "@/packages/users/components/user/profile/legal-information/modal/EditLegaInformationModal";
export default {
  name: "InformationList",
  components: {
    EditLegaInformationModal,
  },
  data() {
    return {
      isShowModal: false,
    };
  },
  props: {
    legalDetail: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
li {
  width: 25% !important;
  flex-basis: 25% !important;
}
</style>
