<template>
  <div class="bg-white py-3 px-5 mb-3 d-flex">
    <router-link
      v-for="(link, index) in links"
      :key="`link-${index}`"
      :ref="`link-${index}`"
      class="ml-4 no-decoration"
      :class="{ 'btn btn-primary text-white': link.isActive }"
      :to="link.to"
    >
      {{ link.title }}
    </router-link>
  </div>
</template>

<script>
export default {
  data() {
    return {
      links: [
        {
          title: "مدیریت مرجوعی",
          to: { name: "manage.return" },
          isActive: false,
        },
        {
          title: "مدیریت پرداخت",
          to: { name: "manage.payoff" },
          isActive: false,
        },
        {
          title: "فعالیت‌ها",
          to: { name: "manage.activities" },
          isActive: false,
        },
      ],
    };
  },
  watch: {
    $route() {
      this.syncLinkActivation();
    },
  },
  mounted() {
    this.syncLinkActivation();
  },
  methods: {
    syncLinkActivation() {
      for (let i = 0; i < this.links.length; ++i) {
        const [link] = this.$refs[`link-${i}`];
        this.links[i].isActive = !!this.$route.name.match(link.to.name);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.no-decoration {
  text-decoration: none;
  color: inherit;
}
</style>
