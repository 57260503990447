<template>
  <b-container fluid class="shadow border-0 bg-white p-4 m-3 mt-lg-2">
    <b-row>
      <!--Start Actions                ------------------------------------------------------>
      <b-col cols="12">
        <div class="d-flex flex-wrap justify-content-start align-items-center">
          <b-avatar
            variant="info-20"
            size="70"
            class="d-flex justify-content-center align-items-center"
          >
            <LIUserInfo class="fs-20 text-info" />
          </b-avatar>
          <span class="text-info mx-3">{{
            $_t(`components.users.user.profile.bank_information.title`)
          }}</span>
          <div class="mr-lg-auto ml-3">
            <span
              v-if="bankDetails.isPaymentByInvoiceActive"
              class="fs-14 text-more"
            >
              <LICheckCircle class="text-success" />

              کاربر مجاز به پرداخت بصورت حواله می‌باشد
            </span>

            <span v-else class="fs-14 text-more">
              <LITimesCircle class="text-danger" /> کاربر مجاز به پرداخت بصورت
              حواله نمی‌باشد
            </span>
          </div>
          <div class="mr-auto mx-lg-0 ml-lg-0 mt-3">
            <b-button
              size="sm"
              class="fs-14"
              variant="primary"
              @click="openPaymentConfirmationModal"
              >ویرایش پرداخت به روش حواله </b-button
            ><b-button
              size="sm"
              pill
              variant="outline-primary"
              class="border-0 ml-0 mr-2"
              @click="isShowModal = true"
            >
              <LIEdit class="fs-12" />
            </b-button>
          </div>
        </div>
      </b-col>
      <!--End   Actions                ------------------------------------------------------>

      <!--Start Bank info              ------------------------------------------------------>
      <b-col cols="12" class="text-right mt-5">
        <InformationList :bank-details="bankDetails.numbers" />
      </b-col>
      <!--Start Bank info              ------------------------------------------------------>
    </b-row>
    <EditBankInformationModal
      :bankDetails="bankDetails.numbers"
      :modal="isShowModal"
      @close="isShowModal = false"
    />
    <PaymentConfirmationModal
      :modal="paymentConfirmationModal"
      @close="paymentConfirmationModal = false"
      :isPaymentByInvoiceActive="bankDetails.isPaymentByInvoiceActive"
      :allowed-access="bankDetails.isPaymentByInvoiceActive"
    />
  </b-container>
</template>

<script>
import InformationList from "@/packages/users/components/user/profile/bank-information/InformationList";
import EditBankInformationModal from "@/packages/users/components/user/profile/bank-information/modal/EditBankInformationModal";
import PaymentConfirmationModal from "@/packages/users/components/user/profile/bank-information/modal/PaymentConfirmationModal";
import { mapGetters } from "vuex";
export default {
  name: "BankInformation",
  components: {
    EditBankInformationModal,
    InformationList,
    PaymentConfirmationModal,
  },

  data() {
    return {
      isShowModal: false,
      paymentConfirmationModal: false,
    };
  },
  computed: {
    ...mapGetters({
      bankDetails: "profile/bankDetails",
    }),
  },

  methods: {
    openPaymentConfirmationModal() {
      this.paymentConfirmationModal = true;
    },
  },
};
</script>

<style scoped></style>
