<template>
  <div class="app">
    <div class="info ss02">
      <div class="print-header"><InvoiceHeader /></div>
      <table class="w-100">
        <thead>
          <tr>
            <td>
              <div class="header-space"></div>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <b-card no-body class="border-0 px-0" elevation="0">
                <div class="main content">
                  <div class="border-0 px-0">
                    <!----------start date and code section ---------------------->
                    <OrderTopDetail
                      :order-code="
                        orderDetail.customerDetails.invoiceCode
                          ? orderDetail.customerDetails.invoiceCode
                          : ''
                      "
                      :order-date="orderDetail.customerDetails.createdAt"
                      :order-sepidar-id="
                        orderDetail.customerDetails.sepidarId || '-'
                      "
                    />
                    <!----------end date and code section ------------------------>

                    <!----------start seller info -------------------------------->
                    <Seller />
                    <!----------end seller info ---------------------------------->

                    <!----------start buyer info --------------------------------->
                    <Buyer :buyer-detail="orderDetail.customerDetails" />
                    <!----------end buyer info ----------------------------------->

                    <!----------start list of product in order ------------------->
                    <OrderItemTabel :order-detail="orderDetail" />
                    <!----------end list of product in order --------------------->
                  </div>
                </div>
              </b-card>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td><div class="footer-space">&nbsp;</div></td>
          </tr>
        </tfoot>
      </table>
      <div class="print-footer"><InvoiceFooter /></div>
    </div>
  </div>
</template>

<script>
import InvoiceHeader from "./InvoiceHeader";
import OrderTopDetail from "./OrderTopDetail";
import Seller from "./Seller";
import Buyer from "./Buyer";
import OrderItemTabel from "./OrderItemTabel";
import InvoiceFooter from "./InvoiceFooter";

export default {
  name: "Invoice",
  components: {
    InvoiceHeader,
    OrderTopDetail,
    Seller,
    Buyer,
    OrderItemTabel,
    InvoiceFooter,
  },
  props: {
    orderDetail: { type: [Array, Object], required: true },
  },

  methods: {
    closeModal() {
      this.rating = 0;
      this.title = "";
      this.content = "";
      this.$emit("input", false);
    },
  },
};
</script>
<style scoped></style>
