<template>
  <b-row>
    <template v-if="comments.length">
      <b-col
        cols="12"
        v-for="(comment, commentIndex) in comments"
        :key="commentIndex"
        class="d-block justify-content-start align-content-start mb-4"
      >
        <div class="d-flex text-secondary text-right">
          <img
            class="pl-2"
            width="30"
            height="30"
            :src="require('@/assets/img/LIPAKd.svg')"
            alt="23"
          />

          <div>
            <span> {{ comment.user }} </span>
            <div class="w-100 text-right pt-1 fs-12 text-secondary">
              {{ $_date(comment.date, "time") }} -
              {{ $_date(comment.date, "jalai") }}
            </div>
          </div>
        </div>
        <b-card class="boeder rounded-lg text-right mt-1 p-0 fs-14">
          <pre style="font-family: yekan-bakh; white-space: pre-wrap">{{
            escape(comment.comment.trim())
          }}</pre>
        </b-card>
      </b-col>
    </template>
    <div
      v-else
      class="w-100 d-flex justify-content-center text-primary fs-14 mt-3"
    >
      نظر از طرف پشتیبان ثبت نشده است !
    </div>
  </b-row>
</template>

<script>
export default {
  name: "Comments",
  props: {
    comments: {
      type: Array,
      requored: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    escape(str) {
      var tagsToReplace = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
      };
      return str.replace(/[&<>]/g, function (tag) {
        return tagsToReplace[tag] || tag;
      });
    },
  },
};
</script>

<style scoped></style>
