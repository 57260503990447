export const ORDERS_FILTER = {
  /* Form SCHEMA */
  schema: [
    {
      id: "state",
      cols: 12,
      lg: 3,
      md: 12,
      sm: 12,
      type: "select",
      options: [
        { value: "INPAYMENT", title: "در انتظار پرداخت" },
        { value: "DELIVERED", title: "سفارش موفق" },
        { value: "RETURNED", title: "مرجوع شده" },
        { value: "CANCELED", title: "لغو شده" },
        { value: "PAID", title: "پرداخت شده" },
        { value: "PICKEDUP", title: "برداشت شده" },
        { value: "MERGED", title: "مرج شده" },
        { value: "PACKAGED", title: "بسته بندی شده" },
        { value: "LOGISTIC", title: "پایان پردازش و تحویل ارسال" },
        { value: "UNDELIVERED", title: "تحویل ناموفق" },
      ],
      default_option_disabled: false,
      required: false,
    },
    {
      id: "shippingSettingId",
      cols: 12,
      lg: 3,
      md: 12,
      sm: 12,
      type: "select",
      options: [],
      default_option_disabled: false,
      required: false,
      service: "shippingMethods",
    },
    {
      id: "paymentMethod",
      cols: 12,
      lg: 3,
      md: 12,
      sm: 12,
      type: "select",
      options: [],
      default_option_disabled: false,
      required: false,
      service: "paymentMethod",
    },
    {
      id: "hasPostalTrackingCode",
      cols: 12,
      lg: 3,
      md: 12,
      sm: 12,
      type: "select",
      options: [
        { value: "true", title: "دارای کد رهگیری ارسال" },
        { value: "false", title: "کد رهگیری ارسال ثبت نشده" },
      ],
      default_option_disabled: false,
      required: false,
    },
    {
      id: "fromDate",
      cols: 12,
      lg: 3,
      md: 12,
      sm: 12,
      type: "date",
      required: false,
    },
    {
      id: "toDate",
      cols: 12,
      lg: 3,
      md: 12,
      sm: 12,
      type: "date",
      required: false,
    },
  ],

  /* Form MODEL */
  model: {
    state: "",
    shippingSettingId: "",
    hasPostalTrackingCode: "",
    paymentMethod: "",
    fromDate: "",
    toDate: "",
  },

  /* VALIDATION SCHEMA */
  validations: {
    form: {
      state: {},
      shippingSettingId: {},
      hasPostalTrackingCode: {},
      paymentMethod: {},
      fromDate: {},
      toDate: {},
    },
  },
};
