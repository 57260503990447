import api from "@/tools/Api";
import urls from "@/tools/Urls";

/*
 * return completion
 *--------------------------
 * Api address /
 *--------------------------
 * @param returnId  {Object}
 *--------------------------
 * methods : PUT
 * -------------------------
 * description :
 * -------------------------
 * return completion
 *--------------------------
 * @return promise
 *--------------------------
 */
export const returnCompletionAxiosReq = function (returnId = null) {
  return api.put(urls.api("return_completion", [returnId]));
};
