// initial state
const state = () => ({
  users: [],
  user: null,
  userComments: [],
});

// getters
const getters = {
  users: (state) => state.users,
  user: (state) => state.user,
  userComments: (state) => state.userComments,
};

// mutations
const mutations = {
  mutateUsers(state, value) {
    state.users = value;
  },
  mutateUser(state, value) {
    state.user = value;
  },
  mutateUserComments(state, value) {
    state.userComments = value;
  },
};

// actions
const actions = {
  setUsers({ commit }, data) {
    commit("mutateUsers", data);
  },

  setUser({ commit }, data) {
    commit("mutateUser", data);
  },
  setUserComments({ commit }, data) {
    commit("mutateUserComments", data);
  },
};

export default {
  namespaced: true,
  name: "users",
  state,
  getters,
  actions,
  mutations,
};
