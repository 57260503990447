<template>
  <b-container
    fluid
    class="header-bottom mx-auto d-none d-lg-flex align-items-center"
  >
    <template v-for="link in links">
      <router-link
        v-if="link.isAdmin ? isAdmin : true"
        :key="'link-' + link.title"
        :to="link.to"
        :disabled="link.isDisabled"
        active-class=" border-bottom item-active text-primary"
        class="header-bottom-items no-decorate h-100 d-flex align-items-center fs-14z"
      >
        <component :is="getIcon(link.icon)" class="fs-12 ml-2" />
        {{ $_t(`navigation_links.${link.title}`) }}
      </router-link>
    </template>
  </b-container>
</template>
<script>
import BOTTOM_HEADER_LINKS from "@/schema/linkes/bottom_header_links.schema";
import { mapGetters } from "vuex";
const icons = {};
BOTTOM_HEADER_LINKS.forEach(async (link) => {
  icons[link.icon] = () =>
    import(`@lipak/lipak-icon/src/lipak-icon/${link.icon}`);
});

export default {
  name: "HeaderBottom",
  data() {
    return {
      links: BOTTOM_HEADER_LINKS,
    };
  },
  computed: {
    ...mapGetters({
      role: "authentication/role",
    }),
    isAdmin() {
      let roles = this.role.simpleRoles.map((role) => role.title);
      const adminRole = process.env.VUE_APP_PANEL_ADMIN || "SUPPORT_ADMIN";
      return roles.some(
        (role) => role.toUpperCase() === adminRole.toUpperCase()
      );
    },
  },
  methods: {
    getIcon(name) {
      return icons[name];
    },
  },
};
</script>
<style lang="scss" scoped>
.item-active {
  border-bottom: 3px solid !important;
}
.header-bottom {
  height: 46px;
  &-items {
    border: 2px solid transparent;
    padding: 0 5px;
    margin: 0 5px;
    width: auto;
    transition: color 500ms, border 500ms;

    &:hover {
      color: #fe6a34;
      border-bottom-color: #fe6a34;
    }
    &.router-link-exact-active {
      color: #fe6a34;
      border-bottom-color: #fe6a34;
      font-weight: bold;
    }
  }
}
</style>
