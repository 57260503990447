<template>
  <div class="w-100 mt-5">
    <b-row
      no-gutters
      class="depotFilter d-flex justify-content-center mt-5 mx-3 mx-lg-0"
    >
      <!-----------------------start button of show filter------------------------------>
      <b-col cols="12" class="d-block justify-content-end">
        <b-button
          @click="isShowFilters = true"
          class="bg-primary text-white border-0 fs-13 px-4"
          size="md"
        >
          <LIWifiFlat class="ml-2 text-white" />
          {{
            $_t(
              "components.orders.total_orders.total_order_filter.filter_button"
            )
          }}
        </b-button>
      </b-col>
      <!-----------------------end button of show filter-------------------------------->

      <!------------------------start filter inputs of receipts-detail-received --------------->
      <b-row
        v-if="isShowFilters"
        class="filterBox mt-3 d-block justify-content-center w-100 pa-0 ma-0"
      >
        <b-col cols="12"
          ><LipakForm ref="bottomFilter">
            <!--Start form body    ------------------------------------------------------------------>
            <template v-slot:body>
              <!--Start form wrapper for catch errors  ------------------------>
              <LipackFormGenerator
                :schema="schema"
                :validator="$v"
              ></LipackFormGenerator>
              <!--End   form wrapper for catch errors  ------------------------>
            </template>
            <!--End   form body    ------------------------------------------------------------------>
          </LipakForm></b-col
        >

        <!-----------start button search and filter ------------------------------------>
        <b-row class="d-flex flex-row justify-content-end mt-5">
          <b-col cols="12" md="4" lg="1">
            <b-button
              class="bg-light text-dark w-100"
              size="sm"
              @click="isShowFilters = false"
            >
              {{
                $_t(
                  "components.orders.total_orders.total_order_filter.close_button"
                )
              }}
            </b-button>
          </b-col>

          <b-col cols="12" md="4" lg="1" class="mt-3 mt-lg-0">
            <b-button
              class="bg-primary text-white w-100 border-0"
              size="sm"
              @click="searchOrder"
            >
              {{
                $_t(
                  "components.orders.total_orders.total_order_filter.search_button"
                )
              }}
            </b-button>
          </b-col>
        </b-row>
        <!-----------end button search and filter -------------------------------------->
      </b-row>
      <!------------------------end filter inputs of receipts-detail-received ----------------->
    </b-row>
  </div>
</template>

<script>
import { ORDERS_FILTER } from "@/packages/orders/schema/filter/ORDERS_FILTER";
import LipackFormGenerator from "@/components/form/LipackFormGenerator";
import LipakForm from "@/components/form/LipakForm";
import { fetchFilters } from "@/packages/orders/services/filter.service";
import filterContainer from "@/mixins/filter-container";
import cloneDeep from "lodash.clonedeep";
export default {
  name: "OrderFilter",
  mixins: [filterContainer],
  components: {
    LipakForm,
    LipackFormGenerator,
  },
  data: () => {
    return {
      schema: ORDERS_FILTER.schema,
      isShowFilters: false,
      form: ORDERS_FILTER.model,
      filterName: "filter2",
    };
  },
  validations: {
    ...ORDERS_FILTER.validations,
  },
  async mounted() {
    const options = await fetchFilters();
    this.schema = this.assignOptionsToSchema(this.schema, options);
  },
  methods: {
    isSuccess(status) {
      return status === "fulfilled";
    },
    hasProperty(obj, prop) {
      return Object.hasOwn(obj, prop);
    },
    assignOptionsToSchema(schema, options) {
      let schemaWithSelectOptions = cloneDeep(schema);
      options.forEach((result) => {
        schemaWithSelectOptions.forEach((schema) => {
          if (
            this.isSuccess(result.status.toLowerCase()) &&
            this.hasProperty(result.value, schema.id)
          ) {
            schema.options = result.value[schema.id];
          }
        });
      });
      return schemaWithSelectOptions;
    },
  },
};
</script>

<style lang="scss">
.depotFilter {
  .filterButton {
    border: none;
    background: #fe6a34;
    box-shadow: 0 3px 16px -4px rgba(0, 0, 0, 0.13);
    border-radius: 4px;
  }
  .closeFilterButton {
    border: 1px solid #c3c3c3 !important;
    color: #c3c3c3;
  }
  .filterBox {
    border: 1px solid #f2f2f2;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
    padding: 12px 26px;
  }
}
.row {
  --bs-gutter-x: 0 !important;
}
</style>
