module.exports = {
  components: {
    activities_table: {
      table: {
        headers: {
          id: "ردیف",
          name: "کاربر",
          operationType: "نوع عملکرد",
          operationCode: "کد عملکرد",
          operation: "اقدام",
          requestDate: "تاریخ",
          time: "ساعت",
        },
      },
    },
    filters: {
      activities_filter: {
        filter_button: "فیلتر",
        close_button: "بستن",
        search_button: "جستجو",
      },
    },
  },
  router: {},
  views: {},

  link: {},
  table: {
    default: {
      busy_text: "در حال بارگذاری...",
      empty_text: "رکوردی برای نمایش وجود ندارد.",
      empty_filter: "رکوردی برای نمایش وجود ندارد.",
    },
  },
  attribute: {
    name: "کاربر",
    operationType: "نوع عملکرد",
    operation: "اقدام",
    from: "از تاریخ",
    to: "تا تاریخ",
    maxPaymentAllowed: "سقف سفارش (ریال)",
  },
  placeholder: {
    name: "کاربر را انتخاب کنید",
    operationType: "نوع عملکرد را انتخاب کنید",
    operation: "اقدام را انتخاب کنید",
    from: "از تاریخ",
    to: "تا تاریخ",
    maxPaymentAllowed: "سقف سفارش را تعیین کنید",
  },
};
