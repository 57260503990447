"use strict";
import { _t } from "@/tools/Utils";

export default [
  {
    path: "return-register",
    name: "return-register",
    component: () =>
      import(
        /* webpackChunkName: "return-register" */ "@/packages/return-register/views/Index"
      ),

    meta: {
      title: _t("route_titles.return_register_routes.return_register.title"),
      link: false,
    },
    redirect: { name: "return-register.detail" },
    children: [
      {
        path: ":id",
        name: "return-register.detail",

        component: () =>
          import(
            /* webpackChunkName: "return-register" */ "@/packages/return-register/views/Detail.view"
          ),
        meta: {
          title: _t(
            "route_titles.return_register_routes.return_register.details"
          ),
          link: true,
        },
      },
    ],
  },
];
