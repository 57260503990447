import { sendEmailForForgotPasswordAxiosRequest } from "@/packages/authentication/repository/api/send_email_forget_password.api";
import MessageService from "@/tools/Message";
import localStorageServiceService from "@/tools/LocalStorageService.service";

/**
 * send email for reset password
 * -----------------------
 * @param payload {Object}
 * -----------------------
 * Description
 * -----------------------
 * Make api call to send email for reset password
 * -----------------------
 * @return users {Array}
 * */
export const sendEmailForgotPasswordService = async function (payload) {
  try {
    await sendEmailForForgotPasswordAxiosRequest(payload);
    localStorageServiceService.set("forgot-email", payload.email);
    MessageService.success("SEND_EMAIL_SUCCESS");
  } catch (e) {
    //code 504
    MessageService.error("SEND_EMAIL_FAILED");
    await Promise.reject(e);
  }
};
