module.exports = {
  components: {
    orders: {
      total_orders: {
        total_order_filter: {
          filter_button: "فیلتر",
          close_button: "بستن",
          search_button: "جستجو",
        },
        total_order_table: {
          total_order_table_header: {
            row: "ردیف",
            orderDate: "تاریخ سفارش",
            code: "شماره سفارش",
            orderId: "شماره سفارش",
            user_name: "نام خریدار",
            mobileNum: "شماره موبایل",
            state: "مرحله سفارش",
            paymentType: "روش پرداخت",
            paymentStatus: "وضعیت پرداخت",
            shippingMethod: "روش ارسال",
            way_to_payment: "روش پرداخت",
            gateway: "درگاه",
            trackingCode: "کد رهگیری پرداخت",
            price: "مبلغ سفارش(ریال)",
            register_date: "تاریخ ثبت سفارش",
            return_date: "تاریخ  ثبت مرجوعی",
            customer_name: "نام خریدار",
            return_code: "کد مرجوعی",
            return_reason: "علت مرجوعی",
            return_price: "مبلغ مرجوعی(ریال)",
            detail: "عملیات",
          },
        },
      },

      detail_all_orders: {
        order_detail_tabel: {
          row: "ردیف",
          lpvName: "نام کالا",
          code: "کد کالا",
          lpvImageUrl: "تصویر کالا",
          count: "تعداد",
          color: "رنگ",
          guarantee: "گارانتی",
          price: "قیمت کالا (ریال)",
          finalPrice: "قیمت نهایی کالا (ریال)",
          total: "مجموع (ریال)",
          discount: "تخفیف (ریال)",
          serials: "شماره سریال",
        },
      },
    },
  },
  router: {},
  views: {},
  route_titles: {
    orders: {
      order_title: "سفارش ها",
      orders_detail: "جزئیات سفارش",
      orders_returned_detail: "سفارش مرجوعی",
      route_list: "لیست سفارش ها",
      all_orders: "همه ی سفارش ها",
      canceled_orders: "سفارش های لغو شده",
      returned_orders: "سفارش های مرجوع شده",
    },
  },

  attribute: {
    orderId: "شماره سفارش",
    state: "وضعیت سفارش",
    fromDate: "از تاریخ",
    toDate: "تا تاریخ",
    mobileNum: "شماره موبایل",
    ssn: "کد ملی ",
    newDate: "تاریخ جدید",
    shippingMethods: "روش ارسال",
    fromOrderDate: "از تاریخ ثبت سفارش",
    toOrderDate: "تا تاریخ ثبت سفارش",
    fromReturnedDate: "از تاریخ ثبت مرجوعی",
    toReturnedDate: "تا تاریخ ثبت مرجوعی",
    username: "کاربر",
    returnedId: "کد مرجوعی",
    addressDetails: "آدرس",
    comment: "نظرات",
    userName: "نام کاربر",
    shippingSettingId: "روش ارسال",
    paymentMethod: "روش‌های پرداخت",
    hasPostalTrackingCode: "کد رهگیری ارسال",
    isComplete: "وضعیت بسته‌شدن",
  },
  placeholder: {
    orderId: "شماره سفارش را جستجو کنید",
    mobileNum: "شماره موبایل را جستجو کنید",
    ssn: "کد ملی را جستجو کنید",
    state: " وضعیت سفارش را جستجو کنید",
    fromDate: "از تاریخ",
    toDate: "تا تاریخ",
    newDate: "تاریخ جدید را وارد کنید",
    shippingMethods: "روش ارسال را وارد کنید",
    fromOrderDate: "تاریخ را انتخاب کنید",
    toOrderDate: "تاریخ را انتخاب کنید",
    fromReturnedDate: "تاریخ را انتخاب کنید",
    toReturnedDate: "تاریخ را انتخاب کنید",
    username: "کاربر را انتخاب کنید.",
    returnedId: "کد مرجوعی را وارد کنید",
    addressDetails: "آدرس را وارد کنید",
    comment: "لطفا نظر خود را وارد کنید",
    userName: "لطفا نام کاربر را وارد کنید",
    shippingSettingId: "روش ارسال را انتخاب کنید",
    paymentMethod: "روش پرداخت را انتتخاب کنید",
    hasPostalTrackingCode: "وضعیت کد رهگیری  ارسال را انتتخاب کنید",
    isComplete: "وضعیت بسته‌شدن را انتخاب کنید",
  },
};
