import api from "@/tools/Api";
import urls from "@/tools/Urls";

/*
 *  create new legal
 *  ------------------------
 *  Api address | /api/v1/
 *  ------------------------
 *  @param
 *  ------------------------
 *  method : post
 *  ------------------------
 *  Description :
 *  ------------------------
 *  create new legal
 *  ------------------------
 *  @return Promise
 *  ------------------------
 */

export const createNewLegalAxiosRequest = function (payload, userId) {
  return api.post(urls.api("create_new_legal", [userId]), payload);
};
