<template>
  <div>
    <Items class="mt-5" />
    <Description class="mt-5 mb-4" :description="description" />
    <b-button
      v-if="state === 'SUPPORT_CONFIRMED' || state === 'RETURNED_BY_CUSTOMER'"
      :disabled="state === 'SUPPORT_CONFIRMED' && !step2Editing"
      @click="verifySupport"
      variant="success"
      class="px-5 my-5"
      >ثبت</b-button
    >
  </div>
</template>

<script>
import Items from "@/packages/orders/components/order-detail/returned-orders-detail/step1-2/items/Index";
import Description from "@/packages/orders/components/order-detail/returned-orders-detail/step1-2/Description";
import message from "@/tools/Message";
import { mapActions, mapGetters } from "vuex";
import { postReturnedOrderDetailVerifySupportAxiosRequest } from "@/packages/orders/api/returned-order-detail.api";
export default {
  name: "Index",
  components: { Items, Description },
  computed: {
    ...mapGetters({
      state: "returned/state",
      step2Editing: "returned/step2Editing",
      description: "returned/description",
      items: "returned/items",
    }),
  },
  methods: {
    ...mapActions({
      setStep2Editing: "returned/setStep2Editing",
      setReturnedOrderDetail: "returned/setReturnedOrderDetail",
    }),
    validateVerifySupportForm() {
      let isValid = true;
      for (const item of this.items) {
        if (!["APPROVED", "REJECTED"].includes(item.status)) {
          isValid = false;
          message.error("وضعیت مرجوعی را مشخص کنید");
          break;
        }
        if (item.status === "REJECTED" && !item.rejectReasonId) {
          isValid = false;
          message.error("دلیل رد مرجوعی را مشخص کنید");
          break;
        }
      }
      return isValid;
    },
    async verifySupport() {
      const isValid = this.validateVerifySupportForm();
      if (!isValid) return;
      this.setStep2Editing(false);
      const body = {
        description: this.description,
        items: this.items.map((item) => ({
          id: item.id,
          status: item.status,
          rejectReasonId: item.rejectReasonId,
          rejectDescription: item.rejectDescription,
        })),
      };
      try {
        await postReturnedOrderDetailVerifySupportAxiosRequest(
          this.$route.params.id,
          body
        );
        this.setStep2Editing(false);
        if (this.state === "RETURNED_BY_CUSTOMER") {
          await this.setReturnedOrderDetail(this.$route.params.id);
        }
      } catch (err) {
        console.log(err);
        message.error("مشکلی پیش آمده");
      }
    },
  },
};
</script>

<style scoped></style>
