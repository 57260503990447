import { _t } from "@/tools/Utils";

export const TOTAL_ORDER_TABLE_LIST_HEADER = [
  {
    key: "row",
    label: _t(
      "components.orders.total_orders.total_order_table.total_order_table_header.row"
    ),
    sortable: false,
    align: "center",
  },
  {
    key: "orderDate",
    label: _t(
      "components.orders.total_orders.total_order_table.total_order_table_header.orderDate"
    ),
    sortable: true,
  },
  {
    key: "code",
    label: _t(
      "components.orders.total_orders.total_order_table.total_order_table_header.code"
    ),
    sortable: false,
    class: "col-1",
  },
  {
    key: "buyer",
    label: _t(
      "components.orders.total_orders.total_order_table.total_order_table_header.user_name"
    ),
    sortable: false,
    class: "col-1",
  },
  {
    key: "mobileNum",
    label: _t(
      "components.orders.total_orders.total_order_table.total_order_table_header.mobileNum"
    ),
    sortable: false,
  },
  {
    key: "state",
    label: _t(
      "components.orders.total_orders.total_order_table.total_order_table_header.state"
    ),
    sortable: false,
    class: "col-2",
  },
  {
    key: "paymentType",
    label: _t(
      "components.orders.total_orders.total_order_table.total_order_table_header.paymentType"
    ),
    sortable: false,
    class: "col-1",
  },
  {
    key: "paymentStatus",
    label: _t(
      "components.orders.total_orders.total_order_table.total_order_table_header.paymentStatus"
    ),
    sortable: false,
    class: "col-1",
  },
  {
    key: "shippingMethod",
    label: _t(
      "components.orders.total_orders.total_order_table.total_order_table_header.shippingMethod"
    ),
    sortable: false,
    class: "col-2",
  },
  {
    key: "shippingMethod",
    label: _t(
      "components.orders.total_orders.total_order_table.total_order_table_header.shippingMethod"
    ),
    sortable: false,
  },
  {
    key: "gateway",
    label: _t(
      "components.orders.total_orders.total_order_table.total_order_table_header.gateway"
    ),
    sortable: false,
  },
  {
    key: "trackingCode",
    label: _t(
      "components.orders.total_orders.total_order_table.total_order_table_header.trackingCode"
    ),
    sortable: false,
  },
  {
    key: "price",
    label: _t(
      "components.orders.total_orders.total_order_table.total_order_table_header.price"
    ),
    sortable: false,
  },
  {
    key: "detail",
    label: _t(
      "components.orders.total_orders.total_order_table.total_order_table_header.detail"
    ),
    sortable: false,
  },
];
