import api from "@/tools/Api";
import urls from "@/tools/Urls";

/*
 *  update baseInformation user
 *  ------------------------
 *  Api address | /api/v1/user-manage/detail/:user_id
 *  ------------------------
 *  @param payload { object }
 *  @param user_id { string }
 *  ------------------------
 *  method : put
 *  ------------------------
 *  Description :
 *  ------------------------
 *   update baseInformation user
 *  ------------------------
 *  @return Promise
 *  ------------------------
 */

export const updateBaseUserInformationAxiosRequest = function (
  patyload,
  user_id
) {
  return api.put(urls.api("update_base_info_user", [user_id]), patyload);
};
