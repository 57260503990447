import api from "@/tools/Api";
import urls from "@/tools/Urls";

/*
 * get all users activities
 *--------------------------
 * Api address /api/v1/user_activity
 *--------------------------
 * @param query_params  {Object}
 *--------------------------
 * methods : GET
 * -------------------------
 * description :
 * -------------------------
 * get all users activities
 *--------------------------
 * @return promise
 *--------------------------
 */
export const fetchAllUsersActivitiesAxiosReq = function (
  query_params = null,
  cancelToken
) {
  let query_string = clean(query_params);
  return api.get(urls.api("user_activities"), {
    params: {
      panel: process.env.VUE_APP_PANEL_SECRET || "support",
      ...query_string,
    },
    ...cancelToken,
  });
};

function clean(obj) {
  for (var propName in obj) {
    if (
      obj[propName] === null ||
      obj[propName] === undefined ||
      obj[propName] === ""
    ) {
      delete obj[propName];
    } else if (propName === "from" || propName === "to") {
      obj[propName] = obj[propName].substring(0, 10);
    }
  }
  return obj;
}
