export default {
  fetch_all_orders: "order/support/all",
  fetch_canceled_orders: "order/support/all/canceled",
  fetch_returned_orders: "order/supportAll/returned",
  fetch_order_detail_by_order_id: "order/support/order-details/%s",
  fetch_addreses: "order/support/customer/%s/addresses",
  create_addreses: "order/support/customer/%s/addresses/createAddress",
  accept_payment: "payment/approvePaymentCheck/%s",
  fetch_address: "order/support/customer/addressShippingMethods/%s",
  fetch_orders_returned_customers: "customer-manage/by-name",
  fetch_sending_times_by_shipping_methods_id:
    "order/card/second-state/all-shipping/shift/%s",
  fetch_shipping_methods_by_city_id: "order/card/second-state/all-shipping/%s",
  update_address_and_shippingMethod:
    "order/support/customer/updateOrderShipment/%s/%s",
  update_address_and_shippingMethod_in_undelivered_state:
    "order/editUndeliveredOrderShipment/%s/%s",
  fetch_state_detail: "order/support/order/getStateFlow/%s",
  // cencele_order: "order/state/update/%s",
  cencele_order: "order/support/cancel/%s",

  returned_order_rejected_reason: "order/return/recordRejectReason/all",
  returned_order_detail: "order/supportAll/returned/%s",
  returned_order_back_to_customer:
    "order/supportAll/returned/backToCustomer/%s",
  submit_delivery: "order/logistic/setOrderAsDelivered/%s",
  fetch_province: "contents/provinces",
  fetch_cities: "contents/provinces/%s",
  submit_comment_by_suport_admin: "order/addNewOrderComment",
  fetch_shipping_method_list: "shipping-method",
  fetch_order_detail_for_factore: "order/customer/saleInvoice/%s",

  fetch_payment_methods: "paymentMethod/all",
  return_completion: "order/supportAll/returned/complete/%s",

  change_payment_method_to_receipt: "order/customer/payment-method-switch",
};
