<template>
  <b-container fluid class="px-0 py-0">
    <b-row no-gutters>
      <b-col cols="12" class="px-0">
        <b-col cols="12" class="text-center bg-header-table py-2 w-100">
          <div class="font-weight-bold w-100">مشخصات کالا یا خدمات</div>
        </b-col>
        <table
          width="100%"
          cellpadding="0"
          cellspacing="0"
          class="ss02 fs-11 font-weight-bold"
        >
          <thead>
            <tr>
              <td
                class="text-center border-external writing-mode py-2"
                width="2%"
              >
                <span> ردیف </span>
              </td>
              <td class="text-center border-external py-2" width="5%">
                <span> کد کالا </span>
              </td>
              <td class="text-center border-external py-2" width="20%">
                <span> شرح کالا یا خدمات </span>
              </td>
              <td class="text-center border-external py-2" width="5%">
                <span> تعداد </span>
              </td>
              <td class="text-center border-external py-2" width="5%">
                <span> واحد </span>
              </td>
              <td class="text-center border-external py-2" width="8%">
                <span> شناسه سریال </span>
              </td>
              <td class="text-center border-external py-2" width="8%">
                <span>
                  مبلغ واحد <br />
                  (ریال)
                </span>
              </td>
              <td class="text-center border-external py-2" width="8%">
                <span class="   ">
                  مبلغ کل<br />
                  (ریال)
                </span>
              </td>
              <td class="text-center border-external py-2" width="8%">
                <span class=""> مبلغ تخفیف </span>
              </td>
              <td class="text-center border-external py-2" width="8%">
                <span class="">
                  مبلغ کل پس از تخفیف<br />
                  (ریال)
                </span>
              </td>
              <td class="text-center border-external py-2" width="9%">
                <span class="">
                  جمع مالیات و عوارض <br />
                  (ریال)
                </span>
              </td>
              <td class="text-center border-external py-2" width="10%">
                <span class=""> جمع مبلغ کل <br />(ریال) </span>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in orderDetail.details.items" :key="index">
              <td class="text-center border-external p-2">
                {{ index + 1 }}
              </td>
              <td class="text-center border-external p-2">{{ item.lpv }}</td>
              <td class="text-center border-external p-2">
                {{ item.title }}
              </td>
              <td class="text-center border-external p-2">
                {{ item.count }}
              </td>
              <td class="text-center border-external p-2">
                {{ item.unit }}
              </td>

              <td class="text-center border-external p-2">
                <template v-if="item.serials.length > 0">
                  <div v-for="serial in item.serials" :key="serial.index">
                    {{ serial }}
                  </div></template
                >
                <div v-else>-</div>
              </td>
              <td class="text-center border-external p-2">
                {{ item.unitPrice ? $_currency(Number(item.unitPrice)) : "-" }}
              </td>
              <td class="text-center border-external p-2">
                {{
                  item.totalPrice ? $_currency(Number(item.totalPrice)) : "-"
                }}
              </td>
              <td class="text-center border-external p-2">
                {{
                  item.totalDiscountAmount
                    ? $_currency(Number(item.totalDiscountAmount))
                    : "-"
                }}
              </td>
              <td class="text-center border-external p-2">
                {{
                  item.totalAmountAfterDiscount
                    ? $_currency(Number(item.totalAmountAfterDiscount))
                    : "-"
                }}
              </td>
              <td class="text-center border-external p-2">
                {{
                  item.taxAndCharge
                    ? $_currency(Number(item.taxAndCharge))
                    : "-"
                }}
              </td>
              <td class="text-center border-external p-2">
                <!--                {{-->
                <!--                  item.totalPrice-->
                <!--                    ? $_currency(item.totalPrice * item.count)-->
                <!--                    : "-"-->
                <!--                }}-->
                {{
                  item.totalAmountWithTaxAndAmount
                    ? $_currency(Number(item.totalAmountWithTaxAndAmount))
                    : "-"
                }}
              </td>
            </tr>
            <tr>
              <td class="text-center border-external p-2" colspan="9"></td>
              <td class="text-center border-external p-2" colspan="2">
                جمع کل قابل پرداخت:
              </td>
              <td class="text-center border-external p-2 ss0">
                {{
                  $_currency(
                    Number(orderDetail.details.sum.totalAmountWithTaxAndAmount)
                  )
                }}
              </td>
            </tr>
          </tbody>

          <caption class="caption-bottom border-external text-right py-4 px-2">
            جمع کل قابل پرداخت :
            {{
              numbersToLetters(
                orderDetail.details.sum.totalAmountWithTaxAndAmount
              )
            }}
            ریال
          </caption>
          <caption class="caption-bottom border-external text-right py-3 px-2">
            <b-row class="d-flex justify-start justify-start align-center">
              <b-col cols="2"> شرایط و نحوه فروش : </b-col>

              <b-col cols="2">
                غیرنقدی
                <!--                <LITimesCircle class="icon-size" />-->
              </b-col>
              <b-col cols="2">
                نقدی
                <LICheckCircle class="icon-size" />
              </b-col>
            </b-row>
          </caption>
          <caption class="caption-bottom border-external text-right py-4 px-2">
            توضیحات :
          </caption>
        </table>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Num2persian from "num2persian";
export default {
  name: "OrderItemTabel",
  props: {
    orderDetail: {
      type: Object,
      required: true,
    },
  },
  methods: {
    numbersToLetters(num) {
      return Num2persian(Number(num));
    },
  },
};
</script>

<style scoped>
.order-table {
  border: 2px solid red;
}
</style>
