<template>
  <b-card no-body class="bg-white shadow rounded-lg mt-3">
    <b-row class="m-0 fa-num-font">
      <b-col cols="12" class="py-5 px-5">
        <!----------------start of skeletonLoader --------------------------->
        <b-skeleton-table
          :rows="7"
          :columns="7"
          v-if="skeletonLoader"
          no-aspect
          height="96px"
          class="mt-3"
        ></b-skeleton-table>
        <!----------------end of skeletonLoader ----------------------------->

        <!----------------start content (tabel and pagination) -------------->
        <template v-else>
          <!--------------start table of receipts-detail received------>
          <ReturnedOrdersTable />

          <!--------------end table of receipts-detail received-------->
        </template>
        <!----------------end content (tabel and pagination) ---------------->

        <!--------------Start pagination ------------------------------------>
        <Pagination
          v-show="!skeletonLoader"
          v-model="paginationParams"
          key="product-pagination"
          class="mt-5"
        />
        <!--------------End pagination -------------------------------------->
      </b-col>
    </b-row></b-card
  >
</template>

<script>
import ReturnedOrdersTable from "./ReturnedOrdersTable";
import Pagination from "@/components/app/Pagination";
import OrderUser from "@/packages/users/services/user.orders.service";
import { mapGetters } from "vuex";

export default {
  name: "Returned",
  data: () => {
    return {
      paginationParams: null,
      OrderUser: new OrderUser(),
      skeletonLoader: false,
    };
  },
  components: { ReturnedOrdersTable, Pagination },
  watch: {
    paginationParams: {
      deep: true,
      handler(newValue, oldValue) {
        if (oldValue) {
          if (
            oldValue.pageSize !== newValue.pageSize ||
            oldValue.pageNumber !== newValue.pageNumber
          )
            this.initialOrders();
        } else {
          this.initialOrders();
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      orders: "orders/orders",
    }),
  },

  methods: {
    async initialOrders() {
      try {
        this.skeletonLoader = true;
        await this.OrderUser.fetchOrders(
          this.$route.meta.state,
          this.$route.params.user_id,
          {
            ...this.paginationParams,
          }
        );
      } catch (e) {
        console.log(e);
      }
      this.skeletonLoader = false;
    },
  },
};
</script>
