import api from "@/tools/Api";
import urls from "@/tools/Urls";

/*
 *  submit answer
 *  ------------------------
 *  Api address |/api/v1/product_comment
 *  ------------------------
 *  @param payload { Object }
 *  ------------------------
 *  method : post
 *  ------------------------
 *  Description :
 *  ------------------------
 *  fetch  comments
 *  ------------------------
 *  @return Promise
 *  ------------------------
 */

export const submitAnswerAxiosRequest = function (payload) {
  return api.post(urls.api("product_comment"), payload);
};
