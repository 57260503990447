module.exports = {
  router: {},
  views: {},
  route_titles: {
    return_register_routes: {
      return_register: {
        title: "ثبت مرجوعی",
        details: "ثبت مرجوعی برای سفارش",
      },
    },
  },
  attribute: {
    returnReason: "دلیل مرجوعی",
    description: "توضیحات مرجوعی",
  },
  placeholder: {
    returnReason: " دلیل مرجوعی را انتخاب کنید",
    description: " توضیحات مرجوعی را وارد کنید",
  },
};
