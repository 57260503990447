import { render, staticRenderFns } from "./Description.vue?vue&type=template&id=11a89a56&scoped=true&"
import script from "./Description.vue?vue&type=script&lang=js&"
export * from "./Description.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11a89a56",
  null
  
)

export default component.exports