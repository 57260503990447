<template>
  <LipakModal
    @close="$emit('close')"
    :modal="modal"
    :title="
      $_t(
        'components.users.user.profile.bank_information.modal.edit_bank_information_modal.title'
      )
    "
    size="md"
  >
    <template v-slot:body>
      <b-row>
        <b-col cols="12">
          <BankNameBasedOnBankCardNumber :cart-number="form.cartNumber" />
        </b-col>
      </b-row>
      <b-col cols="12" class="mx-auto fa-num-font">
        <LipakForm>
          <!--Start form body    ------------------------------------------------------------------>
          <template v-slot:body>
            <!--Start form wrapper for catch errors  ------------------------>
            <LipackFormGenerator
              lg="6"
              :schema="schema"
              :validator="$v"
            ></LipackFormGenerator>
            <!--End   form wrapper for catch errors  ------------------------>
          </template>
          <!--End   form body    ------------------------------------------------------------------>

          <!--Start form actions ------------------------------------------------------------------>
          <template v-slot:action>
            <b-col cols="=12" lg="4" md="4" sm="6">
              <b-form-group class="form-group inputBox">
                <b-button
                  variant="outline-secondary"
                  class="w-100"
                  @click="$emit('close')"
                  :disabled="is_loading"
                >
                  {{
                    $_t(
                      "components.users.user.profile.bank_information.modal.edit_bank_information_modal.buttons.close"
                    )
                  }}
                </b-button>
              </b-form-group>
            </b-col>
            <b-col cols="=12" lg="4" md="4" sm="6">
              <b-form-group class="form-group inputBox">
                <b-button
                  variant="success"
                  class="w-100"
                  @click="submit"
                  :disabled="is_loading || $v.$error"
                >
                  <b-spinner
                    v-if="is_loading"
                    small
                    variants="success"
                  ></b-spinner>
                  {{
                    $_t(
                      "components.users.user.profile.bank_information.modal.edit_bank_information_modal.buttons.submit_text"
                    )
                  }}
                </b-button>
              </b-form-group>
            </b-col>
          </template>
          <!--End   form actions ------------------------------------------------------------------>
        </LipakForm>
      </b-col>
    </template>
    <template v-slot:footer>
      <b-row class="w-100 flex-row-reverse"> </b-row>
    </template>
  </LipakModal>
</template>

<script>
import { FORM_EDIT_SCHEMA } from "@/packages/users/schema/forms/edit.user.bank_information.schema";
import LipakModal from "@/components/app/Modal/LipakModal";
import LipackFormGenerator from "@/components/app/form/LipackFormGenerator";
import LipakForm from "@/components/app/form/LipakForm";
import { mapGetters } from "vuex";
import Profile from "@/packages/users/services/profile";
import BankNameBasedOnBankCardNumber from "@/packages/users/components/user/profile/bank-information/BankNameBasedOnBankCardNumber.vue";
export default {
  name: "EditBankInformationModal",
  components: {
    BankNameBasedOnBankCardNumber,
    LipakModal,
    LipackFormGenerator,
    LipakForm,
  },
  data: function () {
    return {
      Profile: new Profile(),
      schema: FORM_EDIT_SCHEMA.schema,
      form: { ...FORM_EDIT_SCHEMA.model },
    };
  },
  validations: {
    ...FORM_EDIT_SCHEMA.validations,
  },

  props: {
    modal: {
      type: Boolean,
      default: false,
    },
    bankDetails: {
      type: Object,
      required: true,
    },
  },
  watch: {
    bankDetails: {
      handler: function (bankDetails) {
        this.form.cartNumber = bankDetails.cartNumber.value;
        this.form.shebaNumber = bankDetails.shebaNumber.value;
        this.form.accountOwner = bankDetails.accountOwner.value || "";
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({
      is_loading: "loading/is_loading",
    }),
  },

  methods: {
    async submit() {
      await this.Profile.updateBankAccountDetail(
        this.form,
        this.$route.params.user_id
      );
      await this.Profile.fetchUserPersonalInfoById(this.$route.params.user_id);
      this.$emit("close");
    },
  },
};
</script>

<style scoped></style>
