<template>
  <ReturnReason />
</template>

<script>
import ReturnReason from "@/packages/manage/components/return-reason/Index";
export default {
  components: { ReturnReason },
};
</script>

<style scoped></style>
