"use static";
import { _t } from "@/tools/Utils";
export default [
  {
    path: "orders",
    name: "dashboard.orders",
    component: () => import("@/packages/orders/views/Index"),
    meta: {
      title: _t("route_titles.orders.order_title"),
      link: true,
    },
    redirect: { name: "orders.list" },
    children: [
      {
        path: "list",
        name: "orders.list",
        component: () => import("@/packages/orders/views/Orders"),
        meta: {
          title: _t("route_titles.orders.route_list"),
          link: false,
        },
        redirect: { name: "list.all-orders" },
        children: [
          {
            path: "all-orders",
            name: "list.all-orders",
            component: () =>
              import(
                "@/packages/orders/components/orders/all-orders/AllOrders"
              ),
            meta: {
              title: _t("route_titles.orders.all_orders"),
              link: true,
            },
          },
          {
            path: "canceled-orders",
            name: "list.canceled-orders",
            component: () =>
              import(
                "@/packages/orders/components/orders/canceled-orders/CanceledOrders"
              ),
            meta: {
              title: _t("route_titles.orders.canceled_orders"),
              link: true,
            },
          },
          {
            path: "returned-orders",
            name: "list.returned-orders",
            component: () =>
              import(
                "@/packages/orders/components/orders/returned-orders/ReturnedOrders"
              ),
            meta: {
              title: _t("route_titles.orders.returned_orders"),
              link: true,
            },
          },
        ],
      },
      {
        path: "order",
        name: "list.order",
        component: () => import("@/packages/orders/views/OrderDetail"),
        meta: {
          title: _t("route_titles.orders.orders_detail"),
        },
        children: [
          {
            path: "detail-all-order/:id/:type",
            name: "order.detail-all-order",
            component: () =>
              import(
                "@/packages/orders/components/order-detail/all-order-detail/AllOrderDetail"
              ),
            meta: {
              title: _t("route_titles.orders.orders_detail"),
              link: true,
            },
          },
          {
            path: "detail-canceled-order/:id/:type",
            name: "order.detail-canceled-order",
            component: () =>
              import(
                "@/packages/orders/components/order-detail/canceled-order-detail/CanceledOrderDetail"
              ),
            meta: {
              title: _t("route_titles.orders.orders_detail"),
              link: true,
            },
          },
          {
            path: "detail-returned-order/:id",
            name: "order.details-returned-orders",
            component: () =>
              import(
                "@/packages/orders/components/order-detail/returned-orders-detail/Index"
              ),
            meta: {
              title: _t("route_titles.orders.orders_returned_detail"),
              link: true,
            },
          },
        ],
      },
    ],
  },
];
