import {
  fetchShippingMethodListAxiosReq,
  fetchPaymentMethodsAxiosReq,
} from "@/packages/orders/api";
import message from "@/tools/Message";

const shippingMethodsWrapper = function (shippingMethods) {
  return shippingMethods.map((item) => {
    return {
      title: item.displayName,
      value: item.id,
    };
  });
};
const paymentMethodsWrapper = function (paymentMethods) {
  return paymentMethods.map((item) => {
    return {
      title: item.persianName,
      value: item.paymentMethod,
    };
  });
};

/*
 * fetch shipping methods
 * -----------------------
 * @param cityId {String}
 * -----------------------
 * Description
 * -----------------------
 * Make api call to shipping methods
 * -----------------------
 * return promise
 * */
export const fetchShippingMethods = async function () {
  try {
    /* call api to fetch shipping methods */
    let shippingMethods = await fetchShippingMethodListAxiosReq();
    return Promise.resolve({
      shippingSettingId: shippingMethodsWrapper(shippingMethods),
    });
  } catch (err) {
    await message.error(err);
    await Promise.reject(err);
  }
};

/*
 * fetch shipping methods
 * -----------------------
 * @param cityId {String}
 * -----------------------
 * Description
 * -----------------------
 * Make api call to shipping methods
 * -----------------------
 * return promise
 * */
export const fetchPaymentMethods = async function () {
  try {
    /* call api to fetch shipping methods */
    let shippingMethods = await fetchPaymentMethodsAxiosReq();
    return Promise.resolve({
      paymentMethod: paymentMethodsWrapper(shippingMethods),
    });
  } catch (err) {
    await message.error(err);
    await Promise.reject(err);
  }
};

/**
 *  fetch all filter options as parallel promise
 *  ------------------------
 *  Description| fetch all filter as options parallel promise
 *  ------------------------
 *  @return  type {Promise}
 *  ------------------------
 **/
// async fetchFilters() {
//   try {
//     return Promise.allSettled([
//       this.fetchShippingMethods(),
//       // fetchOperationTypesService(),
//     ]);
//   } catch (error) {
//     return Promise.reject(error);
//   }
// }

export const fetchFilters = function () {
  try {
    return Promise.allSettled([fetchShippingMethods(), fetchPaymentMethods()]);
  } catch (error) {
    return Promise.reject(error);
  }
};
