import api from "@/tools/Api";
import urls from "@/tools/Urls";

/*
 *   update bankAccountDetail user
 *  ------------------------
 *  Api address | /api/v1/user-manage/bank/:user_id
 *  ------------------------
 *  @param payload { object }
 *  @param user_id { string }
 *  ------------------------
 *  method : Get
 *  ------------------------
 *  Description :
 *  ------------------------
 *   update bankAccountDetail user
 *  ------------------------
 *  @return Promise
 *  ------------------------
 */

export const updateBankAccountDetailAxiosRequest = function (payload, user_id) {
  return api.put(urls.api("update_bank_account_detail", [user_id]), payload);
};
