import api from "@/tools/Api";
import urls from "@/tools/Urls";

/*
 *  submit delivery
 *  ------------------------
 *  Api address | /api/v1/order/logistic/setOrderAsDelivered/:order-id
 *  ------------------------
 *  @param orderId {String}
 *  ------------------------
 *  method : Put
 *  ------------------------
 *  Description :
 *  ------------------------
 *  submit delivery
 *  ------------------------
 *  @return Promise
 *  ------------------------
 */

export const submitDeliveryAxiosRequest = function (orderId) {
  return api.put(urls.api("submit_delivery", [orderId]));
};
