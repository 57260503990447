import { _t } from "@/tools/Utils";
export const USER_ORDER_TABEL_HEEADER = [
  {
    key: "row",
    label: _t("components.users.user.orders.orders_user_tabel.row"),
    sortable: false,
    class: "text-center",
  },
  {
    key: "code",
    label: _t("components.users.user.orders.orders_user_tabel.code"),
    sortable: false,
    class: "text-center",
  },
  {
    key: "price",
    label: _t("components.users.user.orders.orders_user_tabel.price"),
    sortable: false,
    class: "text-center",
  },

  {
    key: "date",
    label: _t("components.users.user.orders.orders_user_tabel.date"),
    sortable: false,
    class: "text-center",
  },
  {
    key: "state",
    label: _t("components.users.user.orders.orders_user_tabel.state"),
    sortable: false,
    class: "text-center",
  },
  {
    key: "paymentStatus",
    label: _t("components.users.user.orders.orders_user_tabel.payment"),
    sortable: false,
    class: "text-center",
  },
  {
    key: "actions",
    label: _t("components.users.user.orders.orders_user_tabel.actions"),
    sortable: false,
    class: "text-center",
  },
];
