module.exports = {
  key: "change-password",
  "change-password": {
    components: {},
    views: {},
    route_titles: {},
  },
  attribute: {},
  placeholder: {},
};
