import api from "@/tools/Api";
import urls from "@/tools/Urls";

/*
 *  approve a comment
 *  ------------------------
 *  Api address | /api/v1/comments
 *  ------------------------
 *  @param payload { Object }
 *  ------------------------
 *  method : Get
 *  ------------------------
 *  Description : call api for approve a comment
 *  ------------------------
 *  fetch  comments
 *  ------------------------
 *  @return Promise
 *  ------------------------
 */

export const approveCommentAxiosRequest = function (payload) {
  return api.post(urls.api("change_status_comment"), payload);
};
