<template>
  <b-card
    class="bg-grey-light text-start d-flex justify-content-start border-0 card-rounded p-lg-3 mt-4 fa-num-font"
  >
    <div
      class="d-flex justify-content-start align-items-center text-more font-weight-bold mt-2 my-2 fs-18"
    >
      <p
        class="bg-primary rounded-pill ml-2 mt-2"
        style="width: 10px; height: 10px"
      ></p>
      اطلاعات حقوقی
    </div>
    <b-row class="list-info d-lg-flex list-unstyled m-0 mt-lg-4">
      <template v-if="innerLegalDetail">
        <b-col
          v-for="item in innerLegalDetail"
          class="text-right d-flex d-lg-block pt-4 pt-lg-0 px-0 px-lg-3 pb-lg-5"
          :cols="item.col"
          :md="item.md"
          :lg="item.lg"
          :xl="item.xl"
          :key="item.index"
        >
          <div
            class="d-flex align-items-center fs-15 font-weight-bold text-secondary pb-2 pl-1 pl-lg-0"
          >
            {{ item.title }}:
          </div>

          <div class="fs-17 font-weight-bold text-secondary pb-2 pt-lg-2">
            {{ item.value }}
          </div>
        </b-col>
      </template>
      <b-col v-else cols="12" class="text-center py-3">
        <span class="fs-15 font-weight-bold text-primary">
          اطلاعات حقوقی ثبت نشده است !
        </span>
      </b-col>
    </b-row></b-card
  >
</template>

<script>
export default {
  name: "LegalInfo",
  props: {
    legalDetail: {
      type: Object,
      require: true,
    },
  },
  computed: {
    innerLegalDetail() {
      if (!this.legalDetail) return;
      return [
        {
          title: "نام شرکت",
          value: this.legalDetail.companyName,
          col: "12",
          md: "4",
          lg: "6",
          xl: "3",
        },
        {
          title: "شماره ثبت",
          value: this.legalDetail.registerNumber,
          col: "12",
          md: "4",
          lg: "6",
          xl: "3",
        },
        {
          title: "شناسه ملی",
          value: this.legalDetail.nationalId,
          col: "12",
          md: "4",
          lg: "6",
          xl: "3",
        },
      ];
    },
  },
};
</script>

<style scoped></style>
