export const MOBILE_EMAIL_SEARCH_FILTER_SCHEMA = {
  /* Form SCHEMA */
  schema: [
    {
      id: "search_text",
      label: "search_text",
      cols: 12,
      lg: 12,
      md: 12,
      sm: 12,
      type: "text",
      required: true,
      placeholder: "mobile_email_text_search",
    },
  ],

  /* Form MODEL */
  model: {
    search_text: "",
  },

  /* VALIDATION SCHEMA */
  validations: {},
};
