module.exports = {
  components: {
    comments: {
      comments: {
        filters: {
          comments_filters: {
            buttons: {
              filter: "فیلتر",
              submit: "جستجو",
              close: "بستن",
            },
          },
        },
        comments_table: {
          title: "لیست نظرات",
          table: {
            headers: {
              id: "ردیف",
              full_name: "نام و نام خانوادگی کاربر",
              phone: "موبایل",
              product_name: "نام کالا",
              comment: "متن نظر",
              createdAt: "تاریخ ثبت نظر",
              status: "وضعیت",
              actions: "عملیات",
            },
            buttons: {
              detail_text: "جزئیات",
            },
          },
        },
      },
    },
  },
  router: {},
  views: {},
  route_titles: {
    comments_routes: {
      comments: {
        title: "نظرات",
        list: "نظرات",
      },
    },
  },
  attribute: {
    customerMobile: "",
    productGroup: "گروه کالا",
    brand: "برند کالا",
    model: "مدل کالا",
    config: "کانفیگ کالا",
    from: "از تاریخ",
    to: "تا تاریخ",
    status: "وضعیت",
    content: "متن پاسخ",
    title: "عنوان پاسخ",
  },
  placeholder: {
    customerMobile: "نام کالا یا موبایل کاربر را جستجو کنید.",
    productGroup: "گروه کالا را انتخاب کنید.",
    brand: "برند کالا را انتخاب کنید.",
    model: "مدل کالا را انتخاب کنید.",
    config: "کانفیگ کالا را انتخاب کنید.",
    from: "از تاریخ",
    to: "تا تاریخ",
    status: "وضعیت را انتخاب کنید.",
    content: "متن پاسخ را وارد کنید",
    title: "عنوان پاسخ را وارد کنید",
  },
};
