<template>
  <b-form-group
    :label="title + '*'"
    label-for="currency-formatter"
    :description="
      isMaxPriceExist ? `حداکثر قیمت: ${this.$_currency(maxPrice)}` : ''
    "
    class="mb-0"
    label-class="lipak-input-title mb-0"
  >
    <b-form-input
      id="currency-formatter"
      class="lipak-input-main"
      v-currency="options"
      :placeholder="placeHolder"
      v-model="value"
      size="lg"
    ></b-form-input>
  </b-form-group>
</template>

<script>
import { CurrencyDirective, parse } from "vue-currency-input";
export default {
  name: "LipakCurrencyInput",
  directives: {
    currency: CurrencyDirective,
  },
  props: {
    placeHolder: {
      type: String,
      default: "مقدار را وارد کنید.",
    },
    title: {
      type: String,
      default: "",
    },
    range: {
      type: Object,
      default: () => {
        return { min: 0 };
      },
    },
    currentPrice: {
      type: [String, Number],
    },
    maxPrice: {
      type: [String, Number],
    },
  },
  data() {
    return {
      value: 0,
      hideNegligibleDecimalDigits: false,
      hideCurrencySymbol: true,
      hideGroupingSymbol: false,
    };
  },
  watch: {
    value: function (value) {
      let numberValue = parse(value, this.options);
      this.$emit("input", numberValue);
    },
  },
  computed: {
    isMaxPriceExist() {
      return this.maxPrice > 0;
    },
    options() {
      return {
        locale: "fa",
        currency: "IRR",
        valueAsInteger: true,
        distractionFree: {
          hideNegligibleDecimalDigits: false,
          hideCurrencySymbol: true,
          hideGroupingSymbol: false,
        },
        precision: 0,
        autoDecimalMode: true,
        valueRange: { min: 0 },
        allowNegative: false,
      };
    },
  },
  created() {
    this.value = this.currentPrice;
  },
};
</script>
