import api from "@/tools/ApiService.service";
import urls from "@/tools/Urls";
/**
 *  change password
 *  ------------------------
 *  Api address| /stuff/change/password
 *  ------------------------
 *  Method| PATCH
 *  ------------------------
 *  Description| change password
 *  ------------------------
 *  @param payload {Object}
 *  ------------------------
 *  @return  type {Promise}
 *  ------------------------
 **/
export const changePasswordAxiosRequest = function (payload) {
  return api.get(urls.api("auth_change_password"), {
    headers: {
      input_password: payload.input_password,
      password: payload.password,
    },
  });
};
