export const ACTIVITIES_FILTERS_SCHEMA = {
  schema: [
    {
      cols: "12",
      lg: "3",
      id: "name",
      placeholder: "name",
      options: [],
      default_option_disabled: false,
      type: "select",
    },
    {
      cols: "12",
      lg: "3",
      id: "operationType",
      placeholder: "operationType",
      default_option_disabled: false,
      options: [],
      type: "select",
    },
    {
      cols: "12",
      lg: "3",
      id: "operation",
      placeholder: "operation",
      type: "select",
      default_option_disabled: false,
      options: [
        { title: "ایجاد", value: "POST" },
        { title: "حذف", value: "DELETE" },
        { title: "خواندن", value: "GET" },
        { title: "به روز رسانی", value: "PUT,PATCH" },
      ],
    },
    {
      cols: "12",
      lg: "3",
      id: "from",
      placeholder: "from",
      type: "date",
    },
    {
      cols: "12",
      lg: "3",
      id: "to",
      placeholder: "to",
      type: "date",
    },
  ],
  model: {
    name: "",
    operationType: "",
    operation: "",
    from: "",
    to: "",
  },
  validations: {
    form: {
      name: {},
      operationType: {},
      operation: {},
      from: {},
      to: {},
    },
  },
};
